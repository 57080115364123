function influencerSearchKeywords(event) {
  event.preventDefault();
  var enteredInput = $("input[name='search']").val();
  var postText = enteredInput.split(",")

  var enteredText = $(".bootstrap-tagsinput").text();
  var checkData = enteredText.trim();
  if (checkData.length <= 2) {
    alert("Please enter atleast 3 letters for search text");
    return
  }
  var preText = enteredText.split("   ");
  preText.pop();
  preText = preText

  var enterparams = $("#influencerSearch").val();

  var textArray = $.merge($.merge([], preText), postText);

  var textArrayTrim = []
  for (var i = 0; i < textArray.length; i++) {
    var trimtext = textArray[i].trim()
    if (trimtext.length > 2) {
      textArrayTrim.push(textArray[i].trim())
    }
  }
  var url = document.location.href;
  var paramArray = splitIntoParams(url)
  var paramArray = paramArray.filter(function (obj) {
    if ((obj.param != "influencerSearchText") && (obj.param != "influencerFullName") && (obj.param != "influencerUserName")) return obj;
    return false
  });
  if (textArrayTrim && textArrayTrim.length > 0) {
    showLoaderOnClick()
    for (var i = 0; i < textArrayTrim.length; i++) {
      textArrayTrim[i] = textArrayTrim[i].replace(/#/g, "HT:")
      paramArray.push({
        param: enterparams,
        value: textArrayTrim[i]
      });
    }
  }
  var destUrl = mergeIntoUrl(paramArray, true);
  document.location = destUrl;

}

$(document).ready(function () {

  var url = document.location.href;
  var paramArray = splitIntoParams(url)
  var searchkeywords = paramArray.filter(function (val) {
    if (val.param == "influencerSearchText") return val.value
    return false
  })
  var searchNames = paramArray.filter(function (val) {
    if (val.param == "influencerFullName") return val.value
    return false
  })
  var searchUsers = paramArray.filter(function (val) {
    if (val.param == "influencerUserName") return val.value
    return false
  })
  var influencerMainSearchText = paramArray.filter(function (obj) {
    if (obj.param == "influencerMainSearchText") return true;
    return false
  });
  var htmltags = ''

  if (searchkeywords && searchkeywords.length > 0) {
    $("#influencerSearch").val("influencerSearchText")
    $(".influ-search-box-div .tag.label.label-info").remove()
    htmltags = ''
    for (var i = 0; i < searchkeywords.length; i++) {
      searchkeywords[i].value = searchkeywords[i].value.replace("HT:", "#")
      htmltags += '<span class="tag label label-info">' + searchkeywords[i].value + '   <span data-role="remove"></span></span>'
    }
    $(".influ-search-box-div .bootstrap-tagsinput").prepend(htmltags)

  } else if (searchUsers && searchUsers.length > 0) {
    $("#influencerSearch").val("influencerUserName")
    $(".influ-search-box-div .tag.label.label-info").remove()
    htmltags = ''
    for (var i = 0; i < searchUsers.length; i++) {
      htmltags += '<span class="tag label label-info">' + searchUsers[i].value + '   <span data-role="remove"></span></span>'
    }
    $(".influ-search-box-div .bootstrap-tagsinput").prepend(htmltags)

  } else if (searchNames && searchNames.length > 0) {
    $("#influencerSearch").val("influencerFullName")
    $(".influ-search-box-div .tag.label.label-info").remove()
    htmltags = ''
    for (var i = 0; i < searchNames.length; i++) {
      htmltags += '<span class="tag label label-info">' + searchNames[i].value + '   <span data-role="remove"></span></span>'
    }
    $(".influ-search-box-div .bootstrap-tagsinput").prepend(htmltags)

  }
  else { }
  if (influencerMainSearchText && influencerMainSearchText.length > 0) {
    htmltags = ''
    for (var i = 0; i < influencerMainSearchText.length; i++) {
      htmltags += ('<span class="tag label label-info">' + influencerMainSearchText[i].value + '<span data-role="remove"></span></span>')
    }
    $('.influencer-discovery-container .bootstrap-tagsinput>input').before(htmltags)
  }
  $("#influencerSearchBoxDiv span [data-role='remove']").click(function () {
    $(this).parent().remove()
  });



  $("#influencerSearch").change(function () {

    $("input[name='search']").val("")
    if ($("#influencerSearch").val() === "influencerSearchText") {
      $(".influ-search-box-div .tag.label.label-info").remove()
      htmltags = ''
      if (searchkeywords && searchkeywords.length > 0) {
        for (var i = 0; i < searchkeywords.length; i++) {
          htmltags += '<span class="tag label label-info">' + searchkeywords[i].value + '   <span data-role="remove"></span></span>'
        }
        $(".influ-search-box-div .bootstrap-tagsinput").prepend(htmltags)
      }
    }
    if ($("#influencerSearch").val() === "influencerUserName") {
      $(".influ-search-box-div .tag.label.label-info").remove()
      htmltags = ''
      if (searchUsers && searchUsers.length > 0) {
        for (var i = 0; i < searchUsers.length; i++) {
          htmltags += '<span class="tag label label-info">' + searchUsers[i].value + '   <span data-role="remove"></span></span>'
        }
        $(".influ-search-box-div .bootstrap-tagsinput").prepend(htmltags)
      }
    }
    if ($("#influencerSearch").val() === "influencerFullName") {
      $(".influ-search-box-div .tag.label.label-info").remove()
      htmltags = ''
      if (searchNames && searchNames.length > 0) {
        for (var i = 0; i < searchNames.length; i++) {
          htmltags += '<span class="tag label label-info">' + searchNames[i].value + '   <span data-role="remove"></span></span>'
        }
        $(".influ-search-box-div .bootstrap-tagsinput").prepend(htmltags)
      }
    }
    $("#influencerSearchBoxDiv span [data-role='remove']").click(function () {
      $(this).parent().remove()
    });
  })

  // ====================for slider filter===================================================================================


  var followerCount = paramArray.filter(function (val) {
    if (val.param == "followerCount") return val.value
    return false
  })

  var min = 0
  var max = 10000000
  var minValue = min
  var maxValue = max
  if (followerCount.length > 0) {
    minValue = followerCount[0].value.split("-")[0]
    if (minValue !== null && minValue !== undefined && minValue !== "")
      $("#follower_min_count").val(minValue)
    maxValue = followerCount[0].value.split("-")[1]
    if (maxValue !== null && maxValue !== undefined && maxValue !== "")
      $("#follower_max_count").val(maxValue)
  }
  minValue = parseInt(minValue)
  maxValue = parseInt(maxValue)

  $(function () {
    $("#follower-slider-range").slider({
      range: true,
      min: min,
      max: max,
      orientation: "horizontal",
      values: [minValue, maxValue],
      step: 100,
      slide: function (event, ui) {
        if (ui.values[0] == ui.values[1]) {
          return false;
        }
        $("#follower_min_count").val(ui.values[0])
        $("#follower_max_count").val(ui.values[1]);
      }
    });
    $("#follower_min_count").val($("#follower-slider-range").slider("values", 0));
    $("#follower_max_count").val($("#follower-slider-range").slider("values", 1));
  });


  var influenceScore = paramArray.filter(function (val) {
    if (val.param == "influenceScore") return val.value
    return false
  })

  var inflMin = 0
  var inflMax = 100
  var inflMinValue = inflMin
  var inflMaxValue = inflMax
  if (influenceScore.length > 0) {
    inflMinValue = influenceScore[0].value.split("-")[0]
    if (inflMinValue !== null && inflMinValue !== undefined && inflMinValue !== "")
      $("#influence_min_score").val(inflMinValue)
    inflMaxValue = influenceScore[0].value.split("-")[1]
    if (inflMaxValue !== null && inflMaxValue !== undefined && inflMaxValue !== "")
      $("#influence_max_score").val(inflMaxValue)
  }
  inflMinValue = parseInt(inflMinValue * 10)
  inflMaxValue = parseInt(inflMaxValue * 10)

  $(function () {
    $("#influence-slider-range").slider({
      range: true,
      min: inflMin,
      max: inflMax,
      orientation: "horizontal",
      values: [inflMinValue, inflMaxValue],
      step: 1,
      slide: function (event, ui) {
        if (ui.values[0] == ui.values[1]) {
          return false;
        }
        $("#influence_min_score").val(ui.values[0])
        $("#influence_max_score").val(ui.values[1]);
      }
    });
    $("#influence_min_score").val($("#influence-slider-range").slider("values", 0));
    $("#influence_max_score").val($("#influence-slider-range").slider("values", 1));
  });




})

function markInfluencerAuthor(influencer, projectId, authorId, authorName, index) {
  $(influencer).toggleClass("fa-heart-o")
  $(influencer).toggleClass("fa-heart")
  $("#influencer-dropbox-" + index).hide()
  var isMark = false
  if ($(influencer).hasClass("fa-heart")) {
    notifyMarkInfluencer(authorName + " has been successfully marked")
    isMark = true
  } else {
    notifyMarkInfluencer(authorName + " has been removed from the marked list")
  }
  var data = {
    projectId: projectId,
    authorId: authorId,
    isMark: isMark
  }
  $.ajax({
    type: "POST",
    withCredentials: true,
    xhrFields: {
      withCredentials: true
    },
    url: "/markAuthors",
    data: data,
    success: function (ans) {

    },
    error: function (error) { }
  });
}


function notifyMarkInfluencer(message) {
  var offsetdata = checkScreenViewForNotify()
  $.notify({
    title: '',
    icon: 'fa fa-check',
    message: message,
  }, {
    type: 'info',
    animate: {
      enter: 'animated fadeInUp',
      exit: 'animated fadeOutRight'
    },
    placement: {
      from: "top",
      align: "right"
    },
    offset: offsetdata,
    spacing: 10,
    z_index: 1051,

  });
}


function filterRangeSubmitBtn(min, max, filterName) {
  var url = document.location.href

  var minValue = 0
  if (parseInt($(min).val()) > 0) {
    minValue = parseInt($(min).val())
  }
  var maxValue = 0
  if (parseInt($(max).val()) > 0) {
    maxValue = parseInt($(max).val())
  }
  if (minValue >= maxValue) {
    alert("Check filter value")
    return
  } else {
    showLoaderOnClick()
    var paramArray = splitIntoParams(url)

    var index = paramArray.findIndex(function (obj) {
      if (obj.param == filterName) return obj;
    });
    if (index >= 0) {
      paramArray.splice(index, 1);
    }
    if (filterName == "influenceScore") {
      paramArray.push({
        param: filterName,
        value: minValue / 10 + '-' + maxValue / 10
      })

    } else {
      paramArray.push({
        param: filterName,
        value: minValue + '-' + maxValue
      })
    }


    url = mergeIntoUrl(paramArray, true);
    document.location.href = url
  }
}


function generateInfluencerReport(influencerUserid, projectId) {
  var range = $('#reportrange span').text()
  var startDate = range.split("-")[0]
  var endDate = range.split("-")[1]
  if (influencerUserid && projectId && startDate && endDate) {
    showLoaderOnClick()
    var url = "/generateInfluencerReport?&influencerUserId=" + influencerUserid + "&projectId=" + projectId + "&startDate=" + startDate + "&endDate=" + endDate
    $.ajax({
      type: "POST",
      withCredentials: true,
      xhrFields: {
        withCredentials: true
      },
      url: url,
      success: function (ans) {
        hideLoaderOnClick()
        sendNotifyMessage()
        //console.log(ans)
      },
      error: function (error) {
        hideLoaderOnClick()
        //console.log(ans)
      }

    });
  } else {
    $.notify({
      title: '',
      icon: 'fa fa-cross',
      message: "Please check date range or contact to your project manager",
    }, {
      type: 'danger',
      animate: {
        enter: 'animated fadeInUp',
        exit: 'animated fadeOutRight'
      },
      placement: {
        from: "top",
        align: "right"
      },

      offset: offsetdata,
      spacing: 10,
      z_index: 1051,

    });
  }

}

function viewInBrowserInfluencerReport(influencerUserid, projectId, influencerUsername) {
  var range = $('#reportrange span').text()
  var startDate = range.split("-")[0]
  var endDate = range.split("-")[1]
  if (influencerUserid && projectId && startDate && endDate) {
    //showLoaderOnClick()
    var url = "/viewInfluencerReportInBrowser?&influencerUserId=" + influencerUserid + "&projectId=" + projectId + "&startDate=" + startDate + "&endDate=" + endDate + "&influencerUserName=" + influencerUsername
    //console.log("opening url : " + url)
    window.open(url, "_blank")
  } else {
    $.notify({
      title: '',
      icon: 'fa fa-cross',
      message: "Please check date range or contact to your project manager",
    }, {
      type: 'danger',
      animate: {
        enter: 'animated fadeInUp',
        exit: 'animated fadeOutRight'
      },
      placement: {
        from: "top",
        align: "right"
      },

      offset: offsetdata,
      spacing: 10,
      z_index: 1051,

    });

  }
}

function sendNotifyMessage() {
  var offsetdata = checkScreenViewForNotify()
  $.notify({
    title: '',
    icon: 'fa fa-check',
    message: "Your Influencer profile report is queued and will be mailed to you in a few minutes!",
  }, {
    type: 'info',
    animate: {
      enter: 'animated fadeInUp',
      exit: 'animated fadeOutRight'
    },
    placement: {
      from: "top",
      align: "right"
    },
    offset: offsetdata,
    spacing: 10,
    z_index: 1051,

  });
}

function influencerDropboxOnClickFunction(thisInfluencer) {
  $(thisInfluencer).fadeToggle('slow')
}

function showAuthorMentionsOnClick(influencerUserId, authorType, index) {
  showLoaderOnClick()
  var sourceType = changeAuthorTypeToSourceType(authorType)
  $("#influencer-dropbox-" + index).hide()
  document.location.href = "/enterprise-mention?&projectId=" + projectId + "&authorUserId=" + influencerUserId + "&sourceType=" + sourceType + "&showAllPost=true&sortBy=newest"
}


$(document).ready(function () {
  if (document.getElementById('influencerScoreId')) {
    demo.scoreMeterInfluenceScore(infleunceScore, "influencerScoreId")
  }
  if (document.getElementById('influencerScoreIdMobile')) {
    demo.scoreMeterInfluenceScore(infleunceScore, "influencerScoreIdMobile")
  }
})


function downloadInfluencerPage() {
  $(".anticon.anticon-info-circle-o").attr("data-html2canvas-ignore", "true")
  var html2canvasScrollY = -window.scrollY
  var element = document.getElementById('profileDiv')
  if (window.innerWidth < 480) {
    window.scrollTo(0, 0);
    html2canvasScrollY = 0
  }
  html2canvas(element, {
    allowTaint: true,
    useCORS: true,
    scrollX: 0,
    scrollY: html2canvasScrollY,
  }).then(function (canvas) {
    var base64Image = canvas.toDataURL('image/jpeg')
    var img = document.createElement('img');
    img.src = base64Image;
    var a = document.createElement('a');
    a.setAttribute("download", "download.jpeg");
    a.setAttribute("href", base64Image);
    a.appendChild(img);
    a.click()
    $(a).remove()
    $("#shareOnInstagramModal").modal("hide")
    hideLoaderOnClick()
  });
}
function shareFacebook(siteUrl) {
  if (siteUrl == undefined || siteUrl == null)
    siteUrl = document.location.href
  else
    siteUrl = document.location.origin + "/" + siteUrl
  if (siteUrl.indexOf("localhost:3000") >= 0)
    siteUrl = siteUrl.replace('localhost:3000', 'staging.rankmeonline.com')
  var html = "https://www.facebook.com/dialog/share?app_id=1494236777335267&display=popup&href=" + siteUrl
  window.open(html, "_blank")
}


function shareTwitter(tweetText, siteUrl) {
  if (siteUrl == undefined || siteUrl == null)
    siteUrl = document.location.href
  else
    siteUrl = document.location.origin + "/" + siteUrl
  if (siteUrl.indexOf("localhost:3000") >= 0)
    siteUrl = siteUrl.replace('localhost:3000', 'staging.rankmeonline.com')
  var html = "https://twitter.com/intent/tweet?url=" + siteUrl + "&text=" + tweetText
  window.open(html, "_blank")
}

function shareLinkedin(siteUrl) {
  if (siteUrl == undefined || siteUrl == null)
    siteUrl = document.location.href
  else
    siteUrl = document.location.origin + "/" + siteUrl
  if (siteUrl.indexOf("localhost:3000") >= 0)
    siteUrl = siteUrl.replace('localhost:3000', 'staging.rankmeonline.com')
  var html = "https://www.linkedin.com/shareArticle?mini=true&url=" + siteUrl
  window.open(html, "_blank")
}

function shareMail(subject, siteUrl) {
  if (siteUrl == undefined || siteUrl == null)
    siteUrl = document.location.href
  else
    siteUrl = document.location.origin + "/" + siteUrl
  if (siteUrl.indexOf("localhost:3000") >= 0)
    siteUrl = siteUrl.replace('localhost:3000', 'staging.rankmeonline.com')
  var html = "mailto:?body=" + siteUrl + "&subject=" + subject
  window.open(html, "_blank")
}

function shareInstagram() {
  downloadInfluencerPage();
  setTimeout(function () {
    window.open("instagram://camera")
  }, 10000);

}

function shareWhatsapp(siteUrl) {
  if (siteUrl == undefined || siteUrl == null)
    siteUrl = document.location.href
  else
    siteUrl = document.location.origin + "/" + siteUrl
  if (siteUrl.indexOf("localhost:3000") >= 0)
    siteUrl = siteUrl.replace('localhost:3000', 'staging.rankmeonline.com')
  var html = "whatsapp://send?text=" + siteUrl
  window.open(html, "_blank")
}


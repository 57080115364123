
var sentimentColor = ['#c8e400', '#f92000', '#00ccf7'];
var competitiveTotalHashtagPerformancebarGraph
var competitiveTotalEngagementByTimePeriodLineChart;
var competitiveHashtagPerformanceOverTimeLineChart

$(document).ready(function () {
  var ultimateColors = [
    'rgb(176, 252, 204)', 'rgb(0,206,244)', 'rgb(200,228,0)', 'rgb(255,155,0)', 'rgb(175,157,200)', 'rgb(237,69,21)', 'rgb(45,107,182)', 'rgb(242,255,0)', 'rgb(218,181,69)', 'rgb(255,128,114)', 'rgb(241,0,82)', 'rgb(241,201,122)', 'rgb(127,103,191)', 'rgb(203,72,195)', 'rgb(235,247,249)', 'rgb(230,207,88)', 'rgb(248,0,118)', 'rgb(169,193,205)'
  ];


  if (document.getElementById('competitorsOverSentimentsBarGraph')) {
    if (allCompetitorData.length > 0) {
      var compSentimentGraph = document.getElementById('competitorsOverSentimentsBarGraph')
      var allSentimentLabels = ['Positive', 'Negative', 'Neutral']
      var allvalues = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        var ydata = []
        ydata.push(allCompetitorData[i].sentimentMentionsByTimePeriod.positiveMentions.reduce(function (a, b) { return a + b }))
        ydata.push(allCompetitorData[i].sentimentMentionsByTimePeriod.negativeMentions.reduce(function (a, b) { return a + b }))
        ydata.push(allCompetitorData[i].sentimentMentionsByTimePeriod.neutralMentions.reduce(function (a, b) { return a + b }))
        allvalues.push(ydata)
      }
      Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
      Chart.defaults.global.tooltips.bodyFontColor = "#fff";
      Chart.defaults.global.tooltips.titleFontColor = "#fff";
      Chart.defaults.global.legend.display = true
      var BarChartData = {
        labels: allSentimentLabels,
        datasets: []
      }
      for (var i = 0; i < allCompetitorData.length; i++) {
        var data = {
          borderColor: ultimateColors[i],
          data: allvalues[i],
          label: allCompetitorData[i].projectName,
          backgroundColor: ultimateColors[i],
        }
        BarChartData.datasets.push(data)
      }

      stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
      stackBarLayout.legend.labels.fontSize = 10
      stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
      stackBarLayout.scales.yAxes[0].ticks.fontSize = 10

      var ctx = $('#competitorsOverSentimentsBarGraph');
      var chartData = {
        type: 'bar',
        data: BarChartData,
        options: stackBarLayout
      }

      competitorsOverSentimentsBarGraphbarGraph = new Chart(ctx, chartData);
      chartjsGraphsLoaded.push({
        id: "competitorsOverSentimentsBarGraph",
        data: chartData,
        chartObj: competitorsOverSentimentsBarGraphbarGraph,
        expandRatio: 1
      })
      if (competitorsOverSentimentsBarGraphbarGraph) {
        compSentimentGraph.onclick = function (evt) {
          var activePoint = competitorsOverSentimentsBarGraphbarGraph.getElementAtEvent(evt)[0];
          var data = activePoint._model;
          var label = data.datasetLabel;
          var codeSource = data.label
          var currentId = allCompetitorData.filter(function (obj) {
            if (obj.projectName === label) return true
            return false
          })
          console.log(label, codeSource);
          var url = '/enterprise-mention?&projectId=' + currentId[0].brandProject._id + mergeIntoUrl(splitIntoParams(document.location.href)).split("?")[1]
          if (url.indexOf('&sentiment=' + codeSource.toUpperCase()) == -1)
            url = '/enterprise-mention?&projectId=' + currentId[0].brandProject._id + '&sentiment=' + codeSource.toUpperCase() + mergeIntoUrl(splitIntoParams(document.location.href)).split("?")[1];
          document.location.href = url
        };
      }
    }
  }

  if (document.getElementById('competitiveChannelWiseDistribution') && competitiveChannelWiseDistribution) {
    if (allCompetitorData.length > 0) {
      var compChannelDistributionGraph = document.getElementById('competitiveChannelWiseDistribution')
      var labels = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        $.merge(labels, allCompetitorData[i].mentionsBySourceBySentiment.sourceList)
      }
      var allChannelLabels = labels.filter(function (item, i, ar) { return ar.indexOf(item) === i });

      var allvalues = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        var currentLabel = []
        for (var j = 0; j < allChannelLabels.length; j++) {
          if (allCompetitorData[i].mentionsBySourceBySentiment.sourceList.includes(allChannelLabels[j])) {
            var index = allCompetitorData[i].mentionsBySourceBySentiment.sourceList.findIndex(function (obj) { return obj === allChannelLabels[j] })
            currentLabel.push(allCompetitorData[i].mentionsBySourceBySentiment.sourceValue[index])
          }
          else {
            currentLabel.push(0)
          }
        }
        allvalues.push(currentLabel)
      }
      Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
      Chart.defaults.global.tooltips.bodyFontColor = "#fff";
      Chart.defaults.global.tooltips.titleFontColor = "#fff";
      Chart.defaults.global.legend.display = true
      var BarChartData = {
        labels: allChannelLabels,
        datasets: []
      }
      for (var i = 0; i < allCompetitorData.length; i++) {
        var data = {
          borderColor: ultimateColors[i],
          data: allvalues[i],
          label: allCompetitorData[i].projectName,
          backgroundColor: ultimateColors[i],
        }
        BarChartData.datasets.push(data)
      }
      stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
      // stackBarLayout.scales.xAxes[0]['scaleLabel'] = {
      //   display: true,
      //   // labelString: 'Date of Mention (' + mentionsSourceobj.dateFrom + '-' + mentionsSourceobj.dateTo + ')',
      //   fontSize: 9,
      //   fontColor: '#000',
      //   position: 'top'
      // }
      stackBarLayout.legend.labels.fontSize = 10
      stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
      stackBarLayout.scales.yAxes[0].ticks.fontSize = 10
      var ctx = $('#competitiveChannelWiseDistribution');
      var chartData = {
        type: 'bar',
        data: BarChartData,
        options: stackBarLayout
      }

      competitiveChannelWiseDistributionbarGraph = new Chart(ctx, chartData);
      chartjsGraphsLoaded.push({
        id: "competitiveChannelWiseDistribution",
        data: chartData,
        chartObj: competitiveChannelWiseDistributionbarGraph,
        expandRatio: 1
      })
      if (competitiveChannelWiseDistributionbarGraph) {
        compChannelDistributionGraph.onclick = function (evt) {
          var activePoint = competitiveChannelWiseDistributionbarGraph.getElementAtEvent(evt)[0];
          var data = activePoint._model;
          // var datasetIndex = activePoint._datasetIndex;
          var label = data.datasetLabel;
          // var value = data.datasets[datasetIndex].data[activePoint._index];
          var barpoint = data.label
          var codeSource = changeSourceName(barpoint)
          var currentId = allCompetitorData.filter(function (obj) {
            if (obj.projectName === label) return true
            return false
          })
          console.log(label, codeSource);
          var url = '/enterprise-mention?&projectId=' + currentId[0].brandProject._id + mergeIntoUrl(splitIntoParams(document.location.href)).split("?")[1]
          if (url.indexOf('&sourceType=' + codeSource.toUpperCase()) == -1)
            url = '/enterprise-mention?&projectId=' + currentId[0].brandProject._id + '&sourceType=' + codeSource.toUpperCase() + mergeIntoUrl(splitIntoParams(document.location.href)).split("?")[1];
          document.location.href = url
        };
      }
    }
  }

  if (document.getElementById('competitiveTotalHashtagPerformance')) {
    if (allCompetitorData.length > 0) {
      var labels = []
      var values = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        labels.push(allCompetitorData[i].projectName)
        values.push(allCompetitorData[i].hashtagTotal)
      }
      // Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
      // Chart.defaults.global.tooltips.bodyFontColor = "#fff";
      // Chart.defaults.global.tooltips.titleFontColor = "#fff";
      // Chart.defaults.global.legend.display = false
      stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
      stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
      stackBarLayout.scales.yAxes[0].ticks.fontSize = 10
      stackBarLayout.legend.display = false
      var ctx = $('#competitiveTotalHashtagPerformance');
      var chartData = {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            borderColor: 'rgb(176, 252, 204)',
            data: values,
            backgroundColor: 'rgb(176, 252, 204)',
            label: "Mentions",
          }]
        },
        options: stackBarLayout
      }

      competitiveTotalHashtagPerformancebarGraph = new Chart(ctx, chartData);
      chartjsGraphsLoaded.push({
        id: "competitiveTotalHashtagPerformance",
        data: chartData,
        chartObj: competitiveTotalHashtagPerformancebarGraph,
        expandRatio: 1
      })

    }
  }

  if (document.getElementById('competitiveHashtagPerformanceOverTime')) {
    if (allCompetitorData.length > 0) {
      var labels = []

      for (var i = 0; i < allCompetitorData.length; i++) {
        if (allCompetitorData[i].brandHashtagByTimePeriod != null) {
          labels = allCompetitorData[i].brandHashtagByTimePeriod.dates;
          break;
        }
      }

      var datasetForLineGraph = allCompetitorData.map(function (obj, index) {
        if (obj.brandHashtagByTimePeriod != null) {
          return {
            borderColor: ultimateColors[index],
            data: obj.brandHashtagByTimePeriod.count,
            label: obj.projectName,
            backgroundColor: ultimateColors[index]
          }
        } else {
          return {
            borderColor: ultimateColors[index],
            data: new Array(labels.length).fill(0),
            label: obj.projectName,
            backgroundColor: ultimateColors[index]
          }
        }
      })


      var lineChartData = {
        labels: labels,
        datasets: datasetForLineGraph
      }
      var startDate = moment(startDateInEpoch).format("DD MMM")
      var endDate = moment(endDateInEpoch).format("DD MMM")
      var lineLayout = JSON.parse(JSON.stringify(lineChartLayout))
      lineLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + startDate + '-' + endDate + ')'
      lineLayout.scales.xAxes[0].type = 'time'
      lineLayout.scales.xAxes[0].time = {
        unit: 'day',
        tooltipFormat: "DD-MM-YYYY",
        displayFormats: {
          day: 'MMM D'
        },
        round: 'day',
      }
      var ctx = $('#competitiveHashtagPerformanceOverTime');
      var graphData = {
        type: 'line',
        data: lineChartData,
        options: lineLayout
      }
      competitiveHashtagPerformanceOverTimeLineChart = new Chart(ctx, graphData);
      chartjsGraphsLoaded.push({
        id: "competitiveHashtagPerformanceOverTime",
        data: graphData,
        chartObj: competitiveHashtagPerformanceOverTimeLineChart,
        expandRatio: 1
      })

    }
  }

  if (document.getElementById('competitiveAudienceGenderAggregation')) {
    if (allCompetitorData.length > 0) {
      var labels = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        var genderType = allCompetitorData[i].audienceGenderAggregation.map(function (obj) { return obj.key })
        $.merge(labels, genderType)
      }
      var allGenderLabels = labels.filter(function (item, i, ar) { return ar.indexOf(item) === i });

      var allvalues = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        var genderType = allCompetitorData[i].audienceGenderAggregation.map(function (obj) { return obj.key })
        var counts = allCompetitorData[i].audienceGenderAggregation.map(function (obj) { return obj.doc_count })
        var currentLabel = []
        for (var j = 0; j < allGenderLabels.length; j++) {
          if (genderType.includes(allGenderLabels[j])) {
            var index = genderType.findIndex(function (obj) { return obj === allGenderLabels[j] })
            currentLabel.push(counts[index])
          }
          else {
            currentLabel.push(0)
          }
        }
        allvalues.push(currentLabel)
      }
      Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
      Chart.defaults.global.tooltips.bodyFontColor = "#fff";
      Chart.defaults.global.tooltips.titleFontColor = "#fff";
      Chart.defaults.global.legend.display = true
      var BarChartData = {
        labels: allGenderLabels,
        datasets: []
      }
      for (var i = 0; i < allCompetitorData.length; i++) {
        var data = {
          borderColor: ultimateColors[i],
          data: allvalues[i],
          label: allCompetitorData[i].projectName,
          backgroundColor: ultimateColors[i],
        }
        BarChartData.datasets.push(data)
      }
      stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
      // stackBarLayout.scales.xAxes[0]['scaleLabel'] = {
      //   display: true,
      //   // labelString: 'Date of Mention (' + mentionsSourceobj.dateFrom + '-' + mentionsSourceobj.dateTo + ')',
      //   fontSize: 9,
      //   fontColor: '#000',
      //   position: 'top'
      // }
      stackBarLayout.legend.labels.fontSize = 10
      stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
      stackBarLayout.scales.yAxes[0].ticks.fontSize = 10
      var ctx = $('#competitiveAudienceGenderAggregation');
      var chartData = {
        type: 'bar',
        data: BarChartData,
        options: stackBarLayout
      }

      competitiveAudienceGenderAggregationbarGraph = new Chart(ctx, chartData);
      chartjsGraphsLoaded.push({
        id: "competitiveAudienceGenderAggregation",
        data: chartData,
        chartObj: competitiveAudienceGenderAggregationbarGraph,
        expandRatio: 1
      })
    }
  }

  if (document.getElementById('competitiveAudienceMaleAggregation')) {
    if (allCompetitorData.length > 0) {
      var labels = []
      var values = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        labels.push(allCompetitorData[i].projectName)
        if (allCompetitorData[i].audienceGenderAggregation != null) {
          var maleObject = allCompetitorData[i].audienceGenderAggregation.find(function (element) { return element.key == "male" })
          if (maleObject && maleObject != null) {
            values.push(maleObject.doc_count)
          } else {
            values.push(0)
          }
        } else {
          values.push(0)
        }
      }
      stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
      stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
      stackBarLayout.scales.yAxes[0].ticks.fontSize = 10
      stackBarLayout.legend.display = false
      var ctx = $('#competitiveAudienceMaleAggregation');
      var chartData = {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            borderColor: 'rgb(176, 252, 204)',
            data: values,
            backgroundColor: 'rgb(176, 252, 204)',
            label: "Male",
          }]
        },
        options: stackBarLayout
      }

      competitiveAudienceMaleAggregationbarGraph = new Chart(ctx, chartData);
      chartjsGraphsLoaded.push({
        id: "competitiveAudienceMaleAggregation",
        data: chartData,
        chartObj: competitiveAudienceMaleAggregationbarGraph,
        expandRatio: 1
      })

    }
  }
  if (document.getElementById('competitiveAudienceFemaleAggregation')) {
    if (allCompetitorData.length > 0) {
      var labels = []
      var values = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        labels.push(allCompetitorData[i].projectName)
        if (allCompetitorData[i].audienceGenderAggregation != null) {
          var femalObject = allCompetitorData[i].audienceGenderAggregation.find(function (element) { return element.key == "female" })
          if (femalObject && femalObject != null) {
            values.push(femalObject.doc_count)
          } else {
            values.push(0)
          }
        } else {
          values.push(0)
        }
      }
      stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
      stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
      stackBarLayout.scales.yAxes[0].ticks.fontSize = 10
      stackBarLayout.legend.display = false
      var ctx = $('#competitiveAudienceFemaleAggregation');
      var chartData = {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            borderColor: 'rgb(0,206,244)',
            data: values,
            backgroundColor: 'rgb(0,206,244)',
            label: "Female",
          }]
        },
        options: stackBarLayout
      }

      competitiveAudienceFemaleAggregationbarGraph = new Chart(ctx, chartData);
      chartjsGraphsLoaded.push({
        id: "competitiveAudienceFemaleAggregation",
        data: chartData,
        chartObj: competitiveAudienceFemaleAggregationbarGraph,
        expandRatio: 1
      })
    }
  }

  if (document.getElementById('competitiveTotalEngagementByTimePeriodLineChart')) {
    if (allCompetitorData.length > 0) {
      var dates = []
      var datasetForLineGraph = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        dates = dates.concat(allCompetitorData[i].engagementMentionsByTimePeriod.dates)

      }
      var uniqueDates = dates.filter(function (v, i, a) { return a.indexOf(v) === i });
      uniqueDates = uniqueDates.sort(function (a, b) {
        return new Date(a) - new Date(b);
      });
      dates = uniqueDates

      for (var i = 0; i < allCompetitorData.length; i++) {
        var totalEngagementDataFormatted = allCompetitorData[i].engagementMentionsByTimePeriod.total_engagement
        datasetForLineGraph.push({
          borderColor: ultimateColors[i],
          data: totalEngagementDataFormatted,
          label: allCompetitorData[i].projectName,
          backgroundColor: ultimateColors[i]
        })

      }


      var lineChartData = {
        labels: dates,
        datasets: datasetForLineGraph
      }
      var startDate = moment(startDateInEpoch).format("DD MMM")
      var endDate = moment(endDateInEpoch).format("DD MMM")
      var totalMentionByTime = JSON.parse(JSON.stringify(lineChartLayout))
      totalMentionByTime.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + startDate + '-' + endDate + ')'
      totalMentionByTime.scales.xAxes[0].type = 'time'
      totalMentionByTime.scales.xAxes[0].time = {
        unit: 'day',
        displayFormats: {
          day: 'MMM D'
        },
        round: 'day',
      }
      totalMentionByTime.tooltips = {
        enabled: true,
        callbacks: {
          title: function (tooltipItems, data) {
            return data.labels[tooltipItems[0].datasetIndex];
          },
          beforeBody: function (tooltipItems, data) {
            return data.datasets[tooltipItems[0].datasetIndex].label
          },
          label: function (tooltipItems) {
            var engagementCount = tooltipItems.yLabel != null ? tooltipItems.yLabel : tooltipItems.value;
            return "Total Engagement: " + engagementCount
          },
        },
        titleFontStyle: 'normal',
      }
      var ctx = $('#competitiveTotalEngagementByTimePeriodLineChart');
      var graphData = {
        type: 'line',
        data: lineChartData,
        options: totalMentionByTime
      }
      competitiveTotalEngagementByTimePeriodLineChart = new Chart(ctx, graphData);
      chartjsGraphsLoaded.push({
        id: "competitiveTotalEngagementByTimePeriodLineChart",
        data: graphData,
        chartObj: competitiveTotalMentionsByTimePeriodLineChart,
        expandRatio: 1
      })

    }
  }

  if (document.getElementById('competitiveAudienceCategoryAggregation')) {
    if (allCompetitorData.length > 0) {
      var compAudienceCategory = document.getElementById('competitiveAudienceCategoryAggregation');
      var labels = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        var influencerType = allCompetitorData[i].audienceCategoryAggregation.map(function (obj) { return changeInfluencerType(obj.key) })
        $.merge(labels, influencerType)
      }

      // for (var i = 0; i < allCompetitorData.length; i++) {
      //   $.merge(labels, allCompetitorData[i].mentionsBySourceBySentiment.sourceList)
      // }
      var allAudienceLabels = labels.filter(function (item, i, ar) { return ar.indexOf(item) === i });

      var allvalues = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        var influencerType = allCompetitorData[i].audienceCategoryAggregation.map(function (obj) { return changeInfluencerType(obj.key) })
        var counts = allCompetitorData[i].audienceCategoryAggregation.map(function (obj) { return obj.doc_count })
        var currentLabel = []
        for (var j = 0; j < allAudienceLabels.length; j++) {
          if (influencerType.includes(allAudienceLabels[j])) {
            var index = influencerType.findIndex(function (obj) { return obj === allAudienceLabels[j] })
            currentLabel.push(counts[index])
          }
          else {
            currentLabel.push(0)
          }
        }
        allvalues.push(currentLabel)
      }
      Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
      Chart.defaults.global.tooltips.bodyFontColor = "#fff";
      Chart.defaults.global.tooltips.titleFontColor = "#fff";
      Chart.defaults.global.legend.display = true
      var BarChartData = {
        labels: allAudienceLabels,
        datasets: []
      }
      for (var i = 0; i < allCompetitorData.length; i++) {
        var data = {
          borderColor: ultimateColors[i],
          data: allvalues[i],
          label: allCompetitorData[i].projectName,
          backgroundColor: ultimateColors[i],
        }
        BarChartData.datasets.push(data)
      }
      stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
      // stackBarLayout.scales.xAxes[0]['scaleLabel'] = {
      //   display: true,
      //   // labelString: 'Date of Mention (' + mentionsSourceobj.dateFrom + '-' + mentionsSourceobj.dateTo + ')',
      //   fontSize: 9,
      //   fontColor: '#000',
      //   position: 'top'
      // }
      stackBarLayout.legend.labels.fontSize = 10
      stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
      stackBarLayout.scales.yAxes[0].ticks.fontSize = 10
      var ctx = $('#competitiveAudienceCategoryAggregation');
      var chartData = {
        type: 'bar',
        data: BarChartData,
        options: stackBarLayout
      }

      competitiveAudienceCategoryAggregationbarGraph = new Chart(ctx, chartData);
      chartjsGraphsLoaded.push({
        id: "competitiveAudienceCategoryAggregation",
        data: chartData,
        chartObj: competitiveAudienceCategoryAggregationbarGraph,
        expandRatio: 1
      })
      if (competitiveAudienceCategoryAggregationbarGraph) {
        compAudienceCategory.onclick = function (evt) {
          var activePoint = competitiveAudienceCategoryAggregationbarGraph.getElementAtEvent(evt)[0];
          // var data = activePoint._chart.data;
          // var datasetIndex = activePoint._datasetIndex;
          // var label = data.datasets[datasetIndex].label;
          // // var value = data.datasets[datasetIndex].data[activePoint._index];
          // var barpoint = allAudienceLabels[datasetIndex]
          var data = activePoint._model;
          // var datasetIndex = activePoint._datasetIndex;
          var label = data.datasetLabel;
          // var value = data.datasets[datasetIndex].data[activePoint._index];
          var barpoint = data.label
          var codeSource = getAudienceAuthorCategory(barpoint)
          var currentId = allCompetitorData.filter(function (obj) {
            if (obj.projectName === label) return true
            return false
          })
          console.log(label, codeSource);
          var url = '/track-influencers?&projectId=' + currentId[0].brandProject._id + '&authorCategory=' + codeSource + mergeIntoUrl(splitIntoParams(document.location.href)).split("?")[1]
          if (document.location.href.indexOf('&authorCategory=') > -1) {
            var addurl = mergeIntoUrl(splitIntoParams(document.location.href).filter(function (a) { if (a.param != "authorCategory") { return true } })).split("?")[1]
            url = '/track-influencers?&projectId=' + currentId[0].brandProject._id + '&authorCategory=' + codeSource + addurl;
          }
          document.location.href = url
        };
      }
    }
  }

})
type = ['', 'info', 'success', 'warning', 'danger'];


window.demo = {
  initPickColor: function () {
    $('.pick-class-label').click(function () {
      var new_class = $(this).attr('new-class');
      var old_class = $('#display-buttons').attr('data-class');
      var display_div = $('#display-buttons');
      if (display_div.length) {
        var display_buttons = display_div.find('.btn');
        display_buttons.removeClass(old_class);
        display_buttons.addClass(new_class);
        display_div.attr('data-class', new_class);
      }
    });
  },

  checkScrollForTransparentNavbar: debounce(function () {
    $navbar = $('.navbar[color-on-scroll]');
    scroll_distance = $navbar.attr('color-on-scroll') || 500;

    if ($(document).scrollTop() > scroll_distance) {
      if (transparent) {
        transparent = false;
        $('.navbar[color-on-scroll]').removeClass('navbar-transparent');
        $('.navbar[color-on-scroll]').addClass('navbar-default');
      }
    } else {
      if (!transparent) {
        transparent = true;
        $('.navbar[color-on-scroll]').addClass('navbar-transparent');
        $('.navbar[color-on-scroll]').removeClass('navbar-default');
      }
    }
  }, 17),
  competitiveAnalysisView: function (compares, hasCompetitor, brandName, comparesMeta, comparesGraph) {
    var geochartData = hasCompetitor ? comparesGraph : compares.STATEWISE_GRAPH[0].stateGraphPoints
    var brand1 = brandName
    if (hasCompetitor === true) {
      var brand2 = hasCompetitor ? comparesMeta.competitor[0].competitorName : null
    } else {
      var brand2 = null
    }
    if (geochartData && hasCompetitor)
      this.initGoogleGeoChart(geochartData, 'geochart-comparative', '#d0021b', '#0079c0', true, brand1, brand2);
    else if (geochartData)
      this.initGoogleGeoChart(geochartData, 'geochart-comparative', '#d0021b', '#0079c0', false, brand1, brand2);
    var interestOverTimeCompare = compares.POPULARITY_GRAPH
    if (interestOverTimeCompare && hasCompetitor)
      this.initChartInterestsOverTime('#chartComparePopularity', interestOverTimeCompare[0].popGraphPoints, interestOverTimeCompare[1].popGraphPoints);
    else
      this.initChartInterestsOverTime('#chartComparePopularity', interestOverTimeCompare[0].popGraphPoints, null);
  },
  initDocChartist: function () {
    var dataSales = {
      labels: ['9:00AM', '12:00AM', '3:00PM', '6:00PM', '9:00PM', '12:00PM', '3:00AM', '6:00AM'],
      series: [
        [287, 385, 490, 492, 554, 586, 698, 695, 752, 788, 846, 944],
        [67, 152, 143, 240, 287, 335, 435, 437, 539, 542, 544, 647],
        [23, 113, 67, 108, 190, 239, 307, 308, 439, 410, 410, 509]
      ]
    };

    var optionsSales = {
      lineSmooth: false,
      low: 0,
      high: 800,
      showArea: true,
      height: "245px",
      axisX: {
        showGrid: false,
      },
      lineSmooth: Chartist.Interpolation.simple({
        divisor: 3
      }),
      showLine: false,
      showPoint: false,
    };

    var responsiveSales = [
      ['screen and (max-width: 640px)', {
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }]
    ];

    Chartist.Line('#chartHours', dataSales, optionsSales, responsiveSales);


    var data = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      series: [
        [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
        [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695]
      ]
    };

    var options = {
      seriesBarDistance: 10,
      axisX: {
        showGrid: false
      },
      height: "245px"
    };

    var responsiveOptions = [
      ['screen and (max-width: 640px)', {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }]
    ];

    Chartist.Bar('#chartActivity', data, options, responsiveOptions);

    var dataPreferences = {
      series: [
        [25, 30, 20, 25]
      ]
    };

    var optionsPreferences = {
      donut: true,
      donutWidth: 40,
      startAngle: 0,
      total: 100,
      showLabel: false,
      axisX: {
        showGrid: false
      }
    };

    Chartist.Pie('#chartPreferences', dataPreferences, optionsPreferences);

    Chartist.Pie('#chartPreferences', {
      labels: ['62%', '32%', '6%'],
      series: [62, 32, 6]
    });
  },
  initGoogleGeoChart: function (geoChartData, elementToFill, colormin, colormax, isComparative, brand1, brand2) {
    if (typeof (google) === "undefined") return;
    google.charts.load('current', {
      'packages': ['geochart', 'corechart'],
      'mapsApiKey': 'AIzaSyDx2OqmjomNjqt8mdVV-Q6RrDgE9s5VeWM'
    });
    google.charts.setOnLoadCallback(drawRegionsMap);
    var numb1 = []
    var numb2 = []
    var arrDataTable = []
    var hashed = {}
    if (isComparative)
      arrDataTable.push(['State', brand1, brand2])
    else
      arrDataTable.push(['State', 'count'])
    for (var i = 0; geoChartData != null && i < geoChartData.length; i++) {
      if (!isComparative)
        arrDataTable.push([geoChartData[i].city, parseInt(geoChartData[i].value)])
      else {
        var valfix = parseInt(geoChartData[i].value1)
        while (hashed[valfix]) {
          valfix++
        }
        hashed[valfix] = 1
        arrDataTable.push([geoChartData[i].city, parseInt(valfix), parseInt(geoChartData[i].value2)])
        numb1.push(parseInt(valfix))
        numb2.push(parseInt(geoChartData[i].value2))
      }
    }

    function drawRegionsMap() {
      var data = google.visualization.arrayToDataTable(arrDataTable);
      var options = {
        domain: 'IN',
        region: 'IN', // Africa
        colorAxis: {
          minValue: 0,
          maxValue: 100,
          colors: [colormin, "#d0ad02", colormax]
        },
        backgroundColor: '#f5f5f5',
        datalessRegionColor: '#cccccc',
        defaultColor: '#f5f5f5',
        resolution: 'provinces'
      };
      if (isComparative) {
        //numb1.sort(function (a, b) { return Number(a.value) - Number(b.value); );
        //numb2.sort(function (a, b) { return Number(a.value) - Number(b.value); );
        var combined = []
        var valsCombined = []
        var colorsCombined = []
        var i = 0;
        j = 0;
        while (i < numb1.length && j < numb2.length) {
          if (numb1[i] < numb2[j]) {
            combined.push({
              color: colormax,
              value: numb1[i]
            })
            //combined.push({ color: colormax, value: numb2[j] })
            i++;
            j++
          } else if (numb1[i] > numb2[j]) {
            combined.push({
              color: colormin,
              value: numb1[i]
            })
            // combined.push({ color: colormin, value: numb2[j] })
            i++;
            j++
          } else {
            combined.push({
              color: '#f5f5f5',
              value: numb1[i]
            })
            i++;
            j++;
          }
        }

        combined = combined.sort(function (a, b) {
          return Number(a.value) - Number(b.value);
        });
        combined.forEach(function (obj) {
          valsCombined.push(obj.value)
          colorsCombined.push(obj.color)
        })
        options.colorAxis.values = valsCombined
        options.colorAxis.colors = colorsCombined
      }
      var chart = new google.visualization.GeoChart(document.getElementById(elementToFill));
      chart.draw(data, options);
    };
  },
  initCommentsPieChart: function (commentsPieChartData) {

    if (commentsPieChartData.length <= 0)
      return

    var data = {
      series: commentsPieChartData //positive,negative,neutral
    };

    var sum = function (a, b) {
      return a + b
    };

    new Chartist.Pie('#commentsPieChart', data, {
      labelInterpolationFnc: function (value) {
        var ret = Math.round(value / data.series.reduce(sum) * 100);
        if (ret == 0)
          return '';
        return ret + '%';
      },
      donut: true,
      showLabel: true
    });
    /*Chartist.Pie('#commentsPieChart', {
      labels: ['62%', '32%', '6%'],
      series: [62, 32, 6]
    });*/
  },
  initChartInterestsOverTime: function (placeholder, interestOverTime, compareWithInterestOverTime) {
    var labelses = []
    var serieses = []
    for (var i = 0; i < interestOverTime.length; i++) {
      var month = interestOverTime[i].date.split(" ")[0]
      if (labelses.indexOf(month) >= 0) {
        month = " "
      }
      labelses.push(month)
      serieses.push(parseInt(interestOverTime[i].value))

    }

    var data = {
      labels: labelses,
      series: [

        {
          name: "series-1",
          data: serieses
        }
      ]
    }



    var responsiveOptions = {
      fullWidth: true,
      chartPadding: {
        right: 40
      },

    }
    var smootheningOptions = {
      // Remove this configuration to see that chart rendered with cardinal spline interpolation
      // Sometimes, on large jumps in data values, it's better to use simple smoothing.
      fullWidth: true,
      series: {
        'series-1': {
          lineSmooth: Chartist.Interpolation.simple()
        },
      }
    }
    if (compareWithInterestOverTime) {
      var seriesesCompare = []
      for (var i = 0; i < compareWithInterestOverTime.length; i++) {
        seriesesCompare.push(parseInt(compareWithInterestOverTime[i].value))
      }
      data.series.push({
        name: "series-2",
        data: seriesesCompare
      })
      smootheningOptions.series["series-2"] = {
        lineSmooth: Chartist.Interpolation.simple()
      }
    }
    var chart = new Chartist.Line(placeholder, data, smootheningOptions, responsiveOptions);
    chart.on('draw', function (data) {
      if (data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 2000 * data.index,
            dur: 2000,
            from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      }
    });
  },
  initChartist: function (interestOverTime) {

    var dataSales = {
      labels: ['9:00AM', '12:00AM', '3:00PM', '6:00PM', '9:00PM', '12:00PM', '3:00AM', '6:00AM'],
      series: [
        [287, 385, 490, 492, 554, 586, 698, 695, 752, 788, 846, 944],
        [67, 152, 143, 240, 287, 335, 435, 437, 539, 542, 544, 647],
        [23, 113, 67, 108, 190, 239, 307, 308, 439, 410, 410, 509]
      ]
    };

    var optionsSales = {
      lineSmooth: false,
      low: 0,
      high: 800,
      showArea: true,
      height: "245px",
      axisX: {
        showGrid: false,
      },
      lineSmooth: Chartist.Interpolation.simple({
        divisor: 3
      }),
      showLine: false,
      showPoint: false,
    };

    var responsiveSales = [
      ['screen and (max-width: 640px)', {
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }]
    ];

    Chartist.Line('#chartHours', dataSales, optionsSales, responsiveSales);


    var data = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      series: [
        [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
        [412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636, 695]
      ]
    };

    var options = {
      seriesBarDistance: 10,
      axisX: {
        showGrid: false
      },
      height: "245px"
    };

    var responsiveOptions = [
      ['screen and (max-width: 640px)', {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }]
    ];

    Chartist.Bar('#chartActivity', data, options, responsiveOptions);

    var dataPreferences = {
      series: [
        [25, 30, 20, 25]
      ]
    };

    var optionsPreferences = {
      donut: true,
      donutWidth: 40,
      startAngle: 0,
      total: 100,
      showLabel: false,
      axisX: {
        showGrid: false
      }
    };

    Chartist.Pie('#chartPreferences', dataPreferences, optionsPreferences);

    Chartist.Pie('#chartPreferences', {
      labels: ['62%', '32%', '6%'],
      series: [62, 32, 6]
    });
  },
  initStackedChartForReviewsBySource: function (reviewSourceData) {

    var labelData = []
    var seriesDataPos = []
    var seriesDataNeg = []
    var seriesDataNeu = []
    var maxVal = -1
    for (var i = 0; i < reviewSourceData.length; i++) {
      if (labelData.indexOf(reviewSourceData[i].source) < 0) {
        labelData.push(reviewSourceData[i].source)
        seriesDataPos.push(0)
        seriesDataNeg.push(0)
        seriesDataNeu.push(0)
      }
      var indexSource = labelData.indexOf(reviewSourceData[i].source)
      if (reviewSourceData[i].sentiment === "POSITIVE")
        seriesDataPos[indexSource] = reviewSourceData[i].count
      else if (reviewSourceData[i].sentiment === "NEGATIVE")
        seriesDataNeg[indexSource] = reviewSourceData[i].count
      else if (reviewSourceData[i].sentiment === "NEUTRAL" || reviewSourceData[i].sentiment === "MIXED")
        seriesDataNeu[indexSource] = reviewSourceData[i].count
    }

    seriesDataNeu.forEach(function (val) {
      if (val > maxVal) {
        maxVal = val
      }
    })
    seriesDataNeg.forEach(function (val) {
      if (val > maxVal) {
        maxVal = val
      }
    })
    seriesDataPos.forEach(function (val) {
      if (val > maxVal) {
        maxVal = val
      }
    })
    /** 
     * new Chartist.Bar('.ct-chart', {
  labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  series: [
    [5, 4, 3, 7, 5, 10, 3],
    [3, 2, 9, 5, 4, 6, 4]
  ]
}, {
  seriesBarDistance: 10,
  reverseData: true,
  horizontalBars: true,
  axisY: {
    offset: 70
  }
});
 
     * 
    */
    new Chartist.Bar('#reviewsBysource', {
      labels: labelData,
      series: [
        seriesDataPos, //blue
        seriesDataNeg, // red
        seriesDataNeu, //yello

      ]
    }, {
      stackBars: true,
      horizontalBars: true,
      axisY: {
        offset: 100
      },
      axisX: {
        onlyInteger: true,
        high: ((Math.floor(maxVal / 10)) * 10) + 50,
        low: 0,
        divisor: 10
      }
    }, [
      // Options override for media > 800px
      ['screen and (max-width: 768px)', {
        axisY: {
          offset: 100
        },
      }],
      // Options override for media > 1000px
      ['screen and (min-width: 769px)', {
        axisY: {
          offset: 300
        },
      }]
    ]).on('draw', function (data) {
      if (data.type === 'bar') {
        data.element.attr({
          style: 'stroke-width: 30px;color:blue'
        });
      }
    });

  },
  scoreMeter: function (score, divid) {
    var opts = {
      staticLabels: {
        font: "10px sans-serif", // Specifies font
        labels: [-100, -50, -10, 0, 10, 50, 100], // Print labels at these values
        color: "#000000", // Optional: Label text color
        fractionDigits: 0 // Optional: Numerical precision. 0=round off.
      },
      staticZones: [{
        strokeStyle: "rgb(249,32,0)",
        min: -100,
        max: -75,
        height: 1
      },
      {
        strokeStyle: "rgb(255,79,53)",
        min: -75,
        max: -50,
        height: 1
      },
      {
        strokeStyle: "rgb(255,130,112)",
        min: -50,
        max: -25,
        height: 1
      },
      {
        strokeStyle: "#8ae4f1",
        min: -25,
        max: -10,
        height: 1
      },
      {
        strokeStyle: "#0fd6ea",
        min: -10,
        max: 10,
        height: 1
      },

      {
        strokeStyle: "#8ae4f1",
        min: 10,
        max: 25,
        height: 1
      },
      {
        strokeStyle: "rgb(98,255,119)",
        min: 25,
        max: 50,
        height: 1
      },

      {
        strokeStyle: "rgb(20,255,51)",
        min: 50,
        max: 75,
        height: 1
      },
      {
        strokeStyle: "rgb(100,200,0)",
        min: 75,
        max: 100,
        height: 1
      }
      ],
      angle: -0.2, // The span of the gauge arc
      lineWidth: 0.3, // The line thickness
      radiusScale: 0.9, // Relative radius
      pointer: {
        length: 0.66, // // Relative to gauge radius
        strokeWidth: 0.035, // The thickness
        color: '#000000' // Fill color
      },
      limitMax: true, // If false, max value increases automatically if value > maxValue
      limitMin: true, // If true, the min value of the gauge will be fixed
      colorStart: '#6FADCF', // Colors
      colorStop: '#8FC0DA', // just experiment with them
      strokeColor: '#E0E0E0', // to see which ones work best for you
      generateGradient: true,
      highDpiSupport: true, // High resolution support

    };
    var target = document.getElementById(divid); // your canvas element
    var gauge = new Gauge(target).setOptions(opts); // create sexy gauge!
    gauge.maxValue = 100; // set max gauge value
    gauge.setMinValue(-100); // Prefer setter over gauge.minValue = 0
    gauge.animationSpeed = 32; // set animation speed (32 is default value)
    gauge.set(score); // set actual value

  },
  scoreMeterInfluenceScore: function (score, divid) {
    var opts = {
      staticLabels: {
        font: "10px sans-serif", // Specifies font
        labels: [0, 100], // Print labels at these values
        color: "#FFFFFF", // Optional: Label text color
        fractionDigits: 0 // Optional: Numerical precision. 0=round off.
      },
      staticZones: [{
        strokeStyle: "#338DFF",
        min: 0,
        max: 10,
        height: 1
      },
      {
        strokeStyle: "#33A8FF",
        min: 10,
        max: 20,
        height: 1
      },
      {
        strokeStyle: "#33BEFF",
        min: 20,
        max: 30,
        height: 1
      },
      {
        strokeStyle: "#33DAFF",
        min: 30,
        max: 40,
        height: 1
      },
      {
        strokeStyle: "#33F6FF",
        min: 40,
        max: 50,
        height: 1
      },

      {
        strokeStyle: "#33FFAF",
        min: 50,
        max: 60,
        height: 1
      },
      {
        strokeStyle: " #33FF74",
        min: 60,
        max: 70,
        height: 1
      },
      {
        strokeStyle: " #46FF00",
        min: 70,
        max: 80,
        height: 1
      },
      {
        strokeStyle: " #64C800",
        min: 80,
        max: 90,
        height: 1
      },
      {
        strokeStyle: "#4F9E00",
        min: 90,
        max: 1000,
        height: 1
      }
      ],
      angle: -0.2, // The span of the gauge arc
      lineWidth: 0.3, // The line thickness
      radiusScale: 0.9, // Relative radius
      pointer: {
        length: 0.66, // // Relative to gauge radius
        strokeWidth: 0.035, // The thickness
        color: '#FFFFFF' // Fill color
      },
      limitMax: true, // If false, max value increases automatically if value > maxValue
      limitMin: true, // If true, the min value of the gauge will be fixed
      colorStart: '#6FADCF', // Colors
      colorStop: '#8FC0DA', // just experiment with them
      strokeColor: '#E0E0E0', // to see which ones work best for you
      generateGradient: true,
      highDpiSupport: true, // High resolution support
    };
    var target = document.getElementById(divid); // your canvas element
    var gauge = new Gauge(target).setOptions(opts); // create sexy gauge!
    gauge.maxValue = 100; // set max gauge value
    gauge.setMinValue(0); // Prefer setter over gauge.minValue = 0
    gauge.animationSpeed = 1; // set animation speed (32 is default value)
    gauge.set(score); // set actual value

  },
  deleteCompetitor: function (eid, rid) {
    var url = "/remove-competitor?entityId=" + eid + "&requestId=" + rid + "&abc=" + Math.random();
    var that = this;
    $.getJSON({
      url: url
    }).done(function (data) {
      if (data["status"] === "done") {
        setTimeout(function () {
          document.location.reload(1);
        }, 1000);
      }
    })
  },
  clearLoading: function () {
    if ($(".dashboard").length < 1) return;

    if ($('.login-content-holder').length > 0) {
      // not logged in not removing blurr
    } else {
      $(".dashboard").removeClass("blur");
      $("#login-spinner").remove();
    }
  },
  onOverallRequestComplete: function () {
    location.reload();
  },
  onRequestComplete: function (data, eid, rid) {
    var sections = data["loadedSections"]
    var divNameWithNewData = null
    for (var i = 0; i < sections.length; i++) {
      var section = sections[i];
      if ($("#" + section["graphName"]).find('.spinner').length > 0) {
        $("#" + section["graphName"]).html(section["html"]);
        divNameWithNewData = section["graphName"]

        if (section["graphName"] == "statewise-user-split") {
          if ($("#geochart-colors").length > 0)
            demo.initGoogleGeoChart(section["options"].geoChartData, 'geochart-colors', '#d0021b', '#006400', false, 'brand1', 'brand2');
        }
        if (section["graphName"] == "popularity-graph") {
          demo.initChartInterestsOverTime('#chartUserInterestsOverTime', section["options"].interestOverTime, null);
        }
        if (section["graphName"] == "facebook-comments-summary") {
          demo.getAndSetBrandIdentity(eid, rid);
          demo.initCommentsPieChart(section["options"].facebookCommentsPieChartData.series);
        }
        if (section["graphName"] == "reviews-on-the-web") {
          demo.initStackedChartForReviewsBySource(section["options"].reviewChartData)
        }
        if (section["graphName"] == "rep-score") {
          demo.scoreMeter(section["options"].repScore.totalScore, "jyotiGraph")
        }
      }
    }
    if (divNameWithNewData != null) {
      // information box
      /*$.notify({
        icon: 'pe-7s-gift',
        message: "New Data available. <a href='#" + divNameWithNewData + "'>Click here to go directly!</a>"
      }, {
        type: 'success',
        timer: 4000
      });*/

    }
    if (data["overallStatus"] == false) {
      this.pollForStatus(true, undefined, eid, rid);
    }
  },
  getAndSetBrandIdentity: function (eid, rid) {
    var url = "/ormcalculator/getBrandNameAndIcons?entityId=" + eid + "&requestId=" + rid + "&ab=" + Math.random()
    var that = this;
    $.getJSON({
      url: url
    }).done(function (data) {
      var brandHeader = $(".second-top-nav .navbar-brand");
      //notify
      /*
      $.notify({
        title: '<strong>Welcome to RankMeOnline</strong>',
        message: "<div class=\"notifMessage\"><span class=\"notifyHeading\">Hello " + data.brandName + " !</span> <br /> <ul><li> You are seeing 20% of the actual data here. </li><li>The data might not be accurate if the brand presence in very limited online. </li> " + (data.inputString !== data.brandName ? "<li> You are seeing results for " + data.brandName + " instead of your search term : " + data.inputString + " as per our algorithm recommendation for brandname. You can change brandname from <strong> Not You</strong> feature provided at top left corner </li> " : "") + "</ul><span class=\"endnote\"> Happy Monitoring! </span></div>"
      }, {
          allow_dismiss: true,
          delay: 5000,
          type: 'info',
          animate: {
            enter: 'animated fadeInRight',
            exit: 'animated fadeOutRight'
          },
          placement: {
            from: "top",
            align: "center"
          },
          offset: {
            x: 20,
            y: 100
          },
          spacing: 10,
          z_index: 1031,
        });
*/
      if (data.brandName) {
        $(".second-top-nav .brand-name").text(data.brandName);
        if (data.brandName.length > 20 && Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 768)
          $(".second-top-nav .brand-name").css({
            "font-size": "13px"
          });
      }
      if (data.brandImage)
        $(".second-top-nav .navbar-brand").prepend("<img src='" + data.brandImage + "' class='brand-image pull-left'></img>");
      if (data.possibleTerms && data.possibleTerms.length > 0 && $("#notyou-modal").length > 0) {
        for (var i = 0; i < data.possibleTerms.length; i++) {
          $(".radio-button-group").prepend("<input type='radio' name='queryname' id='queryname' value='" + data.possibleTerms[i] + "'> " + data.possibleTerms[i] + " <br/>");
        }
      }
    });
  },
  checkRequestStatus: function (isPartial, eid, rid) {
    var urlstr = undefined
    if (isPartial)
      urlstr = "/ormcalculator/getRequestStatus?entityId=" + eid + "&requestId=" + rid + "&ab=" + Math.random()
    else
      urlstr = "/ormcalculator/getOverallRequestStatus?entityId=" + eid + "&requestId=" + rid + "&ab=" + Math.random()
    var that = this;
    $.getJSON({
      url: urlstr
    }).done(function (data) {
      if (isPartial) {
        if (data["partialStatus"] == true) {
          that.clearLoading();
          that.onRequestComplete(data, eid, rid);
        } else if (window.requestCount > 50) {
          that.clearLoading();
        } else {
          that.pollForStatus(isPartial, undefined, eid, rid);
        }
      } else {
        if (data["overallStatus"] == true) {
          that.clearLoading();
          that.onOverallRequestComplete();
        } else if (window.requestCount > 50) {
          that.clearLoading();
        } else {
          that.pollForStatus(isPartial, undefined, eid, rid);
        }
      }
    });
  },
  pollForStatus: function (isPartial, timer, eid, rid) {
    var that = this;
    if (timer == undefined)
      timer = 3000;
    window.requestCount += 1;
    setTimeout(function () {
      that.checkRequestStatus(isPartial, eid, rid)
    }, timer);
  },
  checkReportStatus: function (dobool, eid, rid) {
    window.requestCount = 0;
    this.pollForStatus(dobool, 10, eid, rid);
  },
  checkOverallStatus: function (eid, rid) {
    window.requestCount = 0;
    this.pollForStatus(false, 10, eid, rid);
  }


}


var SETTINGS = {
  navBarTravelling: false,
  navBarTravelDirection: "",
  navBarTravelDistance: 150
}

document.documentElement.classList.remove("no-js");
document.documentElement.classList.add("js");

// Out advancer buttons
var pnAdvancerLeft = document.getElementById("pnAdvancerLeft");
var pnAdvancerRight = document.getElementById("pnAdvancerRight");
// the indicator
var pnIndicator = document.getElementById("pnIndicator");

var pnProductNav = document.getElementById("pnProductNav");
var pnProductNavContents = document.getElementById("pnProductNavContents");

if (pnProductNav)
  pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));

// Set the indicator
if (pnProductNav)
  moveIndicator(pnProductNav.querySelector("[aria-selected=\"true\"]"), "#0079c0");

// Handle the scroll of the horizontal container
var last_known_scroll_position = 0;
var ticking = false;

function doSomething(scroll_pos) {
  if (pnProductNav)
    pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
}

if (pnProductNav)
  pnProductNav.addEventListener("scroll", function () {
    last_known_scroll_position = window.scrollY;
    if (!ticking) {
      window.requestAnimationFrame(function () {
        doSomething(last_known_scroll_position);
        ticking = false;
      });
    }
    ticking = true;
  });

if (pnAdvancerLeft)
  pnAdvancerLeft.addEventListener("click", function () {
    // If in the middle of a move return
    if (SETTINGS.navBarTravelling === true) {
      return;
    }
    // If we have content overflowing both sides or on the left
    if (determineOverflow(pnProductNavContents, pnProductNav) === "left" || determineOverflow(pnProductNavContents, pnProductNav) === "both") {
      // Find how far this panel has been scrolled
      var availableScrollLeft = pnProductNav.scrollLeft;
      // If the space available is less than two lots of our desired distance, just move the whole amount
      // otherwise, move by the amount in the settings
      if (availableScrollLeft < SETTINGS.navBarTravelDistance * 2) {
        pnProductNavContents.style.transform = "translateX(" + availableScrollLeft + "px)";
      } else {
        pnProductNavContents.style.transform = "translateX(" + SETTINGS.navBarTravelDistance + "px)";
      }
      // We do want a transition (this is set in CSS) when moving so remove the class that would prevent that
      pnProductNavContents.classList.remove("pn-ProductNav_Contents-no-transition");
      // Update our settings
      SETTINGS.navBarTravelDirection = "left";
      SETTINGS.navBarTravelling = true;
    }
    // Now update the attribute in the DOM
    pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
  });

if (pnAdvancerRight)
  pnAdvancerRight.addEventListener("click", function () {
    // If in the middle of a move return
    if (SETTINGS.navBarTravelling === true) {
      return;
    }
    // If we have content overflowing both sides or on the right
    if (determineOverflow(pnProductNavContents, pnProductNav) === "right" || determineOverflow(pnProductNavContents, pnProductNav) === "both") {
      // Get the right edge of the container and content
      var navBarRightEdge = pnProductNavContents.getBoundingClientRect().right;
      var navBarScrollerRightEdge = pnProductNav.getBoundingClientRect().right;
      // Now we know how much space we have available to scroll
      var availableScrollRight = Math.floor(navBarRightEdge - navBarScrollerRightEdge);
      // If the space available is less than two lots of our desired distance, just move the whole amount
      // otherwise, move by the amount in the settings
      if (availableScrollRight < SETTINGS.navBarTravelDistance * 2) {
        pnProductNavContents.style.transform = "translateX(-" + availableScrollRight + "px)";
      } else {
        pnProductNavContents.style.transform = "translateX(-" + SETTINGS.navBarTravelDistance + "px)";
      }
      // We do want a transition (this is set in CSS) when moving so remove the class that would prevent that
      pnProductNavContents.classList.remove("pn-ProductNav_Contents-no-transition");
      // Update our settings
      SETTINGS.navBarTravelDirection = "right";
      SETTINGS.navBarTravelling = true;
    }
    // Now update the attribute in the DOM
    pnProductNav.setAttribute("data-overflowing", determineOverflow(pnProductNavContents, pnProductNav));
  });

if (pnProductNavContents)
  pnProductNavContents.addEventListener(
    "transitionend",
    function () {
      // get the value of the transform, apply that to the current scroll position (so get the scroll pos first) and then remove the transform
      var styleOfTransform = window.getComputedStyle(pnProductNavContents, null);
      var tr = styleOfTransform.getPropertyValue("-webkit-transform") || styleOfTransform.getPropertyValue("transform");
      // If there is no transition we want to default to 0 and not null
      var amount = Math.abs(parseInt(tr.split(",")[4]) || 0);
      pnProductNavContents.style.transform = "none";
      pnProductNavContents.classList.add("pn-ProductNav_Contents-no-transition");
      // Now lets set the scroll position
      if (SETTINGS.navBarTravelDirection === "left") {
        pnProductNav.scrollLeft = pnProductNav.scrollLeft - amount;
      } else {
        pnProductNav.scrollLeft = pnProductNav.scrollLeft + amount;
      }
      SETTINGS.navBarTravelling = false;
    },
    false
  );

// var count = 0;
function moveIndicator(item, color) {
  if (item == null) return;
  var textPosition = item.getBoundingClientRect();
  var container = pnProductNavContents ? pnProductNavContents.getBoundingClientRect().left : undefined;
  var distance = textPosition.left - container;
  var scroll = pnProductNavContents ? pnProductNavContents.scrollLeft : undefined;
  if (pnIndicator) {
    pnIndicator.style.transform = "translateX(" + (distance + scroll) + "px) scaleX(" + textPosition.width * 0.01 + ")";
    if (distance + scroll == 0)
      pnProductNav.scrollLeft = distance + scroll;
    else
      pnProductNav.scrollLeft = distance + scroll - 50;
    // count = count += 100;
    // pnIndicator.style.transform = "translateX(" + count + "px)";

    if (color) {
      pnIndicator.style.backgroundColor = color;
    }
  }
}

function determineOverflow(content, container) {
  var containerMetrics = container.getBoundingClientRect();
  var containerMetricsRight = Math.floor(containerMetrics.right);
  var containerMetricsLeft = Math.floor(containerMetrics.left);
  var contentMetrics = content.getBoundingClientRect();
  var contentMetricsRight = Math.floor(contentMetrics.right);
  var contentMetricsLeft = Math.floor(contentMetrics.left);
  if (containerMetricsLeft > contentMetricsLeft && containerMetricsRight < contentMetricsRight) {
    return "both";
  } else if (contentMetricsLeft < containerMetricsLeft) {
    return "left";
  } else if (contentMetricsRight > containerMetricsRight) {
    return "right";
  } else {
    return "none";
  }
}

/**
 * @fileoverview dragscroll - scroll area by dragging
 * @version 0.0.8
 * 
 * @license MIT, see http://github.com/asvd/dragscroll
 * @copyright 2015 asvd <heliosframework@gmail.com> 
 */


(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define(['exports'], factory);
  } else if (typeof exports !== 'undefined') {
    factory(exports);
  } else {
    factory((root.dragscroll = {}));
  }
}(this, function (exports) {
  var _window = window;
  var _document = document;
  var mousemove = 'mousemove';
  var mouseup = 'mouseup';
  var mousedown = 'mousedown';
  var EventListener = 'EventListener';
  var addEventListener = 'add' + EventListener;
  var removeEventListener = 'remove' + EventListener;
  var newScrollX, newScrollY;

  var dragged = [];
  var reset = function (i, el) {
    for (i = 0; i < dragged.length;) {
      el = dragged[i++];
      el = el.container || el;
      el[removeEventListener](mousedown, el.md, 0);
      _window[removeEventListener](mouseup, el.mu, 0);
      _window[removeEventListener](mousemove, el.mm, 0);
    }

    // cloning into array since HTMLCollection is updated dynamically
    dragged = [].slice.call(_document.getElementsByClassName('dragscroll'));
    for (i = 0; i < dragged.length;) {
      (function (el, lastClientX, lastClientY, pushed, scroller, cont) {
        (cont = el.container || el)[addEventListener](
          mousedown,
          cont.md = function (e) {
            if (!el.hasAttribute('nochilddrag') ||
              _document.elementFromPoint(
                e.pageX, e.pageY
              ) == cont
            ) {
              pushed = 1;
              lastClientX = e.clientX;
              lastClientY = e.clientY;

              e.preventDefault();
            }
          }, 0
        );

        _window[addEventListener](
          mouseup, cont.mu = function () {
            pushed = 0;
          }, 0
        );

        _window[addEventListener](
          mousemove,
          cont.mm = function (e) {
            if (pushed) {
              (scroller = el.scroller || el).scrollLeft -=
                newScrollX = (-lastClientX + (lastClientX = e.clientX));
              scroller.scrollTop -=
                newScrollY = (-lastClientY + (lastClientY = e.clientY));
              if (el == _document.body) {
                (scroller = _document.documentElement).scrollLeft -= newScrollX;
                scroller.scrollTop -= newScrollY;
              }
            }
          }, 0
        );
      })(dragged[i++]);
    }
  }


  if (_document.readyState == 'complete') {
    reset();
  } else {
    _window[addEventListener]('load', reset, 0);
  }

  exports.reset = reset;
}));
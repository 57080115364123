// ***********************************REPORT Page*********************************
// *************************************************************************************
function infographicsPage() {

    var ultimateColors = [
        ['rgb(176, 252, 204)', 'rgb(0,206,244)', 'rgb(200,228,0)', 'rgb(255,155,0)', 'rgb(175,157,200)', 'rgb(237,69,21)', 'rgb(45,107,182)', 'rgb(242,255,0)', 'rgb(218,181,69)', 'rgb(255,128,114)', 'rgb(241,0,82)', 'rgb(241,201,122)', 'rgb(127,103,191)', 'rgb(203,72,195)', 'rgb(235,247,249)', 'rgb(230,207,88)', 'rgb(248,0,118)', 'rgb(169,193,205)'],
        ['rgb(213,215,0)', 'rgb(237,69,21)'],
    ];

    if (document.getElementById('PieChartList') && smallPieChartsDatas) {

        var xVals = [
            [0, .25],
            [.25, .50],
            [.51, .75]
        ]
        var datapielist = []
        for (var i = 0; i < smallPieChartsDatas.length; i++) {
            datapielist.push({
                values: [smallPieChartsDatas[i].positiveCount, smallPieChartsDatas[i].negativeCount],
                labels: ['Positive', 'Negative'],
                domain: {
                    x: xVals[i]
                },
                textinfo: 'none',
                marker: {
                    colors: ultimateColors[1]
                },
                // name: 'facebook',
                hoverinfo: 'label+percent',
                hole: .4,
                type: 'pie'
            })
        }
        var layoutpielist = {
            height: 150,
            width: 550,
            margin: {
                l: 15,
                r: 15,
                b: 10,
                t: 10,
                pad: 10
            },
            showlegend: true,
            legend: {
                "orientation": "h"
            },
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)'
        };

        Plotly.newPlot("PieChartList", datapielist, layoutpielist, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });
    }

    if (document.getElementById('SourcesPieChart')) {
        var datapie = [{
            values: sourcesPieChartData.values,
            labels: sourcesPieChartData.labels,
            type: 'pie',
            marker: {
                colors: ultimateColors[0]
            },
            textfont: {
                color: 'black'
            },
            textinfo: 'none',
        }];
        var layoutpie = {
            height: 400,
            width: 400,
            title: 'Mentions Distribution by Channel',
            showlegend: true,
            legend: {
                "orientation": "h"
            },
            font: {
                family: 'Roboto,Helvetica Neue,Arial,sans-serif',
                size: 12,
                color: "black"
            },
            margin: {
                l: 15,
                r: 15,
                b: 10,
                t: 40,
                pad: 40
            },
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)',
        };
        Plotly.newPlot('SourcesPieChart', datapie, layoutpie, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });
    }

    if (document.getElementById('mentionsWithSentiment') || document.getElementById('mentionsOverTime')) {
        var url = document.location.href;
        if (url.indexOf("&sentiment=POSITIVE") < 0 && url.indexOf("&sentiment=NEGATIVE") > 0) {

            var reportpositiveCount = {
                x: mentionsOverTimeSentimentData.dates,
                y: mentionsOverTimeSentimentData.positiveCount,
                type: 'scatter',
                // mode: 'lines+markers',
                name: 'Positive Mentions',
                hoverinfo: 'x+y',
                line: {
                    color: '#c8e400'
                },
                visible: "legendonly"
            }
        } else {
            var reportpositiveCount = {
                x: mentionsOverTimeSentimentData.dates,
                y: mentionsOverTimeSentimentData.positiveCount,
                type: 'scatter',
                // mode: 'lines+markers',
                name: 'Positive Mentions',
                hoverinfo: 'x+y',
                line: {
                    color: '#c8e400'
                }
            }
        }

        if (url.indexOf("&sentiment=POSITIVE") > 0 && url.indexOf("&sentiment=NEGATIVE") < 0) {
            var reportnegativesCount = {
                x: mentionsOverTimeSentimentData.dates,
                y: mentionsOverTimeSentimentData.negativeCount,
                type: 'scatter',
                // mode: 'lines+markers',
                name: 'Negative Mentions',
                hoverinfo: 'y',
                line: {
                    color: '#f92000'
                },

                visible: "legendonly"
            }
        } else {
            var reportnegativesCount = {
                x: mentionsOverTimeSentimentData.dates,
                y: mentionsOverTimeSentimentData.negativeCount,
                type: 'scatter',
                // mode: 'lines+markers',
                name: 'Negative Mentions',
                hoverinfo: 'y',
                line: {
                    color: '#f92000'
                },

            }
        }
        var reportTotalCount = {
            x: mentionsOverTimeData.dates,
            y: mentionsOverTimeData.totalCount,
            type: 'scatter',
            mode: 'lines+markers',
            name: 'Total Mentions',
            hoverinfo: 'y',
            // showlegend: false,
            line: {
                color: '#00ccf7'
            },

        }

        var layoutsentiment = {
            title: 'Mentions with Sentiment',
            showlegend: true,
            legend: {
                font: {
                    // family: 'sans-serif',
                    size: 12,
                    color: '#000'
                },
                "orientation": "h",
                margin: {
                    // t:20
                    // l:500,
                    // pad:20
                },
                x: 0.25,
                y: -0.2,
            },
            width: 500,
            height: 180,
            // hovermode: 'closest',
            // hoveron: 'points',
            margin: {
                l: 50,
                r: 50,
                b: 10,
                t: 40,
                pad: 0
            },
            font: {
                family: 'Roboto,Helvetica Neue,Arial,sans-serif',
                size: 12,
                color: "black"
            },
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)',
            autosize: true,

            xaxis: {
                showgrid: false,
                zeroline: false,
                showline: true,
                fixedrange: true,
                tickformat: '%d/%m',
                rangemode: 'nonnegative',
                autorange: true,
            },
            yaxis: {
                zeroline: false,
                showgrid: false,
                showline: true,
                fixedrange: true,
                rangemode: 'nonnegative',
                autorange: true,
            }
        }


        var layoutovertime = {
            title: 'Total Mentions Over Time',
            showlegend: false,
            legend: {
                "orientation": "h"
            },
            width: 500,
            height: 150,

            // hovermode: 'closest',
            // hoveron: 'points',
            margin: {
                l: 50,
                r: 50,
                b: 20,
                t: 40,
                pad: 0
            },
            font: {
                family: 'Roboto,Helvetica Neue,Arial,sans-serif',
                size: 12,
                color: "black"
            },
            autosize: true,
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)',

            xaxis: {
                // showticklabels: true,
                // tickangle: 0,
                // tickfont: {
                //     size: 12,
                //     color: '#000'
                // },
                // exponentformat: 'e',
                // showexponent: 'All',
                zeroline: false,
                fixedrange: true,
                showgrid: false,
                showline: true,
                tickformat: '%d/%m',
                rangemode: 'nonnegative',
                autorange: true,
            },
            yaxis: {
                // showticklabels: true,
                // tickangle: 0,
                // tickfont: {
                //     size: 12,
                //     color: '#000'
                // },
                showgrid: false,
                zeroline: false,
                showline: true,
                // exponentformat: 'e',
                // showexponent: 'All',
                fixedrange: true,
                rangemode: 'nonnegative',
                autorange: true,
            }
        }


        var datasentiment = [reportpositiveCount, reportnegativesCount];
        var overtime = [reportTotalCount]

        Plotly.newPlot('mentionsWithSentiment', datasentiment, layoutsentiment, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });

        Plotly.newPlot('mentionsOverTime', overtime, layoutovertime, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });



    }
}


function pdfpage() {
    var ultimateColors = [
        ['rgb(176, 252, 204)', 'rgb(0,206,244)', 'rgb(200,228,0)', 'rgb(255,155,0)', 'rgb(175,157,200)', 'rgb(237,69,21)', 'rgb(45,107,182)', 'rgb(242,255,0)', 'rgb(218,181,69)', 'rgb(255,128,114)', 'rgb(241,0,82)', 'rgb(241,201,122)', 'rgb(127,103,191)', 'rgb(203,72,195)', 'rgb(235,247,249)', 'rgb(230,207,88)', 'rgb(248,0,118)', 'rgb(169,193,205)'],
        ['rgb(38,40,88)', 'rgb(81,86,180)', 'rgb(93,98,185)', 'rgb(61,65,141)', 'rgb(68,77,162)', 'rgb(75,86,183)', 'rgb(85,94,195)', 'rgb(134,140,211)', 'rgb(169,172,222)', 'rgb(189,201,227)', 'rgb(204,206,233)'],
        ['rgb(75,80,175)', 'rgb(145,153,211)'],
    ];

    if (document.getElementById('pdfMentionByType') && pdfmentionsobj && pdfmentionsobj != null) {
        var textInBracket = []
        // for (var i = 0; i != pdfmentionsobj.sourceValue.length; i++) {
        //     textInBracket.push(pdfmentionsobj.sourceName[i] + "(" + pdfmentionsobj.sourceValue[i] + ")")
        // }
        textInBracket.push(pdfmentionsobj.positiveCount.reduce(function (a, b) { return a + b }, 0))
        textInBracket.push(pdfmentionsobj.negativeCount.reduce(function (a, b) { return a + b }, 0))
        textInBracket.push(pdfmentionsobj.neutralCount.reduce(function (a, b) { return a + b }, 0))
        var sourceDistributionpie = [{
            values: textInBracket,
            labels: ['Positive', 'Negative', 'Neutral'],
            text: textInBracket,
            type: 'pie',
            textinfo: 'none',
            marker: {
                colors: ['#c8e400', '#f92000', '#00ccf7']
            },
            hoverinfo: 'text+percent',
            hole: .3,
        }]
        var layout = {
            sort: false,
            showlegend: true,
            legend: {
                "orientation": "h",
                font: {
                    size: 40,
                    color: '#000'
                }

                // y: 0.5
                // x: .7,
            },
            margin: {
                b: 50,
                t: 10,
                l: 10,
                r: 10,
            },
            autosize: true,
            paper_bgcolor: 'rgba(0,0,0,0)',
            font: {
                size: 35,
            },
            height: 400,
            width: 700
        };
        if ($(window).width() < 1920 && $(window).height() < 1080) {
            layout.legend.font.size = 14
            layout.font.size = 12
            layout.height = 220
            layout.width = 270
            layout.margin = {
                b: 0,
                t: 0,
                l: 0,
                r: 0,
            }
        }
        Plotly.newPlot('pdfMentionByType', sourceDistributionpie, layout, {
            displayModeBar: false,
            // modeBarButtons: modeBarButtons,
            displaylogo: false,
            showTips: false
        });

    }
    // pdf 1.1
    if (document.getElementById('pdfmentionsOverTimeBySentiment') && pdfmentionsobj) {

        var lineChartData = {
            labels: pdfmentionsobj.dates,
            datasets: [{
                borderColor: '#c8e400',
                data: pdfmentionsobj.positiveCount,
                label: "Positive Mentions",
                backgroundColor: '#c8e400',
                lineThickness: 0,

            }, {
                data: pdfmentionsobj.negativeCount,
                borderColor: '#f92000',
                label: "Negative Mentions",
                backgroundColor: '#f92000',
            }, {
                data: pdfmentionsobj.neutralCount,
                borderColor: '#00ccf7',
                label: "Neutral Mentions",
                backgroundColor: '#00ccf7',
            }]
        }

        var sentimentChartLayout = JSON.parse(JSON.stringify(lineChartLayout))
        sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + pdfmentionsobj.dateFrom + '-' + pdfmentionsobj.dateTo + ')'
        sentimentChartLayout.scales.xAxes[0].type = 'time'
        sentimentChartLayout.scales.xAxes[0].ticks.source = 'data'
        sentimentChartLayout.scales.xAxes[0].time = {
            // unit: 'day',
            minUnit: 'day',
            displayFormats: {
                quarter: 'MMM D'
            },
            tooltipFormat: "DD-MM-YYYY",
            // round: 'day',
        }
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            sentimentChartLayout.scales.xAxes[0].ticks.fontSize = 35
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 35
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 40
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 40
            // sentimentChartLayout.scales.xAxes[0].gridLines.display = true
            // sentimentChartLayout.scales.yAxes[0].gridLines.display = true
            sentimentChartLayout.legend.labels.fontSize = 40
        }
        else {
            sentimentChartLayout.scales.xAxes[0].ticks.fontSize = 12
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 12
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12
            // sentimentChartLayout.scales.xAxes[0].gridLines.display = true
            // sentimentChartLayout.scales.yAxes[0].gridLines.display = true
            sentimentChartLayout.legend.labels.fontSize = 14
        }
        var ctx = $('#pdfmentionsOverTimeBySentiment');
        var graphData = {
            type: 'line',
            data: lineChartData,
            options: sentimentChartLayout,
        }

        LineChartDemo = new Chart(ctx, graphData);
        chartjsGraphsLoaded.push({
            id: "pdfmentionsOverTimeBySentiment",
            data: graphData,
            chartObj: LineChartDemo,
            expandRatio: 1
        })
        LineChartDemo.resize();



    }

    if (document.getElementById('pdfmentionsOverTimeBySentimentBarGraph') && pdfmentionsobj) {

        var socialMention = document.getElementById('pdfmentionsOverTimeBySentimentBarGraph')
        var urlLabel = pdfmentionsobj.dates;
        for (var i = 0; i < pdfmentionsobj.dates.length; i++)
            urlLabel[i] = new Date(urlLabel[i]).toLocaleString('default', { month: 'short' }) + " " + new Date(urlLabel[i]).getFullYear()
        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: urlLabel,
            datasets: [{
                borderColor: '#c8e400',
                data: pdfmentionsobj.positiveCount,
                label: "Postive Mentions",
                backgroundColor: '#c8e400',
                lineThickness: 0,

            }, {
                data: pdfmentionsobj.negativeCount,
                borderColor: '#f92000',
                label: "Negative Mentions",
                backgroundColor: '#f92000',
            }, {
                data: pdfmentionsobj.neutralCount,
                borderColor: '#00ccf7',
                label: "Neutral Mentions",
                backgroundColor: '#00ccf7',
            }]
        }

        var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
        sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Date of Mention (' + pdfmentionsobj.dateFrom + '-' + pdfmentionsobj.dateTo + ')',
            fontSize: 40,
            fontColor: '#000'
        }
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            sentimentChartLayout.legend.labels.fontSize = 40
            sentimentChartLayout.scales.xAxes[0].ticks.fontSize = 35
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 35
        }
        else {
            sentimentChartLayout.legend.labels.fontSize = 18
            sentimentChartLayout.scales.xAxes[0].ticks.fontSize = 12
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 12
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 18
        }
        // sentimentChartLayout.scales.xAxes[0].type = 'time'
        sentimentChartLayout.scales.xAxes[0].time = {
            // unit: 'month',
            displayFormats: {
                month: 'MMM YYYY'
            },
            // round: 'month',
        }
        sentimentChartLayout.tooltips.callbacks = {
            title: function () { }
        }

        // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
        var ctx = $('#pdfmentionsOverTimeBySentimentBarGraph');
        var chartData = {
            type: 'bar',
            data: BarChartData,
            options: sentimentChartLayout
        }
        pdfmentionsOverTimeBySentimentBarGraph = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "pdfmentionsOverTimeBySentimentBarGraph",
            data: chartData,
            chartObj: pdfmentionsOverTimeBySentimentBarGraph,
            expandRatio: 1
        })
    }

    // pdf 1.2
    if (document.getElementById('pdfmentionsOverTimeBySource') && pdfmentionsSourceobj) {

        var lineChartData = {
            labels: pdfmentionsSourceobj.dates,
            datasets: [{
                data: pdfmentionsSourceobj.totalCount,
                borderColor: '#00FFFF',
                label: "Total Mentions",
                backgroundColor: '#00FFFF',
            }]
        }

        var sentimentChartLayout = JSON.parse(JSON.stringify(lineChartLayout))
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            sentimentChartLayout.scales.xAxes[0].ticks.fontSize = 35
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 35
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 40
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 40
        }
        else {
            sentimentChartLayout.scales.xAxes[0].ticks.fontSize = 12
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 12
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12
        }
        sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + pdfmentionsSourceobj.dateFrom + '-' + pdfmentionsSourceobj.dateTo + ')'
        sentimentChartLayout.scales.xAxes[0].type = 'time'
        sentimentChartLayout.scales.xAxes[0].ticks.source = 'data'
        sentimentChartLayout.scales.xAxes[0].time = {
            // unit: 'day',
            minUnit: 'day',
            displayFormats: {
                quarter: 'MMM D'
            },
            tooltipFormat: "DD-MM-YYYY",
            // round: 'day',
        }
        sentimentChartLayout.animation = {
            duration: 1,
            onComplete: function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;
                if ($(window).width() > 1920 && $(window).height() > 1080) {
                    ctx.font = Chart.helpers.fontString('30', Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                }
                else
                    ctx.font = Chart.helpers.fontString('9', Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.fillStyle = '#000';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];
                        ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    });
                });
            }
        }
        sentimentChartLayout.legend = {
            display: false,
        }
        var ctx = $('#pdfmentionsOverTimeBySource');
        ctx[0].height = 160;
        ctx[0].width = 500;
        ctx[0].getContext('2d')

        // ctx.scale(2, 2);
        var graphData = {
            type: 'line',
            data: lineChartData,
            options: sentimentChartLayout,
        }
        graphData['options']['title'] = {
            display: true,
            fontStyle: "normal",
            fontWeight: "lighter",
        }
        graphData['options']['reponsive'] = true
        graphData['options']['maintainAspectRatio'] = false

        LineChartDemo = new Chart(ctx, graphData);
        chartjsGraphsLoaded.push({
            id: "pdfmentionsOverTimeBySource",
            data: graphData,
            chartObj: LineChartDemo,
            expandRatio: 2
        })
        $('#pdfmentionsOverTimeBySource').removeAttr("style");
    }

    if (document.getElementById('pdfmentionsOverTimeBySourceBarGraph') && pdfmentionsSourceobj) {

        var socialMention = document.getElementById('pdfmentionsOverTimeBySourceBarGraph')
        var urlLabel = pdfmentionsSourceobj.dates;
        for (var i = 0; i < pdfmentionsSourceobj.dates.length; i++)
            urlLabel[i] = new Date(urlLabel[i]).toLocaleString('default', { month: 'short' }) + " " + new Date(urlLabel[i]).getFullYear()
        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        // Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: urlLabel,
            datasets: [{
                data: pdfmentionsSourceobj.totalCount,
                borderColor: '#00FFFF',
                label: "Total Mentions",
                backgroundColor: '#00FFFF',
            }]
        }

        var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
        sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Date of Mention (' + pdfmentionsSourceobj.dateFrom + '-' + pdfmentionsSourceobj.dateTo + ')',
            fontSize: 40,
            fontColor: '#000'
        }
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            sentimentChartLayout.legend.labels.fontSize = 40
            sentimentChartLayout.scales.xAxes[0].ticks.fontSize = 35
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 35
        }
        else {
            sentimentChartLayout.legend.labels.fontSize = 18
            sentimentChartLayout.scales.xAxes[0].ticks.fontSize = 12
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 12
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 18
        }
        // sentimentChartLayout.scales.xAxes[0].type = 'time'
        sentimentChartLayout.legend.display = false
        sentimentChartLayout.scales.xAxes[0].time = {
            unit: 'month',
            displayFormats: {
                month: 'MMM YYYY'
            },
            // round: 'month',
        }
        sentimentChartLayout.tooltips.callbacks = {
            title: function () { }
        }

        sentimentChartLayout.animation = {
            duration: 1,
            onComplete: function () {
                var chartInstance = this.chart,
                    ctx = chartInstance.ctx;
                ctx.font = Chart.helpers.fontString('10', Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                ctx.textAlign = 'center';
                ctx.fillStyle = '#000';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];
                        ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    });
                });
            }
        }
        // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
        var ctx = $('#pdfmentionsOverTimeBySourceBarGraph');
        var chartData = {
            type: 'bar',
            data: BarChartData,
            options: sentimentChartLayout
        }
        pdfmentionsOverTimeBySourceBarGraph = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "pdfmentionsOverTimeBySourceBarGraph",
            data: chartData,
            chartObj: pdfmentionsOverTimeBySourceBarGraph,
            expandRatio: 1
        })
    }

    // pdf 2.2
    if (document.getElementById('pdfMentionsonNonSocialMediaPlatforms') && nsmPlatformDistributionPieChart && nsmPlatformDistributionPieChart != null) {
        var sourceNames = nsmPlatformDistributionPieChart.sourceName
        var lables = []
        for (var i = 0; i < sourceNames.length; i++) {
            lables.push(sourceNames[i])
        }

        var textInBracket = []
        for (var i = 0; i != lables.length; i++) {
            textInBracket.push(lables[i] + "(" + nsmPlatformDistributionPieChart.sourceValue[i] + ")")
        }
        var PieChartData = {
            labels: lables,
            datasets: [{
                data: nsmPlatformDistributionPieChart.sourceValue,
                backgroundColor: ultimateColors[0],
                borderColor: ultimateColors[0],
            }]
        }
        var ctx = $('#pdfMentionsonNonSocialMediaPlatforms');
        var graphData = {
            type: 'doughnut',
            data: PieChartData,
            options: JSON.parse(JSON.stringify(doughnutChartLayout)),
        }
        shareOfVoicePieChart = new Chart(ctx, graphData)
        chartjsGraphsLoaded.push({
            id: "pdfMentionsonNonSocialMediaPlatforms",
            data: graphData,
            chartObj: shareOfVoicePieChart,
            expandRatio: 2
        })
    }
    var mentionlayout = {
        sort: true,
        showlegend: true,
        textinfo: 'label+value',
        textposition: 'outside',
        legend: {
            "orientation": "h",
            font: {
                size: 14,
                color: '#000'
            }

            // y: 0.5
            // x: .7,
        },
        margin: {
            // b: 50,
            // t: 150,
            l: 170,
            r: 170,
            pad: 50,
        },
        autosize: true,
        paper_bgcolor: 'rgba(0,0,0,0)',
        font: {
            size: 14,
        },
    };
    if (document.getElementById('pdfTypePieChart') && pdfTypePieChart && pdfTypePieChart.graph != null) {
        // var sourceNames = 
        var pdfTypePieChartGraph = [{
            values: pdfTypePieChart.graph.values,
            labels: pdfTypePieChart.graph.labels,
            text: pdfTypePieChart.graph.text,
            type: 'pie',
            textinfo: 'text',
            textposition: 'outside',
            marker: {
                colors: ultimateColors[0]
            },
            hoverinfo: 'text+percent',
            hole: .3,
            direction: 'clockwise'
        }]
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            mentionlayout.width = 1000
            mentionlayout.legend.font = 40
            mentionlayout.font.size = 35
        } else {
            mentionlayout.width = 350
        }
        Plotly.newPlot('pdfTypePieChart', pdfTypePieChartGraph, mentionlayout, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });
    }


    // pdf 2.1

    if (document.getElementById('pdfsourceDistributionPieChart') && sourceDistributionObject && sourceDistributionObject != null) {
        var pielabels = []
        for (var i = 0; i < sourceDistributionObject.sourceValue.length; i++) {
            pielabels.push(sourceDistributionObject.sourceName[i] + ' (' + sourceDistributionObject.sourceValue[i] + ')')
        }
        var pdfsourceDistributionPieChart = [{
            values: sourceDistributionObject.sourceValue,
            labels: sourceDistributionObject.sourceName,
            text: pielabels,
            type: 'pie',
            textinfo: 'text',
            textposition: 'outside',
            marker: {
                colors: ultimateColors[0]
            },
            hoverinfo: 'text+percent',
            hole: .3,
            automargin: true,
            direction: 'clockwise'
        }]
        // var pielabels = sourceDistributionObject.sourceName.map(function (name) {
        //     return name
        // })
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            mentionlayout.height = 1250
            mentionlayout.legend.font = 40
            mentionlayout.font.size = 35
        } else {
            mentionlayout.height = 550
            mentionlayout.width = 800
            // mentionlayout.textinfo = pielabels
        }
        Plotly.newPlot('pdfsourceDistributionPieChart', pdfsourceDistributionPieChart, mentionlayout, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });
    }


    if (document.getElementById('pdfkeywordChannelWiseData') && brandKeywordSourceGraph) {
        if (brandKeywordSourceGraph.type === 'bar') {
            var keywordChannelWiseGraph = document.getElementById('pdfkeywordChannelWiseData')
            var keywordsLabels = brandKeywordSourceGraph.graph[0].x
            // var allSentimentLabels = ['Positive', 'Negative', 'Neutral']
            var allvalues = []
            for (var i = 0; i < brandKeywordSourceGraph.graph.length; i++) {
                allvalues.push(brandKeywordSourceGraph.graph[i].y)
            }
            Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
            Chart.defaults.global.tooltips.bodyFontColor = "#fff";
            Chart.defaults.global.tooltips.titleFontColor = "#fff";
            Chart.defaults.global.legend.display = true
            var BarChartData = {
                labels: keywordsLabels,
                datasets: []
            }
            for (var i = 0; i < brandKeywordSourceGraph.graph.length; i++) {
                var data = {
                    borderColor: ultimateColors[0][i],
                    data: allvalues[i],
                    label: brandKeywordSourceGraph.graph[i].name,
                    backgroundColor: ultimateColors[0][i],
                }
                BarChartData.datasets.push(data)
            }

            var stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
            console.log(stackBarLayout)
            if ($(window).width() > 1920 && $(window).height() > 1080) {
                stackBarLayout.legend.labels.fontSize = 38
                stackBarLayout.scales.xAxes[0].ticks.fontSize = 35
                stackBarLayout.scales.yAxes[0].ticks.fontSize = 35
                // stackBarLayout.scales.xAxes[0].gridLines.display = true
                // stackBarLayout.scales.yAxes[0].gridLines.display = true
            }
            else {
                stackBarLayout.scales.xAxes[0].ticks.fontSize = 12
                stackBarLayout.scales.yAxes[0].ticks.fontSize = 12
                // stackBarLayout.scales.xAxes[0].gridLines.display = true
                // stackBarLayout.scales.yAxes[0].gridLines.display = true
                stackBarLayout.legend.labels.fontSize = 14
            }

            var ctx = $('#pdfkeywordChannelWiseData');
            var chartData = {
                type: 'bar',
                data: BarChartData,
                options: stackBarLayout
            }
            pdfkeywordChannelWiseData = new Chart(ctx, chartData);
            chartjsGraphsLoaded.push({
                id: "pdfkeywordChannelWiseData",
                data: chartData,
                chartObj: pdfkeywordChannelWiseData,
                expandRatio: 1
            })
        }
        else if (brandKeywordSourceGraph.type === 'pie') {
            var PieChartData = {
                labels: brandKeywordSourceGraph.graph.labels,
                datasets: [{
                    data: brandKeywordSourceGraph.graph.values,
                    backgroundColor: ultimateColors[0],
                    borderColor: ultimateColors[0],
                }]
            }
            var ctx = $('#pdfkeywordChannelWiseData');
            var pdfkeywordChannelWiseData = JSON.parse(JSON.stringify(doughnutChartLayout))
            if ($(window).width() > 1920 && $(window).height() > 1080) {
                pdfkeywordChannelWiseData.legend.labels.fontSize = 40
            }
            else {
                pdfkeywordChannelWiseData.legend.labels.fontSize = 12
            }
            pdfkeywordChannelWiseData['legend']['position'] = 'right'
            // locationChartLayout['legend']['display'] = false
            // pdfTypePieChartLayout['legend']['position'] = 'right'
            var graphData = {
                type: 'doughnut',
                data: PieChartData,
                options: pdfkeywordChannelWiseData,
            }
            pdfTypePieChartGraph = new Chart(ctx, graphData)
            chartjsGraphsLoaded.push({
                id: "pdfTypePieChart",
                data: graphData,
                chartObj: pdfTypePieChartGraph,
                expandRatio: 1
            })
            // pdfkeywordChannelWiseDataOnClick = function () {
            //     document.getElementById('pdfkeywordChannelWiseData').on('plotly_click', function (data) {
            //         var paramArray = splitIntoParams(href)
            //         paramArray = paramArray.filter(function (val) {
            //             if (val.param !== 'sourceType') return true
            //             return false
            //         })
            //         var hrefParams = mergeIntoUrl(paramArray)
            //         hrefParams = hrefParams.split('?')[1]
            //         var pts = '/enterprise-mention?' + hrefParams;
            //         for (var i = 0; i < data.points.length; i++) {
            //             data.points[i].label.replace("<br>", "")
            //             pts = pts + '&sourceType=' + changeSourceName(encodeURI(data.points[i].label))
            //         }
            //         window.open(pts, '_blank');
            //     });
            // }
            // plotlyPlotData.push({
            //     id: 'pdfkeywordChannelWiseData',
            //     data: [brandKeywordSourceGraph.graph],
            //     layout: rmoPielayout,
            //     onClickFunctionName: 'pdfkeywordChannelWiseDataOnClick'
            // });
            // Plotly.newPlot('pdfkeywordChannelWiseData', [brandKeywordSourceGraph.graph], rmoPielayout, {
            //     displayModeBar: false,
            //     displaylogo: false,
            //     showTips: false,
            //     responsive: true
            // });
            // pdfkeywordChannelWiseDataOnClick()
        }
    }
    if (document.getElementById('pdfkeywordSentimentWiseData') && brandKeywordSentimentGraph) {
        if (brandKeywordSentimentGraph.type === 'bar') {
            var keywordSentimentWiseGraph = document.getElementById('pdfkeywordSentimentWiseData')
            var keywordsLabels = brandKeywordSentimentGraph.graph[0].x
            // var allSentimentLabels = ['Positive', 'Negative', 'Neutral']
            var allvalues = []
            for (var i = 0; i < brandKeywordSentimentGraph.graph.length; i++) {
                allvalues.push(brandKeywordSentimentGraph.graph[i].y)
            }
            Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
            Chart.defaults.global.tooltips.bodyFontColor = "#fff";
            Chart.defaults.global.tooltips.titleFontColor = "#fff";
            Chart.defaults.global.legend.display = true
            var BarChartData = {
                labels: keywordsLabels,
                datasets: []
            }
            for (var i = 0; i < brandKeywordSentimentGraph.graph.length; i++) {
                var data = {
                    borderColor: ultimateColors[0][i],
                    data: allvalues[i],
                    label: brandKeywordSentimentGraph.graph[i].name,
                    backgroundColor: ultimateColors[0][i],
                }
                BarChartData.datasets.push(data)
            }

            stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
            if ($(window).width() > 1920 && $(window).height() > 1080) {
                stackBarLayout.legend.labels.fontSize = 38
                stackBarLayout.scales.xAxes[0].ticks.fontSize = 35
                stackBarLayout.scales.yAxes[0].ticks.fontSize = 35
                // stackBarLayout.scales.xAxes[0].gridLines.display = true
                // stackBarLayout.scales.yAxes[0].gridLines.display = true
            }
            else {
                stackBarLayout.legend.labels.fontSize = 14
                stackBarLayout.scales.xAxes[0].ticks.fontSize = 12
                stackBarLayout.scales.yAxes[0].ticks.fontSize = 12
                // stackBarLayout.scales.xAxes[0].gridLines.display = true
                // stackBarLayout.scales.yAxes[0].gridLines.display = true
            }


            var ctx = $('#pdfkeywordSentimentWiseData');
            var chartData = {
                type: 'bar',
                data: BarChartData,
                options: stackBarLayout
            }

            keywordSentimentWiseDataGraph = new Chart(ctx, chartData);
            chartjsGraphsLoaded.push({
                id: "pdfkeywordSentimentWiseData",
                data: chartData,
                chartObj: keywordSentimentWiseDataGraph,
                expandRatio: 1
            })
            if (keywordSentimentWiseDataGraph) {
                keywordSentimentWiseGraph.onclick = function (evt) {
                    var divertUrl = getCurrentUrlParamsLink('sentiment')
                    var activePoint = keywordSentimentWiseDataGraph.getElementAtEvent(evt)[0];
                    var data = activePoint._model;
                    var label = data.datasetLabel;
                    var codeSource = data.label
                    var url = divertUrl + '&sentiment=' + label.toUpperCase() + '&searchText=' + codeSource;
                    document.location.href = url
                };
            }
        }

        else if (brandKeywordSentimentGraph.type === 'pie') {
            var PieChartData = {
                labels: brandKeywordSentimentGraph.graph.labels,
                datasets: [{
                    data: brandKeywordSentimentGraph.graph.values,
                    backgroundColor: ultimateColors[0],
                    borderColor: ultimateColors[0],
                }]
            }
            var ctx = $('#pdfkeywordSentimentWiseData');
            var pdfkeywordSentimentWiseData = JSON.parse(JSON.stringify(doughnutChartLayout))
            if ($(window).width() > 1920 && $(window).height() > 1080) {
                pdfkeywordSentimentWiseData.legend.labels.fontSize = 40
            }
            else {
                pdfkeywordSentimentWiseData.legend.labels.fontSize = 18
            }
            pdfkeywordSentimentWiseData['legend']['position'] = 'right'
            // locationChartLayout['legend']['display'] = false
            // pdfTypePieChartLayout['legend']['position'] = 'right'
            var graphData = {
                type: 'doughnut',
                data: PieChartData,
                options: pdfkeywordSentimentWiseData,
            }
            pdfTypePieChartGraph = new Chart(ctx, graphData)
            chartjsGraphsLoaded.push({
                id: "pdfkeywordSentimentWiseData",
                data: graphData,
                chartObj: pdfTypePieChartGraph,
                expandRatio: 2
            })


            // keywordSentimentWiseDataOnClick = function () {
            //     document.getElementById('pdfkeywordSentimentWiseData').on('plotly_click', function (data) {
            //         var paramArray = splitIntoParams(href)
            //         paramArray = paramArray.filter(function (val) {
            //             if (val.param !== 'sentiment') return true
            //             return false
            //         })
            //         var hrefParams = mergeIntoUrl(paramArray)
            //         hrefParams = hrefParams.split('?')[1]
            //         var pts = '/enterprise-mention?' + hrefParams;
            //         for (var i = 0; i < data.points.length; i++) {
            //             data.points[i].label.replace("<br>", "")
            //             pts = pts + '&sentiment=' + encodeURI(data.points[i].label.toUpperCase())
            //         }
            //         window.open(pts, '_blank');
            //     });
            // }
            // plotlyPlotData.push({
            //     id: 'pdfkeywordSentimentWiseData',
            //     data: [brandKeywordSentimentGraph.graph],
            //     layout: rmoPielayout,
            //     onClickFunctionName: 'keywordSentimentWiseDataOnClick'
            // });
            // Plotly.newPlot('pdfkeywordSentimentWiseData', [brandKeywordSentimentGraph.graph], rmoPielayout, {
            //     // modeBarButtons: modeBarButtons,
            //     displayModeBar: false,
            //     displaylogo: false,
            //     showTips: false,
            //     responsive: true
            // });
            // keywordSentimentWiseDataOnClick()

        }
    }

    // if (document.getElementById('mentionsWithSentimentPDF') && document.getElementById('mentionsOverTimePDF')) {
    //     var reportpositiveCount = {
    //         x: mentionsOverTimeBySentimentGraph.dates,
    //         y: mentionsOverTimeBySentimentGraph.positiveCount,
    //         type: 'scatter',
    //         // mode: 'lines+markers',
    //         name: 'Positive',
    //         hoverinfo: 'x+y',
    //         line: {
    //             color: '#c8e400'
    //         }
    //     }
    //     var reportnegativesCount = {
    //         x: mentionsOverTimeBySentimentGraph.dates,
    //         y: mentionsOverTimeBySentimentGraph.negativeCount,
    //         type: 'scatter',
    //         // mode: 'lines+markers',
    //         name: 'Negative',
    //         hoverinfo: 'x+y',
    //         line: {
    //             color: '#FF0000'
    //         }
    //     }
    //     var reportTotalCount = {
    //         x: totalMentionsOverTimeGraph.dates,
    //         y: totalMentionsOverTimeGraph.totalCount,
    //         type: 'scatter',
    //         mode: 'lines+markers',
    //         name: 'Total',
    //         hoverinfo: 'x+y',
    //         // showlegend: false,
    //         line: {
    //             color: '#00ccf7'
    //         },
    //     }
    //     var layoutsentimentpdf = {
    //         title: 'Mentions with Sentiment',
    //         showlegend: true,
    //         legend: {
    //             "orientation": "h",
    //             bgcolor: '#fff',
    //         },
    //         height: 600,
    //         width: 550,

    //         font: {
    //             family: 'Roboto,Helvetica Neue,Arial,sans-serif',
    //             size: 12,
    //             color: "black"
    //         },
    //         // paper_bgcolor: 'rgba(255,0,0,0.5); ',
    //         paper_bgcolor: 'rgba(0,0,0,0)',
    //         plot_bgcolor: 'white',
    //         autosize: true,

    //         xaxis: {
    //             zeroline: false,
    //             showgrid: false,
    //             showline: true,
    //             fixedrange: true,
    //             tickformat: '%d/%m',
    //             rangemode: 'nonnegative',
    //             autorange: true,
    //         },
    //         yaxis: {
    //             zeroline: false,
    //             showgrid: false,
    //             showline: true,
    //             fixedrange: true,
    //             rangemode: 'nonnegative',
    //             autorange: true,
    //         },
    //     }
    //     var layoutovertimepdf = {
    //         title: 'Mentions Over Time',
    //         showlegend: true,
    //         legend: {
    //             "orientation": "h",
    //             bgcolor: '#fff',
    //         },
    //         height: 600,
    //         width: 550,

    //         font: {
    //             family: 'Roboto,Helvetica Neue,Arial,sans-serif',
    //             size: 12,
    //             color: "black"
    //         },
    //         autosize: true,
    //         // paper_bgcolor: 'rgba(255,0,0,0.5); ',
    //         paper_bgcolor: 'rgba(0,0,0,0)',
    //         plot_bgcolor: 'white',

    //         xaxis: {
    //             zeroline: false,
    //             fixedrange: false,
    //             showgrid: false,
    //             showline: true,
    //             tickformat: '%d/%m',
    //             rangemode: 'nonnegative',
    //             autorange: true,
    //         },
    //         yaxis: {
    //             zeroline: false,
    //             showgrid: false,
    //             showline: true,
    //             fixedrange: false,
    //             rangemode: 'nonnegative',
    //             autorange: true,
    //         },
    //     }

    //     var datasentiment = [reportpositiveCount, reportnegativesCount];
    //     var overtime = [reportTotalCount]

    //     Plotly.newPlot('mentionsWithSentimentPDF', datasentiment, layoutsentimentpdf, {
    //         displayModeBar: false,
    //         displaylogo: false,
    //         showTips: false
    //     });

    //     Plotly.newPlot('mentionsOverTimePDF', overtime, layoutovertimepdf, {
    //         displayModeBar: false,
    //         displaylogo: false,
    //         showTips: false
    //     });
    // }

    // if (document.getElementById('SourcesPieChartPDF')) {
    //     var datapie = [{
    //         values: sourcesPieChartData.values,
    //         labels: sourcesPieChartData.labels,
    //         type: 'pie',
    //         marker: {
    //             colors: ultimateColors[0]
    //         },
    //         textfont: {
    //             color: '#000'
    //         },
    //         textinfo: 'labels+values',
    //     }];
    //     var layoutpie = {
    //         height: 400,
    //         width: 1050,
    //         title: 'Sources',
    //         showlegend: true,
    //         legend: {
    //             "orientation": "h",
    //             bgcolor: '#fff',
    //             xanchor: 'center',
    //             y: -0.5,
    //             x: 0.5,
    //         },
    //         textfont: {
    //             color: '#000'
    //         },
    //         // paper_bgcolor: 'rgba(255,0,0,0.5); ',
    //         paper_bgcolor: 'rgba(0,0,0,0)',
    //         plot_bgcolor: 'white',
    //     };
    //     Plotly.newPlot('SourcesPieChartPDF', datapie, layoutpie, {
    //         displayModeBar: false,
    //         displaylogo: false,
    //         showTips: false
    //     });
    // }
    if (document.getElementById('pdftopicDivisionPieChart') && pdftopicDivisionPieChart && pdftopicDivisionPieChart != null && pdftopicDivisionPieChart.length > 0) {
        var topicDivision = document.getElementById('pdftopicDivisionPieChart')
        var ctx = $('#pdftopicDivisionPieChart');
        var labels = pdftopicDivisionPieChart[0].x;
        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        var stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            stackBarLayout.scales.xAxes[0].ticks.fontSize = 35
            stackBarLayout.scales.yAxes[0].ticks.fontSize = 35
        }
        else {
            stackBarLayout.scales.xAxes[0].ticks.fontSize = 12
            stackBarLayout.scales.yAxes[0].ticks.fontSize = 12
        }
        var graphData = {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    borderColor: '#00b1b4',
                    data: pdftopicDivisionPieChart[0].y,
                    backgroundColor: '#00b1b4',
                }]
            },
            options: stackBarLayout,
        }
        graphData['options']['legend']['display'] = false
        topicDivisionBarChart = new Chart(ctx, graphData)
        chartjsGraphsLoaded.push({
            id: "pdftopicDivisionPieChart",
            data: graphData,
            chartObj: topicDivisionBarChart,
            expandRatio: 2
        })
    }
    if (document.getElementById('pdfbrandHashtagChannelWiseData') && pdfbrandHashtagSourceBarGraph) {
        var brandHashtagChannelWiseGraph = document.getElementById('pdfbrandHashtagChannelWiseData')
        var hashtagLabels = pdfbrandHashtagSourceBarGraph[0].x
        // var allSentimentLabels = ['Positive', 'Negative', 'Neutral']
        var allvalues = []
        for (var i = 0; i < pdfbrandHashtagSourceBarGraph.length; i++) {
            allvalues.push(pdfbrandHashtagSourceBarGraph[i].y)
        }
        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: hashtagLabels,
            datasets: []
        }
        for (var i = 0; i < pdfbrandHashtagSourceBarGraph.length; i++) {
            var data = {
                borderColor: ultimateColors[0][i],
                data: allvalues[i],
                label: pdfbrandHashtagSourceBarGraph[i].name,
                backgroundColor: ultimateColors[0][i],
            }
            BarChartData.datasets.push(data)
        }

        stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            stackBarLayout.legend.labels.fontSize = 38
            stackBarLayout.scales.xAxes[0].ticks.fontSize = 35
            stackBarLayout.scales.yAxes[0].ticks.fontSize = 35
        }
        else {
            stackBarLayout.legend.labels.fontSize = 14
            stackBarLayout.scales.xAxes[0].ticks.fontSize = 12
            stackBarLayout.scales.yAxes[0].ticks.fontSize = 12
        }
        // stackBarLayout.scales.xAxes[0].gridLines.display = true
        // stackBarLayout.scales.yAxes[0].gridLines.display = true
        // stackBarLayout.scales.xAxes[0].scaleLabel.fontSize = 18
        // stackBarLayout.scales.yAxes[0].scaleLabel.fontSize = 18

        var ctx = $('#pdfbrandHashtagChannelWiseData');
        var chartData = {
            type: 'bar',
            data: BarChartData,
            options: stackBarLayout
        }

        brandHashtagChannelWiseDataGraph = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "pdfbrandHashtagChannelWiseData",
            data: chartData,
            chartObj: brandHashtagChannelWiseDataGraph,
            expandRatio: 1
        })
        if (brandHashtagChannelWiseDataGraph) {
            brandHashtagChannelWiseGraph.onclick = function (evt) {
                var divertUrl = getCurrentUrlParamsLink('sourceType')
                var activePoint = brandHashtagChannelWiseDataGraph.getElementAtEvent(evt)[0];
                var data = activePoint._model;
                var label = data.datasetLabel;
                var codeSource = data.label
                var url = divertUrl + '&sourceType=' + changeSourceName(label) + '&searchText=' + codeSource.replace('#', 'HT:');
                document.location.href = url
            };
        }
    }

    if (document.getElementById('pdfbrandHashtagbyTimePeriod') && pdfbrandHashtagWithTimeGraph) {
        var datasets = [];
        for (var i = 0; i < pdfbrandHashtagWithTimeGraph.graph.length; i++) {
            datasets.push({
                borderColor: ultimateColors[0][i],
                data: pdfbrandHashtagWithTimeGraph.graph[i].y,
                label: "#" + pdfbrandHashtagWithTimeGraph.graph[i].name,
                backgroundColor: ultimateColors[0][i],
            })
        }
        var lineChartData1 = {
            labels: pdfbrandHashtagWithTimeGraph.graph[0].x,
            datasets: datasets
        }
        var sourceChartLayout = JSON.parse(JSON.stringify(lineChartLayout))
        sourceChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention'
        sourceChartLayout.scales.xAxes[0].ticks.source = 'data'
        sourceChartLayout.scales.xAxes[0].type = 'time'
        sourceChartLayout.scales.xAxes[0].time = {
            // unit: 'month',
            minUnit: 'day',
            displayFormats: {
                quarter: 'MMM D'
            },
            tooltipFormat: "MM-DD-YYYY",
            // round: 'month',
        }
        // sourceChartLayout.scales.xAxes[0].gridLines.display = true
        // sourceChartLayout.scales.yAxes[0].gridLines.display = true
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            sourceChartLayout.scales.xAxes[0].ticks.fontSize = 35
            sourceChartLayout.scales.yAxes[0].ticks.fontSize = 35
            sourceChartLayout.scales.xAxes[0].scaleLabel.fontSize = 35
            sourceChartLayout.scales.yAxes[0].scaleLabel.fontSize = 35
            sourceChartLayout.legend.labels.fontSize = 38
        }
        else {
            sourceChartLayout.scales.xAxes[0].ticks.fontSize = 12
            sourceChartLayout.scales.yAxes[0].ticks.fontSize = 12
            sourceChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
            sourceChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12
            sourceChartLayout.legend.labels.fontSize = 14
        }
        var ctx = $('#pdfbrandHashtagbyTimePeriod');
        var graphData = {
            type: 'line',
            data: lineChartData1,
            options: sourceChartLayout
        }
        // graphData['options']['title'] = {
        //   display: true,
        //   text: 'Performance of Web vs Social Channels',
        //   fontStyle: "normal",
        //   fontWeight: "lighter",
        // }
        brandHashtagbyTimePeriodChart = new Chart(ctx, graphData);
        chartjsGraphsLoaded.push({
            id: "pdfbrandHashtagbyTimePeriod",
            data: graphData,
            chartObj: brandHashtagbyTimePeriodChart,
            expandRatio: 1
        })
    }

    if (document.getElementById('pdfnumberofMentionsofYourHashtags') && pdfbrandHashtagMentionsWidget) {
        var brandHashtagChannelWiseGraph = document.getElementById('pdfnumberofMentionsofYourHashtags')
        var brandHashtagLabels = pdfbrandHashtagMentionsWidget.brandHashtagText
        var allvalues = []
        allvalues.push(pdfbrandHashtagMentionsWidget.brandHashtagBrandCount)
        allvalues.push(pdfbrandHashtagMentionsWidget.brandHashtagAudienceCount)
        var noOfHashtags = ['By You', 'By Your Audience']
        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: brandHashtagLabels,
            datasets: []
        }
        for (var i = 0; i < noOfHashtags.length; i++) {
            var data = {
                borderColor: ultimateColors[0][i],
                data: allvalues[i],
                label: noOfHashtags[i],
                backgroundColor: ultimateColors[0][i],
            }
            BarChartData.datasets.push(data)
        }

        stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            stackBarLayout.legend.labels.fontSize = 38
            stackBarLayout.scales.xAxes[0].ticks.fontSize = 35
            stackBarLayout.scales.yAxes[0].ticks.fontSize = 35
        }
        else {
            stackBarLayout.legend.labels.fontSize = 14
            stackBarLayout.scales.xAxes[0].ticks.fontSize = 12
            stackBarLayout.scales.yAxes[0].ticks.fontSize = 12
        }
        // stackBarLayout.scales.xAxes[0].gridLines.display = true
        // stackBarLayout.scales.yAxes[0].gridLines.display = true

        var ctx = $('#pdfnumberofMentionsofYourHashtags');
        var chartData = {
            type: 'horizontalBar',
            data: BarChartData,
            options: stackBarLayout
        }

        numberofMentionsofYourHashtagsGraph = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "pdfnumberofMentionsofYourHashtags",
            data: chartData,
            chartObj: numberofMentionsofYourHashtagsGraph,
            expandRatio: 1
        })
        if (numberofMentionsofYourHashtagsGraph) {
            brandHashtagChannelWiseGraph.onclick = function (evt) {
                var divertUrl = getCurrentUrlParamsLink('mentionOwner')
                var activePoint = numberofMentionsofYourHashtagsGraph.getElementAtEvent(evt)[0];
                var data = activePoint._model;
                var label = data.datasetLabel;
                var codeSource = data.label
                var divertUrl = divertUrl + '&mentionOwner=' + changeMentionOwnerText(label) + '&searchText=' + codeSource.replace('#', 'HT:');
                document.location.href = divertUrl
            };
        }
    }
    changeOfPerformanceOfContent = function (val) {
        if (val === "branddata") {
            return 'Brand Posted Content'
        }
        else if (val === "promoteddata") {
            return 'Brand Promoted UGC'
        }
        else if (val === "audiencedata") {
            return 'Independent UGC'
        }
    }
    if (document.getElementById('pdfugcRelatedDataGraphTimePeriod') && pdfugcRelatedDataGraph && Object.keys(pdfugcRelatedDataGraph).length > 0) {
        var datasets = [];
        var looplength = Object.keys(pdfugcRelatedDataGraph)
        for (var i = 0; i < looplength.length; i++) {
            datasets.push({
                borderColor: ultimateColors[0][i],
                data: pdfugcRelatedDataGraph[looplength[i]].counts,
                label: changeOfPerformanceOfContent(looplength[i]),
                backgroundColor: ultimateColors[0][i],
            })
        }
        var lineChartData1 = {
            labels: pdfugcRelatedDataGraph[looplength[0]].dates,
            datasets: datasets
        }
        var sourceChartLayout = JSON.parse(JSON.stringify(lineChartLayout))
        sourceChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention'
        sourceChartLayout.scales.xAxes[0].type = 'time'
        sourceChartLayout.scales.xAxes[0].time = {
            // unit: 'day',
            displayFormats: {
                quarter: 'MMM D'
            },
            tooltipFormat: "MM-DD-YYYY",
            // round: 'day',
        }
        // sourceChartLayout.scales.xAxes[0].gridLines.display = true
        // sourceChartLayout.scales.yAxes[0].gridLines.display = true
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            sourceChartLayout.scales.xAxes[0].ticks.fontSize = 35
            sourceChartLayout.scales.yAxes[0].ticks.fontSize = 35
            sourceChartLayout.scales.xAxes[0].scaleLabel.fontSize = 35
            sourceChartLayout.scales.yAxes[0].scaleLabel.fontSize = 35
            sourceChartLayout.legend.labels.fontSize = 38
        }
        else {
            sourceChartLayout.scales.xAxes[0].ticks.fontSize = 12
            sourceChartLayout.scales.yAxes[0].ticks.fontSize = 12
            sourceChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
            sourceChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12
            sourceChartLayout.legend.labels.fontSize = 14
        }
        var ctx = $('#pdfugcRelatedDataGraphTimePeriod');
        var graphData = {
            type: 'line',
            data: lineChartData1,
            options: sourceChartLayout
        }

        ugcRelatedDataGraphTimePeriodChart = new Chart(ctx, graphData);
        chartjsGraphsLoaded.push({
            id: "pdfugcRelatedDataGraphTimePeriod",
            data: graphData,
            chartObj: ugcRelatedDataGraphTimePeriodChart,
            expandRatio: 1
        })
    }

    // pdf 4.1
    if (document.getElementById('mentionsOverSocialInPdfFile') && mentionsOverSocialObj) {
        var setHeightManual = (70 * mentionsOverSocialObj.words.sourceList.length)
        var legendy = -0.2
        if (mentionsOverSocialObj.words.sourceList.length < 4) {
            if (mentionsOverSocialObj.words.sourceList.length < 2) {
                setHeightManual = setHeightManual < 140 ? 130 : setHeightManual
                legendy = -1.2
            } else {
                setHeightManual = setHeightManual < 200 ? 230 : setHeightManual > 350 ? 350 : setHeightManual
                legendy = -.5
            }

        } else {
            setHeightManual = setHeightManual > 350 ? 350 : setHeightManual
        }

        var positive = {
            x: mentionsOverSocialObj.words.positiveMentions,
            y: fixWordBreaks(mentionsOverSocialObj.words.sourceList, 15),
            name: 'Positive',
            orientation: 'h',
            marker: {
                color: '#c8e400',
            },
            type: 'bar',
            hoverinfo: 'name+x',
        };

        var negative = {
            x: mentionsOverSocialObj.words.negativeMentions,
            y: fixWordBreaks(mentionsOverSocialObj.words.sourceList, 15),
            name: 'Negative',
            orientation: 'h',
            type: 'bar',
            marker: {
                color: '#f92000',
            },
            hoverinfo: 'name+x',

        };
        var neutral = {
            x: mentionsOverSocialObj.words.neutralMentions,
            y: fixWordBreaks(mentionsOverSocialObj.words.sourceList, 15),
            name: 'Neutral',
            orientation: 'h',
            type: 'bar',
            marker: {
                color: '#00ccf7',
            },
            hoverinfo: 'name+x',
            // visible: 'legendonly',
        };

        var data = [positive, negative, neutral];
        var layout = {

            plot_bgcolor: 'white',
            paper_bgcolor: 'white',
            barmode: 'stack',
            margin: {
                l: 150,
                t: 10,
                b: 0,
                pad: 20,
            },
            font: {
                family: 'Roboto,Helvetica Neue,Arial,sans-serif',
                size: 14,
                color: "black"
            },
            legend: {
                font: {
                    size: 14,
                    color: '#000',

                },
                "orientation": "h",
                y: legendy,
                // x: 2
            },
            autosize: true,
            xaxis: {
                title: 'Counts',
                titlefont: {
                    size: 14,
                    color: '#000',

                },
                showticklabels: true,
                tickangle: 0,
                tickfont: {
                    size: 14,
                    color: '#000'
                },
                // exponentformat: 'e',
                showexponent: 'All',
                fixedrange: true,
                gridwidth: false,
                showgrid: false,
                rangemode: 'nonnegative',
                autorange: true,
            },
            yaxis: {
                showticklabels: true,
                tickangle: 0,
                tickfont: {
                    size: 14,
                    color: '#000'
                },
                // exponentformat: 'e',
                showexponent: 'All',
                fixedrange: true,
                gridwidth: false,
                showgrid: false,
            },
            height: setHeightManual

        }


        Plotly.newPlot('mentionsOverSocialInPdfFile', data, layout, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });
    }

    // pdf 8.1
    if (document.getElementById('pdfPopularHashtagsUsedbyYourAudience') && popularAudienceHashtagsWidget && popularAudienceHashtagsWidget != null) {
        var xhashtag = popularAudienceHashtagsWidget.hashtagsText;
        var yhashtag = popularAudienceHashtagsWidget.hashtagsCount;
        var PopularHashtagsbyYourAudience = {
            x: xhashtag,
            y: yhashtag,
            type: 'bar',
            hoverinfo: 'y',
            marker: {
                color: '#00ccf7',
            },
            hoverlabel: {
                font: {
                    color: '#fff',

                }
            },
        };
        var PopularHashtagsUsed = [PopularHashtagsbyYourAudience];
        var PopularHashtagsUsedbyYourAudiencelayout = {
            xaxis: {
                tickfont: {
                    size: 14,
                    color: '#000'
                },
                tickangle: -30,
                fixedrange: true,
                showticklabels: true,
                showgrid: false,
                zeroline: false,
                showline: false,
            },
            font: {
                family: 'Roboto,Helvetica Neue,Arial,sans-serif',
                size: 14,
                color: "black"
            },
            yaxis: {
                tickfont: {
                    size: 14,
                    color: "#000"
                },
                fixedrange: true,
                showticklabels: true,
                showgrid: false,
                zeroline: false,
                showline: false,
                rangemode: 'nonnegative',
                autorange: true,
            },
            margin: {
                b: 100,
                t: 0,
                r: 80,
                l: 80,
            },
            height: 310
        };
        Plotly.newPlot('pdfPopularHashtagsUsedbyYourAudience', PopularHashtagsUsed, PopularHashtagsUsedbyYourAudiencelayout, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });
    }


    // pdf 8.2


    // pdf 9
    // if (document.getElementById('featuresocialbarGraph') && featuresocialbarGraph) {
    //     var manualWidthSet = setManualWidth(featuresocialbarGraph)
    //     var socialbarGraph = [{
    //         x: featuresocialbarGraph.features,
    //         y: featuresocialbarGraph.count,
    //         width: .8,
    //         type: 'bar',
    //         hoverinfo: 'y',
    //         marker: {
    //             color: 'rgb(0,204,247)',
    //         },
    //         hoverlabel: {
    //             font: {
    //                 color: '#fff'
    //             }
    //         }
    //     }];
    //     var socialbarGraphlayout = {
    //         xaxis: {
    //             tickfont: {
    //                 size: 12,
    //                 color: '#000'
    //             },
    //             tickangle: -35,
    //             fixedrange: true,
    //             showticklabels: true,
    //             showgrid: false,
    //             zeroline: false,
    //             showline: false,
    //         },
    //         font: {
    //             family: 'Roboto,Helvetica Neue,Arial,sans-serif',
    //             size: 11,
    //             color: "black"
    //         },
    //         yaxis: {
    //             tickfont: {
    //                 size: 12,
    //                 color: "#000"
    //             },
    //             fixedrange: true,
    //             showticklabels: true,
    //             showgrid: false,
    //             zeroline: false,
    //             showline: false,
    //             rangemode: 'nonnegative',
    //             autorange: true,
    //         },
    //         margin: {
    //             b: 100,
    //             t: 10,
    //             r: 80,
    //             l: 100,
    //         },
    //         width: manualWidthSet,
    //         height: 300
    //     };
    //     Plotly.newPlot('featuresocialbarGraph', socialbarGraph, socialbarGraphlayout, {
    //         displayModeBar: false,
    //         displaylogo: false,
    //         showTips: false
    //     });
    // }
    // if (document.getElementById('featurearticlebarGraph') && featurearticlebarGraph) {
    //     var manualWidthSet = setManualWidth(featurearticlebarGraph)
    //     var articlebarGraph = [{
    //         x: featurearticlebarGraph.features,
    //         y: featurearticlebarGraph.count,
    //         type: 'bar',
    //         hoverinfo: 'y',
    //         marker: {
    //             color: 'rgb(0,204,247)',
    //         },
    //         hoverlabel: {
    //             font: {
    //                 color: '#fff'
    //             }
    //         }
    //     }];
    //     var articlebarGraphlayout = {
    //         xaxis: {
    //             tickfont: {
    //                 size: 12,
    //                 color: '#000'
    //             },
    //             tickangle: -35,
    //             fixedrange: true,
    //             showticklabels: true,
    //             showgrid: false,
    //             zeroline: false,
    //             showline: false,
    //         },
    //         font: {
    //             family: 'Roboto,Helvetica Neue,Arial,sans-serif',
    //             size: 12,
    //             color: "black"
    //         },
    //         yaxis: {
    //             tickfont: {
    //                 size: 12,
    //                 color: "#000"
    //             },
    //             fixedrange: true,
    //             showticklabels: true,
    //             showgrid: false,
    //             zeroline: false,
    //             showline: false,
    //             rangemode: 'nonnegative',
    //             autorange: true,
    //         },
    //         margin: {
    //             b: 100,
    //             t: 10,
    //             r: 80,
    //             l: 100,
    //         },
    //         width: manualWidthSet,
    //         height: 300
    //     };
    //     Plotly.newPlot('featurearticlebarGraph', articlebarGraph, articlebarGraphlayout, {
    //         displayModeBar: false,
    //         displaylogo: false,
    //         showTips: false
    //     });
    // }

    // if (document.getElementById('featureReviewbarGraph') && featureReviewbarGraph) {
    //     var manualWidthSet = setManualWidth(featureReviewbarGraph)
    //     var reviewbarGraph = [{
    //         x: featureReviewbarGraph.features,
    //         y: featureReviewbarGraph.count,
    //         type: 'bar',
    //         hoverinfo: 'y',
    //         marker: {
    //             color: 'rgb(0,204,247)',
    //         },
    //         hoverlabel: {
    //             font: {
    //                 color: '#fff'
    //             }
    //         }
    //     }];
    //     var reviewbarGraphlayout = {
    //         xaxis: {
    //             tickfont: {
    //                 size: 12,
    //                 color: '#000'
    //             },
    //             tickangle: -35,
    //             fixedrange: true,
    //             showticklabels: true,
    //             showgrid: false,
    //             zeroline: false,
    //             showline: false,
    //         },
    //         font: {
    //             family: 'Roboto,Helvetica Neue,Arial,sans-serif',
    //             size: 12,
    //             color: "black"
    //         },
    //         yaxis: {
    //             tickfont: {
    //                 size: 12,
    //                 color: "#000"
    //             },
    //             fixedrange: true,
    //             showticklabels: true,
    //             showgrid: false,
    //             zeroline: false,
    //             showline: false,
    //             rangemode: 'nonnegative',
    //             autorange: true,
    //         },
    //         margin: {
    //             b: 100,
    //             t: 10,
    //             r: 80,
    //             l: 100,
    //         },
    //         width: manualWidthSet,
    //         height: 300
    //     };
    //     Plotly.newPlot('featureReviewbarGraph', reviewbarGraph, reviewbarGraphlayout, {
    //         displayModeBar: false,
    //         displaylogo: false,
    //         showTips: false
    //     });
    // }
    // if (document.getElementById('featureCompleteBarGraph') && featureCompleteBarGraph) {
    //     var manualWidthSet = setManualWidth(featureCompleteBarGraph)

    //     var completeBarGraph = [{
    //         x: featureCompleteBarGraph.features,
    //         y: featureCompleteBarGraph.count,
    //         type: 'bar',
    //         hoverinfo: 'y',
    //         marker: {
    //             color: 'rgb(0,204,247)',
    //         },
    //         hoverlabel: {
    //             font: {
    //                 color: '#fff'
    //             }
    //         }
    //     }];
    //     var completeBarGraphlayout = {
    //         xaxis: {
    //             tickfont: {
    //                 size: 12,
    //                 color: '#000'
    //             },
    //             tickangle: -35,
    //             fixedrange: true,
    //             showticklabels: true,
    //             showgrid: false,
    //             zeroline: false,
    //             showline: false,
    //         },
    //         font: {
    //             family: 'Roboto,Helvetica Neue,Arial,sans-serif',
    //             size: 12,
    //             color: "black"
    //         },
    //         yaxis: {
    //             tickfont: {
    //                 size: 12,
    //                 color: "#000"
    //             },
    //             fixedrange: true,
    //             showticklabels: true,
    //             showgrid: false,
    //             zeroline: false,
    //             showline: false,
    //             rangemode: 'nonnegative',
    //             autorange: true,
    //         },
    //         margin: {
    //             b: 100,
    //             t: 10,
    //             r: 80,
    //             l: 100,
    //         },
    //         width: manualWidthSet,
    //         height: 300
    //     };
    //     Plotly.newPlot('featureCompleteBarGraph', completeBarGraph, completeBarGraphlayout, {
    //         displayModeBar: false,
    //         displaylogo: false,
    //         showTips: false
    //     });
    // }



    // if (document.getElementById('mostDiscussedPositiveAttributesPieChart') && positiveAttributesObject && positiveAttributesObject != null) {
    //     var values = []
    //     var labels = []
    //     for (var i = 0; i < positiveAttributesObject.length; i++) {
    //         values.push(positiveAttributesObject[i].count)
    //         labels.push(positiveAttributesObject[i].feature)
    //     }

    //     var positiveAttributesPie = [{
    //         values: values,
    //         labels: labels,
    //         text: values,
    //         type: 'pie',
    //         textinfo: 'value',
    //         marker: {
    //             colors: ultimateColors[0]
    //         },
    //         hoverinfo: 'text+percent',
    //         hole: .3,
    //     }]
    //     var layout = {
    //         sort: false,
    //         showlegend: true,
    //         legend: {
    //             "orientation": "h",
    //             size: 12,
    //             // y: 0.5
    //         },
    //         margin: {
    //             b: 0,
    //             t: 0,
    //             l: 10,
    //             r: 10,
    //             pad: 0,
    //         },
    //         autosize: true,
    //         paper_bgcolor: 'rgba(0,0,0,0)',
    //         paper_bgcolor: 'rgba(0,0,0,0)',
    //         font: {
    //             size: 12,
    //         },
    //         height: 300

    //     };
    //     Plotly.newPlot('mostDiscussedPositiveAttributesPieChart', positiveAttributesPie, layout, {
    //         displayModeBar: false,
    //         displaylogo: false,
    //         showTips: false
    //     });
    // }

    // if (document.getElementById('mostDiscussedNegativeAttributesPieChart') && negativeAttributesObject && negativeAttributesObject != null) {
    //     var values = []
    //     var labels = []
    //     for (var i = 0; i < negativeAttributesObject.length; i++) {
    //         values.push(negativeAttributesObject[i].count)
    //         labels.push(negativeAttributesObject[i].feature)
    //     }

    //     var negativeAttributesPie = [{
    //         values: values,
    //         labels: labels,
    //         text: values,
    //         type: 'pie',
    //         textinfo: 'value',
    //         marker: {
    //             colors: ultimateColors[0]
    //         },
    //         hoverinfo: 'text+percent',
    //         hole: .3,
    //     }]
    //     var layout = {
    //         sort: false,
    //         showlegend: true,
    //         legend: {
    //             "orientation": "h",
    //             size: 12,
    //             // y: 0.5
    //         },
    //         margin: {
    //             b: 0,
    //             t: 0,
    //             l: 10,
    //             r: 10,
    //             pad: 0,
    //         },
    //         autosize: true,
    //         paper_bgcolor: 'rgba(0,0,0,0)',
    //         paper_bgcolor: 'rgba(0,0,0,0)',
    //         font: {
    //             size: 12,
    //         },
    //         height: 300

    //     };
    //     Plotly.newPlot('mostDiscussedNegativeAttributesPieChart', negativeAttributesPie, layout, {
    //         displayModeBar: false,
    //         displaylogo: false,
    //         showTips: false
    //     });
    // }

    // pdf 9.1
    if (document.getElementById('pdfshowGoogleTrendLineChart') && pdfgeoLineGraphObj) {
        var trendsColors = [
            'rgb(203,146,174)', 'rgb(0,206,244)', 'rgb(200,228,0)', 'rgb(255,155,0)', 'rgb(175,157,200)', 'rgb(237,69,21)'
        ];
        var showData = [];
        var showTime = [];
        var keywords = []
        if (pdfgeoLineGraphObj.formattedLineGraphsDataArray && pdfgeoLineGraphObj.formattedLineGraphsDataArray.length > 0) {
            for (var i = 0; i < pdfgeoLineGraphObj.formattedLineGraphsDataArray.length && i < 9; i++) {
                var showTimeKeywords = [];
                for (var j = 0; j < pdfgeoLineGraphObj.formattedLineGraphsDataArray[i].time.length; j += 2) {
                    showTimeKeywords.push(pdfgeoLineGraphObj.formattedLineGraphsDataArray[i].time[j])
                    // j = j + 2;
                }
                showTime.push(showTimeKeywords)
                keywords.push(pdfgeoLineGraphObj.formattedLineGraphsDataArray[i].keywords)
            }
        }
        if (pdfgeoLineGraphObj.formattedLineGraphsDataArray && pdfgeoLineGraphObj.formattedLineGraphsDataArray.length > 0) {
            for (var i = 0; i < pdfgeoLineGraphObj.formattedLineGraphsDataArray.length && i < 9; i++) {
                var showDataKeywords = [];
                for (var j = 0; j < pdfgeoLineGraphObj.formattedLineGraphsDataArray[i].value.length; j += 2) {
                    showDataKeywords.push(pdfgeoLineGraphObj.formattedLineGraphsDataArray[i].value[j])
                }
                showData.push(showDataKeywords)
            }
        }
        if (keywords.length > 0) {
            var data = []
            for (var i = 0; i < keywords.length; i++) {
                var CountsData = {
                    x: showTime[i],
                    y: showData[i],
                    type: 'scatter',
                    mode: 'lines+markers',
                    name: keywords[i],
                    hoverinfo: 'x+y',
                    line: {
                        color: trendsColors[i]
                    }
                }
                data.push(CountsData)
            }
        }
        var layout = {
            plot_bgcolor: 'white',
            paper_bgcolor: 'white',
            hovermode: 'closest',
            hoveron: 'points',
            showlegend: true,
            font: {
                family: 'Roboto,Helvetica Neue,Arial,sans-serif',
                size: 14,
                color: "black"
            },
            legend: {
                font: {
                    // family: 'sans-serif',
                    size: 14,
                    color: '#000'
                },
                "orientation": "h",
                // x:0.25,
                y: -0.8
            },
            margin: {
                pad: 00,
                b: 200,
            },
            // height: 500,
            // autosize: true,
            // width: auto,
            xaxis: {
                title: 'Dates',
                tickformat: '%d/%b',
                tickangle: -30,
                titlefont: {
                    size: 14,
                    color: '#000',
                },
                showticklabels: true,
                tickfont: {
                    size: 12,
                    color: '#000'
                },
                exponentformat: 'e',
                showexponent: 'All',
                fixedrange: true,
                gridwidth: false,
                showgrid: false,
                zeroline: true,
            },
            yaxis: {
                title: 'Popularity Score',
                titlefont: {
                    size: 14,
                    color: '#000'
                },
                showticklabels: true,
                tickangle: 0,
                tickfont: {
                    size: 14,
                    color: '#000'
                },
                exponentformat: 'e',
                showexponent: 'All',
                fixedrange: true,
                rangemode: 'nonnegative',
                autorange: true,
                gridwidth: false,
                showgrid: false,
                zeroline: false,
            }

        }

        Plotly.newPlot('pdfshowGoogleTrendLineChart', data, layout, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });
    }

    var href = document.location.href

    var layout = {
        sort: false,
        showlegend: true,
        legend: {
            "orientation": "v",
            font: {
                size: 14,
                color: '#000'
            }

            // y: 0.5
            // x: .7,
        },
        margin: {
            b: 50,
            t: 50,
            l: 10,
            r: 10,
            pad: 50,
        },
        autosize: true,
        paper_bgcolor: 'rgba(0,0,0,0)',
        font: {
            size: 14,
        },
        height: 250,
        width: 450
    };
    if (document.getElementById('pdfaudienceSourceDistribution') && sourcePieChart && sourcePieChart != null) {
        var source = document.getElementById('pdfaudienceSourceDistribution')
        // layout.legend.orientation = 'v'
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            layout.width = 1700
            layout.height = 700
            layout.legend.font = 38
            layout.font.size = 35
        }
        else {
            layout.width = 650
            layout.height = 260
        }
        Plotly.newPlot('pdfaudienceSourceDistribution', [sourcePieChart], layout, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });
        // source.on('plotly_click', function (data) {
        //     var paramArray = splitIntoParams(href)
        //     paramArray = paramArray.filter(function (val) {
        //         if (val.param !== "authorType") return true
        //         return false
        //     })
        //     var hrefParams = mergeIntoUrl(paramArray)
        //     hrefParams = hrefParams.split('?')[1]
        //     var pts = '/track-influencers?' + hrefParams;
        //     for (var i = 0; i < data.points.length; i++) {
        //         data.points[i].label.replace("<br>", "")
        //         pts = pts + '&authorType=' + encodeURI(data.points[i].label.toUpperCase())
        //     }
        //     window.open(pts, '_blank');
        // });
    }


    if (document.getElementById('pdfaudienceCategoryDistribution') && categoryPieChart && categoryPieChart != null) {
        var category = document.getElementById('pdfaudienceCategoryDistribution')
        layout.legend.orientation = 'v'
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            layout.width = 1700
            layout.height = 700
            layout.legend.font = 38
            layout.font.size = 35
        }
        else {
            layout.width = 650
            layout.height = 260
        }
        Plotly.newPlot('pdfaudienceCategoryDistribution', [categoryPieChart], layout, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });
        category.on('plotly_click', function (data) {
            var paramArray = splitIntoParams(href)
            paramArray = paramArray.filter(function (val) {
                if (val.param !== "authorCategory") return true
                return false
            })
            var hrefParams = mergeIntoUrl(paramArray)
            hrefParams = hrefParams.split('?')[1]
            var pts = '/track-influencers?' + hrefParams;
            for (var i = 0; i < data.points.length; i++) {
                data.points[i].label.replace("<br>", "")
                var authorCategory = getAudienceAuthorCategory(data.points[i].label)
                pts = pts + '&authorCategory=' + authorCategory
            }
            window.open(pts, '_blank');
        });
    }


    if (document.getElementById('pdfaudienceGenderDistribution') && genderPieChart && genderPieChart != null) {
        // layout.legend.orientation = 'h'
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            layout.width = 1700
            layout.height = 700
            layout.legend.font = 38
            layout.font.size = 35
        } else {
            layout.width = 650
            layout.height = 260
        }
        Plotly.newPlot('pdfaudienceGenderDistribution', [genderPieChart], layout, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });
    }

    if (document.getElementById('pdflanguageDistribution') && languagePieChart && languagePieChart != null) {
        // layout.legend.orientation = 'h'
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            layout.width = 1700
            layout.height = 700
            layout.legend.font = 40
            layout.font.size = 35
        }
        else {
            layout.width = 650
            layout.height = 260
        }
        Plotly.newPlot('pdflanguageDistribution', [languagePieChart], layout, {
            displayModeBar: false,
            displaylogo: false,
            showTips: false
        });
    }


    if (document.getElementById('pdfTwitterEngagement') && pdfTwitterEngagement) {

        var urlLabel = pdfTwitterEngagement.map(function (obj) {
            return obj.postTime
        })
        var data = pdfTwitterEngagement.map(function (obj) {
            return obj.engagementCount
        })

        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        // Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: urlLabel,
            datasets: [{
                data: data,
                borderColor: '#00FFFF',
                label: "Engagements",
                backgroundColor: '#00FFFF',
            }]
        }

        var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
        sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Date of Engagement (' + startDate.split(',')[0] + '-' + endDate.split(',')[0] + ')'
        }
        sentimentChartLayout.scales.yAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Count'
        }
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 35
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 40
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 40
        }
        else {
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 9
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12
        }
        // sentimentChartLayout.scales.xAxes[0].type = 'time'
        sentimentChartLayout.legend.display = false
        sentimentChartLayout.scales.xAxes[0].ticks.display = false

        sentimentChartLayout.tooltips.callbacks = {
            title: function () { }
        }
        // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
        var ctx = $('#pdfTwitterEngagement');
        var chartData = {
            type: 'bar',
            data: BarChartData,
            options: sentimentChartLayout
        }
        pdfTwitterEngagementChart = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "pdfTwitterEngagement",
            data: chartData,
            chartObj: pdfTwitterEngagementChart,
            expandRatio: 1
        })
    }

    if (document.getElementById('pdFBEngagement') && pdFBEngagement) {

        var urlLabel = pdFBEngagement.map(function (obj) {
            return obj.postTime
        })
        var data = pdFBEngagement.map(function (obj) {
            return obj.engagementCount
        })

        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        // Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: urlLabel,
            datasets: [{
                data: data,
                borderColor: '#00FFFF',
                backgroundColor: '#00FFFF',
            }]
        }

        var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
        sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Date of Engagement (' + startDate.split(',')[0] + '-' + endDate.split(',')[0] + ')'
        }
        sentimentChartLayout.scales.yAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Count'
        }
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 35
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 40
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 40
        }
        else {
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 12
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12
        }
        sentimentChartLayout.scales.xAxes[0].ticks.display = false
        // sentimentChartLayout.scales.xAxes[0].type = 'time'
        sentimentChartLayout.legend.display = false
        sentimentChartLayout.tooltips.callbacks = {
            title: function () { }
        }


        // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
        var ctx = $('#pdFBEngagement');
        var chartData = {
            type: 'bar',
            data: BarChartData,
            options: sentimentChartLayout
        }
        pdFBEngagementChart = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "pdFBEngagement",
            data: chartData,
            chartObj: pdFBEngagementChart,
            expandRatio: 1
        })
    }

    if (document.getElementById('pdfInstaEngagement') && pdfInstaEngagement) {

        var urlLabel = pdfInstaEngagement.map(function (obj) {
            return obj.postTime
        })
        var enagagementData = pdfInstaEngagement.map(function (obj) {
            return obj.engagementCount
        })

        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        // Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: urlLabel,
            datasets: [{
                data: enagagementData,
                borderColor: '#00FFFF',
                backgroundColor: '#00FFFF',
            }]
        }

        var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
        sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Date of Engagement (' + startDate.split(',')[0] + '-' + endDate.split(',')[0] + ')'
        }
        sentimentChartLayout.scales.yAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Count'
        }
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 35
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 40
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 40
        }
        else {
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 9
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12
        }
        sentimentChartLayout.scales.xAxes[0].ticks.display = false
        // sentimentChartLayout.scales.xAxes[0].type = 'time'
        sentimentChartLayout.legend.display = false

        sentimentChartLayout.tooltips.callbacks = {
            title: function () { }
        }


        // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
        var ctx = $('#pdfInstaEngagement');
        var chartData = {
            type: 'bar',
            data: BarChartData,
            options: sentimentChartLayout
        }
        pdfInstaEngagementChart = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "pdfInstaEngagement",
            data: chartData,
            chartObj: pdfInstaEngagementChart,
            expandRatio: 1
        })
    }


    if (document.getElementById('pdfTiktokEngagement') && pdfTiktokEngagement) {

        var urlLabel = pdfTiktokEngagement.map(function (obj) {
            return obj.postTime
        })
        var enagagementData = pdfTiktokEngagement.map(function (obj) {
            return obj.engagementCount
        })

        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        // Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: urlLabel,
            datasets: [{
                data: enagagementData,
                borderColor: '#00FFFF',
                backgroundColor: '#00FFFF',
            }]
        }

        var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
        sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Date of Engagement (' + startDate.split(',')[0] + '-' + endDate.split(',')[0] + ')'
        }
        sentimentChartLayout.scales.yAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Count'
        }
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 35
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 40
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 40
        }
        else {
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 9
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12
        }
        sentimentChartLayout.scales.xAxes[0].ticks.display = false
        // sentimentChartLayout.scales.xAxes[0].type = 'time'
        sentimentChartLayout.legend.display = false

        sentimentChartLayout.tooltips.callbacks = {
            title: function () { }
        }


        // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
        var ctx = $('#pdfTiktokEngagement');
        var chartData = {
            type: 'bar',
            data: BarChartData,
            options: sentimentChartLayout
        }
        pdfTiktokEngagementChart = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "pdfTiktokEngagement",
            data: chartData,
            chartObj: pdfTiktokEngagementChart,
            expandRatio: 1
        })
    }

    if (document.getElementById('pdfLinkedinEngagement') && pdfLinkedinEngagement) {

        var urlLabel = pdfLinkedinEngagement.map(function (obj) {
            return obj.postTime
        })
        var enagagementData = pdfLinkedinEngagement.map(function (obj) {
            return obj.engagementCount
        })

        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        // Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: urlLabel,
            datasets: [{
                data: enagagementData,
                borderColor: '#00FFFF',
                backgroundColor: '#00FFFF',
            }]
        }

        var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
        sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Date of Engagement (' + startDate.split(',')[0] + '-' + endDate.split(',')[0] + ')'
        }
        sentimentChartLayout.scales.yAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Count'
        }
        if ($(window).width() > 1920 && $(window).height() > 1080) {
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 35
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 40
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 40
        }
        else {
            sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 9
            sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
            sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12
        }
        sentimentChartLayout.scales.xAxes[0].ticks.display = false
        // sentimentChartLayout.scales.xAxes[0].type = 'time'
        sentimentChartLayout.legend.display = false

        sentimentChartLayout.tooltips.callbacks = {
            title: function () { }
        }


        // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
        var ctx = $('#pdfLinkedinEngagement');
        var chartData = {
            type: 'bar',
            data: BarChartData,
            options: sentimentChartLayout
        }
        pdfLinkedinEngagementChart = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "pdfLinkedinEngagement",
            data: chartData,
            chartObj: pdfLinkedinEngagement,
            expandRatio: 1
        })
    }




    if (document.getElementById('pdftrendingHashtagOfBrand') && trendingHashtagObj) {
        var width = (document.getElementsByClassName('pdfwordcloud')) ? ($(".pdfwordcloud").width() - 50) : 500
        var height = (document.getElementsByClassName('pdfwordcloud')) ? ($(".pdfwordcloud").height() - 50) : 379
        TrendingHashtagOfBrand = trendingHashtagObj.brandHashtags;
        d3.wordcloud()
            .selector("#pdftrendingHashtagOfBrand")
            .size([width, height])
            .fill(d3.scale.ordinal().range(["#884400", "#448800", "#888800", "#444400"]))
            .words(TrendingHashtagOfBrand)
            .onwordclick(function (d, i) {
                if (d.href) {
                    document.location = d.href;
                }
            })
            .start();
    }
    if (document.getElementById('pdftrendingEntitiesOfBrand') && trendingEntitiesObj) {
        var width = (document.getElementsByClassName('pdfwordcloud')) ? ($(".pdfwordcloud").width() - 50) : 500
        var height = (document.getElementsByClassName('pdfwordcloud')) ? ($(".pdfwordcloud").height() - 50) : 379
        TrendingEntitiesOfBrand = trendingEntitiesObj.brandEntities;
        d3.wordcloud()
            .selector("#pdftrendingEntitiesOfBrand")
            .size([width, height])
            .fill(d3.scale.ordinal().range(["#00b1b4", "#ed4515", "#fd9400", "#272b35"]))
            .words(TrendingEntitiesOfBrand)
            .onwordclick(function (d, i) {
                if (d.href) {
                    document.location = d.href;
                }
            })
            .start();
    }

}
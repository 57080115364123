function addOnAllKeywords(existKeysClassName, notifyClassName, keywordsLimit, keyCountClass, onClickClassName, removeClassName, appendNewInput, showLimitMessageClass) {
    var iCnt = $(existKeysClassName + ".alreadySet").length;
    var container = $(document.createElement('div'));
    container.addClass(notifyClassName);
    // var planType = (activeProjectObject && activeProjectObject.adminSettings && activeProjectObject.adminSettings.planType) ? activeProjectObject.adminSettings.planType : null
    var limit = (typeof planKeywordsLimits !== 'undefined') ? limit = planKeywordsLimits[keywordsLimit] : 40
    var wordLeft = limit - iCnt
    var wordCount = limit - wordLeft
    $(keyCountClass).text('(' + wordCount + ' added out of ' + limit + ' allowed)')

    $(document).on("click", onClickClassName, function () {
        $(showLimitMessageClass).html("")
        $("#backendErrorDiv").text("")
        var checkToOpenInput = checkEmptyInputBoxBeforeIncrementInput(existKeysClassName)
        if (checkToOpenInput) {
            if (removeClassName === 'socialSources') {
                $(this).removeClass("fa-plus-circle").addClass("fa-check").addClass('infl_displayNone');
            }
            else if (removeClassName === 'kolSocialSources') {
                $(this).removeClass("fa-plus-circle").addClass("fa-check").addClass('infl_displayNone');
            }
            else {
                $(this).removeClass("fa-plus-circle").addClass("fa-check")
            }
            $(this).removeClass(removeClassName);
            iCnt = iCnt + 1;
            wordLeft = limit - iCnt
            wordCount = limit - wordLeft
            $(keyCountClass).text('(' + wordCount + ' added out of ' + limit + ' allowed)')

            var brandPromotedSocialProfile = ""
            var brandPromotedSocialProfileSM = ""
            if (currentActiveProject && currentActiveProject.adminSettings && currentActiveProject.adminSettings.prohibitPlatform && currentActiveProject.adminSettings.prohibitPlatform != [] && currentActiveProject.adminSettings.prohibitPlatform.findIndex(function (a) { if (a == "INSTAGRAM") { return true } }) > -1) { }
            else {
                brandPromotedSocialProfile += '<option value="INSTAGRAM"> &#xf16d; Instagram</option> '
                brandPromotedSocialProfileSM += '<option value="INSTAGRAM"> &#xf16d;</option> '
            }
            if (currentActiveProject && currentActiveProject.adminSettings && currentActiveProject.adminSettings.prohibitPlatform && currentActiveProject.adminSettings.prohibitPlatform != [] && currentActiveProject.adminSettings.prohibitPlatform.findIndex(function (a) { if (a == "TWITTER") { return true } }) > -1) { }
            else {
                brandPromotedSocialProfile += '<option value="TWITTER"> &#xf099; Twitter</option> '
                brandPromotedSocialProfileSM += '<option value="TWITTER"> &#xf099;</option> '
            }
            if (currentActiveProject && currentActiveProject.adminSettings && currentActiveProject.adminSettings.prohibitPlatform && currentActiveProject.adminSettings.prohibitPlatform != [] && currentActiveProject.adminSettings.prohibitPlatform.findIndex(function (a) { if (a == "FACEBOOK") { return true } }) > -1) { }
            else {
                brandPromotedSocialProfile += '<option value="FACEBOOK"> &#xf082; Facebook</option> '
                brandPromotedSocialProfileSM += '<option value="FACEBOOK"> &#xf082;</option> '

            }
            if (currentActiveProject && currentActiveProject.adminSettings && currentActiveProject.adminSettings.prohibitPlatform && currentActiveProject.adminSettings.prohibitPlatform != [] && currentActiveProject.adminSettings.prohibitPlatform.findIndex(function (a) { if (a == "YOUTUBE") { return true } }) > -1) { }
            else {
                brandPromotedSocialProfile += '<option value="YOUTUBE"> &#xf166; Youtube</option> '
                brandPromotedSocialProfileSM += '<option value="YOUTUBE"> &#xf166;</option> '

            }
            if (currentActiveProject && currentActiveProject.adminSettings && currentActiveProject.adminSettings.prohibitPlatform && currentActiveProject.adminSettings.prohibitPlatform != [] && currentActiveProject.adminSettings.prohibitPlatform.findIndex(function (a) { if (a == "TIKTOK") { return true } }) > -1) { }
            else {
                brandPromotedSocialProfile += '<option value="TIKTOK"> &#xf03d; Tiktok</option> '
                brandPromotedSocialProfileSM += '<option value="TIKTOK"> &#xf03d;</option> '

            }

            if (currentActiveProject && currentActiveProject.adminSettings && currentActiveProject.adminSettings.prohibitPlatform && currentActiveProject.adminSettings.prohibitPlatform != [] && currentActiveProject.adminSettings.prohibitPlatform.findIndex(function (a) { if (a == "LINKEDIN") { return true } }) > -1) { }
            else {
                brandPromotedSocialProfile += '<option value="LINKEDIN"> &#xf0e1; Linkedin</option> '
                brandPromotedSocialProfileSM += '<option value="LINKEDIN"> &#xf0e1;</option> '

            }

            if (iCnt < limit) {
                if (appendNewInput && appendNewInput == "#supportingnotify") {
                    $(container).append('<div class="col-md-4 col-md-offset-3 col-sm-6 inside"><div style="display: flex;margin-bottom: 2%;"><input type=text ng-click="two=true" placeholder="keywords"  autocomplete="off" class="form-control increasekeyword supportingkeys" id=supporting' + iCnt + ' ' + 'ng-click="supportingText' + iCnt + '=true" /><span class="increaseInput"> <i aria-hidden="true"   class="fa fa-plus-circle  supportingKeyword" ng-click="supportingText' + iCnt + '"></i></span></div></div><div class="col-md-6 col-sm-6" style="width: 100%;"></div>');
                    $(appendNewInput).after(container);
                } else if (appendNewInput && appendNewInput == "#requirednotifiy") {
                    $(container).append('<div class="col-md-4 col-md-offset-3 col-sm-6 inside"><div style="display: flex;margin-bottom: 2%;"><input type=text ng-click="two=true" placeholder="keywords"  autocomplete="off" class="form-control increasekeyword requiredkeys" id=tb' + iCnt + ' ' + 'ng-click="requireText' + iCnt + '=true" /><span class="increaseInput"> <i aria-hidden="true"   class="fa fa-plus-circle  requiredKeyword" ng-click="requireText' + iCnt + '"></i></span></div></div><div class="col-md-6 col-sm-6" style="width: 100%;"></div>');
                    $(appendNewInput).after(container);
                } else if (appendNewInput && appendNewInput == "#optionalnotifiy") {
                    $(container).append('<div class="col-md-4 col-md-offset-3 col-sm-6 inside"><div style="display: flex;margin-bottom: 2%;"><input type=text placeholder="keywords" autocomplete="off"  class="form-control increasekeyword audiencekey" id=tb' + iCnt + ' ' + 'ng-click="Text' + iCnt + '=true" /><span class="increaseInput"> <i aria-hidden="true"   class="fa fa-plus-circle  optionalkeyword" ng-show="Text' + iCnt + '"></i></span></div></div><div class="col-md-6 col-sm-6" style="width: 100%;"></div>');
                    $(appendNewInput).after(container);
                } else if (appendNewInput && appendNewInput == "#hashtagnotifiy") {
                    $(container).append('<div class="col-md-4 col-md-offset-3 col-sm-6 inside"><div style="display: flex;margin-bottom: 2%;"><input type=text placeholder="hashtag"  autocomplete="off"  class="form-control increasekeyword hashtagkey" id=tb' + iCnt + ' ' + 'ng-click="Text' + iCnt + '=true" /><span class="increaseInput"> <i aria-hidden="true"   class="fa fa-plus-circle  hashtagkeyword" ng-show="Text' + iCnt + '"></i></span></div></div><div class="col-md-6 col-sm-6" style="width: 100%;"></div>');
                    $(appendNewInput).after(container);
                } else if (appendNewInput && appendNewInput == "#brandPromotedHashtagsnotifiy") {
                    $(container).append('<div class="col-md-4 col-md-offset-3 col-sm-6 inside"><div style="display: flex;margin-bottom: 2%;"><input type=text placeholder="brandPromotedHashtags"  autocomplete="off"  class="form-control increasekeyword brandPromotedHashtagskey" id=tb' + iCnt + ' ' + 'ng-click="Text' + iCnt + '=true" /><span class="increaseInput"> <i aria-hidden="true"   class="fa fa-plus-circle  brandPromotedHashtagskeyword" ng-show="Text' + iCnt + '"></i></span></div></div><div class="col-md-6 col-sm-6" style="width: 100%;"></div>');
                    $(appendNewInput).after(container);
                } else if (appendNewInput && appendNewInput == "#audienceHashtagsnotifiy") {
                    $(container).append('<div class="col-md-4 col-md-offset-3 col-sm-6 inside"><div style="display: flex;margin-bottom: 2%;"><input type=text placeholder="audienceHashtags"  autocomplete="off"  class="form-control increasekeyword audienceHashtagskey" id=tb' + iCnt + ' ' + 'ng-click="Text' + iCnt + '=true" /><span class="increaseInput"> <i aria-hidden="true"   class="fa fa-plus-circle  audienceHashtagskeyword" ng-show="Text' + iCnt + '"></i></span></div></div><div class="col-md-6 col-sm-6" style="width: 100%;"></div>');
                    $(appendNewInput).after(container);
                } else if (appendNewInput && appendNewInput == "#notKeywords") {
                    $(container).append('<div class="col-md-4 col-md-offset-3 col-sm-6 inside"><div style="display: flex;margin-bottom: 2%;"><input type=text placeholder="Not Keyword" , autocomplete="off"  class="form-control increasekeyword notKeywordkey" id="ntkey' + iCnt + ' "/><span class="increaseInput"> <i aria-hidden="true"   class="fa fa-plus-circle  notKeyword-Keyword"></i></span></div></div><div class="col-md-6  col-sm-6" style="width: 100%;"></div>');
                    $(appendNewInput).after(container);
                } else if (appendNewInput && appendNewInput == "#personalities") {
                    $(container).append('<div class="col-md-4 col-md-offset-3 col-sm-6 inside"> \
                    <div style="display: flex;margin-bottom: 2%;"> \
                    <input type=text placeholder="Personality"  autocomplete="off" class="form-control increasekeyword personalitykey" id="personalitykey' + iCnt + ' "/> \
                    <span class="increaseInput"> \
                    <i aria-hidden="true"   class="fa fa-plus-circle  personalityKeyword"> \
                    </i></span></div></div><div class="col-md-6 col-sm-6" style="width: 100%;"></div>');
                    $(appendNewInput).after(container);
                } else if (appendNewInput && appendNewInput == "#socialSources") {
                    if (is_phone) {
                        $(container).append('<div class="row baseline-extra"> \
                        <div class="col-md-3 text-right"></div> \
                        <div class="col-md-9 col-sm-8 col-xs-12 row"> \
                        <div style="padding-bottom: 50px"> \
                        <div class="col-md-3 col-sm-5 col-xs-3"> \
                        <select id="sources" class="form-control socialOption" autocomplete="off"> '+ brandPromotedSocialProfileSM + '  <option value="ZOMATO"> &#xf1b1;</option> \
                            <option value="GOOGLE_REVIEW"> &#xf1a0;</option> \
                            </select ></div > <div class="col-md-5 col-sm-7 col-xs-7 text-left baseline" style="padding-right:0"> \
                            <input type="text" autocomplete="off" id="usr' + iCnt + ' " value="" placeholder="username/page-name/handle" class="form-control increasekeyword socialSrc" /></div><div class="col-md-4 col-sm-2 col-xs-2 flex-align-center"><span class="increaseInput"><i aria-hidden="true" class="fa fa-plus-circle socialSources"></i></span></div></div ></div ></div > ');

                    } else {
                        $(container).append('<div class="row baseline-extra"> \
                        <div class="col-md-3 text-right"></div> \
                        <div class="col-md-9 col-sm-8 col-xs-12 row"> \
                        <div style="padding-bottom: 50px"> \
                        <div class="col-md-3 col-sm-5 col-xs-3"> \
                        <select id="sources" class="form-control socialOption" autocomplete="off"> '+ brandPromotedSocialProfile + '<option value="ZOMATO"> &#xf1b1; Zomato</option> \
                            <option value="GOOGLE_REVIEW"> &#xf1a0; Google</option> \
                            </select ></div > <div class="col-md-5 col-sm-7 col-xs-7 text-left baseline" style="padding-right:0"><input type="text" autocomplete="off" id="usr' + iCnt + ' " value="" placeholder="username/page-name/handle" class="form-control increasekeyword socialSrc" /></div><div class="col-md-4 col-sm-2 col-xs-2"><span class="increaseInput"><i aria-hidden="true" class="fa fa-plus-circle socialSources"></i></span></div></div ></div ></div > ');
                    }
                    $(appendNewInput).after(container);


                } else if (appendNewInput && appendNewInput == "#kolSources") {
                    if (is_phone) {
                        $(container).append('<div class="row baseline-extra"> \
                        <div class="col-md-3 text-right"></div> \
                        <div class="col-md-9 col-sm-8 col-xs-12 row"> \
                        <div style="padding-bottom: 50px"> \
                        <div class="col-md-3 col-sm-5 col-xs-3"> \
                            < select id = "kolSources" class= "form-control kolSocialOption" autocomplete = "off" > \
                            <option value="INSTAGRAM">  &#xf16d;</option> \
                            <option value="TWITTER"> &#xf099;</option> \
                            <option value="FACEBOOK"> &#xf082;</option> \
                            <option value="YOUTUBE"> &#xf166;</option> \
                            <option value="TIKTOK"> &#xf03d;</option> \
                            <option value="LINKEDIN"> &#xf0e1; </option> \
                            </select ></div > <div class="col-md-5 col-sm-7 col-xs-7 text-left baseline" style="padding-right:0"> \
                            < select id = "kolSources" class= "form-control kolSocialOption" autocomplete = "off" > '+ brandPromotedSocialProfileSM + ' </select ></div > <div class="col-md-5 col-sm-7 col-xs-7 text-left baseline" style="padding-right:0"> \
                            <input type="text" autocomplete="off" id="usr' + iCnt + ' " value="" placeholder="username/page-name/handle" class="form-control increasekeyword kolSocialSrc" /></div><div class="col-md-4 col-sm-2 col-xs-2 flex-align-center"><span class="increaseInput"><i aria-hidden="true" class="fa fa-plus-circle kolSocialSources"></i></span></div></div ></div ></div > ');

                    } else {
                        $(container).append('<div class="row baseline-extra"> \
                        <div class="col-md-3 text-right"></div> \
                        <div class="col-md-9 col-sm-8 col-xs-12 row"> \
                        <div style="padding-bottom: 50px"> \
                        <div class="col-md-3 col-sm-5 col-xs-3"> \
                        <select id="sources" class="form-control kolSocialOption" autocomplete="off">'+ brandPromotedSocialProfile + ' </select></div><div class="col-md-5 col-sm-7 col-xs-7 text-left baseline" style="padding-right:0"><input type="text"  autocomplete="off" id="usr' + iCnt + ' " value="" placeholder="username/page-name/handle" class="form-control increasekeyword kolSocialSrc"/></div><div class="col-md-4 col-sm-2 col-xs-2"><span class="increaseInput"><i aria-hidden="true" class="fa fa-plus-circle kolSocialSources"></i></span></div></div></div></div>');
                    }

                    $(appendNewInput).after(container);


                } else if (appendNewInput && appendNewInput == "#additionalSource") {
                    $(container).append('<div class="row"><div class="col-md-4 col-md-offset-3 col-sm-6"><div style="display: flex;margin-bottom: 2%;"><input type="text"  autocomplete="off" id="url' + iCnt + '" placeholder="url" class="form-control increasekeyword addSource"/><span class="increaseInput"><i aria-hidden="true" class="fa fa-plus-circle additionalSource"></i></span></div></div><div class="col-sm-6" style="width=100%"></div></div>');
                    $(appendNewInput).after(container);
                } else if (appendNewInput && appendNewInput == "#brandSource") {
                    $(container).append('<div class="row"><div class="col-md-4 col-md-offset-3 col-sm-6"><div style="display: flex;margin-bottom: 2%;"><input type="text" id="brand' + iCnt + '" placeholder="URL"  autocomplete="off" class="form-control increasekeyword brandSourceInput"/><span class="increaseInput"><i aria-hidden="true" class="fa fa-plus-circle brandSourceKey"></i></span></div></div><div class="col-sm-6" style="width=100%"></div></div>');
                    $(appendNewInput).after(container);
                }
            } else {
                $(showLimitMessageClass).append('<label style="color:#0079c0">*Maximum limit is exceed, Upgrade your plan to continue</label>');
            }
        } else {
            if (existKeysClassName === '.socialSrc') {
                $(showLimitMessageClass).append("<label>*Input box can't be less then 2 characters</label>");
            } else {
                $(showLimitMessageClass).append("<label>*Input box can't be less then 3 characters</label>");
            }
        }

    });

}

function requiredKeyword() {
    addOnAllKeywords('.requiredkeys', 'row', 'requiredKeywordsLimit', '#reqKeyCount', '.requiredKeyword', 'requiredKeyword', '#requirednotifiy', '.requiredreach')
}

// function supportingKeywords() {
//     addOnAllKeywords('.supportingkeys', 'row supportingnotifyclass', 'supportingKeywordsLimit', '#supportingKeyCount', '.supportingKeyword', 'supportingKeyword', '#supportingnotify', '.supportingreach')
// }

// function optionalkeyword() {
//     addOnAllKeywords('.audiencekey', 'row', 'audienceKeywordsLimit', '#targetAudienceKeyCount', '.optionalkeyword', '.optionalkeyword', '#optionalnotifiy', '.optionalreach')
// }

function hashtagkeyword() {
    addOnAllKeywords('.hashtagkey', 'row', 'hashtagsLimit', '#hashtagsKeyCount', '.hashtagkeyword', 'hashtagkeyword', '#hashtagnotifiy', '.hashtagreach')
}
function brandPromotedHashtagskeyword() {
    addOnAllKeywords('.brandPromotedHashtagskey', 'row', 'brandPromotedHashtagsLimit', '#brandPromotedHashtagsKeyCount', '.brandPromotedHashtagskeyword', 'brandPromotedHashtagskeyword', '#brandPromotedHashtagsnotifiy', '.brandPromotedHashtagsreach')
}
function audienceHashtagskeyword() {
    addOnAllKeywords('.audienceHashtagskey', 'row', 'audienceHashtagsLimit', '#audienceHashtagsKeyCount', '.audienceHashtagskeyword', 'audienceHashtagskeyword', '#audienceHashtagsnotifiy', '.audienceHashtagsreach')
}

function showNotKeywords() {
    addOnAllKeywords('.notKeywordkey', 'row', 'notSearchKeywordsLimt', '#notSearchKeyCount', '.notKeyword-Keyword', 'notKeyword-Keyword', '#notKeywords', '.notKeywordreach')
}

function personalitiesKeywords() {
    addOnAllKeywords('.personalitykey', 'row', 'personalitiesLimit', '#personalityKeyCount', '.personalityKeyword', 'personalityKeyword', '#personalities', '.personalityreach')
}

function socialSources() {
    addOnAllKeywords('.socialSrc', 'justify-content-center-extra', 'socialHandleLimit', '#socialSourceKeyCount', '.socialSources', 'socialSources', '#socialSources', '.socialreach')
}

function kolSocialHandles() {
    addOnAllKeywords('.kolSocialSrc', 'justify-content-center-extra', 'socialHandleLimit', '#kolSocialSourceKeyCount', '.kolSocialSources', 'kolSocialSources', '#kolSources', '.kolSourcesError')
}

function additionalSource() {
    addOnAllKeywords('.addSource', 'text', 'additionalSourceLimit', '#additionalSourceKeyCount', '.additionalSource', 'additionalSource', '#additionalSource', '.additionalsocialreach')
}

function brandSource() {
    addOnAllKeywords('.brandSourceInput', 'text', 'brandOwnedWebsitesLimit', '#brandSourceKeyCount', '.brandSourceKey', 'brandSourceKey', '#brandSource', '.brandSourceReach')
}


function addSaveSearchHtml() {
    var mysaveSearchesHtml = "";
    if (typeof (saveSearches) != 'undefined') {
        $.each(saveSearches, function (i, item) {

            mysaveSearchesHtml += "<option value=" + item.id + "> " + item.filterName;

        });
    }
    return mysaveSearchesHtml
}

function addFrequency() {
    var iCnt = $(".addfreq").length;
    var container = $(document.createElement('div'));
    container.addClass("justify-content-center-extra")
    $(document).on("click", ".addFrequency", function () {
        $(this).removeClass("fa-plus-circle").addClass("fa-check");
        $(this).removeClass("addFrequency");
        var planType = (activeProjectObject && activeProjectObject.adminSettings && activeProjectObject.adminSettings.planType) ? activeProjectObject.adminSettings.planType : null
        if (iCnt < 10) {
            iCnt = iCnt + 1;
            $(container).append('<div class="row baseline-extra"><div class="col-md-3 col-sm-4 col-xs-12 text-right"></div><div class="col-md-9 col-sm-8 col-xs-12  row"><div style="padding-bottom: 50px"><div class="col-sm-3 col-xs-5"><select id="frequency" class="form-control frequencyOption" autocomplete="off"><option value="DAILY"> Daily</option><option value="WEEKLY"> Weekly</option><option value="MONTHLY">  Monthly</option></select></div><div class="col-sm-5 col-xs-7 text-left baseline"> \
            <input type="text" style="margin-right: 0" autocomplete="off" id="usr' + iCnt + '" value="" placeholder="email-ids(separated by comma)" class="form-control increasekeyword addfreq"/> </div> <div  class="col-sm-4 col-xs-5 text-left baseline"> <select id="frequency" class="form-control savefilterOption" style="margin-right: 5%" autocomplete="off"><option value=""> Select Filters</option> \
            ' + addSaveSearchHtml() + ' </div> </option></select>  <span class="increaseInput"><i aria-hidden="true" class="fa fa-plus-circle addFrequency"></i></span></div></div></div></div>');
            $('#frequencySources').after(container);
        } else {
            $(".freqreach").append('<label class=text-right>*Reached the limit</label>');
            $('.addFrequency').attr('class', 'bt-disable');
            $('.addFrequency').attr('disabled', 'disabled');
        }
    });
}





function confirmByUser() {
    var txt = false;
    if (confirm("Are you sure you want to update the project settings?")) {
        txt = true;
    } else {
        txt = false;
    }
    return txt;
}

function checkLengthOfKeywordsAndShowError(errorMainClass, checklength, currentErrorClass, mainErrorClassText) {

    if (checklength) {
        $(errorMainClass).text("")
        $(currentErrorClass).text("")
        $(errorMainClass).append("<label>*" + mainErrorClassText + "</label>");

        if (currentErrorClass === '.socialreach') {
            $(currentErrorClass).append("<label>*Input box can't be less then 2 characters</label>");
        }
        else if (currentErrorClass === '.projectNameError') {
            $(currentErrorClass).append("<label>*Project name cannot be left blank</label>");
        } else {
            $(currentErrorClass).append("<label>*Input box can't be less then 3 characters</label>");
        }
        return false
    } else {
        $(currentErrorClass).text("")
        $(errorMainClass).text("")
        return true
    }

}

function confirmKeywords(viewselect) {
    var url = "/onboarding"; // the script where you handle the form input.
    var category = $(".categoryvalue").map(function () {
        if ($(this).val() != '') {
            return $(this).val();
        }
    })
    var country = $(".countryvalue").map(function () {
        return $(this).val();
    })
    var city = $(".cityvalue").map(function () {
        return $(this).val();
    })
    // var language = $(".languagevalue").map(function () {
    //     return $(this).val();
    // })
    var language = languageSelected
    if (language.length == 0) {
        var tags = $('.languageDropdown span.select-pure__selected-label').map(function () {
            return $(this).text();
        });
        tags = Array.prototype.join.call(tags, ",");
        language = tags.split(",")
    }
    var checklength = false
    var errorClass = null
    var mainErrorClassText = "There is some error in Updating. Please fix it first before Update"

    var extraHashtags = []
    var extraPersonalities = []

    var projectName = ""
    if ($("#settingProjectName").val() != '' && $("#settingProjectName").val() != undefined && $("#settingProjectName").val() != "undefined") {
        projectName = $("#settingProjectName").val();
    }
    else {
        checklength = true
        errorClass = ".projectNameError"
        mainErrorClassText = "There is some error in Updating. Project name cannot be empty"
    }
    var requirekeys = $(".requiredkeys").map(function () {
        if (($.trim($(this).val().length) > 0) && ($.trim($(this).val().length) < 3)) {
            checklength = true
            errorClass = ".requiredreach"
            mainErrorClassText = "There is some error in Updating. Required keywords can't be less than 3 characters"
        }
        if ($.trim($(this).val()) !== '') {
            var text = $.trim($(this).val())
            if (text.charAt(0) === '#') {

                while (text.charAt(0) === '#') {
                    text = text.substr(1);
                }
                extraHashtags.push(text)
                return $(this).val().trim();
            } else if (text.charAt(0) === '@') {
                while (text.charAt(0) === '@') {
                    text = text.substr(1);
                }
                extraPersonalities.push(text)
                return $(this).val().trim();
            } else
                return $(this).val().trim();
        }
    });
    if (requirekeys.length == 0) {
        checklength = true
        errorClass = ".requiredreach"
        mainErrorClassText = "There is some error in Updating. Atleast one required keyword is expected"
    }

    // var supportingkeys = $(".supportingkeys").map(function () {
    //     if (($.trim($(this).val().length) > 0) && ($.trim($(this).val().length) < 3)) {
    //         checklength = true
    //         errorClass = ".supportingreach"
    //     }
    //     if ($.trim($(this).val()) !== '') {
    //         return $(this).val().trim();
    //     }

    // });
    // var optionkeys = $(".audiencekey").map(function () {
    //     if (($.trim($(this).val().length) > 0) && ($.trim($(this).val().length) < 3)) {
    //         checklength = true
    //         errorClass = ".optionalreach"
    //     }
    //     if ($.trim($(this).val()) !== '') {
    //         return $(this).val().trim();
    //     }
    // });


    var hashtagkeys = $(".hashtagkey").map(function () {
        if (($.trim($(this).val().length) > 0) && ($.trim($(this).val().length) < 3)) {
            checklength = true
            errorClass = ".hashtagreach"
            mainErrorClassText = "There is some error in Updating. Hashtags can't be less than 3 characters"
        }
        if ($.trim($(this).val()) !== '') {
            var text = $.trim($(this).val())
            while (text.charAt(0) === '#') {
                text = text.substr(1);
            }
            return text;

        }
    });

    var brandPromotedHashtagskeys = $(".brandPromotedHashtagskey").map(function () {
        if (($.trim($(this).val().length) > 0) && ($.trim($(this).val().length) < 3)) {
            checklength = true
            errorClass = ".brandPromotedHashtagsreach"
            mainErrorClassText = "There is some error in Updating. Brand Promoted Hashtags can't be less than 3 characters"
        }
        if ($.trim($(this).val()) !== '') {
            var text = $.trim($(this).val())
            while (text.charAt(0) === '#') {
                text = text.substr(1);
            }
            return text;

        }
    });

    var audienceHashtagskeys = $(".audienceHashtagskey").map(function () {
        if (($.trim($(this).val().length) > 0) && ($.trim($(this).val().length) < 3)) {
            checklength = true
            errorClass = ".audienceHashtagsreach"
            mainErrorClassText = "There is some error in Updating. Audience Hashtags can't be less than 3 characters"

        }
        if ($.trim($(this).val()) !== '') {
            var text = $.trim($(this).val())
            while (text.charAt(0) === '#') {
                text = text.substr(1);
            }
            return text;

        }
    });



    hashtagkeys = $.merge(hashtagkeys, extraHashtags)
    var notKeywordkeys = $(".notKeywordkey").map(function () {
        if (($.trim($(this).val().length) > 0) && ($.trim($(this).val().length) < 3)) {
            checklength = true
            errorClass = ".notKeywordreach"
            mainErrorClassText = "There is some error in Updating. NOT Search Keywords can't be less than 3 characters"

        }
        if ($.trim($(this).val()) !== '') {
            return $(this).val().trim();
        }
    });
    var personalitiesName = []
    var personalitieskeys = $(".personalitykey").map(function () {
        if (($.trim($(this).val().length) > 0) && ($.trim($(this).val().length) < 3)) {
            checklength = true
            errorClass = ".personalityreach"
            mainErrorClassText = "There is some error in Updating. Personalities can't be less than 3 characters"

        }
        if ($.trim($(this).val()) !== '') {
            var text = $.trim($(this).val())
            while (text.charAt(0) === '@') {
                text = text.substr(1);
            }
            return text;
            // return $(this).val().trim();
        }
    });
    personalitieskeys = $.merge(personalitieskeys, extraPersonalities)

    for (var i = 0; i < personalitieskeys.length; i++) {
        var personalityname = {
            "name": personalitieskeys[i],
        }
        personalitiesName.push(personalityname)
    }


    var socialWebsite = $(".socialOption option:selected").map(function () {
        //   if($.trim($(this).val()) !== ''){
        return $(this).val();
        // }
    });

    var socialSrc = $(".socialSrc").map(function () {
        //if($.trim($(this).val()) !== ''){
        if (($.trim($(this).val().length) > 0) && ($.trim($(this).val().length) < 2)) {
            checklength = true
            errorClass = ".socialreach"
            mainErrorClassText = "There is some error in Updating. Brand Social Profiles can't be less than 2 characters"

        }
        return $(this).val().trim();
        // }
    });
    var kolSocialWebsite = $(".kolSocialOption option:selected").map(function () {
        //   if($.trim($(this).val()) !== ''){
        return $(this).val();
        // }
    });
    var kolSrc = $(".kolSocialSrc").map(function () {
        //if($.trim($(this).val()) !== ''){
        if (($.trim($(this).val().length) > 0) && ($.trim($(this).val().length) < 2)) {
            checklength = true
            errorClass = ".kolSourcesError"
            mainErrorClassText = "There is some error in Updating. Brand Promoted Social Profiles can't be less than 2 characters"

        }
        return $(this).val().trim();
        // }
    });
    var addSource = $(".addSource").map(function () {
        if (($.trim($(this).val().length) > 0) && ($.trim($(this).val().length) < 3)) {
            checklength = true
            errorClass = ".additionalsocialreach"
            mainErrorClassText = "There is some error in Updating. Additional Sources can't be less than 3 characters"

        }
        if ($.trim($(this).val()) !== '') {
            return $(this).val().trim();
        }
    });
    var brandWebsite = $(".brandSourceInput").map(function () {
        if (($.trim($(this).val().length) > 0) && ($.trim($(this).val().length) < 3)) {
            checklength = true
            errorClass = ".brandSourceReach"
            mainErrorClassText = "There is some error in Updating. Brand Owned Websites can't be less than 3 characters"

        }
        if ($.trim($(this).val()) !== '') {
            return $(this).val().trim();
        }
    })
    var isUpdate = checkLengthOfKeywordsAndShowError("#backendErrorDiv", checklength, errorClass, mainErrorClassText)

    var socialWebsites = socialWebsite.get()
    var sourcesWebsites = socialSrc.get()
    var sourcesArray = []
    for (var i = 0; i < socialWebsites.length; i++) {

        if (socialWebsites[i] == '' || sourcesWebsites[i] == '') {
            continue;
        }
        var socialObject = {
            "website": socialWebsites[i],
            "handle": sourcesWebsites[i]
        }
        sourcesArray.push(socialObject)
    }

    var kolSocialWebsites = kolSocialWebsite.get()
    var kolSourcesWebsites = kolSrc.get()
    var kolSourcesArray = []
    for (var i = 0; i < kolSocialWebsites.length; i++) {

        if (kolSocialWebsites[i] == '' || kolSourcesWebsites[i] == '') {
            continue;
        }
        var kolSocialObject = {
            "website": kolSocialWebsites[i],
            "handle": kolSourcesWebsites[i]
        }
        kolSourcesArray.push(kolSocialObject)
    }


    var frequencylists = $(".frequencyOption option:selected").map(function () {
        return $(this).val();
    });
    var emailaddresses = $(".addfreq").map(function () {
        return $(this).val();
    });
    var savedSearchIds = $(".savefilterOption option:selected").map(function () {
        return $(this).val();
    });
    var emaillist = []
    var emailflag = false
    for (var i = 0; i < emailaddresses.length; i++) {
        if (emailaddresses[i] != " " && emailaddresses[i] != "") {
            var emaillist = emailaddresses[i].split(",")
            for (var j = 0; j < emaillist.length; j++) {
                if (emaillist[j] != " " && emaillist[j] != "") {
                    if (emaillist[j].indexOf("@") < 0) {
                        emailflag = true
                    }
                } else {
                    emailflag = true;
                }
            }
        }
    }
    var notificationsEnabled = $('.switch-input').is(":checked")
    if (emailflag === true) {
        alert("Please Check email addresses in Dashboard › Project settings › Notifications")
        return false
    } else {
        if (emailaddresses[0].length <= 8 && emailaddresses[0].indexOf("@") < 0) {
            notificationsEnabled = false
            $('.switch-input').prop('checked', false);
        }// else {
        //     $('.switch-input').prop('checked', true);

        // }
    }
    var frequency = frequencylists.get()
    var destAddresses = emailaddresses.get()
    var savedSearchIds = savedSearchIds.get()
    var settings = []
    for (var i = 0; i < frequency.length; i++) {

        if (frequency[i] == '' || destAddresses[i] == '') {
            continue;
        }
        var freqencyObject = {
            "frequency": frequency[i],
            "destAddresses": destAddresses[i].split(","),
            "medium": "EMAIL",
            "savedSearchIds": savedSearchIds[i]
        }
        settings.push(freqencyObject)
    }
    if (viewselect === 'confirmKeywordsReport') {
        var viewselectvalue = 'Reports'
    }
    else if (viewselect === 'confirmKeywordsNotification') {
        var viewselectvalue = 'Notifications'
    }
    else if (viewselect === 'confirmKeywordsduplicate') {
        var viewselectvalue = 'Sources'
    }
    else if (viewselect === 'confirmKeywords') {
        var viewselectvalue = 'Keywords'
    }


    var smsEnabled = $('.switch-input-sms').is(":checked")

    var smsNumber = $('#smsMobileNumber').val()
    if (smsNumber.length > 0) {
        if (smsNumber.length != 10) {
            alert("Please Check Mobile Number in Dashboard › Project settings › Notifications")
            return false
        }

    } else {
        smsEnabled = false;
        $('.switch-input-sms').prop('checked', false);
    }


    var companyLogo = $('#companyLogoPreview').css('background-image');
    var checkUpdateImage = companyLogo.indexOf("images/default_profile_normal.png") < 0
    var companyLogoImage = ""
    if (checkUpdateImage) {
        companyLogoImage = companyLogo.replace('url(', '').replace(')', '').replace(/\"/gi, "");
    }

    var headerHtml = $('#headerHtml').val()

    var footerHtml = $('#footerHtml').val()

    var reportSettings = {
        "companyLogoImage": companyLogoImage,
        "headerHtml": headerHtml,
        "footerHtml": footerHtml,
    }
    var notificationSettings = {
        "notificationsEnabled": notificationsEnabled,
        "settings": settings,
        "smsEnabled": smsEnabled,
        "smsNumber": smsNumber,
    }

    var data = {
        "project": {
            "projectName": $("#settingProjectName").val(),
            "category": category.get(),
            "city": city.get(),
            // "language": language.get(),
            "language": language,
            "enteredKeywords": requirekeys.get(),
            // "supportingKeywords": supportingkeys.get(),
            "country": country.get(),
            // "audienceKeywords": optionkeys.get(),
            "hashtags": hashtagkeys.get(),
            "brandPromotedHashtags": brandPromotedHashtagskeys.get(),
            "audienceHashtags": audienceHashtagskeys.get(),
            "notKeywords": notKeywordkeys.get(),
            "personalities": personalitiesName,
            "socialMediaHandles": sourcesArray,
            "kolSocialHandles": kolSourcesArray,
            "specialWebsites": addSource.get(),
            "websites": brandWebsite.get(),
            "projectId": activeProjectObject._id,
            "notificationSettings": notificationSettings,
            "reportSettings": reportSettings
        }
    }

    if (isUpdate) {
        $.ajax({
            url: url,
            type: "POST",
            withCredentials: true,
            xhrFields: {
                withCredentials: true
            },
            data: JSON.stringify(data),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            beforeSend: function () {
                $("#updatekeywords").html("<img style='height: 50px;width: 50px;position: fixed;top: 45%;left: 45%;z-index:10000' src = '../../images/loader-icon.gif'>");
                return true;

            },
            complete: function () {
                $("#updatekeywords").css("display", "none");
                return true;
            },
            success: function (ans) {
                //alert("ajax response ans : " + status)
                // document.location.reload();
                var paramArray = splitIntoParams(document.location.href)
                paramArray = paramArray.filter(function (val) {
                    if (val.param == "viewselect") return false
                    return true
                })
                paramArray.push({
                    param: 'viewselect',
                    value: viewselectvalue
                })
                var destUrl = mergeIntoUrl(paramArray, true);
                document.location.href = destUrl
            },
            error: function (ans) {
                if (ans !== null && ans.responseJSON !== null && ans.responseJSON.errorStatus !== null) {
                    var str = ans.responseJSON.errorStatus
                    $("#backendErrorDiv").text("* Error in updating project settings. " + str)
                    window.scrollTo(0, 0);

                }

            }

        });
    }
}


function checkEmptyInputBoxBeforeIncrementInput(existKeysClassName) {
    var newparam = [];
    var check = false
    $(existKeysClassName).each(function () {
        newparam.push({
            param: existKeysClassName,
            value: $(this).val()
        });
    });
    var emptyInputFields = newparam.findIndex(function (obj) {
        if (obj.value == "")
            return obj;
    });
    var minInputDataLength = newparam.findIndex(function (obj) {
        if (existKeysClassName === '.socialSrc') {
            if (obj.value.trim().length < 2)
                return obj;
        } else {
            if (obj.value.trim().length < 3)
                return obj;
        }


    });
    if (emptyInputFields && emptyInputFields === -1)
        check = true
    else
        check = false

    if (minInputDataLength && minInputDataLength === -1)
        check = true
    else
        check = false

    return check
}


function readURL(input, preview) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            var src = e.target.result
            var fileSize = src.length * 0.75
            var fileSizeKB = fileSize / 1000
            if (fileSizeKB < 64) {
                $(preview).css('background-image', 'url(' + e.target.result + ')');
                $(preview).hide();
                $(preview).fadeIn(650);
                $("#imgUploadErrDiv").html("")
            } else {
                $("#imgUploadErrDiv").html("<p style='margin:0'>The size of the file is more than 64KB. Please upload a smaller sized image.</p>")
            }
        }
        reader.readAsDataURL(input.files[0]);
    }
}


function callAndGetSignedUrl() {
    var signedUrl = null
    var reporturl = "/reportUpload"
    var projectName = document.getElementById("reportName").value
    var projectId = document.getElementById("projectId").value
    $.ajax({
        url: reporturl,
        data: {
            name: projectName,
            projectId: projectId
        },
        async: false,
        type: "GET",
        success: function (res) {
            signedUrl = res
        }
    });
    return signedUrl
}


function uploadReport(input) {
    var projectName = document.getElementById("reportName").value
    var projectId = document.getElementById("projectId").value
    if (projectId != "" && projectId != null) {
        if (projectName != "" && projectName != null) {
            if (input.files && input.files[0]) {
                var signedUrl = callAndGetSignedUrl()
                var uploadConfirmation = reportUploadConfirmByUser()
                if (uploadConfirmation) {
                    $.ajax({
                        url: signedUrl,
                        type: 'PUT',
                        async: false,
                        processData: false,
                        contentType: false,
                        data: input.files[0],
                        success: function () {
                            alert("Report Uploaded Successfully!!")
                        },
                        error: function () {
                            alert("Sorry your report could not be uploaded.")
                        }
                    });
                }
            }
        } else alert("Report Name is Empty!!")
    } else alert("Project Id is Empty!!")
}

function reportUploadConfirmByUser() {
    var txt = false;
    if (confirm("Are you sure you want to upload the report?")) {
        txt = true;
    } else {
        txt = false;
    }
    return txt;
}

var languageSelected = []
$(document).ready(function () {

    var url = document.location.href
    if (url.indexOf("viewselect") > -1) {
        projectSettingsSection()
    }

    $("#supporingContainerId").on("click", function () {
        $(".supportingnotifyclass").toggle(function () {
            var check = $("#topSupportingArrow").hasClass("fa-chevron-down")
            if (check) {
                $("#topSupportingArrow").removeClass("fa-chevron-down")
                $("#topSupportingArrow").addClass("fa-chevron-up")
            } else {
                $("#topSupportingArrow").removeClass("fa-chevron-up")
                $("#topSupportingArrow").addClass("fa-chevron-down")

            }
        })
    })


    $(".confirmKeywords").on("click", function (e) {
        e.stopPropagation()
        var viewselect = $(this).attr('id')
        var confirmSelect = confirmByUser()
        if (confirmSelect) {
            confirmKeywords(viewselect)
        }
    });



    var pageUrl = document.location.href;
    if (pageUrl.indexOf("?") == -1) {
        pageUrl = pageUrl + "?";
    }
    var params = splitIntoParams(pageUrl)
    if (params !== null && params.length > 0) {
        for (var i = 0; i < params.length; i++) {
            if (params[i].param == "alertMessage") {
                alert(params[i].value)
            }
        }
    }


    $('.first').click(function () {
        if ($("#projectkeywords").tagsinput('items').length <= 0) {
            alert("Please fill your Brand Name and other Keywords")
            return
        } else {
            $('#step-1').hide();
            $('#step-2').show();
            $('#step-3').hide();
            $('a.two').css("background-color", "#0079c0").css("color", "white");
            $('a[href="#step-2"]').removeClass("disabled");
            enjoyhintOnboardingResume()
            return
        }
    });
    $('.second').click(function () {
        var countryvalue = $("#country").val()
        var categoriesvalue = $("#categories").val()
        if ((countries.indexOf(countryvalue) < 0) || (categories.indexOf(categoriesvalue) < 0)) {
            alert("Please select country and category from the list")
            return
        }

        $('#step-1').hide();
        $('#step-2').hide();
        $('#step-3').show();
        $('.three').css("background-color", "#0079c0").css("color", "white");
        $('a[href="#step-2"]').remove("disabled");
        $("#keyFinal").text($("#projectkeywords").tagsinput('items').join(', '));

        if ($("#city").val() !== "") {
            $("#locationFinal").text($("#city").val() + "," + $("#country").val());
        } else {
            $("#locationFinal").text($("#country").val());
        }

        $("#categoriesFinal").text($("#categories").val());
        enjoyhintOnboardingResume()
    });

    $('a.one').click(function () {

        $('#step-2').hide();
        $('#step-3').hide();
        $('#step-1').show();
        $('a[href="#step-2"]').addClass("disabled");
        $('a[href="#step-3"]').addClass("disabled");
        $('.three').css("background-color", "#fff").css("color", "#545454");
        $('.two').css("background-color", "#fff").css("color", "#545454");
        $('#backendErrorDiv').html("")
        $('html, body').animate({
            scrollTop: 0
        }, 'fast');
    });
    $('a.two').click(function () {
        $('#step-1').hide();
        $('#step-3').hide();
        $('#step-2').show();
        $('a.three').addClass("disabled");
        $('.three').css("background-color", "#fff").css("color", "#545454");
        $('#backendErrorDiv').html("")
        $('html, body').animate({
            scrollTop: 0
        }, 'fast');

    });


    $("#confirmationbackend").on('click', function confirmationbackend(e) {
        e.stopPropagation()
        showLoaderOnClick()
        $("#confirmationbackend").attr("disabled", true)
        var url = "/onboarding"; // the script where you handle the form input.


        var data = {
            "project": {
                "projectName": $("#projectkeywords").tagsinput('items')[0],
                "enteredKeywords": $("#projectkeywords").tagsinput('items'),
                "country": $("#country").val(),
                "city": $("#city").val(),
                "category": $("#categories").val()
            }
        }
        $.ajax({
            url: url,
            type: "POST",
            withCredentials: true,
            xhrFields: {
                withCredentials: true
            },
            data: JSON.stringify(data),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (ans) {
                document.location.assign("/enterprise-panel-result?firstTime=true&projectId=" + ans["project"]["_id"])
                // $("#confirmationbackend").attr("disabled", false)
            },
            error: function (ans) {
                if (ans !== null && ans.responseJSON !== null && ans.responseJSON.errorStatus !== null) {
                    var str = ans.responseJSON.errorStatus
                    $("#backendErrorDiv").text("* Error in creating the project. " + str)
                    //window.scrollTo(0, 0);
                    $("#confirmationbackend").attr("disabled", false)
                    hideLoaderOnClick()
                }
            }
        });
    });

    requiredKeyword();
    // supportingKeywords();
    // optionalkeyword();
    hashtagkeyword();
    brandPromotedHashtagskeyword()
    audienceHashtagskeyword()
    showNotKeywords();
    personalitiesKeywords();
    socialSources();
    kolSocialHandles();
    additionalSource();
    brandSource();
    addFrequency();

    $("#companyLogoUpload").change(function () {
        readURL(this, "#companyLogoPreview");
    });

    $("#reportUpload").change(function () {
        uploadReport(this);
    });


    if (typeof (allLanguages) != 'undefined' && allLanguages && allLanguages.length > 0) {
        var myOptions = []
        var projectLang = []
        if (activeProjectObject && activeProjectObject.context && activeProjectObject.context.language && activeProjectObject.context.language.length > 0 && activeProjectObject.context.language.filter(function (a) { if (a != "") { return true } }).length > 0) {
            projectLang = activeProjectObject.context.language
        }
        allLanguages.map(function (language) {
            myOptions.push({
                label: language,
                value: language,
            })
        })
        var languageInstance = new SelectPure(".languageDropdown", {
            options: myOptions,
            multiple: true,
            autocomplete: true,
            placeholder: "Language",
            icon: "fa fa-times",
            value: projectLang,
            inlineIcon: false,
            onChange: function (value) { languageSelected = value; },
            classNames: {
                select: "select-pure__select",
                dropdownShown: "select-pure__select--opened",
                multiselect: "select-pure__select--multiple",
                label: "select-pure__label",
                placeholder: "select-pure__placeholder",
                dropdown: "select-pure__options",
                option: "select-pure__option",
                autocompleteInput: "select-pure__autocomplete",
                selectedLabel: "select-pure__selected-label",
                selectedOption: "select-pure__option--selected",
                placeholderHidden: "select-pure__placeholder--hidden",
                optionHidden: "select-pure__option--hidden",
            }

        });
    }
})
// =========================link on url to select==========================================

function projectSettingsSection() {
    var url = document.location.href
    if (url.indexOf("viewselect") > -1) {
        var splitUrl = splitIntoParams(url)
        for (var i = 0; i < splitUrl.length; i++) {
            if (splitUrl[i].param == "viewselect") {
                var selectId = splitUrl[i].value
                $('.option-settings>li').removeClass("active");
                $("#project" + selectId).addClass("active");
                $('.targetDiv').hide();
                $('#div' + $("#project" + selectId).attr('target')).show();
            }
        }
    }
}
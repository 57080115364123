var walkthroughInstance = null

function triggerWindowResize() {
    setTimeout(function () {
        $("#content-dashboard").trigger("resize");
    }, 800)
}

function enjoyhintOnboarding() {

    var enjoyhint_instance_onboarding = new EnjoyHint({
        onNext: function () {
            triggerWindowResize();
        },
        onSkip: function () {
            // runs after the last hint

            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-onboarding", "SKIPPED");
            } else {
                // Sorry! No Web Storage support..
            }
        },
        onEnd: function () {
            // runs after the last hint
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-onboarding", "END");
            } else {
                // Sorry! No Web Storage support..
            }
        }
    });
    var enjoyhint_script_steps_onboarding = [{
        'custom #inputForKeywords': "Enter keywords you want to track and hit 'NEXT' when done.",
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "showNext": false
    },
    {
        'custom #step2-walkthrough': 'Click to select target country, city and industry for monitoring. The data will be gathered from all over the world with a specific focus on the mentioned country and city. Hit \'next\' when you are done!',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 200,
        "showNext": false

    },
    {
        'next #step3-walkthrough': 'Take a moment to check all your project settings and hit \'Confirm\' once you are fine. Ofcourse, you can change these settings later from Project Settings',
        "nextButton": {
            className: "myNext",
            text: "GOT IT!"
        },
        "showSkip": false,
        "shape": "circle",
        "radius": 150,
    },
    ];

    enjoyhint_instance_onboarding.set(enjoyhint_script_steps_onboarding);
    walkthroughInstance = enjoyhint_instance_onboarding
    enjoyhint_instance_onboarding.run();

}

function enjoyhintOnboardingResume() {

    if (walkthroughInstance !== undefined && walkthroughInstance !== null)
        walkthroughInstance.trigger("next")
}

function enjoyhintDashboard() {
    var enjoyhint_instance = new EnjoyHint({
        onNext: function () {
            triggerWindowResize();
        },
        onSkip: function () {
            // runs after the last hint

            if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-right")) {
                $("#sidebarCollapse").click()
            }
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-summary", "SKIPPED");
            } else {
                // Sorry! No Web Storage support..
            }
        },
        onEnd: function () {
            // runs after the last hint
            if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-right")) {
                $("#sidebarCollapse").click()
            }
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-summary", "END");
            } else {
                // Sorry! No Web Storage support..
            }
        }
    });
    var enjoyhint_script_steps = [
        /*{
            'next #bs-example-navbar-collapse-1': 'Select the time for which you want to see the data. You can select any defined range like Last 30 days or a custom range as per your requirements.',
            "nextButton": { className: "myNext", text: "NEXT" },
            "skipButton": { className: "mySkip", text: "SKIP" },
            "shape": "circle",
            "radius": 50,
}, */
        {
            'next #sb-summaryactive': 'This is the current page! It shows highlights of the time you selected. The major action items can also be seen here for quick reference.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-right")) {
                    $("#sidebarCollapse").click()
                }
            }

        },
        {
            'next #sb-mentionsactive': 'A mentions playground for you to understand what the audience is saying, the influence of these mentions, labels and sentiment.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,

        },
        {
            'next #sb-influencersactive': 'Discover and Engage with the influential audience who are talking about the keywords you are tracking. Filter out the relevant influencers and see detailed metrics of these influencers.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,

        },
        {
            'next #sb-analysisactive': 'Here you can see graphs showing detailed analysis of the mentions , audience and hashtags. A feature level analysis of mentions shows what is working for the brand and what is not.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,

        },
        {
            'next #sb-competitiveactive': "Compare your brand mentions with competitor's mentions. Only works if you have more than one running project.",
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,

        },

        {
            'next #sb-reportsactive': 'You can generate infographics report, PDF reports, Excel reports and all custom reports with filters over here.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,

        },
        {
            'next #sb-project_settingsactive': 'Fine tune sources, websites, keywords, notifications from here for your project. You can also take help of your account manager to take care of these things.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,

        },
        {
            'next #sb-add_project': 'Create new projects as per your requirements. Works only if you are on a paid plan.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,

        },
        {
            'next #summaryMetrixDiv1': 'Summary of total mentions accumulated in the time period selected. Web sources include articles, blogs, news, forums and reviews. Social Mentions include posts from Facebook, Twitter and Instagram.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
                    $("#sidebarCollapse").click()
                }
            }
        },
        {
            'next #summaryMetrixDiv2': 'Key performance indicators for social media listed below. Rank Me Online measures the total potential reach of the mentions by aggregating the follower counts of all authors mentioned.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
                    $("#sidebarCollapse").click()
                }
            }
        },
        {
            'next #summaryMetrixDiv3': 'Sentiment wise analysis of all the mentions. Positive mentions can be promoted while negative mentions need to be worked on.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
                    $("#sidebarCollapse").click()
                }
            }
        },
        {
            'next #summaryMetrixDiv4': 'On lines of Net Promoter Score, it gives the score of the brand across all touch-points. A score more than 0 means a positive influence of the brand over the audience.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
                    $("#sidebarCollapse").click()
                }
            }
        },
        {
            'next #walk-shareOfVoice': 'A pie-chart showing who had the most portion of the reviews and articles.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
                    $("#sidebarCollapse").click()
                }
            }
        },
        {
            'next #label-mentionsoverTime-source1': 'Graphical representation of the audience activity over time. Check how are web and social mentions count moving over time.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
                    $("#sidebarCollapse").click()
                }
            }
        },
        {
            'next #label-mentionsoverTime-source2': 'Graphical representation of the audience activity over time. Check how are positive and negative mentions count moving over time. Use this information to analyse the time frames for campaigns and deployments.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
                    $("#sidebarCollapse").click()
                }
            }
        },

        {
            'next #walk-trendingPosts': 'Trending posts featuring the keywords in a summary snapshot.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
                    $("#sidebarCollapse").click()
                }
            }
        },
        {
            'next #features-popular-hashtags-industry': 'These are the popular hashtags your audience is using in your industry. To better connect with your audience you should try adding these hashtags in your content so that your content will be easily discoverable.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
                    $("#sidebarCollapse").click()
                }
            }
        },
        {
            'next #features-popular-buzzwords-industry': 'Provides a list of buzzwords your brand is associated with. These include both the buzzwords created by you and the buzzwords people associate with you. It helps you understand your current audience better and helps you create campaigns where this audience can be retargetted even better.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
                    $("#sidebarCollapse").click()
                }
            }
        },
        {
            'next #label-HashtagsUsedbyYourAudience': 'The top hashtags with which the audience associates the brand most frequently. Use them in your next campaign to go trending in your target audience.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
                    $("#sidebarCollapse").click()
                }
            }
        },
        {
            'next #label-mentionOversources': 'A bar chart of the sources mentioning. It highlights which source is talking more about you and where you should concentrate your marketing campaigns.',
            "nextButton": {
                className: "myNext",
                text: "NEXT"
            },
            "skipButton": {
                className: "mySkip",
                text: "SKIP"
            },
            "shape": "circle",
            "radius": 20,
            onBeforeStart: function () {
                if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
                    $("#sidebarCollapse").click()
                }
            }
        },
        {
            'next #walkthrough': 'Thanks for giving us this time! To see the walkthrough once again please click here.',
            "nextButton": {
                className: "myNext",
                text: "GOT IT!"
            },
            "showSkip": false,
            "shape": "circle",
            "radius": 20,
        },


    ];

    enjoyhint_instance.set(enjoyhint_script_steps);

    enjoyhint_instance.run();
}

function enjoyhintMentions() {
    var enjoyhint_instance = new EnjoyHint({
        onNext: function () {
            triggerWindowResize();
        },
        onSkip: function () {
            // runs after the last hint

            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-mentions", "SKIPPED");
            } else {
                // Sorry! No Web Storage support..
            }
        },
        onEnd: function () {
            // runs after the last hint
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-mentions", "END");
            } else {
                // Sorry! No Web Storage support..
            }
        }
    });
    var enjoyhint_script_steps = [{
        'next #filters-heading': 'Refine your data using these extensive list of filters. You can refine by source type, language, image/video and even attributes.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        "onBeforeStart": function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }

    },
    /*{
        'next #filters_source': 'This filter will refine the data on the basis of source platforms.',
        "nextButton": { className: "myNext", text: "NEXT" },
        "skipButton": { className: "mySkip", text: "SKIP" },
    },
    {
        'next #filter_sentiment': 'This filter will help to refine the data on the basis of sentiment.',
        "nextButton": { className: "myNext", text: "NEXT" },
        "skipButton": { className: "mySkip", text: "SKIP" },
        onBeforeStart: function () {
            $("#toggle-source-filter").click()
            $("#toggle-sentiment-filter").click()
            $("#filterScroller").animate({
                scrollTop: $("#filterScroller").scrollTop() + 150
            });
        }
    },
    {
        'next #filter_website': 'This filter shows all the non-social media websites. ',
        "nextButton": { className: "myNext", text: "NEXT" },
        "skipButton": { className: "mySkip", text: "SKIP" },
    },*/
    {
        'next #sort_by': 'You can sort your data over here on the basis of relevance, time and influence score.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #searchBoxWalkthrough': 'The search bar will help you find what you are looking for. By default, it searches for the whole world. You can use operator - OR to search for multiple keywords in the mentions. Eg. #blogger OR blogging OR #fashionBlogger',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    /*
    {
        'next #showingNumber': 'It shows the number of results we got for your keywords, however this is just an approximation.',
        "nextButton": { className: "myNext", text: "NEXT" },
        "skipButton": { className: "mySkip", text: "SKIP" },
    },*/
    {
        'next #mention_post_walkthrough-header0': 'Checkout the author of the mentiom, date and our proprietary \'Influence Score\' to get an understanding of how important this mention is to your usecase',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next .mention_post_walkthrough-engage0': 'Click to directly engage with the mention using the direct link to source website.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }

    },
    {
        'next .mention_post_walkthrough-details0': 'Click to see detailed report of the mention with more background and insights.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }

    },
    {
        'next .mention_post_walkthrough-label0': 'Tag the mention to come back later with the list of different colours available',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }

    },
    {
        'next .mention_post_walkthrough-sentiment0': 'If you feel the sentiment is wrongly classified by our AI algorithm, you can manually change the sentiment to fix it for future as well.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next .mention_post_walkthrough-irrelevant0': 'Does this post not make sense to your usecase? Just click to mark irrelevant for future.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }

        }

    },
    /*
    {
        'next #paginationWalkthrough': 'You can scroll through different pages over here.',
        "nextButton": { className: "myNext", text: "NEXT" },
        "skipButton": { className: "mySkip", text: "SKIP" },
    },
    */
    {
        'next #walkthrough': 'Thanks for giving us this time! To see the walkthrough once again please click here.',
        "nextButton": {
            className: "myNext",
            text: "GOT IT!"
        },
        "showSkip": false,
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },

    ];

    enjoyhint_instance.set(enjoyhint_script_steps);

    enjoyhint_instance.run();
}

function enjoyhintEnterpriseComparison() {
    var enjoyhint_instance = new EnjoyHint({
        onNext: function () {
            triggerWindowResize();
        },
        onSkip: function () {
            // runs after the last hint

            if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-right")) {
                $("#sidebarCollapse").click()
            }
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-comparison", "SKIPPED");
            } else {
                // Sorry! No Web Storage support..
            }
        },
        onEnd: function () {
            // runs after the last hint
            if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-right")) {
                $("#sidebarCollapse").click()
            }
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-comparison", "END");
            } else {
                // Sorry! No Web Storage support..
            }
        }
    });


    var enjoyhint_script_steps = [{
        'next #walkthrough-comparison-projects-select': "Compare your own online performance with your competitors. Add competition's project to see the analysis",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #label-competitiveTotalMentionsPieChart': "This graph shows a comparison of amount of conversation happening around your brand against your competitors",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #label-competitiveTotalMentionsByTimePeriod': "This graph shows you the days where your brand and your competitors received most mentions.",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #label-competitiveNetBrandScore': "Overall Comparison of brands performance based on digital footprint. The net brand score gives a customer perception score which can be used on the same terms of net promoter score",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #label-competitiveSentimentWiseDistribution': "This graph shows you how much of the conversation happening around your brand is positive or negative compared with your competitors",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #label-competitivePositiveMentionsByTimePeriod': "This graph shows you the days where your brand and your competitors received most positive mentions.",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #label-competitiveNegativeMentionsByTimePeriod': "This graph shows you the days where your brand and your competitors received most negative mentions. Sentiment is calculated by Rank Me Online's proprietary algorithm.",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #label-competitiveChannelWiseDistribution': "This graph shows you presence of your competitors on different channels. You can also compare it with your presence",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #label-competitiveWebPlatformMentionDistribution': "These are the top web platforms which have mentioned your brand vs your competitors",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #label-competitivetopAuthors': "Hover on each circle to find our more about the most influential people talking about your brand and your competitors",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #competitive-competitiveArticleMentions': "Hover on each circle to find our more about the articles published about your brand vs your competitors",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #competitive-AudienceGenderAggregation': "This graph shows you which gender talks more about your brand vs the competitors. Gender is identified by Rank Me Online's proprietary algorithm",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #competitive-AudienceCategoryAggregation': "This graph shows you how influential are the people who are talking about your brand vs the competitors. Influence Score is calculated by Rank Me Online's proprietary algorithm",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #walkthrough-radarCompetitorsOverFeatureChartData': "This graph shows you the most important features your audience associates with you and your competitors",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #walkthrough-subtopicAnalysis': " This is the comparative analysis for the parent topic under which some smaller topics will fall. For eg. Delivery Time, Delivery Executive, Delivered Package Condition will fall under Delivery",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    }, {
        'next #label-competitiveSocialMediaInteractionsCounts': "This graph shows you the comparison on Social Media likes, comments and shares for your brand vs the competitors.",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #walkthrough': 'Thanks for giving us this time! To see the walkthrough once again please click here.',
        "nextButton": {
            className: "myNext",
            text: "GOT IT!"
        },
        "showSkip": false,
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    ];

    enjoyhint_instance.set(enjoyhint_script_steps);

    enjoyhint_instance.run();
}

function enjoyhintFeatureAnalysis() {
    var enjoyhint_instance = new EnjoyHint({
        onNext: function () {
            triggerWindowResize();
        },
        onSkip: function () {
            // runs after the last hint
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-feature-analysis", "SKIPPED");
            } else {
                // Sorry! No Web Storage support..
            }
        },
        onEnd: function () {
            // runs after the last hint
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-feature-analysis", "END");
            } else {
                // Sorry! No Web Storage support..
            }
        }
    });

    var enjoyhint_script_steps2 = [{
        'next #filters-heading': 'See custom analysis for your brand using these extensive list of filters. You can refine by source type, language, image/video and even location.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }

    },
    {
        'next #searchBoxWalkthrough': 'The search bar will help you find what you are looking for. By default, it searches for the whole world. You can use operator - OR to search for multiple keywords in the mentions. Eg. #blogger OR blogging OR #fashionBlogger',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-shareOfBrandAttributes': 'See here which major topics are getting the maximum volume of mentions against other topics',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-overallPopularAttributes': 'These are the main sub-topics which are getting the maximum volume of mentions among all subtopics',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-overallMostLikedAttributes': 'These are the main sub-topics which are getting the maximum volume of positive mentions among all subtopics',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-overallMostDislikedAttributes': 'These are the main sub-topics which are getting the maximum volume of negative mentions among all subtopics',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-DistributionOfTopicsByTime': 'See here how the number of mentions around each topic have changed over time',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },

    // {
    //     'next #enterprise-feature-carousel': 'Through this section, you can find out which topic is mentioned more on which platform and ow many times this topics has been mentioned positively or negatively',
    //     "nextButton": {
    //         className: "myNext",
    //         text: "NEXT"
    //     },
    //     "skipButton": {
    //         className: "mySkip",
    //         text: "SKIP"
    //     },
    //     onBeforeStart: function () {
    //         if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
    //             $("#sidebarCollapse").click()
    //         }
    //     }
    // },
    {
        'next #feature-channelwiseDistribution0': 'Through this graph, you can find out which topic is mentioned more on which platform',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-sentimentwiseDistribution0': 'Through this graph, you can find out which how many times this topics has been mentioned positively or negatively',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-popularTopicAttribute0': 'These are the main sub-topics under the title Topic which are getting the maximum volume of mentions ',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-likedTopicAttribute0': 'These are the main sub-topics under the title Topic which are getting the maximum volume of positive mentions',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-dislikedTopicAttribute0': 'These are the main sub-topics under the title Topic which are getting the maximum volume of negative mentions',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 10,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #walkthrough': 'Thanks for giving us this time! To see the walkthrough once again please click here.',
        "nextButton": {
            className: "myNext",
            text: "GOT IT!"
        },
        "showSkip": false,
        "shape": "circle",
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }

    },

    ];

    var enjoyhint_script_steps = [{
        'next #filters-heading': 'See custom analysis for your brand using these extensive list of filters. You can refine by source type, language, image/video and even location.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }

    },
    {
        'next #searchBoxWalkthrough': 'The search bar will help you find what you are looking for. By default, it searches for the whole world. You can use operator - OR to search for multiple keywords in the mentions. Eg. #blogger OR blogging OR #fashionBlogger',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-shareOfBrandAttributes': 'See here which major topics are getting the maximum volume of mentions against other topics',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-overallPopularAttributes': 'These are the main sub-topics which are getting the maximum volume of mentions among all subtopics',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-overallMostLikedAttributes': 'These are the main sub-topics which are getting the maximum volume of positive mentions among all subtopics',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-overallMostDislikedAttributes': 'These are the main sub-topics which are getting the maximum volume of negative mentions among all subtopics',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #feature-DistributionOfTopicsByTime': 'See here how the number of mentions around each topic have changed over time',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #walkthrough': 'Thanks for giving us this time! To see the walkthrough once again please click here.',
        "nextButton": {
            className: "myNext",
            text: "GOT IT!"
        },
        "showSkip": false,
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    ];



    if (document.getElementById('feature-attributionAnalysis0')) {
        enjoyhint_instance.set(enjoyhint_script_steps2);
    } else {
        enjoyhint_instance.set(enjoyhint_script_steps);
    }
    enjoyhint_instance.run();

}


function enjoyhintContentAnalysis() {
    var enjoyhint_instance = new EnjoyHint({
        onNext: function () {
            triggerWindowResize();
        },
        onSkip: function () {
            // runs after the last hint
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-content-analysis", "SKIPPED");
            } else {
                // Sorry! No Web Storage support..
            }
        },
        onEnd: function () {
            // runs after the last hint
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-content-analysis", "END");
            } else {
                // Sorry! No Web Storage support..
            }
        }
    });

    var enjoyhint_script_steps = [{
        'next #filters-heading': 'See custom analysis for your brand using these extensive list of filters. You can refine by source type, language, image/video and even location.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }

    },
    {
        'next #searchBoxWalkthrough': 'The search bar will help you find what you are looking for. By default, it searches for the whole world. You can use operator - OR to search for multiple keywords in the mentions. Eg. #blogger OR blogging OR #fashionBlogger',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-mentionovertime': 'Graphical trend of mentions over time. Check how are the mentions count, for the selected keyword or filters, moves over time.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },

    {
        'next #content-channelwisedistribution': 'See which platform had the most volume of mentions for the selected keyword or filters',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-sentimentwisedistribution': 'Check the overall sentiment of the mentions for the selected keyword or filters',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-keyworddistributionbychannel': 'See which platform had the most volume of mentions for the keywords added in the project setting. You should regularly monitor the most important keywords for your use case.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-keyworddistributionbysentiment': 'Check which keyword is receiving more Negative mentions compared to the others. Add more keywords in the project settings to compare.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-brandhashtagonsocial': 'Measure the performance of your brand hashtags on different social media platforms. This graph shows the analysis of Hashtags you have added in Project Settings. We recommend that you add at least 5 hashtags pertaining to your brand here.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-audienceHashtags': 'Discover and measure the performance of the hashtags used by your audience on social media platforms',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-buzzwords': 'Check the major buzzwords in your industry on all platforms. These include both the buzzwords created by you and the buzzwords people associate with you. It helps you understand your current audience better and helps you create campaigns where this audience can be retargetted even better.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-timeHashtagPerformance': 'Graphical trend of hashtags over time. Check how are the mentions count, for the your hashtags, moves over time. This graph shows the analysis of Hashtags you have added in Project Settings. We recommend that you add at least 5 hashtags pertaining to your brand here.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-performanceComparisonBrandVsIndustry': 'Performance Comparison of Brand Promoted Hashtags vs Industry',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-performanceComparisonBrandVsAudience': 'Comparison the performance of the hashtags used by you and by your audience',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-mentionsByLocationState': 'Discover important locations in state wise for the selected keyword or filters. This will help you in targeting your marketing campaigns.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-mentionsByLocationCity': 'Discover important locations in city wise for the selected keyword or filters. This will help you in targeting your marketing campaigns.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-mentionsByLanguage': 'Discover important languages for the selected keyword or filters',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-contentType': 'See which type of content is posted more for the selected keyword or filters',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-PromotersByNumberOfMentions': 'Discover people who have been posting positive content about your brand frequently',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-DetractorsByNumberOfMentions': 'Discover people who have been posting negative content about your brand online',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #walkthrough': 'Thanks for giving us this time! To see the walkthrough once again please click here.',
        "nextButton": {
            className: "myNext",
            text: "GOT IT!"
        },
        "showSkip": false,
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    ];

    enjoyhint_instance.set(enjoyhint_script_steps);

    enjoyhint_instance.run();
}

function enjoyhintDemographicAnalysis() {
    var enjoyhint_instance = new EnjoyHint({
        onNext: function () {
            triggerWindowResize();
        },
        onSkip: function () {
            // runs after the last hint
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-right")) {
            //     $("#sidebarCollapse").click()
            // }
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-demographic-analysis", "SKIPPED");
            } else {
                // Sorry! No Web Storage support..
            }
        },
        onEnd: function () {
            // runs after the last hint
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-right")) {
            //     $("#sidebarCollapse").click()
            // }
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-demographic-analysis", "END");
            } else {
                // Sorry! No Web Storage support..
            }
        }
    });
    var enjoyhint_script_steps = [{
        'next #features-demo-linegraph': 'This graph captures the movement of each of your keywords over time. You can see which keyword was associated with your brand more at each point in time.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },

    {
        'next #features-demo-geoIndia': 'This graph captures the areas where audience is more interested in your keywords in India. This will help you in targeting your marketing campaigns.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            //     if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //         $("#sidebarCollapse").click()
            //     }
        }
    },
    {
        'next #features-demo-geoWorld': 'This graph captures the locations where audience is more interested in your keywords globally. This will help you expand into new geographies.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            //     if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //         $("#sidebarCollapse").click()
            //     }
        }
    },
    {
        'next #walkthrough': 'Thanks for giving us this time! To see the walkthrough once again please click here.',
        "nextButton": {
            className: "myNext",
            text: "GOT IT!"
        },
        "showSkip": false,
        "shape": "circle",
        "radius": 20,
        // onBeforeStart: function () {
        //     if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
        //         $("#sidebarCollapse").click()
        //     }
        // }
    },
    ];

    enjoyhint_instance.set(enjoyhint_script_steps);

    enjoyhint_instance.run();
}

function enjoyhintAudienceGraph() {
    var enjoyhint_instance = new EnjoyHint({
        onNext: function () {
            triggerWindowResize();
        },
        onSkip: function () {
            // runs after the last hint

            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-audience", "SKIPPED");
            } else {
                // Sorry! No Web Storage support..
            }
        },
        onEnd: function () {
            // runs after the last hint
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-audience", "END");
            } else {
                // Sorry! No Web Storage support..
            }
        }
    });
    var enjoyhint_script_steps = [{
        'next #filters-influencers-list': 'These are the filters to refine your Audience Graph. You can filter by source and also by language to get the audience of choice.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #walkthrough-Channelwisedistributionofaudience': 'Understand how does the audience volume spans across channels.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    // {
    //     'next #walkthrough-Divisionofaudience': 'This graph shows the share of voice between your organic audience and brand',
    //     "nextButton": {
    //         className: "myNext",
    //         text: "NEXT"
    //     },
    //     "skipButton": {
    //         className: "mySkip",
    //         text: "SKIP"
    //     },
    //     onBeforeStart: function () {
    //         if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
    //             $("#sidebarCollapse").click()
    //         }
    //     }
    // },
    {
        'next #walkthrough-AudiencebyGender': 'Which gender is more participant towards the niche, find using this graph.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #walkthrough-AudiencebyLanguage': 'Which language is used by the audience? find using this graph.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #walkthrough-AudiencebyInfluencer': ' Influencers can be categorized by the volume of followers to understand the relative influence. How relevant is the niche amongst the audience can be found from this pie chart.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-PromotersByNumberOfMentions': 'Most influential people who mention the brand in their posts.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-DetractorsByNumberOfMentions': ' Top people who talk the most about you!',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #content-BrandAdvocatesByInfluenceScore': '  Top people having the most followers and also  mentioning your keywords in their posts',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },

    {
        'next #walkthrough': 'Thanks for giving us this time! To see the walkthrough once again please click here.',
        "nextButton": {
            className: "myNext",
            text: "GOT IT!"
        },
        "showSkip": false,
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    ];

    enjoyhint_instance.set(enjoyhint_script_steps);

    enjoyhint_instance.run();

}

function enjoyhintInfluencers() {
    var enjoyhint_instance = new EnjoyHint({
        onNext: function () {
            triggerWindowResize();
        },
        onSkip: function () {
            // runs after the last hint

            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-influencers", "SKIPPED");
            } else {
                // Sorry! No Web Storage support..
            }
        },
        onEnd: function () {
            // runs after the last hint
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-influencers", "END");
            } else {
                // Sorry! No Web Storage support..
            }
        }
    });
    var enjoyhint_script_steps = [{
        'next #filters-influencers-list': 'These are the filters to refine your Influencers. You can filter by source and also by language to get the influencers of choice.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #sort_Walkthrough': 'You can sort the influencers over here according to their Influence Score, Active Score and Reach Score. ',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next .infl-social-contain': 'You can see the score of the influencer in terms of of its Genuine Influence, Reach and Activity',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #walkthroughEngage0': 'Click here to Engage with the influencer directly.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #walkthroughDetail0': 'Click here to see the detailed view of post and influencer both.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    {
        'next #walkthrough': 'Thanks for giving us this time! To see the walkthrough once again please click here.',
        "nextButton": {
            className: "myNext",
            text: "GOT IT!"
        },
        "showSkip": false,
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },
    ];

    enjoyhint_instance.set(enjoyhint_script_steps);

    enjoyhint_instance.run();
}

function enjoyhintReports() {
    var enjoyhint_instance = new EnjoyHint({
        onNext: function () {
            triggerWindowResize();
        },
        onSkip: function () {
            // runs after the last hint

            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-reports", "SKIPPED");
            } else {
                // Sorry! No Web Storage support..
            }
        },
        onEnd: function () {
            // runs after the last hint
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-reports", "END");
            } else {
                // Sorry! No Web Storage support..
            }
        }
    });
    var enjoyhint_script_steps = [{
        'next #reports-filter-name': "Select all the filters for which you need the data in your reports",
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            // if ($("#sidebarCollapse").children(".angle").hasClass("fa-angle-double-left")) {
            //     $("#sidebarCollapse").click()
            // }
        }
    },

    {
        'next #infographicReport_Walkthrough': 'Click here to Generate Infographics report for your keywords.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,

    },
    {
        'next #pdfReport_Walkthrough': 'Click here to Generate PDF report for your keywords.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,

    },
    {
        'next #excelReport_Walkthrough': 'Click here to generate Excel report for your keywords.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,

    },
    {
        'next #customReport_Walkthrough': 'You can request for a custom report by clicking over here.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,

    },
    {
        'next #walkthrough': 'Thanks for giving us this time! To see the walkthrough once again please click here.',
        "nextButton": {
            className: "myNext",
            text: "GOT IT!"
        },
        "showSkip": false,
        "shape": "circle",
        "radius": 20,
    },

    ];

    enjoyhint_instance.set(enjoyhint_script_steps);

    enjoyhint_instance.run();
}

function enjoyhintProjectSettings() {
    var enjoyhint_instance = new EnjoyHint({
        onNext: function () {
            triggerWindowResize();
        },
        onSkip: function () {
            // runs after the last hint
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-settings", "SKIPPED");
            } else {
                // Sorry! No Web Storage support..
            }
        },
        onEnd: function () {
            // runs after the last hint
            if (typeof (Storage) !== "undefined") {
                localStorage.setItem("walkthrough-settings", "END");

            } else {
                // Sorry! No Web Storage support..
            }
        }
    });
    var enjoyhint_script_steps = [{
        'next #projectKeywords': 'This is where you can add more keywords for your project.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
    },
    {
        'next #projectSources': 'You can add your media handles and more websites you want us track for you.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            $("#projectSources").click()
        }
    },
    {
        'next #projectNotifications': 'You can change your notification settings over here.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            $("#projectNotifications").click()
        }
    },
    {
        'next #projectReports': 'You can add manual header, footer and company logo for your reports. ',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            $("#projectReports").click()
        }
    },
    {
        'next #projectSupport': 'There is a small tutorial demo for you as well as your personal account manager details.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            $("#projectSupport").click()
        }
    },
    {
        'next #categories': 'If you want to change the kind of industry your project belongs to you can change it here.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            $("#projectKeywords").click()
        }
    },

    {
        'next #projectMarkedIrrelevant': 'All the irrelevant posts marked by you on mentions page can be found here.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,

    },
    {
        'next #projectSettingsRequiredKeywords0': 'List the keywords you want to track about your brand here. If you add words separated by space, we will search for both words in a single mention. For eg. "Surf Excel" will look for both Surf and Excel in a single mention.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,

    },
    // {
    //     'next #addSupporingKeyword': 'Add more supporting keywords for even better results.',
    //     "nextButton": {
    //         className: "myNext",
    //         text: "NEXT"
    //     },
    //     "skipButton": {
    //         className: "mySkip",
    //         text: "SKIP"
    //     },
    // },
    // {
    //     'next .audiencekey': 'These are keywords of the audience that you want to track. Influencers whose mentions contains these keywords will come up first in your list.',
    //     "nextButton": {
    //         className: "myNext",
    //         text: "NEXT"
    //     },
    //     "skipButton": {
    //         className: "mySkip",
    //         text: "SKIP"
    //     },
    // },
    {
        'next .hashtagkey': 'List the hashtags you want to track here. These hashtags could be associated with your brand or your industry.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,


    },
    {
        'next .notKeywordkey': 'List the keywords that you do NOT want to see mentions of. You can also add any website url if you do not want to see any data related to it.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,

    },
    {
        'next .personalitykey': 'List all relevant personalities that you want to keep a track of mentioning your relevant data. eg. Narendra Modi , Warren Buffet etc.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,

    },
    {
        'next .source-one-setting': 'List the Social Media Handles from where you want to start crawling for all the relevant data about your brand.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            $("#projectSources").click()

        }
    },
    {
        'next .addSource': 'If there are any sites you specifically want to track, list the complete URL here.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,

    },
    {
        'next .brandSourceInput': 'Add your own brand websites over here so that your mentions can be seggregated.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,

    },
    {
        'next .email-notif-one-setting': 'Add your emails with the frequency of emailers desired over here.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            $("#projectNotifications").click();
        }
    },
    {
        'next #smsMobileNumber': 'You can add your mobile numbers over here you want to receive SMS notifications.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,

    },
    {
        'next #companyLogoPreview': 'Add a logo of your company which would appear on your reports.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            $("#projectReports").click()
        }
    },
    {
        'next #account-manager-settings-name': 'Your personal account manager details are listed here for your reference. Feel free to email or call at time of your need.',
        "nextButton": {
            className: "myNext",
            text: "NEXT"
        },
        "skipButton": {
            className: "mySkip",
            text: "SKIP"
        },
        "shape": "circle",
        "radius": 20,
        onBeforeStart: function () {
            $("#projectSupport").click()
        }
    },
    {
        'next #walkthrough': 'Thanks for giving us this time! To see the walkthrough once again please click here.',
        "nextButton": {
            className: "myNext",
            text: "GOT IT!"
        },
        "showSkip": false,
        "shape": "circle",
        "radius": 20,
    },


    ];

    enjoyhint_instance.set(enjoyhint_script_steps);

    enjoyhint_instance.run();
}



function demoVideoSeeAgain() {
    $("#firsttimeUserHintBtnClick").click()
}
$(document).ready(function () {
  if (document.getElementById("panalresultPage")) {
    var url = document.location.href
    console.log("url is " + url)

    setTimeout(function () {
      // $(".show-panal-loader").html("<img style='height: 25px;width: 25px;' src = '/images/loader-icon.gif'/>")
      // $(".mention-panal-loader").toggleClass("hideAllFilters")
      // var data = {}
      // postCallForRefreshPanal(data)
      // document.location.href = url;
    }, 5000);
  }


  $('.count-long-number').each(function () {
    $(this).prop('Counter', 0).animate({
      Counter: $(this).data('text')
    }, {
      duration: 4000,
      easing: 'swing',
      step: function (now) {
        $(this).text(Math.ceil((now) * 10) / 10);
      }
    });
  });


  $('.count-number').each(function () {
    $(this).prop('Counter', 0).animate({
      Counter: $(this).data('text')
    }, {
      duration: 4000,
      easing: 'swing',
      step: function (now) {
        $(this).text(Math.ceil(now));
      }
    });
  });
})


function postCallForRefreshPanal(data) {
  $.ajax({
    method: "POST",
    url: "/enterprise-panel-result",
    data: data,
    success: function (res) {
      console.log(JSON.stringify(res))
      if (res && res.status && res.status == "success") {
        repliedSuccess('fa fa-check', "success")
      }
    },
    error: function (res) {
      console.log(JSON.stringify(res))
      repliedSuccess('fa fa-times', "failed")
    }
  })

}



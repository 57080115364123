$(document).ready(function () {
  if (document.getElementById('trendingHashtagOfBrand') || document.getElementById('trendingHashtagOverLocation')) {
    if (trendingHashtagObj && document.getElementById('trendingHashtagOfBrand') || document.getElementById('trendingHashtagOverLocation')) {
      var width = (document.getElementsByClassName('wordcloud')) ? ($(".wordcloud").width() - 10) : 500
      if (document.getElementById('trendingHashtagOfBrand')) {
        TrendingHashtagOfBrand = trendingHashtagObj.brandHashtags;
        d3.wordcloud()
          .selector("#trendingHashtagOfBrand")
          .size([width, 379])
          .fill(d3.scale.ordinal().range(["#884400", "#448800", "#888800", "#444400"]))
          .words(TrendingHashtagOfBrand)
          .onwordclick(function (d, i) {
            if (d.href) {
              document.location = d.href;
            }
          })
          .start();
      }

      if (document.getElementById('trendingHashtagOverLocation')) {
        TrendingHashtagOverLocation = trendingHashtagObj.categoryHashtags;
        d3.wordcloud()
          .selector("#trendingHashtagOverLocation")
          .size([width, 400])
          .fill(d3.scale.ordinal().range(["#884400", "#448800", "#888800", "#444400"]))
          .words(TrendingHashtagOverLocation)
          .onwordclick(function (d, i) {
            if (d.href) {
              document.location = d.href;
            }
          })
          .start();
      }


    }
  }

  if (document.getElementById('trendingEntitiesOfBrand') || document.getElementById('trendingEntitiesOverLocation')) {
    if (trendingEntitiesObj && document.getElementById('trendingEntitiesOfBrand') || document.getElementById('trendingEntitiesOverLocation')) {
      var width = (document.getElementsByClassName('wordcloud')) ? ($(".wordcloud").width() - 10) : 500
      if (document.getElementById('trendingEntitiesOfBrand')) {
        TrendingEntitiesOfBrand = trendingEntitiesObj.brandEntities;
        d3.wordcloud()
          .selector("#trendingEntitiesOfBrand")
          .size([width, 379])
          .fill(d3.scale.ordinal().range(["#00b1b4", "#ed4515", "#fd9400", "#272b35"]))
          .words(TrendingEntitiesOfBrand)
          .onwordclick(function (d, i) {
            if (d.href) {
              document.location = d.href;
            }
          })
          .start();
      }
      if (document.getElementById('trendingEntitiesOverLocation')) {
        TrendingEntitiesOverLocation = trendingEntitiesObj.categoryEntities;
        d3.wordcloud()
          .selector("#trendingEntitiesOverLocation")
          .size([width, 400])
          .fill(d3.scale.ordinal().range(["#00b1b4", "#ed4515", "#fd9400", "#272b35"]))
          .words(TrendingEntitiesOverLocation)
          .onwordclick(function (d, i) {
            if (d.href) {
              document.location = d.href;
            }
          })
          .start();
      }
    }
  }

  // enterprise-features's word graphs 
  if (document.getElementById('featureCompleteWordGraph') || document.getElementById('featureSocialWordGraph') || document.getElementById('featureArticleWordGraph') || document.getElementById('featureReviewWordGraph')) {
    if (featureCompleteWordGraph && document.getElementById('featureCompleteWordGraph')) {
      var width = (document.getElementsByClassName('wordcloud')) ? ($(".wordcloud").width() - 10) : 500
      if (document.getElementById('featureCompleteWordGraph')) {
        d3.wordcloud()
          .selector("#featureCompleteWordGraph")
          .size([width, 300])
          .fill(d3.scale.ordinal().range(["#884400", "#448800", "#888800", "#444400"]))
          .words(featureCompleteWordGraph)
          .onwordclick(function (d, i) {
            if (d.href) {
              document.location = d.href;
            }
          })
          .start();
      }
    }
    if (featureSocialWordGraph && document.getElementById('featureSocialWordGraph')) {
      var width = (document.getElementsByClassName('wordcloud')) ? ($(".wordcloud").width() - 10) : 500
      if (document.getElementById('featureSocialWordGraph')) {
        d3.wordcloud()
          .selector("#featureSocialWordGraph")
          .size([width, 300])
          .fill(d3.scale.ordinal().range(["#884400", "#448800", "#888800", "#444400"]))
          .words(featureSocialWordGraph)
          .onwordclick(function (d, i) {
            if (d.href) {
              document.location = d.href;
            }
          })
          .start();
      }
    }
    if (featureArticleWordGraph && document.getElementById('featureArticleWordGraph')) {
      var width = (document.getElementsByClassName('wordcloud')) ? ($(".wordcloud").width() - 10) : 500
      if (document.getElementById('featureArticleWordGraph')) {
        d3.wordcloud()
          .selector("#featureArticleWordGraph")
          .size([width, 300])
          .fill(d3.scale.ordinal().range(["#884400", "#448800", "#888800", "#444400"]))
          .words(featureArticleWordGraph)
          .onwordclick(function (d, i) {
            if (d.href) {
              document.location = d.href;
            }
          })
          .start();
      }
    }
    if (featureReviewWordGraph && document.getElementById('featureReviewWordGraph')) {
      var width = (document.getElementsByClassName('wordcloud')) ? ($(".wordcloud").width() - 10) : 500
      if (document.getElementById('featureReviewWordGraph')) {
        d3.wordcloud()
          .selector("#featureReviewWordGraph")
          .size([width, 300])
          .fill(d3.scale.ordinal().range(["#884400", "#448800", "#888800", "#444400"]))
          .words(featureReviewWordGraph)
          .onwordclick(function (d, i) {
            if (d.href) {
              document.location = d.href;
            }
          })
          .start();
      }
    }
  }

});
$(document).ready(function () {
  $('.js-location-report-filter-source').click(function () {
    jsFilterBarReportScroller()
  });
  $('.js-language-report-filter-source').click(function () {
    jsFilterBarReportScroller()
  });
  $('.js-searchbox-report-filter-source').click(function () {
    jsFilterBarReportScroller()
  });
  $('.js-sort-report-filter-source').click(function () {
    jsFilterBarReportScroller()
  });
  $('.js-website-report-filter-source').click(function () {
    jsFilterBarReportScroller()
  });


  $("#clearAll, #filterclearall").on('click', function (event) {
    event.preventDefault();
    showLoaderOnClick()
    clearAllFilter();

  });

  if (document.getElementById('reportClearAllFilter')) {
    $("#reportClearAllFilter").on('click', function (event) {
      event.preventDefault();
      $(".reportFilterClear")[0].reset();
      $("#fromdate").attr("value", "")
      $("#todate").attr("value", "")
      reportClearAllNotify();
      checkAndSetFiltersApplied();
    });
  }


  // if (document.getElementById('generateinfo')) {
  $(".createInfographicsSidebar").on("click", function () {
    console.log(this)
    var projectId = $(this).children("input").val()
    var url = "/generateReport?reportType=INFOGRAPHICS&projectId=" + projectId
    var check = validate("#generateInfoLoader", ".infoerr", "#generateinfo")
    if (check != false) {
      // $("#generateinfo").attr('disabled', 'disabled')
      url = generateData(url)
      callReportBackend(url, "#generateInfoLoader", ".infoerr", "#generateinfo")
      window.open(url)

    }
    //var callReportBackendCheck = 
    //callReportBackend(url, "#generateInfoLoader", ".infoerr", "#generateinfo")
    // url = generateData(url)
    // if (callReportBackendCheck != false) {
    //     window.open(url)
    // }

  });
  // }
  if (document.getElementById('viewInBrowser')) {
    $("#viewInBrowser").on("click", function () {
      var isLogoUploded = true
      var isCoverUploaded = true
      if ($('#pdfcompanyLogoUpload')[0].files.length > 0) {
        var logo = document.getElementById('pdfcompanyLogoUpload')
        isLogoUploded = uploadpdfLogo(logo)
      }
      if ($('.pdfCover:checked').val() === 'customCover') {
        if ($('#coverbutton').text() === "Choose File") {
          alert("Please Choose Report Cover")
          return false
        }
        var cover = document.getElementById('reportCover')
        isCoverUploaded = uploadpdfCover(cover)
      }
      if (isLogoUploded && isCoverUploaded) {
        var brandReportUrl = generateData('/viewBrandReportInBrowser?reportType=PDF')
        window.open(brandReportUrl)
      }

    })
  }
  $("#pdfcompanyLogoUpload").change(function () {
    readURL(this, "#pdfcompanyLogoPreview");
  });
  $("#reportCover").change(function () {
    document.getElementById('coverbutton').innerHTML = this.files[0].name;
  });







  if (document.getElementById('generatepdf')) {
    $("#generatepdf").on("click", function () {
      var url = "/generateReport?reportType=PDF"
      var isLogoUploded = true
      var isCoverUploaded = true
      if ($('#pdfcompanyLogoUpload')[0].files.length > 0) {
        var logo = document.getElementById('pdfcompanyLogoUpload')
        isLogoUploded = uploadpdfLogo(logo)
      }
      if ($('.pdfCover:checked').val() === 'customCover') {
        if ($('#coverbutton').text() === "Choose File") {
          alert("Please Choose Report Cover")
          return false
        }
        var cover = document.getElementById('reportCover')
        isCoverUploaded = uploadpdfCover(cover)
      }
      // callReportBackend(url, "#generatePdfLoader", ".pdferr", "#generatepdf")
      var check = validate("#generatePdfLoader", ".pdferr", "#generatepdf")
      if (check != false && isLogoUploded && isCoverUploaded) {

        $(".generatepdfButton").attr('disabled', 'disabled')
        $('#pdfgenerate').attr('disabled', 'disabled')
        $('#pdfFilter').modal('hide')
        url = generateData(url)
        callReportBackend(url, "#generatePdfLoader", ".pdferr", ".generatepdfButton")
        // window.open(url)
      }
      else {
        return false
      }
    })
  }

  if (document.getElementById('generateexcel')) {
    $("#generateexcel").on("click", function () {
      var url = "/generateReport?reportType=EXCEL"
      // callReportBackend(url, "#generateExcelLoader", ".excelerr", "#generateexcel")
      var check = validate("#generateExcelLoader", ".excelerr", "#generateexcel")
      if (check != false) {
        $(".generateExcelButton").attr('disabled', 'disabled')
        url = generateData(url)
        callReportBackend(url, "#generateExcelLoader", ".excelerr", "#generateexcel")
        // window.open(url)
      }
    })
  }

  if (document.getElementById('generatecustom')) {
    $("#generatecustom").on("click", function () {
      var url = "/generateReport?reportType=CUSTOM"
      // callReportBackend(url, "#generateCustomLoader", ".customerr", "#generatecustom")
      var check = validate("#generateCustomLoader", ".customerr", "#generatecustom")
      if (check != false) {
        $("#generatecustom").attr('disabled', 'disabled')
        url = generateData(url)
        callReportBackend(url, "#generateCustomLoader", ".customerr", "#generatecustom")
        // window.open(url)
      }
    })
  }









})




function uploadpdfLogo(input) {
  var logoUploadStatus = true
  if (input.files && input.files[0]) {
    var signedUrl = callAndGetSignedUrl('logo')
    $.ajax({
      url: signedUrl,
      type: 'PUT',
      async: false,
      processData: false,
      contentType: false,
      data: input.files[0],
      success: function (res) {
        logoUploadStatus = true
      },
      error: function (res) {
        alert("Sorry your Logo could not be uploaded.")
        logoUploadStatus = false
      }
    });

  }
  return logoUploadStatus
}
function uploadpdfCover(input) {
  var coverUploadStatus = true
  if (input.files && input.files[0]) {
    var signedUrl = callAndGetSignedUrl('cover')
    $.ajax({
      url: signedUrl,
      type: 'PUT',
      async: false,
      processData: false,
      contentType: false,
      data: input.files[0],
      success: function (res) {
        coverUploadStatus = true
      },
      error: function (res) {
        alert("Sorry your Cover could not be uploaded.")
        coverUploadStatus = false
      }
    });

  }
  return coverUploadStatus
}
function callAndGetSignedUrl(imageType) {
  var signedUrl = null
  var reporturl = "/pdfLogoUpload"
  var projectId = document.location.href.split('=')[1]
  $.ajax({
    url: reporturl,
    async: false,
    type: "GET",
    data: {
      uploadData: imageType,
      projectId: projectId
    },
    success: function (res) {
      signedUrl = res
    }
  });
  return signedUrl
}



function jsFilterBarReportScroller() {
  var filterLastHeight = $("#reportScroller").scrollTop();
  // if (filterLastHeight > 100) {
  $("#reportScroller").animate({
    scrollTop: filterLastHeight + 150
  });
  // }
}



function clearAllFilter() {
  paramArray = [];
  var destUrl = clearAll();
  document.location = destUrl;
}




function validate(loaderDivId, errorClass, buttonId) {
  var check = false;
  var dateRange = $("#reportSelectDate>span").html()
  var fromdate = dateRange.substr(0, dateRange.indexOf("-"))
  var todate = dateRange.substr(dateRange.indexOf("-") + 1)
  // var fromdate = $("#fromdate").val()
  // var todate = $("#todate").val()
  // var mindate = $("#fromdate")[0].min
  // var maxdate = $("#fromdate")[0].max
  if ((fromdate !== null && fromdate !== "") && (todate !== null && todate !== "")) {
    // if ((todate >= mindate && todate <= maxdate) && (fromdate >= mindate && fromdate <= maxdate)) {
    //     if (todate < fromdate) {
    //         check = false
    //         alert("Please check start data & end date")
    //     } else {
    //         check = true
    //     }
    // } else {
    // check = false
    // alert("Please check start data & end date")
    // }
    check = true
  } else {
    check = false
    getErrorMessage(loaderDivId, errorClass, buttonId)
  }
  return check
}

function getErrorMessage(loaderDivId, errorClass, buttonId) {
  // $(loaderDivId).css("display", "none");
  if (errorClass === ".customerr") {
    $(errorClass).html("Please contact support@rankmeonline.com")
    $(errorClass).css("color", "green")
  } else {
    $(errorClass).html("Please select start date & end date ")
    $(errorClass).css("color", "red")
  }
}

function reportSentNotify(message, icon) {
  var offsetdata = checkScreenViewForNotify()
  $.notify({
    title: '',
    icon: icon,
    message: message,
  }, {
    type: 'info',
    animate: {
      enter: 'animated fadeInUp',
      exit: 'animated fadeOutRight'
    },
    placement: {
      from: "top",
      align: "right"
    },
    offset: offsetdata,
    spacing: 10,
    z_index: 1051,

  });
}

function generateData(url) {
  var url = url
  var str = document.location.href;
  var reportTypeUrl = url.split('?')
  var reportTypeArr = reportTypeUrl[1].split('&')
  var reportType;
  var res = str.split('?');
  var array = []
  if (res.length > 1)
    array = res[1].split("&");
  var projectid;
  for (var i = 0; i != array.length; i++) {
    var proj = array[i].startsWith("projectId");
    if (proj == true) {
      projectid = array[i].split("=")[1];
    }
  }
  for (var i = 0; i < reportTypeArr.length; i++) {
    if (reportTypeArr[i].startsWith('reportType')) {
      reportType = reportTypeArr[i].split('=')[1]
    }
    if (projectid == null || projectid == undefined || projectid == "")
      if (reportTypeArr[i].startsWith('projectId')) {
        projectid = reportTypeArr[i].split('=')[1]
      }
  }
  var dateRange = $("#reportSelectDate>span").html()
  var fromdate = dateRange.substr(0, dateRange.indexOf("-"))
  var todate = dateRange.substr(dateRange.indexOf("-") + 1)
  var mentionOwners = $(".mentionOwner:checkbox:checked")
  var labels = $(".js-toggle-reportlabel-filter.is-active")
  var sources = $(".sourceType:checkbox:checked")
  var mentions = $(".mentionType:checkbox:checked")
  var sentiments = $(".sentiment:checkbox:checked")
  var locations = $(".location:checkbox:checked")
  var languages = $(".language:checkbox:checked")
  var attributeTopic = $(".attributeTopic:checkbox:checked")
  var attributeSubtopic = $(".attributeSubtopic:checkbox:checked")
  var attributesentiment = $(".attributesentiment:checkbox:checked")
  var locationCity = $('.locationCity:checkbox:checked')
  var locationState = $('.locationState:checkbox:checked')
  var locationRegion = $('.locationRegion:checkbox:checked')
  var searchtext = $(".filtersearch").val()
  var sortby = $(".sort:checked")
  var websites = $(".website:checkbox:checked")
  url = url + '&projectId=' + projectid

  if (reportType == "PDF") {
    var pdfPage = $(".pdfPage:checkbox:checked")
    var pdfTitle = $(".pdfTitle").val()
    if (pdfTitle != "undefined" && pdfTitle != undefined && pdfTitle != null)
      pdfTitle = pdfTitle.split('"').join('')
    var color1 = $(".color1").val()
    var color2 = $(".color2").val()
    var color3 = $(".color3").val()
    var logoChosen = $('#pdfcompanyLogoUpload')[0].files.length
    var coverChosen = $('.pdfCover:checked').val()
    var iconColor = $("input[type='radio'].iconColor:checked").val()
    var showPageNo = $("#showPageNo:checkbox:checked").val()
    var showInsights = $("#showInsight:checkbox:checked").val()
    var showRmoLogo = $("#rmoLogo:checkbox:checked").val()
  }



  // var check = validate()
  // if (check === true) {


  if (fromdate !== null && fromdate !== "") {
    url = url + '&startDate=' + fromdate
  }

  if (todate !== null && todate !== "") {
    url = url + '&endDate=' + todate
  }
  if (mentionOwners !== null && mentionOwners !== undefined) {
    var mentionOwnersName
    for (var i = 0; i != mentionOwners.length; i++) {
      mentionOwnersName = mentionOwners[i].name
      if (mentionOwnersName !== null && mentionOwnersName !== undefined) {
        url = url + '&mentionOwner=' + mentionOwnersName
      }
    }
  }
  if (labels !== null && labels !== undefined) {
    var labelColor
    for (var i = 0; i < labels.length; i++) {
      labelColor = labels[i].getAttribute("name")
      if (labelColor !== null && labelColor !== undefined) {
        url = url + '&mentionLabel=' + labelColor
      }
    }
  }
  if (sources !== null && sources !== undefined) {
    var sourceName
    for (var source = 0; source != sources.length; source++) {
      sourceName = sources[source].name
      if (sourceName !== null && sourceName !== undefined) {
        url = url + '&sourceType=' + sourceName
      }
    }
  }
  if (mentions !== null && mentions !== undefined) {
    var mentionName
    for (var mention = 0; mention != mentions.length; mention++) {
      mentionName = mentions[mention].name
      if (mentionName !== null && mentionName !== undefined) {
        url = url + '&mentionType=' + mentionName
      }
    }
  }
  if (sentiments !== null && sentiments !== undefined) {
    var sentimentName
    for (var sentiment = 0; sentiment != sentiments.length; sentiment++) {
      sentimentName = sentiments[sentiment].name
      if (sentimentName !== null && sentimentName !== undefined) {
        url = url + '&sentiment=' + sentimentName
      }
    }
  }
  if (locations !== null && locations !== undefined) {
    var locationName
    for (var location = 0; location != locations.length; location++) {
      locationName = locations[location].name
      if (locationName !== null && locationName !== undefined) {
        url = url + '&location=' + locationName
      }
    }
  }
  if (languages !== null && languages !== undefined) {
    var languageName
    for (var i = 0; i < languages.length; i++) {
      languageName = languages[i].name
      if (languageName !== null && languageName !== undefined && languageName !== "") {
        url = url + '&language=' + languageName
      }
    }
  }
  if (typeof (attributeTopic) != 'undefined' && attributeTopic !== null) {
    var topicName
    for (var i = 0; i < attributeTopic.length; i++) {
      topicName = attributeTopic[i].name
      if (topicName !== null && topicName !== undefined && topicName !== "") {
        url = url + '&attributeTopic=' + topicName
      }
    }
  }

  if (typeof (attributeSubtopic) != 'undefined' && attributeSubtopic !== null) {
    var subtopicName
    for (var i = 0; i < attributeSubtopic.length; i++) {
      subtopicName = attributeSubtopic[i].name
      if (subtopicName !== null && subtopicName !== undefined && subtopicName !== "") {
        url = url + '&attributeSubtopic=' + subtopicName
      }
    }
  }

  if (typeof (attributesentiment) != 'undefined' && attributesentiment !== null) {
    var attributesentimentName
    for (var i = 0; i < attributesentiment.length; i++) {
      attributesentimentName = attributesentiment[i].name
      if (attributesentimentName !== null && attributesentimentName !== undefined && attributesentimentName !== "") {
        url = url + '&attributesentiment=' + attributesentimentName
      }
    }
  }

  if (typeof (locationCity) != 'undefined' && locationCity !== null) {
    var subtopicName
    for (var i = 0; i < locationCity.length; i++) {
      subtopicName = locationCity[i].name
      if (subtopicName !== null && subtopicName !== undefined && subtopicName !== "") {
        url = url + '&locationCity=' + subtopicName
      }
    }
  }
  if (typeof (locationState) != 'undefined' && locationState !== null) {
    var subtopicName
    for (var i = 0; i < locationState.length; i++) {
      subtopicName = locationState[i].name
      if (subtopicName !== null && subtopicName !== undefined && subtopicName !== "") {
        url = url + '&locationState=' + subtopicName
      }
    }
  }
  if (typeof (locationRegion) != 'undefined' && locationRegion !== null) {
    var subtopicName
    for (var i = 0; i < locationRegion.length; i++) {
      subtopicName = locationRegion[i].name
      if (subtopicName !== null && subtopicName !== undefined && subtopicName !== "") {
        url = url + '&locationRegion=' + subtopicName
      }
    }
  }

  if (searchtext !== null && searchtext !== undefined && searchtext !== "") {
    searchtext = searchtext.toLowerCase()
    searchtext = searchtext.replace(/#/g, "HT:")
    url = url + '&searchText=' + searchtext + '&influencerSearchText=' + searchtext
  }

  if (sortby !== null && sortby !== undefined) {
    var sortName
    for (var sort = 0; sort != sortby.length; sort++) {
      sortName = sortby[sort].value
      if (sortName !== null && sortName !== undefined && sortName !== "") {
        url = url + '&sortBy=' + sortName
      }
    }
  }
  if (websites !== null && websites !== undefined) {
    var websiteName
    for (var i = 0; i < websites.length; i++) {
      websiteName = websites[i].getAttribute("name")
      if (websiteName !== null && websiteName !== undefined && websiteName !== "") {
        url = url + '&website=' + websiteName
      }
    }
  }
  if (reportType == "PDF") {

    if (pdfPage !== null && pdfPage !== undefined) {
      var pdfPageadd = ""
      if (pdfPage.length == 0) {
        url = url + "&pdfPage=" + pdfPageadd
      }
      else {
        for (var i = 0; i < pdfPage.length; i++) {
          pdfPageadd = pdfPage[i].getAttribute("name")
          if (pdfPageadd !== null && pdfPageadd !== undefined && pdfPageadd !== "") {
            url = url + "&pdfPage=" + pdfPageadd
          }
        }
      }

    }
    if (pdfTitle !== null && pdfTitle !== undefined && pdfTitle !== "") {
      url = url + '&pdfTitle=' + encodeURIComponent(pdfTitle)
    }

    if (coverChosen === "customCover") {
      url = url + '&customCover=' + true
    }
    if (logoChosen > 0) {
      url = url + '&customLogo=' + true
    }
    if (color1 !== null && color1 !== undefined && color1 !== "") {
      url = url + '&color1=' + color1.slice(1)
    }
    if (color2 !== null && color2 !== undefined && color2 !== "") {
      url = url + '&color2=' + color2.slice(1)
    }
    if (color3 !== null && color3 !== undefined && color3 !== "") {
      url = url + '&color3=' + color3.slice(1)
    }
    if (iconColor !== null && iconColor !== undefined && iconColor !== "") {
      url = url + '&iconColor=' + iconColor
    }
    if (showPageNo !== null && showPageNo !== undefined && showPageNo == "on") {
      url = url + '&showPageNo=' + showPageNo
    }
    if (showInsights !== null && showInsights !== undefined && showInsights == "on") {
      url = url + '&showInsights=' + showInsights
    }
    if (showRmoLogo !== null && showRmoLogo !== undefined && showRmoLogo == "on") {
      url = url + '&showRmoLogo=' + showRmoLogo
    }
  }
  return url
}

function callReportBackend(url, loaderDivId, errorClass, buttonId) {
  // var callReportBackendCheck = false
  // var url = generateData(url)
  $(errorClass).html("")
  $(loaderDivId).html("<img style='height: 25px;width: 25px;z-index:1051;position: absolute;top: 20%;left: 45%;' src = '/images/loader-icon.gif'/>");
  $("#filterReport").ajaxSubmit({
    method: "GET",
    url: url,
    success: function (responseText) {
      $(loaderDivId).css("display", "none");
      var response = responseText["message"];
      var status = responseText["status"];
      if (status == "SUCCESS") {
        if (errorClass != ".infoerr")
          reportSentNotify(response, "fa fa-check")
        // $(errorClass).html(response)
        // $(errorClass).css("color", "green")
        $(buttonId).attr('disabled', 'disabled')
      } else {
        if (errorClass != ".infoerr")
          reportSentNotify(response, "fa fa-times")
        // $(errorClass).html(response)
        // $(errorClass).css("color", "red")
      }
      return true;
    },
    error: function () {
      $(loaderDivId).css("display", "none");
      reportSentNotify("There is some error. Please contact support@rankmeonline.com", "fa fa-times")
      // $(errorClass).html("There is some error. Please contact support@rankmeonline.com")
      // $(errorClass).css("color", "red")
      $(buttonId).removeAttr("disabled")
      return false;
    }
  })
  // callReportBackendCheck = true
}
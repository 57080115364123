function updateAssignOnSubmit(selectId, statusText, userid) {
  var status = $(statusText).val()
  var assignedName = $(selectId).val()
  var data = {
    status: status,
    assignedName: assignedName,
    userId: userid
  }
  showLoaderOnClick()
  $("#updateAssignStatus").ajaxSubmit({
    method: "POST",
    url: "/updateLeadStatus",
    withCredentials: true,
    xhrFields: {
      withCredentials: true
    },
    data: data,
    success: function (res) {
      UpdateOnLeadsNotify("fa fa-check", "Assign to " + assignedName + " & comment change to " + status + " successfully")
    },
    error: function (res) {
      UpdateOnLeadsNotify("fa fa-times", "Assign to " + assignedName + " & comment change to " + status + " failed")
    }
  });
}

function updateLeadStatusOnSubmit(leadStatus, contactNext, userid) {
  var contactNext = $(contactNext).val()
  var leadStatus = $(leadStatus).val()
  var data = {
    leadStatus: leadStatus,
    contactNext: contactNext,
    userId: userid
  }
  showLoaderOnClick()
  $("#updateLeadStatus").ajaxSubmit({
    method: "POST",
    withCredentials: true,
    xhrFields: {
      withCredentials: true
    },
    url: "/updateLeadStatus",
    data: data,
    success: function (res) {
      UpdateOnLeadsNotify("fa fa-check", "Status change to " + leadStatus + " is successfully")
      UpdateOnLeadsNotify("fa fa-check", "Contect them next on " + contactNext + " is save")
    },
    error: function (res) {
      UpdateOnLeadsNotify("fa fa-times", "Status change to " + leadStatus + " is Failed")
      UpdateOnLeadsNotify("fa fa-times", "Contect them next on " + contactNext + " is fail")
    }
  });
}


function UpdateOnLeadsNotify(icon, message) {
  $.notify({
    title: '',
    icon: icon,
    message: message,
  }, {
    type: 'info',
    animate: {
      enter: 'animated fadeInUp',
      exit: 'animated fadeOutRight'
    },
    placement: {
      from: "top",
      align: "right"
    },
    offset: {
      x: -800,
      y: 40
    },
    spacing: 10,
    z_index: 1051,

  });
}
function forceBackFill(project) {
  $.ajax({
    method: 'POST',
    url: '/admin/instantRunStrategyForNewProject',
    data: {
      'sourceType': 'all',
      'userProject': project
    },
    withCredentials: true,
    xhrFields: {
      withCredentials: true
    },
    success: function (ans) {
      alert(JSON.stringify(ans))
    },
    error: function (err) {
      hideLoaderOnClick()
      alert(JSON.stringify(err))
    }

  })
}

function crawlHistoricalData() {
  var selectedOption = $("input[name='historicalDataOption']:checked").val();

  var hashtag = $("#hashtag").val()
  var curlCall = $("#curlCall").val()
  var queryHash = $("#queryHash").val()
  var userId = $("#userId").val()

  var url = ""
  var data = {
    userId: userId,
    curlCall: curlCall,
    queryHash: queryHash,
  }
  if (selectedOption == "hashtagPosts") {
    url = "/historicalDataUpdate/Insta/HashtagPosts"
    data = {
      hashtag: hashtag,
      curlCall: curlCall,
      queryHash: queryHash,
    }
  }
  else if (selectedOption == "userTaggedPost") {
    url = "/historicalDataUpdate/Insta/UserTaggedPosts"
  } else {
    url = "/historicalDataUpdate/Insta/UserPosts"
  }
  showLoaderOnClick()
  console.log(url)
  $.ajax({
    method: "POST",
    url: url,
    data: data,
    success: function (ans) {
      hideLoaderOnClick()
      alert("Success")
    },
    error: function (err) {
      alert("Error: " + JSON.stringify(err))
    }
  })

}

$(document).ready(function () {
  $("#hashtagPosts").click(function () {
    $("#hashtagDiv").removeClass("infl_displayNone")
    $("#userIdDiv").addClass("infl_displayNone")
    $(".hashtagInstruction").removeClass("infl_displayNone")
    $(".userTaggedInstruction, .userPostsInstruction").addClass("infl_displayNone")
    $("#userId").val("")
  })
  $("#userPost").click(function () {
    $("#hashtagDiv").addClass("infl_displayNone")
    $("#userIdDiv").removeClass("infl_displayNone")
    $(".userPostsInstruction").removeClass("infl_displayNone")
    $(".userTaggedInstruction, .hashtagInstruction").addClass("infl_displayNone")
    $("#hashtag").val("")
  })
  $("#userTaggedPost").click(function () {
    $("#hashtagDiv").addClass("infl_displayNone")
    $("#userIdDiv").removeClass("infl_displayNone")
    $(".userTaggedInstruction,.userPostsInstruction").removeClass("infl_displayNone")
    $(".hashtagInstruction").addClass("infl_displayNone")
    $("#hashtag").val("")
  })
})



function crawlUserFunctions() {
  showLoaderOnClick()
  // $("#crawlExcelUser").prop("disabled", true);
  // $("#generateInfluencerDetail").prop("disabled", true);
  var excelData = $("#jsonData").text()
  var url = "/crawUserByExcel";
  var data = {
    excelData: excelData,
    dateRange: $("#useYTDate").is(":checked") ? $('#reportSelectDate span').text() : null
  }
  $.ajax({
    method: "POST",
    url: url,
    data: data,
    async: false,
    success: function (ans) {
      if (ans.status) {
        alert(ans.status)
      }
      else {
        alert("Success")
      }
      hideLoaderOnClick()
      $("#generateInfluencerDetail").prop("disabled", false);

    },
    error: function (err) {
      alert("Error: " + JSON.stringify(err))
      hideLoaderOnClick()
      $("#crawlExcelUser").prop("disabled", false);
    }
  });
}

function crawlMentionFunctions() {
  showLoaderOnClick()
  // $("#crawlExcelUser").prop("disabled", true);
  // $("#generateInfluencerDetail").prop("disabled", true);
  var excelData = $("#jsonData").text()
  var url = "/crawlMentionByExcel";
  var data = {
    excelData: excelData
  }
  $.ajax({
    method: "POST",
    url: url,
    data: data,
    async: false,
    success: function (ans) {
      if (ans.status) {
        alert(ans.status)
      }
      else {
        alert("Success")
      }
      hideLoaderOnClick()
      $("#generateInfluencerDetail").prop("disabled", false);

    },
    error: function (err) {
      alert("Error: " + JSON.stringify(err))
      hideLoaderOnClick()
      $("#crawlExcelUser").prop("disabled", false);
    }
  });
}


function downloadExcelInfluencerCSV() {

  var excelData = $("#jsonData").text()
  var url = "/influencerCSVReport?"
  var data = {
    excelData: excelData
  }
  showLoaderOnClick()
  $.ajax({
    method: "POST",
    url: url,
    data: data,
    success: function (ans) {
      alert("Your CSV report is queued and will be mailed to " + ans["email"] + " in a few minutes!")
      hideLoaderOnClick()
    },
    error: function () {
      alert("There is some error!. Please contact support or drop an email to info@rankmeonline.com")
      hideLoaderOnClick()
    }
  });
}

function downloadExcelInfluencerMentionCSV() {

  var excelData = $("#jsonData").text()
  var url = "/influencerMentionCSVReport?"
  var data = {
    excelData: excelData,
    dateRange: $("#useYTDate").is(":checked") ? $('#reportSelectDate span').text() : null
  }
  showLoaderOnClick()
  $.ajax({
    method: "POST",
    url: url,
    data: data,
    success: function (ans) {
      hideLoaderOnClick()
      if (ans.status && ans.status == "success")
        alert("Your CSV report is queued and will be mailed to " + ans["email"] + " in a few minutes!")
      else
        alert("Error in downloading sheet" + ans.error)
    },
    error: function () {
      alert("There is some error!. Please contact support or drop an email to info@rankmeonline.com")
      hideLoaderOnClick()
    }
  });
}
function downloadExcelMentionCSV() {

  var excelData = $("#jsonData").text()
  var url = "/downloadExcelMentionCSV?"
  var data = {
    excelData: excelData
  }
  showLoaderOnClick()
  $.ajax({
    method: "POST",
    url: url,
    data: data,
    success: function (ans) {
      hideLoaderOnClick()
      if (ans.status && ans.status == "success")
        alert("Your CSV report is queued and will be mailed to " + ans["email"] + " in a few minutes!")
      else
        alert("Error in downloading sheet " + ans.error)
    },
    error: function () {
      alert("There is some error!. Please contact support or drop an email to info@rankmeonline.com")
      hideLoaderOnClick()
    }
  });
}
function addBrandToMongo(type) {
  var url = "/saveExcelFileForBrandList?"

  if (type == "excel") {
    var excelData = $("#jsonData").text()
    var data = {
      excelData: excelData
    }
    showLoaderOnClick()
    $.ajax({
      method: "POST",
      url: url,
      data: data,
      success: function (ans) {
        alert("Success")
        hideLoaderOnClick()
      },
      error: function () {
        alert("Failure")
        hideLoaderOnClick()
      }
    });
  } else if (type == "form") {
    var brand_name = $("#brand_name").val()
    var category = $("#category").val()
    var parent_brand = $("#parent_brand").val()
    var twitterUrl = $("#twitterUrl").val()
    var facebookUrl = $("#facebookUrl").val()
    var instagramUrl = $("#instagramUrl").val()
    var data = {
      excelData: '[{ "brand_name": "' + brand_name + '", "category": "' + category + '", "facebook_URL": "' + facebookUrl + '", "instagram_URL": "' + instagramUrl + '", "twitter_URL": "' + twitterUrl + '", "parent_brand": "' + parent_brand + '"}]'
    }
    showLoaderOnClick()
    $.ajax({
      method: "POST",
      url: url,
      data: data,
      success: function (ans) {
        alert("Success")
        hideLoaderOnClick()
      },
      error: function () {
        alert("Failure")
        hideLoaderOnClick()
      }
    });
  }
}

function changeRedisKeyView() {
  $('.redisKeyOptions').addClass('infl_displayNone')
  var id = $('.redisKeySelect').children(":selected").val()
  if (id == "linkedin Cookie") {
    $("#linkedinCookieDiv").removeClass('infl_displayNone')
  }
  else if (id == "instagram Cookie") {
    $("#instagramCookieDiv").removeClass('infl_displayNone')
  }
};


function editLinkedinCookie() {
  var linkedin01Cookie = $("#li_atCookie01").val()
  var linekdin02Cookie = $("#li_atCookie02").val()
  showLoaderOnClick()
  $.ajax({
    url: "/createHeaderLinkedinRedis",
    method: "GET",
    data: {
      li_atCookie01: linkedin01Cookie,
      li_atCookie02: linekdin02Cookie
    },
    success: function (res) {
      hideLoaderOnClick()
      alert(JSON.stringify(res))
    },
    error: function (err) {
      hideLoaderOnClick()
      alert("Updation failed: " + JSON.stringify(err))
    }
  })
}

function editInstagramSessionId() {
  var sessionIdInsta = $("#sessionIdInsta").val()
  showLoaderOnClick()
  $.ajax({
    url: "/instaSessionRedis",
    method: "GET",
    data: {
      sessionIdInsta: sessionIdInsta,
    },
    success: function (res) {
      hideLoaderOnClick()
      alert(JSON.stringify(res))
    },
    error: function (err) {
      hideLoaderOnClick()
      alert("Updation failed: " + JSON.stringify(err))
    }
  })
}

function deleteMention(mentionId) {
  showLoaderOnClick();
  var postId = mentionId.split("_")[1];
  var sourceType = mentionId.split("_")[0];
  $.ajax({
    url: "/delete-mention",
    method: "POST",
    data: {
      mentionId: postId,
      sourceType: sourceType
    },
    success: function (res) {
      hideLoaderOnClick()
      alert(JSON.stringify(res))
      window.location.href = "/admin/editMention"
    },
    error: function (err) {
      hideLoaderOnClick()
      alert("Updation failed: " + JSON.stringify(err))
    }
  })
}
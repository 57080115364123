function generateCustomPaymentBtn(email, couponCode, amount) {
  showLoaderOnClick()
  $.ajax({
    method: "POST",
    url: "/generatePaymentForCouponHoldUser",
    data: { "email": email, "couponCode": couponCode, "amount": amount },
    success: function (res) {
      console.log(JSON.stringify(res))
      hideLoaderOnClick()
      repliedSuccess('fa fa-check', "Thanks for taking intrest, we will get back to you soon.")
      $("#generateCustomPaymentBtn").addClass("btndisabled");
    },
    error: function (res) {
      console.log(JSON.stringify(res))
      hideLoaderOnClick()
      repliedSuccess('fa fa-times', "Thanks for taking intrest, There is some error, please contact your project manager")
    }
  })
}
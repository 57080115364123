$(document).ready(function () {
    // ==================label=======================
    hoverOnLabelSelect()
    var paramArray = splitIntoParams(document.location.href);
    $(".filter-div .js-toggle-label-filter").on("click", function () {
        showLoaderOnClick()
        $(this).toggleClass("is-active")
        var param = $(this).attr('class').split(" ")[0];
        var value = $(this).attr('data-name');
        var sortByIndex = paramArray.findIndex(
            function (obj) {
                if (obj.param == "startFrom") {
                    return obj;
                }
            }
        );
        if (sortByIndex > -1) {
            paramArray[sortByIndex].value = 0;
        }
        var prechecks = []
        for (var i = 0; i < paramArray.length; i++) {
            if (paramArray[i].param == 'mentionLabel') {
                prechecks.push(paramArray[i].value)
            }
        }
        paramArray = paramArray.filter(function (val) {
            if (val.param == "mentionLabel") return false
            return true
        })
        if (prechecks.length > -1) {
            for (var i = 0; i < prechecks.length; i++) {
                paramArray.push({
                    param: 'mentionLabel',
                    value: prechecks[i]
                });
            }
        }
        var check = $(this).hasClass("is-active")
        if (check) {
            paramArray.push({
                param: param,
                value: value
            });
        } else {
            var index = paramArray.findIndex(
                function (obj) {
                    if (obj.param == param && obj.value == value) return obj;
                }
            );
            if (index >= 0) {
                paramArray.splice(index, 1);
            }
        }
        var destUrl = mergeIntoUrl(paramArray, true);
        hideLoaderOnClick()
        window.location = destUrl;
    });

    // // ===================relevance Slider =====================
    // var relevanceScore = paramArray.filter(function (val) {
    //     if (val.param == "relevanceScore") return val.value
    //     return false
    // })
    // var relMin = 0
    // var relMax = 10
    // var relMinValue = relMin
    // var relMaxValue = relMax
    // var max_score = $('#max_score').val()
    // if (relevanceScore.length > 0) {
    //     relMinValue = (relevanceScore[0].value.split("-")[0] / max_score) * 10
    //     if (relMinValue !== null && relMinValue !== undefined && relMinValue !== "")
    //         $("#relevance_min_score").val(relMinValue)
    //     relMaxValue = (relevanceScore[0].value.split("-")[1] / max_score) * 10
    //     if (relMaxValue !== null && relMaxValue !== undefined && relMaxValue !== "")
    //         $("#relevance_max_score").val(relMaxValue)
    // }
    // relMinValue = parseInt(relMinValue)
    // relMaxValue = parseInt(relMaxValue)

    // $(function () {
    //     $("#relevance-slider-range").slider({
    //         range: true,
    //         min: relMin,
    //         max: relMax,
    //         orientation: "horizontal",
    //         values: [relMinValue, relMaxValue],
    //         step: 1,
    //         slide: function (event, ui) {
    //             if (ui.values[0] == ui.values[1]) {
    //                 return false;
    //             }
    //             $("#relevance_min_score").val(ui.values[0])
    //             $("#relevance_max_score").val(ui.values[1]);
    //         }
    //     });
    //     $("#relevance_min_score").val($("#relevance-slider-range").slider("values", 0));
    //     $("#relevance_max_score").val($("#relevance-slider-range").slider("values", 1));
    // });

})

// function relevanceRangeSubmitBtn(min, max, filterName, max_score) {
//     max_score = $(max_score).val()
//     var url = document.location.href

//     var minValue = 0
//     if (parseInt($(min).val()) > 0) {
//         minValue = (parseInt($(min).val()) * max_score) / 10
//     }
//     var maxValue = 0
//     if (parseInt($(max).val()) > 0) {
//         maxValue = (parseInt($(max).val()) * max_score) / 10
//     }
//     if (minValue >= maxValue) {
//         alert("Check filter value")
//         return
//     } else {
//         showLoaderOnClick()
//         var paramArray = splitIntoParams(url)

//         var index = paramArray.findIndex(function (obj) {
//             if (obj.param == filterName) return obj;
//         });
//         if (index >= 0) {
//             paramArray.splice(index, 1);
//         }
//         paramArray.push({
//             param: filterName,
//             value: minValue + '-' + maxValue
//         })

//         url = mergeIntoUrl(paramArray, true);
//         document.location.href = url
//     }
// }

function filterModalSubmitButton(filterId, formClass, searchParam, paramArray) {
    $(filterId).on("click", function () {
        showLoaderOnClick()
        var newParamArray = paramArray.filter(function (obj) {
            if (obj.param == searchParam) return false;
            return true;
        });
        $(formClass + " input:checked").each(function () {
            newParamArray.push({
                param: searchParam, value: $(this).attr("name")
            });
        });
        document.location = mergeIntoUrl(newParamArray, true);
    });
}

$(document).on('click', '.filtermodalClick', function () {
    showLoaderOnClick()
    var requestParams = splitIntoParams(document.location.href);
    var filterType = $(this).attr('id')
    filterType = filterType.split('_')[1]
    var filterData = {
        userProject: activeProject,
        startDateInEpoch: startDateEpoch,
        endDateInEpoch: endDateEpoch,
        userId: user._id,
        requestParams: {},
        filterType: filterType,
        filterProcess: filterProcess
    }
    $.ajax({
        method: 'POST',
        data: filterData,
        url: "/mentionFilters",
        success: function (ans) {
            hideLoaderOnClick()
            if (filterType == 'byCountry') {
                showCountryModal(ans)
                $('#showAllCountriesModalClick').click()
            }
            else if (filterType == 'byState') {
                showStateModal(ans)
                $('#showAllStatesModalClick').click()
            }
            else if (filterType == 'byCity') {
                showCityModal(ans)
                $('#showAllCityModalClick').click()
            }
            else if (filterType == 'byLanguage') {
                showLanguageModal(ans)
                $('#showAllLanguageModalClick').click()
            }
            else if (filterType == 'byWebsite') {
                showWebsiteModal(ans)
                $('#showAllWebsiteModalClick').click()
            }
        }
    })
})
function showCountryModal(ans) {
    var html = ""
    $('#showAllCountriesModalBody').html("")
    if (ans.error) {
        html += "No countries found"
    }
    else {
        var paramArray = splitIntoParams(document.location.href);
        var locationCountryInUrl = paramArray.map(function (obj) {
            if (obj.param == "location")
                return obj.value
        })
        locationCountryInUrl = locationCountryInUrl.filter(Boolean)
        var countriesData = ans.countriesData
        if (countriesData.length > 0) {
            var locationNoOfRows = Math.ceil((countriesData.length) / 10)
            for (var i = 0; i < locationNoOfRows; i++) {
                html += "<ul class='list-unstyled'>"
                for (var index = 0; index < countriesData.length; index++) {
                    var locationNum = (i) * 10 + index
                    var num = locationNum + 1101
                    var locationCheckboxid = 'locationcheckbox' + num
                    if (locationNum + 1 > countriesData.length)
                        break
                    if (index > 9)
                        break
                    var currentCountries = countriesData[locationNum]
                    html += "<li>"
                    html += "<div class='form-check js_location flex-align-center' style='margin-bottom:5px'>"
                    if (locationCountryInUrl.indexOf(currentCountries.country) > -1) {
                        html += "<input class='location form-check-input' type='checkbox' name='" + currentCountries.country + "' id='" + locationCheckboxid + "' checked=true>"
                    } else {
                        html += "<input class='location form-check-input' type='checkbox' name='" + currentCountries.country + "' id='" + locationCheckboxid + "'>"
                    }
                    html += "<label class='form-check-label' for='" + locationCheckboxid + "' style='margin-bottom: 0px;'>" + currentCountries.country + " (" + currentCountries.count + ")"
                    html += "</div></li>"
                }
                html += "</ul>"
            }
        }
        else {
            html += "No countries Found"
        }
    }
    $('#showAllCountriesModalBody').append(html)
}
function showStateModal(ans) {
    var html = ""
    $('#showAllStatesModalBody').html("")
    if (ans.error) {
        html += "No states found"
    }
    else {
        var paramArray = splitIntoParams(document.location.href);
        var locationStateInUrl = paramArray.map(function (obj) {
            if (obj.param == "locationState")
                return obj.value
        })
        locationStateInUrl = locationStateInUrl.filter(Boolean)
        var statesData = ans.statesData
        if (statesData.length > 0) {
            var locationNoOfRows = Math.ceil((statesData.length) / 10)
            for (var i = 0; i < locationNoOfRows; i++) {
                html += "<ul class='list-unstyled'>"
                for (var index = 0; index < statesData.length; index++) {
                    var locationNum = (i) * 10 + index
                    var num = locationNum + 1201
                    var locationCheckboxid = 'locationcheckbox' + num
                    if (locationNum + 1 > statesData.length)
                        break
                    if (index > 9)
                        break
                    var currentState = statesData[locationNum]
                    html += "<li>"
                    html += "<div class='form-check js_locationState flex-align-center' style='margin-bottom:5px'>"
                    if (locationStateInUrl.indexOf(currentState.state) > -1) {
                        html += "<input class='locationState form-check-input' type='checkbox' checked=true name='" + currentState.state + "' id='" + locationCheckboxid + "'>"
                    } else {
                        html += "<input class='locationState form-check-input' type='checkbox' name='" + currentState.state + "' id='" + locationCheckboxid + "'>"
                    }
                    html += "<label class='form-check-label' for='" + locationCheckboxid + "' style='margin-bottom: 0px;'>" + currentState.state + " (" + currentState.count + ")"
                    html += "</div></li>"
                }
                html += "</ul>"
            }
        }
        else {
            html += "No States Found"
        }
    }
    $('#showAllStatesModalBody').append(html)
}
function showCityModal(ans) {
    var html = ""
    $('#showAllCityModalBody').html("")
    if (ans.error) {
        html += "No cities found"
    }
    else {
        var paramArray = splitIntoParams(document.location.href);
        var locationCityInUrl = paramArray.map(function (obj) {
            if (obj.param == "locationCity")
                return obj.value
        })
        locationCityInUrl = locationCityInUrl.filter(Boolean)

        var cityData = ans.cityData
        if (cityData.length > 0) {
            var locationNoOfRows = Math.ceil((cityData.length) / 10)
            for (var i = 0; i < locationNoOfRows; i++) {
                html += "<ul class='list-unstyled'>"
                for (var index = 0; index < cityData.length; index++) {
                    var locationNum = (i) * 10 + index
                    var num = locationNum + 1301
                    var locationCheckboxid = 'locationcheckbox' + num
                    if (locationNum + 1 > cityData.length)
                        break
                    if (index > 9)
                        break
                    var currentCity = cityData[locationNum]
                    html += "<li>"
                    html += "<div class='form-check js_locationCity flex-align-center' style='margin-bottom:5px'>"
                    if (locationCityInUrl.indexOf(currentCity.city) > -1)
                        html += "<input class='locationCity form-check-input' type='checkbox' name='" + currentCity.city + "' id='" + locationCheckboxid + "' checked=true>"
                    else
                        html += "<input class='locationCity form-check-input' type='checkbox' name='" + currentCity.city + "' id='" + locationCheckboxid + "'>"
                    html += "<label class='form-check-label' for='" + locationCheckboxid + "' style='margin-bottom: 0px;'>" + currentCity.city + " (" + currentCity.count + ")"
                    html += "</div></li>"
                }
                html += "</ul>"
            }
        }
        else {
            html += "No Cities Found"
        }
    }
    $('#showAllCityModalBody').append(html)
}
function showLanguageModal(ans) {
    var html = ""
    $('#showAllLanguageModalBody').html("")
    if (ans.error) {
        html += "No languages found"
    }
    else {
        var paramArray = splitIntoParams(document.location.href);
        var languageInUrl = paramArray.map(function (obj) {
            if (obj.param == "language")
                return obj.value
        })
        languageInUrl = languageInUrl.filter(Boolean)

        var languageData = ans.languageData
        if (languageData.length > 0) {
            var locationNoOfRows = Math.ceil((languageData.length) / 10)
            for (var i = 0; i < locationNoOfRows; i++) {
                html += "<ul class='list-unstyled'>"
                for (var index = 0; index < languageData.length; index++) {
                    var locationNum = (i) * 10 + index
                    var num = locationNum + 1401
                    var locationCheckboxid = 'locationcheckbox' + num
                    if (locationNum + 1 > languageData.length)
                        break
                    if (index > 9)
                        break
                    var currentLanguage = languageData[locationNum]
                    html += "<li>"
                    html += "<div class='form-check js_language flex-align-center' style='margin-bottom:5px'>"
                    if (languageInUrl.indexOf(currentLanguage.language) > -1)
                        html += "<input class='language form-check-input' type='checkbox' name='" + currentLanguage.language + "' id='" + locationCheckboxid + "' checked=true>"
                    else
                        html += "<input class='language form-check-input' type='checkbox' name='" + currentLanguage.language + "' id='" + locationCheckboxid + "'>"
                    html += "<label class='form-check-label' for='" + locationCheckboxid + "' style='margin-bottom: 0px;'>" + currentLanguage.language + " (" + currentLanguage.count + ")"
                    html += "</div></li>"
                }
                html += "</ul>"
            }
        }
        else {
            html += "No Languages Found"
        }
    }
    $('#showAllLanguageModalBody').append(html)
}
function showWebsiteModal(ans) {
    var html = ""
    $('#showAllWebsiteModalBody').html("")
    if (ans.error) {
        html += "No websites found"
    }
    else {
        var paramArray = splitIntoParams(document.location.href);
        var websiteInUrl = paramArray.map(function (obj) {
            if (obj.param == "website")
                return obj.value
        })
        websiteInUrl = websiteInUrl.filter(Boolean)

        var websiteData = ans.websiteData
        if (websiteData.length > 0) {
            var locationNoOfRows = Math.ceil((websiteData.length) / 10)
            for (var i = 0; i < locationNoOfRows; i++) {
                html += "<ul class='list-unstyled'>"
                for (var index = 0; index < websiteData.length; index++) {
                    var locationNum = (i) * 10 + index
                    var num = locationNum + 1601
                    var locationCheckboxid = 'locationcheckbox' + num
                    if (locationNum + 1 > websiteData.length)
                        break
                    if (index > 9)
                        break
                    var currentWebsite = websiteData[locationNum]
                    html += "<li>"
                    html += "<div class='form-check js_website flex-align-center' style='margin-bottom:5px'>"
                    if (websiteInUrl.indexOf(currentWebsite.website) > -1)
                        html += "<input class='website form-check-input' type='checkbox' name='" + currentWebsite.website + "' id='" + locationCheckboxid + "'checked=true>"
                    else
                        html += "<input class='website form-check-input' type='checkbox' name='" + currentWebsite.website + "' id='" + locationCheckboxid + "'>"
                    html += "<label class='form-check-label' for='" + locationCheckboxid + "' style='margin-bottom: 0px;'>" + currentWebsite.website + " (" + currentWebsite.count + ")"
                    html += "</div></li>"
                }
                html += "</ul>"
            }
        }
        else {
            html += "No Websites Found"
        }
    }
    $('#showAllWebsiteModalBody').append(html)
}
$(document).ready(function () {
    // mention filter javascript
    var paramArray = splitIntoParams(document.location.href);
    if (document.getElementById('subTopicsFilterModalSubmitButton')) {
        filterModalSubmitButton('#subTopicsFilterModalSubmitButton', '.js_subtopics', 'attributeSubtopic', paramArray)
    }
    if (document.getElementById('locationFilterModalSubmitButton')) {
        filterModalSubmitButton('#locationFilterModalSubmitButton', '.js_location', 'location', paramArray)
    }
    if (document.getElementById('websiteModalSubmitButton')) {
        filterModalSubmitButton('#websiteModalSubmitButton', '.js_website', 'website', paramArray)
    }
    if (document.getElementById('locationCityFilterModalSubmitButton')) {
        filterModalSubmitButton('#locationCityFilterModalSubmitButton', '.js_locationCity', 'locationCity', paramArray)
    }
    if (document.getElementById('locationStateFilterModalSubmitButton')) {
        filterModalSubmitButton('#locationStateFilterModalSubmitButton', '.js_locationState', 'locationState', paramArray)
    }
    if (document.getElementById('locationRegionFilterModalSubmitButton')) {
        filterModalSubmitButton('#locationRegionFilterModalSubmitButton', '.js_locationRegion', 'locationRegion', paramArray)
    }

    if (document.getElementById('languageFilterModalSubmitButton')) {
        filterModalSubmitButton('#languageFilterModalSubmitButton', '.js_language', 'language', paramArray)
    }
    if (document.getElementById('websiteFilterModalSubmitButton')) {
        filterModalSubmitButton('#websiteFilterModalSubmitButton', '.js_website', 'website', paramArray)
    }
    // if (document.getElementById('websiteModalSubmitButton')) {
    //     $("#websiteModalSubmitButton").on("click", function () {
    //         var newParamArray = paramArray.filter(function (obj) {
    //             if (obj.param == "website") return false;
    //             return true;
    //         });
    //         $(".js_website input:checked").each(function () {
    //             newParamArray.push({
    //                 param: "website", value: $(this).attr("name")
    //             });
    //         });
    //         document.location = mergeIntoUrl(newParamArray, true);
    //     });
    // }
    if (document.getElementById('modalSubmitButton')) {
        $("#modalSubmitButton").on("click", function () {
            var newParamArray = paramArray.filter(function (obj) {
                if (obj.param == "extraattributes") return false;
                return true;
            });
            $(".modal-dialog  input:checked").each(function () {
                newParamArray.push({
                    param: "extraattributes", value: $(this).attr("name")
                });
            });
            document.location = mergeIntoUrl(newParamArray, true);
        });
    }
})

function replyOnClick(clickbox, viewBox) {
    $(viewBox).fadeToggle('slow')
}


function showBackButton() {
    if (history.length > 1) {
        showLoaderOnClick()
        history.back();
    } else {
        window.close()
    }
}

function hideMeLabelBox(labelpostId) {
    $("" + labelpostId + "").removeClass("showlabelSection")
}

function cancelLabelSelected(labeldiv, label) {
    $("" + labeldiv + " " + label + ".active").removeClass("active")
}

function submitLabelSelected(labeldiv, label, postId) {
    var selectedColor = []
    var refreshDetail = labeldiv
    var check = $("" + labeldiv + " " + label + ".active")
    if (check.length > 0) {
        for (var i = 0; i < check.length; i++) {
            var colors = $(check[i]).attr("value");
            selectedColor.push(colors)
        }
    }
    selectedColor = $.unique(selectedColor);
    if (selectedColor) {
        var labelUrl = '/mention/putTag?projectId=' + projectId
        for (var i = 0; i < selectedColor.length; i++) {
            labelUrl = labelUrl + '&tagType=' + selectedColor[i]
        }
        labelUrl = labelUrl + '&postId=' + postId
        var data = selectedColor
        $.ajax({
            url: labelUrl,
            type: "GET",
            data: data,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            async: "false",
            success: function (ans) { },
            error: function (ans) { }

        });
        labelAllNotify()
        $("#labelColorFromOnClick" + postId).html("")
        if (selectedColor && selectedColor.length > 0) {
            for (var i = 0; i < selectedColor.length; i++) {
                $("#labelColorFromOnClick" + postId).append("<div class='mentionLabeltag card-label-" + selectedColor[i] + "' style='height:8px'></div>")
            }
        }
        $("" + labeldiv + "").removeClass("showlabelSection")
        var check = refreshDetail.indexOf("#detail-") > -1
        if (check) {
            document.location.reload()
        }
    }
}


function labelAllNotifyOnMobileView() {
    $("#lableAlertMessageOnMobileView").attr("style", "display:block")
}

function hoverOnLabelSelect() {
    $('.mentionColorCard').hover(
        function () {
            $(this).children().addClass('selected')
        },
        function () {
            $(this).children().removeClass('selected')
        }
    )
}

function clickOnLabelSelect(colorSelect) {
    $(colorSelect).toggleClass('active')
}
// function labelactive(activeid) {
//     $("" + activeid + "").toggleClass('active')
// }

function summaryShowShareBlock(labelid) {
    $("" + labelid + "").next().toggleClass("showShareSection");
    toggleAnimation()
    //4649.42
    // var bodyHeight = $("body").height()
    //684
    var windowHeight = $(window).height()
    //3965
    var displayDistance = $(window).scrollTop();
    //4447.421875
    var elementHeightFromTop = $("" + labelid + "").offset().top
    var difference = windowHeight - (elementHeightFromTop - displayDistance)
    if (difference < (windowHeight / 2)) {
        $("" + labelid + "").next().children().removeClass("displayInBottom")
        $("" + labelid + "").next().children().addClass("displayInTop")
    } else {
        $("" + labelid + "").next().children().removeClass("displayInTop")
        $("" + labelid + "").next().children().addClass("displayInBottom")
    }
}

function summaryShowLabelBlock(shareid) {
    $("" + shareid + "").next().toggleClass("showlabelSection");
    toggleAnimation()
    //4649.42
    // var bodyHeight = $("body").height()
    //684
    var windowHeight = $(window).height()
    //3965
    var displayDistance = $(window).scrollTop();
    //4447.421875
    var elementHeightFromTop = $("" + shareid + "").offset().top
    var difference = windowHeight - (elementHeightFromTop - displayDistance)
    if (difference < (windowHeight / 2)) {
        $("" + shareid + "").next().children().removeClass("displayInBottom")
        $("" + shareid + "").next().children().addClass("displayInTop")
    } else {
        $("" + shareid + "").next().children().removeClass("displayInTop")
        $("" + shareid + "").next().children().addClass("displayInBottom")
    }
}

function showDetailLabelBox(labelid, sentimentId) {
    $("" + labelid + "").toggleClass("showlabelSection")
    $("" + labelid + "").removeClass("detailLabelDropDownRight")
    $("" + labelid + "").removeClass("detailLabelDropDownLeft")
    var check = $("" + sentimentId + "").hasClass("showlabelSection")
    if (check) {
        $("" + sentimentId + "").removeClass("showlabelSection")
    }
    // $("" + labelid + "").next().toggleClass("showlabelSection");
    //4649.42
    // var bodyHeight = $("body").height()
    //684
    var windowWidth = $(window).width()
    //3965
    // var displayDistance = $(window).scrollTop();
    //4447.421875
    var elementHeightFromLeft = $("" + labelid + "").offset().left
    var difference = windowWidth - (elementHeightFromLeft)
    if (difference < (windowWidth / 4)) {
        $("" + labelid + "").removeClass("detailLabelDropDownRight")
        $("" + labelid + "").addClass("detailLabelDropDownLeft")
    } else {

        $("" + labelid + "").removeClass("detailLabelDropDownLeft")
        $("" + labelid + "").addClass("detailLabelDropDownRight")
    }
}

function showDetailSentimentBox(sentimentId, labelid) {
    $("" + sentimentId + "").removeClass("detailSentimentDropDownRight")
    $("" + sentimentId + "").removeClass("detailSentimentDropDownLeft")
    $("" + sentimentId + "").toggleClass("showlabelSection")
    var check = $("" + labelid + "").hasClass("showlabelSection")
    if (check) {
        $("" + labelid + "").removeClass("showlabelSection")
    }
    // $("" + labelid + "").next().toggleClass("showlabelSection");
    //4649.42
    // var bodyHeight = $("body").height()
    //684
    var windowWidth = $(window).width()
    //3965
    // var displayDistance = $(window).scrollTop();
    //4447.421875
    var elementHeightFromLeft = $("" + sentimentId + "").offset().left
    var difference = windowWidth - (elementHeightFromLeft)
    if (difference < (windowWidth / 4)) {
        $("" + sentimentId + "").removeClass("detailSentimentDropDownRight")
        $("" + sentimentId + "").addClass("detailSentimentDropDownLeft")
    } else {

        $("" + sentimentId + "").removeClass("detailSentimentDropDownLeft")
        $("" + sentimentId + "").addClass("detailSentimentDropDownRight")
    }
}
function toggleAnimation() {
    $(".mentionBox").attr('data-aos') ? $(".mentionBox").removeAttr('data-aos') : $(".mentionBox").attr('data-aos', "fade-up");
}
function showAllFilterInMobile(filter) {
    $(filter).toggle()
    $(".mention-sm-filterinside").toggleClass("opacityOnFilter")
}

function showAllSortInMobile(sort) {
    $(sort).toggle()
    $(".mention-sm-sortinside").toggleClass("opacityOnFilter")
    $(document).on("click", function (e) {
        var container = $(".mention-sm-sortinside"); // YOUR CONTAINER SELECTOR
        if (!container.is(e.target) // if the target of the click isn't the container...
            &&
            container.has(e.target).length === 0) { // ... nor a descendant of the container
            $("#sortInMobileView").hide()
            $("#sortInfluencerInMobileView").hide()
            $(".mention-sm-sortinside").removeClass("opacityOnFilter")
        } else {
            $("#sortInMobileView").show()
            $("#sortInfluencerInMobileView").show()
            $(".mention-sm-sortinside").addClass("opacityOnFilter")
        }

    });
}

function saveFilterData(projectId) {

    var redirectUrl = document.location.href
    var filterName = $(".mention-filter-name").val().trim()
    if (filterName.length <= 0) {
        alert("This field cannot be blank")
    } else if (filterName.length < 3) {
        alert("Saved name should contain atleast 3 letters or more(you are using " + filterName.length + " letter )")

    } else {
        showLoaderOnClick()
        var paramArray = splitIntoParams(redirectUrl)
        paramArray = paramArray.filter(function (obj) {
            if (obj.param == "saveFilter" || obj.param == "dateRange" || obj.param == "startFrom") return false;
            return true;
        });
        var filterUrl = mergeIntoUrl(paramArray, true);
        filterUrl = filterUrl.substring(filterUrl.indexOf("/enterprise-mention"));

        var redirectArry = splitIntoParams(redirectUrl)
        var index = redirectArry.findIndex(function (obj) {
            if (obj.param == "saveFilter") return obj;
        });
        if (index >= 0) {
            redirectArry.splice(index, 1);
        }
        redirectArry.push({
            param: "saveFilter",
            value: filterName
        })
        redirectUrl = mergeIntoUrl(redirectArry, true);

        var data = {
            filterName: filterName,
            redirectUrl: redirectUrl,
            filterUrl: filterUrl,
            projectId: projectId,
        }
        $("#savefilterform").ajaxSubmit({
            url: "/savefilters",
            method: "POST",
            withCredentials: true,
            xhrFields: {
                withCredentials: true
            },
            data: data,
            success: function (res) {
                $('#generateFilterName').modal('hide');
                saveFilterNotify("Your saved search name is saved successfully.")
                document.location.href = redirectUrl

            },
            error: function (res) {
                console.log(res)
                $('#generateFilterName').modal('hide');
                saveFilterNotify("Your saved search name is not saved.")
                document.location.href = redirectUrl
            }
        })
    }
}

function saveFilterNotify(message) {
    var offsetdata = checkScreenViewForNotify()
    $.notify({
        title: '',
        icon: 'fa fa-check',
        message: message,
    }, {
        type: 'info',
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutRight'
        },
        placement: {
            from: "top",
            align: "right"
        },

        offset: offsetdata,
        spacing: 10,
        z_index: 1051,

    });
}

$(document).ready(function () {
    var currentFilterName = $("#currentSearchName").text().trim()
    var url = document.location.href
    var urlparamArray = splitIntoParams(url)
    var paramArray = urlparamArray.filter(function (obj) {
        if (obj.param == "saveFilter") return true;
        return false;
    });
    var isSetName = true

    var filterUrl = ''
    if (typeof (saveFilters) != "undefined" && saveFilters && saveFilters.length > 0) {
        for (var i = 0; i < saveFilters.length; i++) {
            if (paramArray && paramArray.length > 0) {
                if (saveFilters[i].filterName == paramArray[0].value) {
                    filterUrl = saveFilters[i].filterUrl
                }
            }
        }
    }
    var filterParam = splitIntoParams(filterUrl)
    var urlParam = urlparamArray.filter(function (obj) {
        if (obj.param == "saveFilter") return false;
        return true;
    });
    if (filterParam.length != urlParam.length) {
        isSetName = false
    }
    var newCheckParam = []
    for (var i = 0; i < filterParam.length; i++) {
        for (var j = 0; j < urlParam.length; j++) {
            if (filterParam[i].param === urlParam[j].param && filterParam[i].value === urlParam[j].value) {
                newCheckParam.push(filterParam[i])
            }
        }
    }
    if (filterParam.length != newCheckParam.length) {
        isSetName = false
    }

    if (isSetName) {
        if (paramArray && paramArray[0] && paramArray[0].param == "saveFilter") {
            $("#currentSearchName").text(paramArray[0].value)
        }

        if (isSetName) {
            if (paramArray && paramArray[0] && paramArray[0].param == "saveFilter") {
                $("#currentSearchName").text(paramArray[0].value)
            }
        }
    }
})
$(document).ready(function () {
    $("#mentionFilterNameEnter").on("keyup", function (event) {
        event.preventDefault();
        if (event.keyCode === 13) {
            saveFilterData(projectId)
        }
    })
})

function deleteSaveFilter(filterId, projectId) {
    showLoaderOnClick()
    var url = "/deletefilters";
    var redirectUrl = document.location.href
    var paramArray = splitIntoParams(redirectUrl)
    var index = paramArray.findIndex(function (obj) {
        if (obj.param == "saveFilter") return obj;
    });
    if (index >= 0) {
        paramArray.splice(index, 1);
    }
    var destUrl = mergeIntoUrl(paramArray, true);
    var data = {
        projectId: projectId,
        filterId: filterId,
        redirectUrl: destUrl
    }

    $.ajax({
        url: url,
        type: "POST",
        withCredentials: true,
        xhrFields: {
            withCredentials: true
        },
        data: data,
        success: function (res) {
            saveFilterNotify("Your saved search name deleted successfully.")
            document.location.href = destUrl
        },
        error: function (res) {
            saveFilterNotify("Your saved search name not deleted.")
            document.location.href = destUrl
        }
    })

}

function deselectFilters() {
    showLoaderOnClick()
    var redirectUrl = document.location.href
    var paramArray = splitIntoParams(redirectUrl)
    var index = paramArray.findIndex(function (obj) {
        if (obj.param == "saveFilter") return obj;
    });
    if (index >= 0) {
        paramArray.splice(index, 1);
    }
    var destUrl = mergeIntoUrl(paramArray, true);
    document.location.href = destUrl
}


function showSavedFilters() {

    $("#showAllSaveFilterDiv").toggleClass("showAllFilters").toggleClass("hideAllFilters")

}

function activateCurrentFilter(filterName, filterUrl) {
    showLoaderOnClick()
    var redirectUrl = filterUrl
    var paramArray = splitIntoParams(redirectUrl)
    var index = paramArray.findIndex(function (obj) {
        if (obj.param == "saveFilter") return obj;
    });
    if (index >= 0) {
        paramArray.splice(index, 1);
    }
    paramArray.push({
        param: "saveFilter",
        value: filterName
    });
    var destUrl = mergeIntoUrl(paramArray, true);
    document.location.href = destUrl
}

function alertOnSavedFilters() {
    alert("You do not have any saved searches as of now. Please select a few filters from the left panel and click on 'Save This Search' to create one.")
}

function postReply(postId, socialType, isCommentofComment, parentPostId) {
    var replyId = postId;
    var isLikeButtonChecked = $("#likecheckbox" + postId).is(":checked")
    var charCountBox

    if (socialType === "TWITTER") {
        var postType = "Tweet"
        var textBoxName = "#tweetReply" + postId
        var replyText = $(textBoxName).val()
        var handleDoc = document.getElementById("twitterHandle" + postId)
        var handle = handleDoc.options[handleDoc.selectedIndex].text
        charCountBox = textBoxName.slice(1)
        var url = "/postReply"
        var data = {
            userId: user._id,
            postId: postId,
            handle: handle,
            replyText: replyText,
            type: socialType,
            isCommentofComment: isCommentofComment,
            parentPostId: parentPostId
        }
    }

    else if (socialType === "FACEBOOK") {
        var parentId = $('.parentCommentId' + postId).val()
        if (parentId)
            replyId = parentId
        var postType = "Comment"
        var textBoxName = "#commentReply" + postId
        var replyText = $(textBoxName).val()
        var handleDoc = document.getElementById("facebookHandle" + postId)
        var handle = handleDoc.options[handleDoc.selectedIndex].text
        charCountBox = textBoxName.slice(1)
        var url = "/postReply"
        var data = {
            userId: user._id,
            postId: replyId,
            handle: handle,
            replyText: replyText,
            type: socialType,
            isCommentofComment: isCommentofComment,
            parentPostId: parentPostId
        }
    }

    else if (socialType === "INSTAGRAM") {
        var postType = "Instagram Reply"
        var textBoxName = "#instagramReply" + postId
        var replyText = $(textBoxName).val()
        var handleDoc = document.getElementById("instagramHandle" + postId).innerHTML
        var handle = handleDoc
        charCountBox = textBoxName.slice(1)
        var url = "/postReply"

        var data = {
            userId: user._id,
            postId: replyId,
            handle: handle,
            replyText: replyText,
            type: socialType,
            isCommentofComment: isCommentofComment,
            parentPostId: parentPostId
        }
    }

    else if (socialType === "INSTAGRAM_COMMENT") {
        var parentId = $('.parentCommentId').val()
        if (parentId)
            replyId = parentId
        var postType = "Instagram Comment"
        var textBoxName = "#instagramCommentReply" + postId
        var replyText = $(textBoxName).val()
        var handleDoc = document.getElementById("instagramHandle" + postId).innerHTML
        var handle = handleDoc
        charCountBox = textBoxName.slice(1)
        var url = "/postReply"

        var data = {
            userId: user._id,
            postId: replyId,
            handle: handle,
            replyText: replyText,
            type: socialType,
            isCommentofComment: isCommentofComment,
            parentPostId: parentPostId
        }
    }
    if (replyText == "") {
        alert("Message cannot be empty")
        return false
    }
    if (isLikeButtonChecked === true) {
        likeSocialMediaObject(postId, socialType)
    }

    showLoaderOnClick()
    $.ajax({
        url: url,
        type: "POST",
        withCredentials: true,
        xhrFields: {
            withCredentials: true
        },
        data: data,
        success: function (res) {
            repliedSuccess('fa fa-check', postType + ' posted successfully.')

            $('#charCount' + charCountBox).text(0);
            $(textBoxName).val('')
            //$(replydiv).fadeToggle()
            hideLoaderOnClick()
        },
        error: function (res) {
            if (res && res.responseJSON && res.responseJSON.status_message) {
                repliedSuccess('fa fa-times', res.responseJSON.status_message)
            }
            else {
                repliedSuccess('fa fa-times', 'Posting ' + postType + ' failed!')
            }
            //$(replydiv).fadeToggle()
            // $(textBoxName).val('')
            hideLoaderOnClick()
        }
    })
}

function likeSocialMediaObject(postId, socialType) {

    if (socialType === "TWITTER") {
        var handleDoc = document.getElementById("twitterHandle" + postId)
        var handle = handleDoc.options[handleDoc.selectedIndex].text
    }
    else if (socialType === "FACEBOOK") {
        var handleDoc = document.getElementById("facebookHandle" + postId)
        var handle = handleDoc.options[handleDoc.selectedIndex].text
    }

    data = {
        postId: postId,
        socialType: socialType,
        handle: handle
    }


    showLoaderOnClick()
    $.ajax({
        url: '/likeSocialMediaObject',
        type: "POST",
        withCredentials: true,
        xhrFields: {
            withCredentials: true
        },
        data: data,
        success: function (res) {
            repliedSuccess('fa fa-check', 'Liked successfully.')
            hideLoaderOnClick()
        },
        error: function (res) {
            if (res && res.responseJSON && res.responseJSON.status_message) {
                repliedSuccess('fa fa-times', res.responseJSON.status_message)
            }
            hideLoaderOnClick()
        }
    })
}
var twitterSuggestionStart = false
function checkPublishTweetWordLimit(sourceType, textBoxId) {
    var characterCount = $('#' + textBoxId).val().length
    var influencerHandleId = textBoxId.split('_')[1]
    var charCount = '#charCount_' + influencerHandleId
    $(charCount).text(characterCount);

    if (sourceType === "twitter") {
        if (characterCount > 280) {
            $('#' + textBoxId).css({ 'border-color': '#eb5a46', 'box-shadow': '0px 0px 2px 0px red' })
            $('#postStatusUpdate' + textBoxId.split('_')[1]).attr('disabled', true)
            $('#postStatusUpdate' + textBoxId.split('_')[1]).addClass('btndisabled')
        }
        else {
            $('#' + textBoxId).css({ 'border-color': '', 'box-shadow': '' })
            $('#postStatusUpdate' + textBoxId.split('_')[1]).attr('disabled', false)
            $('#postStatusUpdate' + textBoxId.split('_')[1]).removeClass('btndisabled')
        }
    }
}



function getEngagementImageSignedUrl(pageId, sourceType, mediaType, schedulePostTime, updatePostId) {
    var signedUrl = null
    var uploadurl = "/engagementMediaSignedUrl"
    $.ajax({
        url: uploadurl,
        async: false,
        type: "GET",
        data: {
            pageId: pageId,
            sourceType: sourceType,
            mediaType: mediaType,
            schedulePostTime: schedulePostTime,
            updatePostId: updatePostId
        },
        success: function (res) {
            signedUrl = res
        }
    });
    return signedUrl
}


function saveSchedulePostRequest(pageId, statusText, sourceType, isMediaAdded, mediaType, isMediaUploaded, errorMessage, schedulePostTime, updatePostId) {
    var url = "/schedulePostRequest"

    if (sourceType === "facebook") {
        var postType = "Facebook Status"
        var textBoxName = "#fbPost_" + pageId
    }
    else if (sourceType === "twitter") {
        var postType = "Twitter status"
        var textBoxName = "#twitterPost_" + pageId
    }

    if (isMediaUploaded) {
        var data = {
            url: url,
            pageId: pageId,
            statusText: statusText,
            sourceType: sourceType,
            isMediaAdded: isMediaAdded,
            mediaType: mediaType,
            schedulePostTime: schedulePostTime,
            updatePostId: updatePostId
        }
    }
    else {
        var message = errorMessage + ", please click on this message to post directly."
        var url = 'https://www.' + sourceType + '.com/' + activeInfluencerHandle
        console.log(activeUserId)
        if (sourceType == "facebook")
            url = url + "-" + activeUserId
        hideLoaderOnClick()
        repliedSuccess('fa fa-times', message, url)
        return false
    }

    $.ajax({
        url: url,
        type: "POST",
        withCredentials: true,
        xhrFields: {
            withCredentials: true
        },
        data: data,
        success: function (res) {
            repliedSuccess('fa fa-check', res.status_message)
            $(textBoxName).val('')
            if (mediaType === "video") {
                $(".videoPreviewtweet").addClass("infl_displayNone")
                $("#labelImageUploadNewPost").removeClass("disabledOpacityCursor")
                $(".videoPreviewtweet video")[0].pause()
                document.getElementById('statusMediaImage_' + activeUserId).removeAttribute('disabled');
            }
            else if (mediaType == "image") {
                $("#labelVideoUploadNewPost").removeClass("disabledOpacityCursor")
                document.getElementById('statusMediaVideo_' + activeUserId).removeAttribute('disabled');
                $(".imagePreviewtweet").addClass("infl_displayNone")
            }
            $('#statusMediaImage_' + pageId).val('')
            $('#statusMediaVideo_' + pageId).val('')
            $(".detailCommentsView").toggle('slow')
            $('#updateScheduleData' + pageId).text('Schedule the post')
            hideLoaderOnClick()
            return true
        },
        error: function (res) {
            if (res && res.responseJSON && res.responseJSON.status_message) {
                repliedSuccess('fa fa-times', res.responseJSON.status_message)
            }
            else {
                repliedSuccess('fa fa-times', 'Posting ' + postType + ' failed!')
            }
            $(".detailCommentsView").toggle('slow')
            // $(textBoxName).val('')
            // $('#statusMediaImage_' + pageId).val('')
            // $('#statusMediaVideo_' + pageId).val('')
            hideLoaderOnClick()
            return false
        }
    })
}


function schedulePostNow(schedulePostId, postType) {
    console.log("in schedulePostNow schedulePostId: " + schedulePostId)
    showLoaderOnClick()
    var url = "/schedulePostNow"
    $.ajax({
        url: url,
        type: "POST",
        withCredentials: true,
        xhrFields: {
            withCredentials: true
        },
        data: { schedulePostId: schedulePostId },
        success: function (res) {
            repliedSuccess('fa fa-check', postType + ' posted successfully.')
            $('#' + postType + '_' + schedulePostId).remove()
            hideLoaderOnClick()
        },
        error: function (res) {
            if (res && res.responseJSON && res.responseJSON.status_message) {
                repliedSuccess('fa fa-times', res.responseJSON.status_message)
            }
            else {
                repliedSuccess('fa fa-times', 'Posting ' + postType + ' failed!')
            }
            // $(textBoxName).val('')
            // $('#statusMediaImage_' + pageId).val('')
            // $('#statusMediaVideo_' + pageId).val('')
            hideLoaderOnClick()
        }
    })

}

function sendStatusUpdateRequestNow(pageId, statusText, sourceType, isMediaAdded, mediaType, isMediaUploaded, errorMessage, schedulePostTime, updatePostId) {
    var url = "/makeStatusUpdate"

    if (sourceType === "facebook") {
        var postType = "Facebook Status"
        var textBoxName = "#fbPost_" + pageId
    }
    else if (sourceType === "twitter") {
        var postType = "Twitter status"
        var textBoxName = "#twitterPost_" + pageId
    }


    if (isMediaUploaded) {
        var data = {
            url: url,
            pageId: pageId,
            statusText: statusText,
            sourceType: sourceType,
            isMediaAdded: isMediaAdded,
            mediaType: mediaType,
            updatePostId: updatePostId
        }
    }
    else {
        var message = errorMessage + ", please click on this message to post directly."
        var url = 'https://www.' + sourceType + '.com/' + activeInfluencerHandle
        console.log(activeUserId)
        if (sourceType == "facebook")
            url = url + "-" + activeUserId
        hideLoaderOnClick()
        repliedSuccess('fa fa-times', message, url)
        // $('#statusMediaImage_' + pageId).val('')
        // $('#statusMediaVideo_' + pageId).val('')
        return false
    }

    $.ajax({
        url: url,
        type: "POST",
        withCredentials: true,
        xhrFields: {
            withCredentials: true
        },
        data: data,
        success: function (res) {
            repliedSuccess('fa fa-check', postType + ' posted successfully.')
            $(textBoxName).val('')
            if (mediaType === "video") {
                $(".videoPreviewtweet").addClass("infl_displayNone")
                $("#labelImageUploadNewPost").removeClass("disabledOpacityCursor")
                $(".videoPreviewtweet video")[0].pause()
                document.getElementById('statusMediaImage_' + activeUserId).removeAttribute('disabled');
            }
            else if (mediaType == "image") {
                $("#labelVideoUploadNewPost").removeClass("disabledOpacityCursor")
                document.getElementById('statusMediaVideo_' + activeUserId).removeAttribute('disabled');
                $(".imagePreviewtweet").addClass("infl_displayNone")
            }
            $('#statusMediaImage_' + pageId).val('')
            $('#statusMediaVideo_' + pageId).val('')
            $(".detailCommentsView").toggle('slow')
            $('#updateScheduleData' + pageId).text('Schedule the post')
            hideLoaderOnClick()
            return true
        },
        error: function (res) {
            if (res && res.responseJSON && res.responseJSON.status_message) {
                repliedSuccess('fa fa-times', res.responseJSON.status_message)
            }
            else {
                repliedSuccess('fa fa-times', 'Posting ' + postType + ' failed!')
            }
            $(".detailCommentsView").toggle('slow')
            // $(textBoxName).val('')
            // $('#statusMediaImage_' + pageId).val('')
            // $('#statusMediaVideo_' + pageId).val('')
            hideLoaderOnClick()
            return false
        }
    })
}

function sendStatusUpdateRequest(pageId, statusText, sourceType, isMediaAdded, mediaType, isMediaUploaded, errorMessage, schedulePostTime, updatePostId) {
    console.log("schedulePostTime: " + schedulePostTime)
    if (schedulePostTime && schedulePostTime != "") {
        return saveSchedulePostRequest(pageId, statusText, sourceType, isMediaAdded, mediaType, isMediaUploaded, errorMessage, schedulePostTime, updatePostId)
    }
    else {
        return sendStatusUpdateRequestNow(pageId, statusText, sourceType, isMediaAdded, mediaType, isMediaUploaded, errorMessage, schedulePostTime, updatePostId)
    }


}


function uploadMediaAndPost(influencerId, sourceType, statusText, mediaObject, mediaType, isMediaAdded, schedulePostTime, updatePostId) {
    var mediaUploadStatus = true
    var errorMessage = ""
    // if (isMediaAdded && updatePostId && updatePostId != "" && input == "null") {
    //     mediaUploadStatus = false
    // }
    if (isMediaAdded && mediaObject) {
        var mediaSize = mediaObject.size
        var mediaSizeInMb = mediaSize / (1024 * 1024)
        if (sourceType === "twitter") {
            if (mediaType === "image") {
                if (mediaSizeInMb > 5) {
                    mediaUploadStatus = false
                    errorMessage = "Image must not exceed 5Mb"
                }
            }
            else if (mediaType === "video") {
                if (mediaSizeInMb > 15) {
                    mediaUploadStatus = false
                    errorMessage = "Video should be smaller than 15 MB and less than 140 seconds"
                }
            }
        }

        else if (sourceType === "facebook") {
            if (mediaType === "image") {
                if (mediaSizeInMb > 4) {
                    mediaUploadStatus = false
                    errorMessage = "Image must not exceed 4Mb"
                }
            }
            else if (mediaType === "video") {
                if (mediaSizeInMb > 1024) {
                    mediaUploadStatus = false
                    errorMessage = "Video duration must be less than 20 minutes"
                }
            }
        }

        if (!mediaUploadStatus) {
            return sendStatusUpdateRequest(influencerId, statusText, sourceType, isMediaAdded, mediaType, mediaUploadStatus, errorMessage, schedulePostTime, updatePostId)
        }
        var signedUrl = getEngagementImageSignedUrl(influencerId, sourceType, mediaType, schedulePostTime, updatePostId)
        $.ajax({
            url: signedUrl,
            type: 'PUT',
            async: true,
            processData: false,
            contentType: false,
            data: mediaObject,
            success: function (res) {
                return sendStatusUpdateRequest(influencerId, statusText, sourceType, isMediaAdded, mediaType, mediaUploadStatus, errorMessage, schedulePostTime, updatePostId)
            },
            error: function (res) {
                alert("Sorry your Image could not be uploaded, please refresh the page and try again.")
                mediaUploadStatus = false
                return sendStatusUpdateRequest(influencerId, statusText, sourceType, isMediaAdded, mediaType, mediaUploadStatus, errorMessage, schedulePostTime, updatePostId)
            }
        });

    }
    else {
        return sendStatusUpdateRequest(influencerId, statusText, sourceType, isMediaAdded, mediaType, mediaUploadStatus, errorMessage, schedulePostTime, updatePostId)
    }
}

function upadateViewSchedulePost(pageId, sourceType) {
    console.log("in upadateViewSchedulePost postId : " + pageId + " socialType: " + sourceType)
    showLoaderOnClick()
    if (pageId && sourceType) {
        $.ajax({
            url: '/viewAllScheduleTweets',
            type: "POST",
            withCredentials: true,
            xhrFields: {
                withCredentials: true
            },
            data: {
                pageId: pageId,
                sourceType: sourceType,
            },
            success: function (res) {
                console.log(JSON.stringify(res))
                if (res && res.status && res.statusCode == 200 && res.schedulePosts) {
                    console.log("change allSchedulePosts to : " + JSON.stringify(res.schedulePosts))
                    allSchedulePosts = res.schedulePosts
                }
                hideLoaderOnClick()
            },
            error: function (res) {
                console.log("error on updating allSchedulePost: " + JSON.stringify(res))
                hideLoaderOnClick()
            }
        })
    }
}

function editSchedulePost(schedulePostId) {
    console.log("in editSchedulePost schedulePostId: " + schedulePostId)
    showLoaderOnClick()
    if (allSchedulePosts && allSchedulePosts.length > 0) {
        var selectedPost = allSchedulePosts.filter(function (a) { if (a._id == schedulePostId) { return true } })
        if (selectedPost && selectedPost.length > 0) {
            var post = selectedPost[0]
            console.log(JSON.stringify(post))

            if (post.socialType === "facebook") {
                $('#fbPost_' + post.pageId).val(post.statusText)
            }
            else if (post.socialType === "twitter") {
                $('#twitterPost_' + post.pageId).val(post.statusText)
            }

            if (post.mediaType && post.mediaType == "image" && post.isMediaAdded && post.isMediaAdded == "true") {
                $("source#newPostvideoPreview").attr('src', '');
                $("#labelImageUploadNewPost").removeClass("disabledOpacityCursor")
                $(".videoPreviewtweet").addClass("infl_displayNone")
                $(".previewStyle#thumbnail").attr('src', 'https://images-rmo-clients.s3.ap-south-1.amazonaws.com/engageMedia/' + post.socialType + '/' + post.userId + '-' + post.pageId + '_' + encodeURIComponent(post.schedule_date) + '.jpeg');
                $(".imagePreviewtweet").removeClass("infl_displayNone");
                $("#labelVideoUploadNewPost").addClass("disabledOpacityCursor")


            }
            else if (post.mediaType && post.mediaType == "video" && post.isMediaAdded && post.isMediaAdded == "true") {
                $(".previewStyle#thumbnail").attr('src', '');
                $(".imagePreviewtweet").addClass("infl_displayNone");
                $("source#newPostvideoPreview").attr('src', 'https://images-rmo-clients.s3.ap-south-1.amazonaws.com/engageMedia/' + post.socialType + '/' + post.userId + '-' + post.pageId + '_' + encodeURIComponent(post.schedule_date) + '.mp4');
                $(".videoPreviewtweet").removeClass("infl_displayNone");
                $("#labelImageUploadNewPost").addClass("disabledOpacityCursor")
                $("#labelVideoUploadNewPost").removeClass("disabledOpacityCursor")

            }
            else {
                $("source#newPostvideoPreview").attr('src', '');
                $(".previewStyle#thumbnail").attr('src', '');
                $("#labelVideoUploadNewPost").removeClass("disabledOpacityCursor")
                $("#labelImageUploadNewPost").removeClass("disabledOpacityCursor")
                $(".imagePreviewtweet").addClass("infl_displayNone");
                $(".videoPreviewtweet").addClass("infl_displayNone");
            }
            $("#postStatusUpdate" + post.pageId).attr("onclick", "postStatusUpdate('" + post.pageId + "', '.statusPostView" + post.pageId + "', '" + post.socialType + "', 'false', '" + post._id + "');deleteSchedulePost('" + post.socialType + "','" + post._id + "', 'false')")
            $("#updateScheduleData" + post.pageId).attr("onclick", "postStatusUpdate('" + post.pageId + "', '.statusPostView" + post.pageId + "', '" + post.socialType + "', 'true', '" + post._id + "' )").text('Update Schedule Post')
            $('#scheduleDatePicker span').text(moment(post.schedule_date).format('DD-MM-YYYY hh:mm a'))
            console.log("post time " + moment(post.schedule_date).format('YYYY-MM-DD hh:mm a'))
            console.log(moment().format())
        }
    }
    hideLoaderOnClick()



}

function deleteSchedulePost(socialType, schedulePostId, notify) {
    console.log("in deleteSchedulePost schedulePostId: " + schedulePostId)
    showLoaderOnClick()
    if (allSchedulePosts && allSchedulePosts.length > 0) {
        if (allSchedulePosts && allSchedulePosts.length > 0) {
            var selectedPost = allSchedulePosts.filter(function (a) { if (a._id == schedulePostId) { return true } })
            if (selectedPost && selectedPost.length > 0) {
                post = selectedPost[0]
                $.ajax({
                    url: '/deleteSchedulePost',
                    type: "POST",
                    withCredentials: true,
                    xhrFields: {
                        withCredentials: true
                    },
                    data: { schedulePostId: schedulePostId },
                    success: function (res) {

                        console.log(JSON.stringify(res))
                        if (res && res.status && res.statusCode == 200 && res.result) {
                            $('#' + socialType + '_' + schedulePostId).remove()
                            if (notify && notify != 'false') {
                                repliedSuccess('fa fa-check', socialType + ' schedule post deleted successfully.')
                            }

                        }
                        hideLoaderOnClick()
                    },
                    error: function (res) {
                        if (notify && notify == 'false') {
                            repliedSuccess('fa fa-times', "Sorry schedule post not found, please try again.")
                        }
                        hideLoaderOnClick()
                    }
                })
            }
        }
        hideLoaderOnClick()
    }
}




function postStatusUpdate(pageId, replydiv, sourceType, schedulePost, updatePostId) {
    var textBoxName = null
    var statusText = null
    var schedulePostTime = undefined
    if (schedulePost && schedulePost == "true") {
        schedulePostTime = moment($('#scheduleDatePicker span').text(), "DD-MM-YYYY hh:mm a").format()
        if (schedulePostTime && schedulePostTime != "Invalid date") {
            console.log("schedulePostTime : " + schedulePostTime)
        }
        else {
            alert("please select schedule post date")
            return false
        }
    }

    if (sourceType === "facebook") {
        var textBoxName = "#fbPost_" + pageId
        var statusText = $(textBoxName).val()
        statusText = changePageNameWithId(statusText)
    }

    else if (sourceType === "twitter") {
        var textBoxName = "#twitterPost_" + pageId
        var statusText = $(textBoxName).val()
    }

    var isMediaAdded = false
    var mediaType = null
    var mediaObject = null


    if ($('#statusMediaImage_' + pageId)[0].files.length > 0) {
        mediaObject = document.getElementById('statusMediaImage_' + pageId)
        isMediaAdded = true
        mediaType = "image"
        if (!isMediaAdded && statusText == "") {
            if (sourceType === "facebook") {
                alert("Empty post cannot be posted")
            }

            else if (sourceType === "twitter") {
                alert("Empty tweet cannot be posted")
            }
            return false
        } else {
            showLoaderOnClick()
            mediaObject = mediaObject.files[0]
            uploadMediaAndPost(pageId, sourceType, statusText, mediaObject, mediaType, isMediaAdded, schedulePostTime, updatePostId)
        }
    }

    else if ($('#statusMediaVideo_' + pageId)[0].files.length > 0) {
        mediaObject = document.getElementById('statusMediaVideo_' + pageId)
        isMediaAdded = true
        mediaType = "video"
        if (!isMediaAdded && statusText == "") {
            if (sourceType === "facebook") {
                alert("Empty post cannot be posted")
            }

            else if (sourceType === "twitter") {
                alert("Empty tweet cannot be posted")
            }
            return false
        } else {
            showLoaderOnClick()
            mediaObject = mediaObject.files[0]
            uploadMediaAndPost(pageId, sourceType, statusText, mediaObject, mediaType, isMediaAdded, schedulePostTime, updatePostId)
        }
    } else {
        if (updatePostId && updatePostId.trim() != "") {
            var selectedPost = allSchedulePosts.filter(function (a) { if (a._id == updatePostId) { return true } })
            if (Boolean(selectedPost[0].isMediaAdded)) {
                isMediaAdded = Boolean(selectedPost[0].isMediaAdded)
                mediaType = selectedPost[0].mediaType
                if (mediaType == "image") {
                    var urlpath = 'https://images-rmo-clients.s3.ap-south-1.amazonaws.com/engageMedia/' + selectedPost[0].socialType + '/' + selectedPost[0].userId + '-' + selectedPost[0].pageId + '_' + encodeURIComponent(selectedPost[0].schedule_date) + '.jpeg'
                    $.ajax({
                        url: '/convertToImageToBase64',
                        type: "POST",
                        withCredentials: true,
                        xhrFields: {
                            withCredentials: true
                        },
                        data: {
                            imageUrl: urlpath
                        },
                        success: function (res) {
                            console.log(JSON.stringify(res))
                            if (res && res.status && res.statusCode == 200 && res.result) {
                                var base64 = res.result
                                mediaObject = dataURLtoFile('data:image/jpeg;base64,' + base64, 'filename.jpeg')
                                showLoaderOnClick()
                                uploadMediaAndPost(pageId, sourceType, statusText, mediaObject, mediaType, isMediaAdded, schedulePostTime, updatePostId)
                                return true

                            }

                        },
                        error: function (res) {
                            console.log("error on converting base64: " + JSON.stringify(res))
                            hideLoaderOnClick()
                        }
                    })

                }
                else if (mediaType == "video") {
                    var urlpath = 'https://images-rmo-clients.s3.ap-south-1.amazonaws.com/engageMedia/' + selectedPost[0].socialType + '/' + selectedPost[0].userId + '-' + selectedPost[0].pageId + '_' + encodeURIComponent(selectedPost[0].schedule_date) + '.mp4'
                    $.ajax({
                        url: '/convertToImageToBase64',
                        type: "POST",
                        withCredentials: true,
                        xhrFields: {
                            withCredentials: true
                        },
                        data: {
                            imageUrl: urlpath
                        },
                        success: function (res) {
                            console.log(JSON.stringify(res))
                            if (res && res.status && res.statusCode == 200 && res.result) {
                                var base64 = res.result
                                mediaObject = dataURLtoFile('data:video/mp4;base64,' + base64, 'filename.mp4')
                                showLoaderOnClick()
                                uploadMediaAndPost(pageId, sourceType, statusText, mediaObject, mediaType, isMediaAdded, schedulePostTime, updatePostId)
                                return true

                            }

                        },
                        error: function (res) {
                            console.log("error on converting base64: " + JSON.stringify(res))
                            hideLoaderOnClick()
                        }
                    })

                }
                console.log("urlpath " + urlpath)
            }
            else {
                if (!isMediaAdded && statusText == "") {
                    if (sourceType === "facebook") {
                        alert("Empty post cannot be posted")
                    }

                    else if (sourceType === "twitter") {
                        alert("Empty tweet cannot be posted")
                    }
                    return false
                } else {
                    showLoaderOnClick()
                    uploadMediaAndPost(pageId, sourceType, statusText, mediaObject, mediaType, isMediaAdded, schedulePostTime, updatePostId)
                }
            }

        }
        else {
            if (!isMediaAdded && statusText == "") {
                if (sourceType === "facebook") {
                    alert("Empty post cannot be posted")
                }

                else if (sourceType === "twitter") {
                    alert("Empty tweet cannot be posted")
                }
                return false
            } else {
                showLoaderOnClick()
                uploadMediaAndPost(pageId, sourceType, statusText, mediaObject, mediaType, isMediaAdded, schedulePostTime, updatePostId)
            }
        }
    }
}

function imgToBase64(img) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;

    // I think this won't work inside the function from the console
    img.crossOrigin = 'anonymous';

    ctx.drawImage(img, 0, 0);

    return canvas.toDataURL();
}

function repliedSuccess(icon, message, url) {
    var offsetdata = checkScreenViewForNotify()
    var notifyParams = {
        title: '',
        icon: icon,
        message: message
    }

    if (url) {
        notifyParams['url'] = url
    }
    $.notify(notifyParams
        , {
            type: 'info',
            animate: {
                enter: 'animated fadeInUp',
                exit: 'animated fadeOutRight'
            },
            placement: {
                from: "top",
                align: "right"
            },

            offset: offsetdata,
            spacing: 10,
            z_index: 1051,

        });
}

function checkWordLimit(postId, sourceType) {
    var characterCount = $('#' + postId).val().length
    var charCount = '#charCount' + postId
    $(charCount).text(characterCount);
    if (sourceType == 'TWITTER') {
        if (characterCount > 280) {
            $('#' + postId).css({ 'border-color': '#eb5a46', 'box-shadow': '0px 0px 2px 0px red' })
            $('#sendTweettweet_' + postId.split('_')[1]).attr('disabled', true)
            $('#sendTweettweet_' + postId.split('_')[1]).addClass('btndisabled')
        }
        else {
            $('#' + postId).css({ 'border-color': '', 'box-shadow': '' })
            $('#sendTweettweet_' + postId.split('_')[1]).attr('disabled', false)
            $('#sendTweettweet_' + postId.split('_')[1]).removeClass('btndisabled')
        }
    }
}




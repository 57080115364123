// global variables
var audienceSourceDistributionOnClick;
var audienceLanguageDistributionOnClick;
var audienceDistributionOnClick;
var audienceCategoryDistributionOnClick
var audienceLanguageDistributionPieChart
var audienceSourceDistributionPieChart
var audienceGenderDistributionPieChart
var audienceCategoryDistributionPieChart

function getAudienceProfile(profile) {
    if (profile === 'Brand') {
        return 'brandOwnProfiles'
    }
    if (profile === 'Audience') {
        return 'audienceProfiles'
    }
}

function getAudienceAuthorCategory(category) {
    if (category === "Micro-Influencer") {
        return 'micro'
    }
    if (category === "Social Profile") {
        return 'social_profile'
    }
    if (category === "Macro-Influencer") {
        return 'macro'
    }
    if (category === "Mega-Influencer(Celebrity)") {
        return 'mega'
    }
    return category
}

$(document).ready(function () {
    var href = document.location.href
    var layout = {
        sort: false,
        showlegend: true,
        legend: {
            "orientation": "v",
            size: 8,
            // y: 0.5
        },
        margin: {
            b: 0,
            t: 0,
            l: 0,
            r: 0,
            pad: 0,
        },
        autosize: true,
        paper_bgcolor: 'rgba(0,0,0,0)',
        font: {
            size: 10,
        },
        height: 150
    };

    if (document.getElementById('audienceSourceDistribution') && sourcePieChart && sourcePieChart != null) {
        document.getElementById("audienceSourceDistribution").onclick = function (event) {
            var activePoint = audienceSourceDistributionPieChart.getElementAtEvent(event)[0]
            if (activePoint) {
                var activePointIndex = activePoint["_index"];
                var paramArray = splitIntoParams(href)
                paramArray = paramArray.filter(function (val) {
                    if (val.param !== "authorType") return true
                    return false
                })
                var hrefParams = mergeIntoUrl(paramArray)
                hrefParams = hrefParams.split('?')[1]
                var url = '/track-influencers?' + hrefParams + '&authorType=' + sourcePieChart.labels[activePointIndex].toUpperCase();
                window.open(url, '_blank');
            }
            // document.getElementById('audienceSourceDistribution').on('plotly_click', function (data) {
            //     var paramArray = splitIntoParams(href)
            //     paramArray = paramArray.filter(function (val) {
            //         if (val.param !== "authorType") return true
            //         return false
            //     })
            //     var hrefParams = mergeIntoUrl(paramArray)
            //     hrefParams = hrefParams.split('?')[1]
            //     var pts = '/track-influencers?' + hrefParams;
            //     for (var i = 0; i < data.points.length; i++) {
            //         data.points[i].label.replace("<br>", "")
            //         pts = pts + '&authorType=' + encodeURI(data.points[i].label.toUpperCase())
            //     }
            //     window.open(pts, '_blank');
            // });
        }

        var PieChartData = {
            labels: sourcePieChart.labels,
            datasets: [{
                data: sourcePieChart.values,
                backgroundColor: ultimateColors[0],
                borderColor: ultimateColors[0],
            }]
        }
        var ctx = $('#audienceSourceDistribution');
        var audienceSourceDistributionLayout = JSON.parse(JSON.stringify(doughnutChartLayout))
        audienceSourceDistributionLayout['legend']['position'] = 'right'
        var graphData = {
            type: 'doughnut',
            data: PieChartData,
            options: audienceSourceDistributionLayout,
        }
        audienceSourceDistributionPieChart = new Chart(ctx, graphData)
        chartjsGraphsLoaded.push({
            id: "audienceSourceDistribution",
            data: graphData,
            chartObj: audienceSourceDistributionPieChart,
            expandRatio: 2
        })



        // plotlyPlotData.push({
        //     id: 'audienceSourceDistribution',
        //     data: [sourcePieChart],
        //     layout: layout,
        //     onClickFunctionName: 'audienceSourceDistributionOnClick'
        // });
        // Plotly.newPlot('audienceSourceDistribution', [sourcePieChart], layout, {
        //     displaylogo: false,
        //     showTips: false,
        //     responsive: true
        // });

        // audienceSourceDistributionOnClick()

    }




    if (document.getElementById('audienceLanguageDistribution') && languagePieChart && languagePieChart != null) {
        document.getElementById("audienceLanguageDistribution").onclick = function (event) {
            var activePoint = audienceLanguageDistributionPieChart.getElementAtEvent(event)[0]
            if (activePoint) {
                var activePointIndex = activePoint["_index"];
                var paramArray = splitIntoParams(href)
                paramArray = paramArray.filter(function (val) {
                    if (val.param !== "language") return true
                    return false
                })
                var hrefParams = mergeIntoUrl(paramArray)
                hrefParams = hrefParams.split('?')[1]
                var url = '/track-influencers?' + hrefParams + '&language=' + languagePieChart.labels[activePointIndex];
                window.open(url, '_blank');
            }
        }
        // audienceLanguageDistributionOnClick = function () {
        //     document.getElementById('audienceLanguageDistribution').on('plotly_click', function (data) {
        //         var paramArray = splitIntoParams(href)
        //         paramArray = paramArray.filter(function (val) {
        //             if (val.param !== "language") return true
        //             return false
        //         })
        //         var hrefParams = mergeIntoUrl(paramArray)
        //         hrefParams = hrefParams.split('?')[1]
        //         var pts = '/track-influencers?' + hrefParams;
        //         for (var i = 0; i < data.points.length; i++) {
        //             data.points[i].label.replace("<br>", "")
        //             pts = pts + '&language=' + encodeURI(data.points[i].label)
        //         }
        //         window.open(pts, '_blank');
        //     });
        // }

        var PieChartData = {
            labels: languagePieChart.labels,
            datasets: [{
                data: languagePieChart.values,
                backgroundColor: ultimateColors[0],
                borderColor: ultimateColors[0],
            }]
        }
        var ctx = $('#audienceLanguageDistribution');
        var audienceLanguageDistributionLayout = JSON.parse(JSON.stringify(doughnutChartLayout))
        audienceLanguageDistributionLayout['legend']['position'] = 'right'
        var graphData = {
            type: 'doughnut',
            data: PieChartData,
            options: audienceSourceDistributionLayout,
        }
        audienceLanguageDistributionPieChart = new Chart(ctx, graphData)
        chartjsGraphsLoaded.push({
            id: "audienceLanguageDistribution",
            data: graphData,
            chartObj: audienceLanguageDistributionPieChart,
            expandRatio: 2
        })
        // plotlyPlotData.push({
        //     id: 'audienceLanguageDistribution',
        //     data: [languagePieChart],
        //     layout: layout,
        //     onClickFunctionName: 'audienceLanguageDistributionOnClick'
        // });
        // Plotly.newPlot('audienceLanguageDistribution', [languagePieChart], layout, {
        //     displaylogo: false,
        //     showTips: false,
        //     responsive: true
        // });
        // audienceLanguageDistributionOnClick()

    }

    if (document.getElementById('audienceGenderDistribution') && genderPieChart && genderPieChart != null) {
        var PieChartData = {
            labels: genderPieChart.labels,
            datasets: [{
                data: genderPieChart.values,
                backgroundColor: ultimateColors[0],
                borderColor: ultimateColors[0],
            }]
        }
        var ctx = $('#audienceGenderDistribution');
        var audienceGenderDistributionLayout = JSON.parse(JSON.stringify(doughnutChartLayout))
        audienceGenderDistributionLayout['legend']['position'] = 'right'
        var graphData = {
            type: 'doughnut',
            data: PieChartData,
            options: audienceSourceDistributionLayout,
        }
        audienceGenderDistributionPieChart = new Chart(ctx, graphData)
        chartjsGraphsLoaded.push({
            id: "audienceGenderDistribution",
            data: graphData,
            chartObj: audienceGenderDistributionPieChart,
            expandRatio: 2
        })


        // plotlyPlotData.push({
        //     id: 'audienceGenderDistribution',
        //     data: [genderPieChart],
        //     layout: layout,
        // });
        // Plotly.newPlot('audienceGenderDistribution', [genderPieChart], layout, {
        //     displaylogo: false,
        //     showTips: false,
        //     responsive: true
        // });
    }


    if (document.getElementById('audienceDistribution') && audiencePieChart && audiencePieChart != null) {
        audienceDistributionOnClick = function () {
            document.getElementById('audienceDistribution').on('plotly_click', function (data) {
                var paramArray = splitIntoParams(href)
                paramArray = paramArray.filter(function (val) {
                    if (val.param !== "profileType") return true
                    return false
                })
                var hrefParams = mergeIntoUrl(paramArray)
                hrefParams = hrefParams.split('?')[1]
                var pts = '/track-influencers?' + hrefParams;
                for (var i = 0; i < data.points.length; i++) {
                    data.points[i].label.replace("<br>", "")
                    var audienceProfile = getAudienceProfile(data.points[i].label)
                    pts = pts + '&profileType=' + audienceProfile
                }
                window.open(pts, '_blank');
            });
        }
        plotlyPlotData.push({
            id: 'audienceDistribution',
            data: [audiencePieChart],
            layout: layout,
            onClickFunctionName: 'audienceDistributionOnClick'
        });
        Plotly.newPlot('audienceDistribution', [audiencePieChart], layout, {
            displaylogo: false,
            showTips: false,
            responsive: true
        });
        audienceDistributionOnClick()

    }

    if (document.getElementById('audienceCategoryDistribution') && categoryPieChart && categoryPieChart != null) {
        // var categorylayout = JSON.parse(JSON.stringify(layout))
        // categorylayout['legend']['x'] = .7

        var PieChartData = {
            labels: categoryPieChart.labels,
            datasets: [{
                data: categoryPieChart.values,
                backgroundColor: ultimateColors[0],
                borderColor: ultimateColors[0],
            }]
        }
        var ctx = $('#audienceCategoryDistribution');
        var audienceCategoryDistributionLayout = JSON.parse(JSON.stringify(doughnutChartLayout))
        audienceCategoryDistributionLayout['legend']['position'] = 'right'
        var graphData = {
            type: 'doughnut',
            data: PieChartData,
            options: audienceSourceDistributionLayout,
        }
        audienceCategoryDistributionPieChart = new Chart(ctx, graphData)
        chartjsGraphsLoaded.push({
            id: "audienceCategoryDistribution",
            data: graphData,
            chartObj: audienceCategoryDistributionPieChart,
            expandRatio: 2
        })


        document.getElementById("audienceCategoryDistribution").onclick = function (event) {
            var activePoint = audienceCategoryDistributionPieChart.getElementAtEvent(event)[0]
            if (activePoint) {
                var activePointIndex = activePoint["_index"];
                var paramArray = splitIntoParams(href)
                paramArray = paramArray.filter(function (val) {
                    if (val.param !== "authorCategory") return true
                    return false
                })
                var hrefParams = mergeIntoUrl(paramArray)
                hrefParams = hrefParams.split('?')[1]
                var authorCategory = getAudienceAuthorCategory(categoryPieChart.labels[activePointIndex])
                var url = '/track-influencers?' + hrefParams + '&authorCategory=' + authorCategory;
                window.open(url, '_blank');
            }
        }
        // audienceCategoryDistributionOnClick = function () {
        //     document.getElementById('audienceCategoryDistribution').on('plotly_click', function (data) {
        //         var paramArray = splitIntoParams(href)
        //         paramArray = paramArray.filter(function (val) {
        //             if (val.param !== "authorCategory") return true
        //             return false
        //         })
        //         var hrefParams = mergeIntoUrl(paramArray)
        //         hrefParams = hrefParams.split('?')[1]
        //         var pts = '/track-influencers?' + hrefParams;
        //         for (var i = 0; i < data.points.length; i++) {
        //             data.points[i].label.replace("<br>", "")
        //             var authorCategory = getAudienceAuthorCategory(data.points[i].label)
        //             pts = pts + '&authorCategory=' + authorCategory
        //         }
        //         window.open(pts, '_blank');
        //     });
        // }
        // plotlyPlotData.push({
        //     id: 'audienceCategoryDistribution',
        //     data: [categoryPieChart],
        //     layout: categorylayout,
        //     onClickFunctionName: 'audienceCategoryDistributionOnClick'
        // });
        // Plotly.newPlot('audienceCategoryDistribution', [categoryPieChart], categorylayout, {
        //     displaylogo: false,
        //     showTips: false,
        //     responsive: true
        // });
        // audienceCategoryDistributionOnClick()

    }

})


$(document).ready(function () {


    $(".accountProfileConfirm").on("click", function (e) {
        e.stopPropagation()
        var accountConfirmSelect = accountConfirmByUser()
        if (accountConfirmSelect) {
            accountConfirmKeywords()
        }
    });

    function accountConfirmByUser() {
        var txt = false;
        if (confirm("Are you sure you want to update the account settings?")) {
            txt = true;
        } else {
            txt = false;
        }
        return txt;
    }

    function accountConfirmKeywords() {
        var accounturl = "/postUserAccount";
        var userId = $(".accountuserid").text();
        var name = $("#accountUserName").val()
        // var checkTimeZoneOk = false
        var timeZone = $("#accountUserTimeZone").val()
        // if (!!moment.tz.zone(timeZone)) {
        //     checkTimeZoneOk = true
        // }
        // if (!checkTimeZoneOk) {
        //     alert("Please enter correct time zone")
        //     return;
        // }
        var gender = "male"
        if ($("#account_male").is(":checked")) {
            gender = "male"
        } else
            gender = "female"
        var companyname = $("#accountUserCompanyName").val()


        var accountImage = $('#imagePreview-display').css('background-image');
        var checkUpdateAccountImage = (accountImage.indexOf("images/reputation-hero.jpg") < 0) ? accountImage.indexOf("images/reputation-hero.jpg") < 0 : (accountImage.indexOf("images/default_profile_normal.png") < 0) ? accountImage.indexOf("images/default_profile_normal.png") < 0 : false
        var accountImageUrl = ""
        if (checkUpdateAccountImage) {
            accountImageUrl = accountImage.replace('url(', '').replace(')', '').replace(/\"/gi, "");
        }
        var userInfo = {
            "userName": name,
            "userCompanyName": companyname,
            "userGender": gender,
            "userImgLink": accountImageUrl,
            "timeZone": timeZone
        }
        var data = {
            "ans": {
                "userId": userId,
                "userInfo": userInfo,
            }
        }
        $.ajax({
            url: accounturl,
            type: "POST",
            withCredentials: true,
            xhrFields: {
                withCredentials: true
            },
            data: JSON.stringify(data),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            beforeSend: function () {
                $("#accountUpdate").html("<img style='height: 50px;width: 50px;position: fixed;top: 45%;left: 45%;' src = '../images/loader-icon.gif'>");
                return true;
            },
            complete: function () {
                $("#accountUpdate").css("display", "none");
                return true;
            },
            success: function (res) {
                if (res && res.status && res.status == "failed") {
                    alert("please enter correct time zone")
                    $("#accountUpdate").css("display", "none");
                } else {
                    document.location.reload();
                }
            },
            error: function (res) {
                document.location.reload();
            }

        });
    }

    $("#imageUpload").change(function () {
        uploadProfileImage(this, "#imagePreview-display");
    });

    function callAndGetSignedUrl() {
        var signedUrl = null
        var pictureurl = "/photoUpload"
        $.ajax({
            url: pictureurl,
            async: false,
            type: "GET",
            success: function (res) {
                signedUrl = res
            }
        });
        return signedUrl
    }


    function uploadProfileImage(input, preview) {
        if (input.files && input.files[0]) {
            var signedUrl = callAndGetSignedUrl()
            var uploadConfirmation = imageUploadConfirmByUser()
            if (uploadConfirmation) {
                $.ajax({
                    url: signedUrl,
                    type: 'PUT',
                    async: false,
                    processData: false,
                    contentType: false,
                    data: input.files[0],
                    success: function () {
                        saveImageUrl()
                    },
                    error: function () {
                        alert("Sorry your profile pic could not be uploaded.")
                    }
                });
            }
        }
    }

    function imageUploadConfirmByUser() {
        var txt = false;
        if (confirm("Are you sure you want to update the profile image?")) {
            txt = true;
        } else {
            txt = false;
        }
        return txt;
    }


    function saveImageUrl() {
        var accounturl = "/postUserAccount";
        var userId = $(".accountuserid").text();
        var name = $("#accountUserName").val()
        var gender = "male"
        if ($("#account_male").is(":checked")) {
            gender = "male"
        } else
            gender = "female"
        var companyname = $("#accountUserCompanyName").val()

        var accountImage = $('#imagePreview-display').css('background-image');
        var checkUpdateAccountImage = true
        // var checkUpdateAccountImage = accountImage.indexOf("images/default_profile_normal.png") < 0
        var profileImgUrl = ""
        if (checkUpdateAccountImage) {
            profileImgUrl = "https://s3.ap-south-1.amazonaws.com/images-rmo-clients/profileImages/" + userId + ".jpeg";
        }

        var userInfo = {
            "userName": name,
            "userCompanyName": companyname,
            "userGender": gender,
            "userImgLink": profileImgUrl,
        }
        var data = {
            "ans": {
                "userId": userId,
                "userInfo": userInfo,
            }
        }
        $.ajax({
            url: accounturl,
            type: "POST",
            withCredentials: true,
            xhrFields: {
                withCredentials: true
            },
            data: JSON.stringify(data),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            beforeSend: function () {
                $("#accountUpdate").html("<img style='height: 50px;width: 50px;position: fixed;top: 45%;left: 45%;' src = '../images/loader-icon.gif'>");
                return true;
            },
            complete: function () {
                $("#accountUpdate").css("display", "none");
                return true;
            },
            success: function () {
                document.location.reload();
            },
            error: function (res) {
                document.location.reload();
            }
        });
    }
})

function cancelSubscription(subscriptionId) {
    showLoaderOnClick()
    $.ajax({
        url: '/cancelSubscription',
        type: "POST",
        data: {
            subscriptionId: subscriptionId
        },
        withCredentials: true,
        xhrFields: {
            withCredentials: true
        },
        json: true,
        success: function (res) {
            console.log(JSON.stringify(res))
            hideLoaderOnClick()
            if (typeof (res) != 'undefined' && res.status == "success") {
                if (typeof (res.isTrial) != 'undefined' && res.isTrial == false)
                    cancelSubscriptionNotification('fa fa-check', 'Subscription will be cancelled at the end of current billing cycle.')
                else
                    cancelSubscriptionNotification('fa fa-check', 'Successfully cancelled your subscription')

                document.location.reload();
            }
            else {
                cancelSubscriptionNotification('fa fa-times', 'Failed to cancel subscription, please contact your project manager')
            }
        },
        error: function (res) {
            console.log(JSON.stringify(res))
            hideLoaderOnClick()
            cancelSubscriptionNotification('fa fa-times', 'Failed to cancel subscription, please contact your project manager')
        }
    })
}
function revokeAccess(userHandle, socialType) {
    var url = "/revokeAccess"

    var data = {
        userHandle: userHandle,
        socialType: socialType
    }

    showLoaderOnClick()
    $.ajax({
        url: url,
        type: "POST",
        data: data,
        withCredentials: true,
        xhrFields: {
            withCredentials: true
        },
        success: function (res) {
            hideLoaderOnClick()
            repliedSuccess('fa fa-check', 'Account revoked successfully.')
            location.reload()
        },
        error: function (res) {
            hideLoaderOnClick()
            repliedSuccess('fa fa-times', 'Account could not be revoked, please contact support')
        }
    })
}

function cancelSubscriptionNotification(icon, message) {
    var offsetdata = checkScreenViewForNotify()
    $.notify({
        title: '',
        icon: icon,
        message: message,
    }, {
        type: 'info',
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutRight'
        },
        spacing: 10,
        offset: offsetdata,
        z_index: 1051,
    });
}

function repliedSuccess(icon, message) {
    var offsetdata = checkScreenViewForNotify()
    $.notify({
        title: '',
        icon: icon,
        message: message,
    }, {
        type: 'info',
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutRight'
        },
        placement: {
            from: "top",
            align: "right"
        },

        offset: offsetdata,
        spacing: 10,
        z_index: 1051,

    });
}



function openPricingPlanDirectly() {
    var params = splitIntoParams(document.location.href)
    if (params.length > 0) {
        var index = params.findIndex(function (obj) { if (obj.param === "tabname") { return true } })
        if (index > -1) {
            if (params[index].value === "accountpricingInfo") {
                $("#accountpricingInfo").click()
            }
        }
    }
}


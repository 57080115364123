
function sendToAccountPricingPage() {
  showLoaderOnClick()
  document.location.href = "/account?tabname=accountpricingInfo"
  hideLoaderOnClick()
}


function changeAuthorTypeToSourceType(authorType) {
  if (authorType === 'FACEBOOK') {
    return 'FBPOST'
  } else if (authorType === 'INSTAGRAM') {
    return 'INSTAPOST'
  } else if (authorType === 'TWITTER') {
    return 'TWEET'
  } else if (authorType === 'YOUTUBE') {
    return 'YTVIDEO'
  } else
    return authorType
}

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function showAllPostComments(postId) {
  showLoaderOnClick()
  $.ajax({
    url: "/getAllMentionComments",
    type: "POST",
    data: { postId: postId },
    success: function (res) {
      if (res && res.comments) {
        $(".allMentionBody").html('')
        var defaultImg = "'/images/default_profile_normal.png'";
        var defaultCommentImg = "'/images/fallback-img-post-2.png'"
        for (var i = 0; i < res.comments.length; i++) {
          var commentHtml = '<div class="row"> <div class="col-md-12"><ul class="list-unstyled" style="margin-bottom:0" >'
          commentHtml += '<li>'
          commentHtml += '<a href=' + res.comments[i].link + '>'
          commentHtml += '<div class="detail-header-comment">'
          commentHtml += ' <div class="detail-commentProfilePic">'
          if (res.comments[i].imageUrl) {
            commentHtml += '<img src="' + res.comments[i].imageUrl + '" onerror="this.src=' + defaultImg + '">'
          }
          else
            commentHtml += '<img src="/images/default_profile_normal.png" >'
          commentHtml += '</div>'
          commentHtml += '<div class="detail-commentProfileName"> '
          commentHtml += '<div class="detail-comment-title-box">'
          commentHtml += '<span style="font-size:12px">'
          if (res.comments[i].authorname) {
            commentHtml += '<strong>' + res.comments[i].authorname + '&nbsp</strong>'
          }
          commentHtml += res.comments[i].dateOfComment + '</span>'
          commentHtml += '</div> </div>'
          commentHtml += '<div style="overflow:hidden">'
          commentHtml += '<p class="text-left" style="color:#000;margin-bottom: 0px; overflow:hidden;font-size: 12px;"> ' + res.comments[i].text + '</p>'
          if (res.comments[i].commentImage) {
            commentHtml += '<img src="' + res.comments[i].commentImage + '" onerror="this.src=' + defaultCommentImg + '" style="height:60px">'
          }
          commentHtml += '<div class="flexdirectionRow" style="margin-top:3px;color:rgb(84,84,84)">'
          if (res.comments[i].likeCount && res.comments[i].likeCount != "0")
            commentHtml += '<div class="flexdirectionRow score text-right mention-optionType"><div class="font-size-12 mention-valueType text-left"><i class="fa fa-thumbs-o-up"></i></div><div class="font-size-12 mention-optionName"><strong> ' + res.comments[i].likeCount + '</strong></div></div>'
          if (res.comments[i].commentCount && res.comments[i].commentCount != "0") {
            console.log(" dat check" + postId.split("_")[0].toUpperCase() + " and " + postId.split("_")[0].toUpperCase() == "TWEET")
            if (postId.split("_")[0].toUpperCase() == "TWEET")
              commentHtml += '<div class="flexdirectionRow score text-right mention-optionType"><div class="font-size-12 mention-valueType text-left"><i class="fa fa-retweet"></i></div><div class="font-size-12 mention-optionName"><strong> ' + res.comments[i].commentCount + '</strong></div></div>'
            else
              commentHtml += '<div class="flexdirectionRow score text-right mention-optionType"><div class="font-size-12 mention-valueType text-left"><i class="fa fa-commenting"></i></div><div class="font-size-12 mention-optionName"><strong> ' + res.comments[i].commentCount + '</strong></div></div>'
          }
          if (res.comments[i].sentiment)
            commentHtml += '<div class="flexdirectionRow score text-right mention-optionType"><div class="font-size-12 mention-valueType text-left"><div class="sentiment-type sentiment' + res.comments[i].sentiment + '"></div></div><div class="font-size-12 mention-optionName"><strong> Sentiment</strong></div></div>'

          commentHtml += '</div>'
          commentHtml += '</div></div> </a> </li>'
          commentHtml += ' </ul> </div> </div>'
          commentHtml += '<hr style="margin:10px 0px">'
          $(".allMentionBody").append(commentHtml)
        }
        $("#showAllFiltersModal").modal("show")
      }
      hideLoaderOnClick()
      // alert("Showing Results")
    },
    error: function (err) {
      hideLoaderOnClick()
      alert("Errors")
    }
  })
}

function showAllPostDetail(userId, authorType, skip) {
  showLoaderOnClick()
  $.ajax({
    url: "/getAllMentionsDetail",
    type: "POST",
    data: { userId: userId, authorType: authorType, skipCount: skip },
    success: function (res) {
      if (res && res.posts) {
        if (skip == 0)
          $(".allMentionBody").html('')
        var defaultImg = "'/images/fallback-img-post-2.png'"
        for (var i = 0; i < res.posts.length; i++) {
          var commentHtml = '<div class="row"> <div class="col-md-12"><ul class="list-unstyled" style="margin-bottom:0" >'
          commentHtml += '<li>'
          commentHtml += '<a href=' + res.posts[i].link + '>'
          commentHtml += '<div class="detail-header-comment">'
          commentHtml += ' <div class="detail-showAllPost">'
          if (res.posts[i].isImage) {
            if (res.posts[i].imageUrl) {
              commentHtml += '<img src="' + res.posts[i].imageUrl + '" onerror="this.src=' + defaultImg + '">'
            } else
              commentHtml += '<img src="/images/fallback-img-post-2.png" >'
          } else if (res.posts[i].isVideo) {
            commentHtml += '<img src="/images/icons/videoicon.png" >'
          }
          commentHtml += '</div>'
          commentHtml += '<div class="detail-commentProfileName"> '
          commentHtml += '<div class="detail-comment-title-box">'
          commentHtml += '<span style="font-size:12px"><strong>' + res.posts[i].authorname + '&nbsp</strong>'
          commentHtml += res.posts[i].dateOfComment + '</span>'
          commentHtml += '</div> </div>'
          commentHtml += '<div style="overflow:hidden">'
          commentHtml += '<p class="text-left" style="color:#000;margin-bottom: 0px; overflow:hidden;font-size: 12px;"> ' + res.posts[i].text + '</p>'
          commentHtml += '<div class="flexdirectionRow" style="margin-top:3px;color:rgb(84,84,84)">'
          if (res.posts[i].likeCount && res.posts[i].likeCount != "0")
            commentHtml += '<div class="flexdirectionRow score text-right mention-optionType"><div class="font-size-12 mention-valueType text-left"><i class="fa fa-thumbs-o-up"></i></div><div class="font-size-12 mention-optionName"><strong> ' + res.posts[i].likeCount + '</strong></div></div>'
          if (res.posts[i].commentCount && res.posts[i].commentCount != "0")
            commentHtml += '<div class="flexdirectionRow score text-right mention-optionType"><div class="font-size-12 mention-valueType text-left"><i class="fa fa-commenting"></i></div><div class="font-size-12 mention-optionName"><strong> ' + res.posts[i].commentCount + '</strong></div></div>'
          if (res.posts[i].videoViewCount && res.posts[i].videoViewCount != "0")
            commentHtml += '<div class="flexdirectionRow score text-right mention-optionType"><div class="font-size-12 mention-valueType text-left"><i class="fa fa-eye"></i></div><div class="font-size-12 mention-optionName"><strong> ' + res.posts[i].videoViewCount + '</strong></div></div>'
          if (res.posts[i].shareCount && res.posts[i].shareCount != "0")
            commentHtml += '<div class="flexdirectionRow score text-right mention-optionType"><div class="font-size-12 mention-valueType text-left"><i class="fa fa-share-alt"></i></div><div class="font-size-12 mention-optionName"><strong> ' + res.posts[i].shareCount + '</strong></div></div>'

          commentHtml += '</div>'
          commentHtml += '</div></div> </a> </li>'
          commentHtml += ' </ul> </div> </div>'
          commentHtml += '<hr style="margin:10px 0px">'
          $(".allMentionBody").append(commentHtml)
        }
        if (res.posts.length > 0) {
          $("#morePostModal").removeClass("infl_displayNone")
          $("#showMorePosts").attr("onclick", "showAllPostDetail('" + userId + "','" + authorType + "'," + $(".detail-header-comment").length + ")");
        } else
          $("#morePostModal").addClass("infl_displayNone")
        // $("#showMorePosts").html(`<button class="btn-primary btn" onclick="showAllPostDetail("' + userId + '","' + authorType + '",' + res.posts.length + ')">Show More</button>`)
        $("#showAllFiltersModal").modal("show")
      }
      hideLoaderOnClick()
      // alert("Showing Results")
    },
    error: function (err) {
      hideLoaderOnClick()
      alert("Errors")
    }
  })
}

function convertChartToCSV(filename, chartName, chartType, rowTitle1) {
  var data, filename, link;
  var csv = "";
  if (chartType == "pie") {
    csv += pieChartToCSV(chartName)
  } else {
    csv += lineChartToCSV(chartName, chartType, rowTitle1)
  }
  if (csv == null) return;

  filename = filename || 'chart-data.csv';
  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }
  data = encodeURI(csv).replaceAll("#", "%23");
  link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', filename);
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
}

function pieChartToCSV(chartName) {

  var dataSet = chartName.data.datasets[0];
  var graphData = dataSet.data;
  var labels = chartName.data.labels;
  if (graphData == null || !graphData.length || labels == null || !labels.length) {
    return null;
  }
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  var keys = ["Attribute", "Value"];
  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  graphData.forEach(function (item, index) {
    result += labels[index];
    result += columnDelimiter;
    result += item
    result += lineDelimiter;
  });
  return result;
}

function lineChartToCSV(chartName, chartType, rowTitle1) {
  var dataSets = chartName.data.datasets;
  var keys = [""];
  if (chartType == "line") {
    keys = ["Date"]
  }
  if (rowTitle1) {
    keys = [rowTitle1]
  }
  var graphData = dataSets.map(function (d) {
    if (d.label)
      keys.push(d.label)
    else
      keys.push("Valus")
    return d.data;
  })
  var labels = chartName.data.labels;
  if (graphData == null || !graphData.length || labels == null || !labels.length) {
    return null;
  }
  const columnDelimiter = ',';
  const lineDelimiter = '\n';

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  labels.forEach(function (item, index) {
    result += chartType == "line" && moment(item).isValid() ? moment(item).format("DD-MM-YYYY") : item;
    result += columnDelimiter;
    graphData.forEach(function (key) {
      result += key[index];
      result += columnDelimiter;
    });
    result += lineDelimiter;
  });
  return result;
}
$(document).ready(function () {
  $(".contentOption").click(function () {
    $(".contentOption").removeClass("active");
    $(this).addClass("active")
  })

  $(".chooseContentPageDropDown").on("change", function () {
    var classname = $(this).children("option:selected").attr('class')
    if (classname != null) {
      var pageName = classname.split("-")[1]
      changeContentPage(pageName)
    }
  })

})

function changeContentPage(pageName) {
  showLoaderOnClick()
  var paramArray = splitIntoParams(document.location.href)
  paramArray = paramArray.filter(function (val) {
    if (val.param == "contentPage") return false
    return true
  })
  paramArray.push({
    param: "contentPage",
    value: pageName
  });
  var destUrl = mergeIntoUrl(paramArray, false);
  window.location = destUrl
}

$(document).ready(function () {
  var pageName = document.location.href;
  var params = Object.fromEntries(new URLSearchParams(location.search));
  if (pageName.indexOf("analysis/content") > -1 && params && params.contentPage && params.contentPage == "hashtagAnalysis") {
    params.userId = user._id;
    $.ajax({
      url: "/getHashtagAnalysisWordCloud",
      method: "POST",
      data: params,
      timeout: false,
      success: function (res) {
        if (res && res.status == "success") {
          if (res.data.viewData && res.data.viewData.socialMediaHashtagWordCloud && res.data.viewData.socialMediaHashtagWordCloud.wordList && res.data.viewData.socialMediaHashtagWordCloud.wordList.length > 0) {
            var hashtagHtml = '<div class="sum-des-above-graph"><span><i class="fa fa-lightbulb-o" aria-hidden="true"></i>Discover the most popular content topics and choose the right one to connect with your audience better.</span></div>'
            hashtagHtml += '<div class="flexdirectionRow" style="padding: 48px; padding-top: 24px;align-items: center; background: #fff; line-height: 1.4;"><div class="text-center content-word-cloud-div" ><div class="text-center cloud-paragraph">'
            for (var index = 0; index < res.data.viewData.socialMediaHashtagWordCloud.wordList.length; index++) {
              var word = res.data.viewData.socialMediaHashtagWordCloud.wordList[index];
              var fontSize = res.data.viewData.socialMediaHashtagWordCloud.wordsFontSize[index];
              var rotationSelect = res.data.viewData.socialMediaHashtagWordCloud.wordsRotationSelect[index];
              var colorSelect = res.data.viewData.socialMediaHashtagWordCloud.wordsColorSelect[index];
              var href = "/enterprise-mention?&projectId=" + projectId + "&mentionOwner=onlyAudiencePost&sourceType=TWEET&sourceType=INSTAPOST&sourceType=FBCOMMENT&sourceType=FBPOST&searchText=HT:" + word.text;
              hashtagHtml += '<a href=' + href + '>'
              hashtagHtml += '<p class="cloudParablock" style="font-size:' + fontSize + 'px; color:' + colorSelect + '; transform:' + rotationSelect + ';">#' + word.text + '</p>'
              hashtagHtml += '</a>'
            }
            hashtagHtml += '</div></div></div>'
            $("#hashtag-analysis-hashtag").html(hashtagHtml)
          } else {
            noDataFoundHtml("hashtag-analysis-hashtag")
          }
          if (res.data.viewData && res.data.viewData.buzzwordWordCloud && res.data.viewData.buzzwordWordCloud.wordList && res.data.viewData.buzzwordWordCloud.wordList.length > 0) {
            var buzzwordHtml = '<div class="sum-des-above-graph"><span><i class="fa fa-lightbulb-o" aria-hidden="true"></i>Discover the most popular content topics and choose the right one to connect with your audience better.</span></div>'
            buzzwordHtml += '<div class="flexdirectionRow" style="padding: 48px; padding-top: 24px;align-items: center; background: #fff; line-height: 1.4;"><div class="text-center content-word-cloud-div" ><div class="text-center cloud-paragraph">'
            for (var index = 0; index < res.data.viewData.buzzwordWordCloud.wordList.length; index++) {
              var word = res.data.viewData.buzzwordWordCloud.wordList[index];
              var fontSize = res.data.viewData.buzzwordWordCloud.wordsFontSize[index];
              var rotationSelect = res.data.viewData.buzzwordWordCloud.wordsRotationSelect[index];
              var colorSelect = res.data.viewData.buzzwordWordCloud.wordsColorSelect[index];
              var href = "/enterprise-mention?&projectId=" + projectId + "&searchText=" + word.text;
              buzzwordHtml += '<a href=' + href + '>'
              buzzwordHtml += '<p class="cloudParablock" style="font-size:' + fontSize + 'px; color:' + colorSelect + '; transform:' + rotationSelect + ';">' + word.text + '</p>'
              buzzwordHtml += '</a>'
            }
            buzzwordHtml += '</div></div></div>'
            $("#hashtag-analysis-buzzword").html(buzzwordHtml)
          } else {
            noDataFoundHtml("hashtag-analysis-buzzword")
          }
          if (res.data.brandAudienceHashtagMentionsWidget && res.data.brandAudienceHashtagMentionsWidget.brandHashtagText && res.data.brandAudienceHashtagMentionsWidget.brandHashtagText.length > 0) {
            var brandHashtagMentionsWidget = res.data.brandAudienceHashtagMentionsWidget;
            var graphHtml = '<div class = "padding-24" style="max-height:400px;">';
            graphHtml += '<div style="position: relative; display:block;min-height:200px;">';
            graphHtml += '<canvas id="numberofMentionsofYourHashtags">'
            graphHtml += '</div></div>';
            $("#hashtag-analysis-brandvsaudiencehashtag").html(graphHtml)
            makeChart('numberofMentionsofYourHashtags', brandHashtagMentionsWidget)
          } else {
            document.querySelector('#numberofMentionsofYourHashtagsDropdownDots').classList.add("infl_displayNone");
            var graphHtml = '<div class="text-center content-showtext-notfound" style="min-height:100px"><span>Shows the analysis of Hashtags you have added in Project Settings. We recommend that you add at least 5 hashtags pertaining to your brand here.</span></div>'
            $("#hashtag-analysis-brandvsaudiencehashtag").html(graphHtml)
          }
        }
      },
      error: function (res) {

      }
    });
  }
})

function makeChart(id, graphData) {
  var ultimateColors = [
    'rgb(176, 252, 204)', 'rgb(0,206,244)', 'rgb(200,228,0)', 'rgb(255,155,0)', 'rgb(175,157,200)', 'rgb(237,69,21)', 'rgb(45,107,182)', 'rgb(242,255,0)', 'rgb(218,181,69)', 'rgb(255,128,114)', 'rgb(241,0,82)', 'rgb(241,201,122)', 'rgb(127,103,191)', 'rgb(203,72,195)', 'rgb(235,247,249)', 'rgb(230,207,88)', 'rgb(248,0,118)', 'rgb(169,193,205)'
  ];
  if (id == "numberofMentionsofYourHashtags") {
    var brandHashtagMentionsWidget = graphData
    var brandHashtagChannelWiseGraph = document.getElementById('numberofMentionsofYourHashtags')
    var brandHashtagLabels = brandHashtagMentionsWidget.brandHashtagText
    var allvalues = []
    allvalues.push(brandHashtagMentionsWidget.brandHashtagBrandCount)
    allvalues.push(brandHashtagMentionsWidget.brandHashtagAudienceCount)
    var noOfHashtags = ['By You', 'By Your Audience']
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    Chart.defaults.global.tooltips.bodyFontColor = "#fff";
    Chart.defaults.global.tooltips.titleFontColor = "#fff";
    Chart.defaults.global.legend.display = true
    var BarChartData = {
      labels: brandHashtagLabels,
      datasets: []
    }
    for (var i = 0; i < noOfHashtags.length; i++) {
      var data = {
        borderColor: ultimateColors[i],
        data: allvalues[i],
        label: noOfHashtags[i],
        backgroundColor: ultimateColors[i],
      }
      BarChartData.datasets.push(data)
    }

    stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
    stackBarLayout.legend.labels.fontSize = 10
    stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
    stackBarLayout.scales.yAxes[0].ticks.fontSize = 10

    var ctx = $('#numberofMentionsofYourHashtags');
    var chartData = {
      type: 'horizontalBar',
      data: BarChartData,
      options: stackBarLayout
    }

    numberofMentionsofYourHashtagsGraph = new Chart(ctx, chartData);
    chartjsGraphsLoaded.push({
      id: "numberofMentionsofYourHashtags",
      data: chartData,
      chartObj: numberofMentionsofYourHashtagsGraph,
      expandRatio: 1
    })
    if (numberofMentionsofYourHashtagsGraph) {
      brandHashtagChannelWiseGraph.onclick = function (evt) {
        var divertUrl = getCurrentUrlParamsLink('mentionOwner')
        var activePoint = numberofMentionsofYourHashtagsGraph.getElementAtEvent(evt)[0];
        var data = activePoint._model;
        var label = data.datasetLabel;
        var codeSource = data.label
        var searchTextCheckUrl = splitIntoParams(divertUrl)
        var searchResult = searchTextCheckUrl.find(function (element) { return element.param == "searchText" });
        if (searchResult) {
          searchTextCheckUrl = searchTextCheckUrl.map(function (obj) {
            if (obj.param == "searchText") {
              obj.value += " and " + codeSource.replace("#", 'HT:')
            }
            return obj
          })
        } else {
          searchTextCheckUrl.push({
            param: "searchText",
            value: codeSource.replace("#", "HT:")
          })
        }
        var finalUrl = mergeIntoRedirectUrl(searchTextCheckUrl, "enterprise-mention")
        var divertUrl = finalUrl + '&mentionOwner=' + changeMentionOwnerText(label)
        window.open(divertUrl, '_blank')
        // document.location.href = divertUrl
      };
    }
  }

}
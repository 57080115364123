$(document).ready(function () {
    showInsightDataInfo();
    if (typeof (pageTypeName) != "undefined" && pageTypeName !== "actionable-insights" && pageTypeName !== 'manageSocialHandle')
        showProjectInsightDataInfo();
})

function showInsightDataInfo() {
    if (typeof (Storage) !== "undefined") {
        if (localStorage.getItem("notification-date") != moment().format("DD MMMM YYYY")) {
            $(".notify-bellIcon").fadeIn();
        }
    }
    $(".notification-bellicon").on('click', function () {
        if (typeof (Storage) !== "undefined") {
            localStorage.setItem("notification-date", moment().format("DD MMMM YYYY"));
            $(".notify-bellIcon").fadeOut();
        }
    })
}



function showProjectInsightDataInfo() {
    if (todaysInsights !== "undefined") {
        var projectsCounts = todaysInsights.countsByProject
        if (projectsCounts !== "undefined" && projectsCounts.length > 0) {
            for (var i = 0; i < projectsCounts.length; i++) {
                var keyStorage = "notificationinsights-date" + projectsCounts[i].projectId
                if (projectsCounts[i].count > 0) {
                    if (typeof (Storage) !== "undefined") {
                        if (localStorage.getItem(keyStorage) == null || localStorage.getItem(keyStorage) != moment().format("DD MMMM YYYY")) {
                            $(".notify-" + projectsCounts[i].projectId).fadeIn();
                        }
                    }
                    $(".notify-" + projectsCounts[i].projectId).on('click', function () {
                        $(this).fadeOut();

                    })
                }
            }
        }
    }
}



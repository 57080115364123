$(document).ready(function () {
  //=========================================1st =======================now 1st==================================

  function prettyDate(dateString) {
    //if it's already a date object and not a string you don't need this line:
    var date = new Date(dateString);
    var d = date.getDate();
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var m = monthNames[date.getMonth()];
    var y = date.getFullYear();
    return d + ' ' + m + ' ' + y;
  }

  if (document.getElementById('followersCountData') && followersCountData && followersCountDataRelative) {
    var yscore = followersCountData.map(function (obj) {
      return obj.followerCount
    })
    var xdate = followersCountData.map(function (obj) {
      return obj.notedDate
    })
    var currentText = followersCountData.map(function (obj) {
      return (prettyDate(obj.notedDate) + '<br>' + obj.followerCount)
    })
    var x2date = followersCountDataRelative.map(function (obj) {
      return obj.notedDate
    })
    var y2score = followersCountDataRelative.map(function (obj) {
      return obj.followerCount
    })
    var relativeText = followersCountDataRelative.map(function (obj) {
      return (prettyDate(obj.notedDate) + '<br>' + obj.followerCount)
    })

    var currentMaxValue = followersCountData[0].followerCount
    var currentMinValue = followersCountData[followersCountData.length - 1].followerCount
    var relativeMaxValue = followersCountDataRelative[0].followerCount
    var relativeMinValue = followersCountDataRelative[followersCountDataRelative.length - 1].followerCount

    var x = xdate
    var y1 = yscore
    var x2 = x2date
    var y2 = y2score

    var trace1 = {
      x: x,
      y: y1,
      type: 'scatter',
      xaxis: 'x',
      yaxis: 'y',
      line: {
        color: '#5054b4',
        width: 3,
        shape: 'spline'
      },
      text: currentText,
      name: influencerProfileReportData.profileData.startDateString + ' to ' + influencerProfileReportData.profileData.endDateString + ' Followers',
      marker: {
        size: 8
      },
      cliponaxis: false,
      hoverinfo: 'text',
    }
    var trace2 = {
      x: x2,
      y: y2,
      type: 'scatter',
      xaxis: 'x2',
      yaxis: 'y2',
      line: {
        color: '#cac6ec',
        width: 3,
        shape: 'spline'
      },
      text: relativeText,
      name: influencerProfileReportData.profileData.relativeStartDateString + ' to ' + influencerProfileReportData.profileData.relativeEndDateString + ' Followers',
      // fill: 'tonexty',
      marker: {
        size: 8
      },
      cliponaxis: false,
      hoverinfo: 'text',
    }
    var data = [trace1, trace2];
    var layout = {
      showlegend: true,
      legend: {
        font: {
          size: 16,
          family: 'Roboto,Helvetica Neue,Arial,sans-serif',
          color: '#000'
        },
        orientation: 'h',
        y: -.5
      },
      autosize: true,
      margin: {
        left: 10, right: 10, bottom: 10, top: 10, padding: 5
      },
      xaxis: {
        title: 'Dates', tickformat: '%d/%b', titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        // showgrid: false,
        gridwidth: false,
        tickangle: -15,
        tickfont: {
          size: 16,
          color: '#000'
        },
        zeroline: true,
        showline: true,
      },
      yaxis: {
        title: 'Current Followers',
        titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        range: [currentMinValue - 10, currentMaxValue + 10],
        rangemode: 'nonnegative',
        // showgrid: false,
        gridwidth: false,
        tickfont: {
          size: 16,
          color: '#000'
        },
      },

      xaxis2: {
        title: 'Dates', tickformat: '%d/%b', titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        visible: false,
        showgrid: false,
        gridwidth: false,
        tickangle: -15,
        tickfont: {
          size: 16,
          color: '#000'
        },
        overlaying: 'x',
        zeroline: true,
        showline: true,
      },
      yaxis2: {
        title: 'Relative Followers',
        overlaying: 'y',
        side: 'right',
        // showgrid: false,
        titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        range: [relativeMinValue - 10, relativeMaxValue + 10],
        rangemode: 'nonnegative',
        showgrid: false,
        gridwidth: false,
        tickfont: {
          size: 16,
          color: '#000'
        },
      }
    }
    Plotly.plot('followersCountData', data, layout, {
      displayModeBar: false,
      displaylogo: false,
      showTips: false
    });

  }


  if (document.getElementById('profilerReachCountGraph') && reachCountData && reachCountDataRelative) {
    var yscore = reachCountData.map(function (obj) {
      return obj.reachCount
    })
    var xdate = reachCountData.map(function (obj) {
      return obj.notedDate
    })
    var currentText = reachCountData.map(function (obj) {
      return (prettyDate(obj.notedDate) + '<br>' + obj.reachCount)
    })
    var x2date = reachCountDataRelative.map(function (obj) {
      return obj.notedDate
    })
    var y2score = reachCountDataRelative.map(function (obj) {
      return obj.reachCount
    })
    var relativeText = reachCountDataRelative.map(function (obj) {
      return (prettyDate(obj.notedDate) + '<br>' + obj.reachCount)
    })

    var currentMaxValue = Math.max(yscore)
    var currentMinValue = Math.min(yscore)
    var relativeMaxValue = Math.max(y2score)
    var relativeMinValue = Math.min(y2score)

    var x = xdate
    var y1 = yscore
    var x2 = x2date
    var y2 = y2score

    var trace1 = {
      x: x,
      y: y1,
      type: 'scatter',
      xaxis: 'x',
      yaxis: 'y',
      line: {
        color: '#3c9f00',
        width: 3,
        shape: 'spline'
      },
      fill: 'tonexty',
      text: currentText,
      name: influencerProfileReportData.profileData.startDateString + ' to ' + influencerProfileReportData.profileData.endDateString + ' Total Reach',
      marker: {
        size: 8
      },
      cliponaxis: false,
      hoverinfo: 'text',
    }
    var trace2 = {
      x: x2,
      y: y2,
      type: 'scatter',
      xaxis: 'x2',
      yaxis: 'y2',
      line: {
        color: '#b2cb82',
        width: 3,
        shape: 'spline'
      },
      text: relativeText,
      name: influencerProfileReportData.profileData.relativeStartDateString + ' to ' + influencerProfileReportData.profileData.relativeEndDateString + ' Total Reach',
      fill: 'tonexty',
      marker: {
        size: 8
      },
      cliponaxis: false,
      hoverinfo: 'text',
    }
    var data = [trace1, trace2];
    var layout = {
      showlegend: true,
      legend: {
        font: {
          size: 16,
          family: 'Roboto,Helvetica Neue,Arial,sans-serif',
          color: '#000'
        },
        orientation: 'h',
        y: -.5
      },
      autosize: true,
      margin: {
        left: 10, right: 10, bottom: 10, top: 10, padding: 5
      },
      xaxis: {
        title: 'Dates', tickformat: '%d/%b', titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        // showgrid: false,
        gridwidth: false,
        tickangle: -15,
        tickfont: {
          size: 16,
          color: '#000'
        },
        zeroline: true,
        showline: false,
      },
      yaxis: {
        title: 'Current Reach',
        titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        range: [currentMinValue - 10, currentMaxValue + 10],
        rangemode: 'nonnegative',
        // showgrid: false,
        gridwidth: false,
        tickfont: {
          size: 16,
          color: '#000'
        },
      },

      xaxis2: {
        title: 'Dates', tickformat: '%d/%b', titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        visible: false,
        showgrid: false,
        gridwidth: false,
        tickangle: -15,
        tickfont: {
          size: 16,
          color: '#000'
        },
        overlaying: 'x',
        zeroline: false,
        showline: false,
      },
      yaxis2: {
        title: 'Relative Reach',
        overlaying: 'y',
        side: 'right',
        // showgrid: false,
        titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        range: [relativeMinValue - 10, relativeMaxValue + 10],
        rangemode: 'nonnegative',
        gridwidth: false,
        tickfont: {
          size: 16,
          color: '#000'
        },
        showgrid: false,
        zeroline: false,
        showline: false,
      }
    }
    Plotly.plot('profilerReachCountGraph', data, layout, {
      displayModeBar: false,
      displaylogo: false,
      showTips: false
    });

  }




  if (document.getElementById('impressionCountGraph') && impressionCountData && impressionCountDataRelative) {
    var yscore = impressionCountData.map(function (obj) {
      return obj.impressionCount
    })
    var xdate = impressionCountData.map(function (obj) {
      return obj.notedDate
    })
    var currentText = impressionCountData.map(function (obj) {
      return (prettyDate(obj.notedDate) + '<br>' + obj.impressionCount)
    })
    var x2date = impressionCountDataRelative.map(function (obj) {
      return obj.notedDate
    })
    var y2score = impressionCountDataRelative.map(function (obj) {
      return obj.impressionCount
    })
    var relativeText = impressionCountDataRelative.map(function (obj) {
      return (prettyDate(obj.notedDate) + '<br>' + obj.impressionCount)
    })

    var currentMaxValue = Math.max(yscore)
    var currentMinValue = Math.min(yscore)
    var relativeMaxValue = Math.max(y2score)
    var relativeMinValue = Math.min(y2score)

    var x = xdate
    var y1 = yscore
    var x2 = x2date
    var y2 = y2score

    var trace1 = {
      x: x,
      y: y1,
      type: 'scatter',
      xaxis: 'x',
      yaxis: 'y',
      line: {
        color: '#b20000',
        width: 3,
        shape: 'spline'
      },
      fill: 'tonexty',
      text: currentText,
      name: influencerProfileReportData.profileData.startDateString + ' to ' + influencerProfileReportData.profileData.endDateString + ' Impressions',
      marker: {
        size: 8
      },
      cliponaxis: false,
      hoverinfo: 'text',
    }
    var trace2 = {
      x: x2,
      y: y2,
      type: 'scatter',
      xaxis: 'x2',
      yaxis: 'y2',
      line: {
        color: '#ffb7b7',
        width: 3,
        shape: 'spline'
      },
      text: relativeText,
      name: influencerProfileReportData.profileData.relativeStartDateString + ' to ' + influencerProfileReportData.profileData.relativeEndDateString + ' Impressions',
      fill: 'tonexty',
      marker: {
        size: 8
      },
      cliponaxis: false,
      hoverinfo: 'text',
    }
    var data = [trace1, trace2];
    var layout = {
      showlegend: true,
      legend: {
        font: {
          size: 16,
          family: 'Roboto,Helvetica Neue,Arial,sans-serif',
          color: '#000'
        },
        orientation: 'h',
        y: -.5
      },
      autosize: true,
      margin: {
        left: 10, right: 10, bottom: 10, top: 10, padding: 5
      },
      xaxis: {
        title: 'Dates', tickformat: '%d/%b', titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        // showgrid: false,
        gridwidth: false,
        tickangle: -15,
        tickfont: {
          size: 16,
          color: '#000'
        },
        zeroline: true,
        showline: false,
      },
      yaxis: {
        title: 'Current Impression',
        titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        range: [currentMinValue - 10, currentMaxValue + 10],
        rangemode: 'nonnegative',
        // showgrid: false,
        gridwidth: false,
        tickfont: {
          size: 16,
          color: '#000'
        },
      },

      xaxis2: {
        title: 'Dates', tickformat: '%d/%b', titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        visible: false,
        showgrid: false,
        gridwidth: false,
        tickangle: -15,
        tickfont: {
          size: 16,
          color: '#000'
        },
        overlaying: 'x',
        zeroline: false,
        showline: false,
      },
      yaxis2: {
        title: 'Relative Impression',
        overlaying: 'y',
        side: 'right',
        // showgrid: false,
        titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        range: [relativeMinValue - 10, relativeMaxValue + 10],
        rangemode: 'nonnegative',
        // showgrid: false,
        gridwidth: false,
        tickfont: {
          size: 16,
          color: '#000'
        },
        showgrid: false,
        zeroline: false,
        showline: false,
      }
    }
    Plotly.plot('impressionCountGraph', data, layout, {
      displayModeBar: false,
      displaylogo: false,
      showTips: false
    });

  }


  if (document.getElementById('likesAndCommentsGraph') && avgCommentsData && avgLikesData) {
    var yscore = avgLikesData.map(function (obj) {
      return obj.likeCount
    })
    var xdate = avgLikesData.map(function (obj) {
      return obj.postDate
    })
    var currentText = avgLikesData.map(function (obj) {
      return (prettyDate(obj.postDate) + '<br>' + obj.likeCount)
    })
    var x2date = avgCommentsData.map(function (obj) {
      return obj.postDate
    })
    var y2score = avgCommentsData.map(function (obj) {
      return obj.commentCount
    })
    var relativeText = avgCommentsData.map(function (obj) {
      return (prettyDate(obj.postDate) + '<br>' + obj.commentCount)
    })

    var currentMaxValue = Math.max(yscore)
    var currentMinValue = Math.min(yscore)
    var relativeMaxValue = Math.max(y2score)
    var relativeMinValue = Math.min(y2score)

    var x = xdate
    var y1 = yscore
    var x2 = x2date
    var y2 = y2score

    var trace1 = {
      x: x,
      y: y1,
      type: 'scatter',
      xaxis: 'x',
      yaxis: 'y',
      line: {
        color: '#b0a62d',
        width: 3,

      },
      text: currentText,
      name: 'Likes on Posts',
      marker: {
        size: 8
      },
      cliponaxis: false,
      hoverinfo: 'text',
    }
    var trace2 = {
      x: x2,
      y: y2,
      type: 'scatter',
      xaxis: 'x2',
      yaxis: 'y2',
      line: {
        color: '#868cd3',
        width: 3,
      },
      text: relativeText,
      name: 'Comments on Posts',
      // fill: 'tonexty',
      marker: {
        size: 8
      },
      cliponaxis: false,
      hoverinfo: 'text',
    }
    var data = [trace1, trace2];
    var layout = {
      showlegend: true,
      legend: {
        font: {
          size: 16,
          family: 'Roboto,Helvetica Neue,Arial,sans-serif',
          color: '#000'
        },
        orientation: 'h',
        y: -.5
      },
      autosize: true,
      margin: {
        left: 10, right: 10, bottom: 10, top: 10, padding: 5
      },
      xaxis: {
        title: 'Dates', tickformat: '%d/%b', titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        // showgrid: false,
        gridwidth: false,
        tickangle: -15,
        tickfont: {
          size: 16,
          color: '#000'
        },
        zeroline: true,
        showline: false,
      },
      yaxis: {
        title: 'Likes',
        titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        range: [currentMinValue - 10, currentMaxValue + 10],
        rangemode: 'nonnegative',
        // showgrid: false,
        gridwidth: false,
        tickfont: {
          size: 16,
          color: '#000'
        },
      },

      xaxis2: {
        title: 'Dates', tickformat: '%d/%b', titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        visible: false,
        showgrid: false,
        gridwidth: false,
        tickangle: -15,
        tickfont: {
          size: 16,
          color: '#000'
        },
        overlaying: 'x',
        zeroline: false,
        showline: false,
      },
      yaxis2: {
        title: 'Comments',
        overlaying: 'y',
        side: 'right',
        // showgrid: false,
        titlefont: {
          family: 'Arial, sans-serif',
          size: 16,
          color: '#000'
        },
        range: [relativeMinValue - 10, relativeMaxValue + 10],
        rangemode: 'nonnegative',
        // showgrid: false,
        gridwidth: false,
        tickfont: {
          size: 16,
          color: '#000'
        },
        showgrid: false,
        zeroline: false,
        showline: false,
      }
    }
    Plotly.plot('likesAndCommentsGraph', data, layout, {
      displayModeBar: false,
      displaylogo: false,
      showTips: false
    });

  }



})
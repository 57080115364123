var geoSummaryGraphData = [];
var cityMapGraphClickFunction;
var stateMapGraphClickFunction;
var ultimateColors = [
    ['rgb(176, 252, 204)', 'rgb(0,206,244)', 'rgb(200,228,0)', 'rgb(255,155,0)', 'rgb(175,157,200)', 'rgb(237,69,21)', 'rgb(45,107,182)', 'rgb(242,255,0)', 'rgb(218,181,69)', 'rgb(255,128,114)', 'rgb(241,0,82)', 'rgb(241,201,122)', 'rgb(127,103,191)', 'rgb(203,72,195)', 'rgb(235,247,249)', 'rgb(230,207,88)', 'rgb(248,0,118)', 'rgb(169,193,205)'],
    ['rgb(213,215,0)', 'rgb(213,215,0)'],
];
var LineChartDemo;
var barChartMentionsOverSourceAndSentiment;
var mentionsOverTimeBySourceLineChart;
var mentionsOverTimeBySourceBarChart;
var mentionsOverTimeBySentimentBarGraph
var shareOfVoicePieChart;
var popularHashtagsBarChart;
function stringDivider(str, width, spaceReplacer) {
    if (str.length > width) {
        var p = width
        for (; p > 0 && str[p] != ' '; p--) { }
        if (p > 0) {
            var left = str.substring(0, p);
            var right = str.substring(p + 1);
            return left + spaceReplacer + " " + stringDivider(right, width, spaceReplacer);
        } else {
            var left = str.substring(0, width);
            var right = str.substring(width - 1 + 1)
            return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
        }
    }
    return str;
}
function fixWordBreaks(stringArr, maxLen) {
    var newArr = []
    for (var i = 0; i < stringArr.length; i++) {
        var term = stringArr[i]
        newArr.push(stringDivider(term, maxLen, "<br>"))
    }
    return newArr
}

var lineChartLayout = {
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            fontSize: 10,
            fontColor: '#000',
            boxWidth: 10,
        },
        fullWidth: false
    },
    elements: {
        line: {
            //  tension: 0,// disables bezier curves
            fill: false
        }
    },
    scales: {
        xAxes: [{
            labelFontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
            ticks: {
                fontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
                fontSize: 9,
                fontColor: '#000',
            },
            gridLines: {
                drawborder: false,
                display: false
            },
            scaleLabel: {
                display: true,
                fontSize: 10,
                fontColor: '#000'
            }
        }],
        yAxes: [{
            labelFontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
            ticks: {
                fontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
                fontSize: 9,
                fontColor: '#000',
                beginAtZero: true,
            },
            gridLines: {
                drawborder: false,
                display: false
            },
            scaleLabel: {
                display: true,
                labelString: 'Counts',
                fontSize: 10,
                fontColor: '#000',
            },
        }],
    },
    plugins: {
        outlabels: false,
    },
}


var doughnutChartLayout = {
    maintainAspectRatio: false,
    plugins: {
        outlabels: false,
    },
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            fontSize: 10,
            fontColor: '#000',
            boxWidth: 10,
        },
    },
    hover: {
        onHover: function (e) {
            var point = this.getElementAtEvent(e);
            if (point.length) e.target.style.cursor = 'pointer';
            else e.target.style.cursor = 'default';
        }
    },

    rotation: Math.PI * -0.5,
    cutoutPercentage: 35,
    tooltips: {
        enabled: true,
        callbacks: {
            title: function () {
                return "Share Of Voice";
            },
            label: function (tooltipItems, data) {
                return data.labels[tooltipItems.index];
            },
            afterBody: function (tooltipItems, data) {
                return "Total Counts:" + data.datasets[0].data[tooltipItems[0].index];
            },
        },
        titleFontStyle: 'normal',
    }
}

var ScatterChartLayout = {
    maintainAspectRatio: false,
    legend: {
        display: false,
        position: 'bottom',
        labels: {
            fontSize: 10,
            fontColor: '#000',
            boxWidth: 10,
        },
        fullWidth: false
    },
    layout: {
        padding: {
            // left: 50,
            right: 10,
            // top: 0,
            // bottom: 0
        }
    },
    scales: {
        xAxes: [{
            labelFontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
            ticks: {
                fontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
                fontSize: 9,
                fontColor: '#000',
            },
            gridLines: {
                drawborder: false,
                display: false
            },
            scaleLabel: {
                display: true,
                fontSize: 10,
                fontColor: '#000'
            }
        }],
        yAxes: [{
            labelFontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
            ticks: {
                fontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
                fontSize: 9,
                fontColor: '#000',
                beginAtZero: true,
                // padding: 5
            },
            gridLines: {
                drawborder: false,
                display: false
            },
            scaleLabel: {
                display: true,
                labelString: 'Counts',
                fontSize: 10,
                fontColor: '#000',
            },
        }],
    },
    plugins: {
        outlabels: false,
    },
    responsive: true, // Instruct chart js to respond nicely.
};

var BarChartLayout = {
    maintainAspectRatio: false,
    legend: {
        display: true,
        position: 'bottom',
        labels: {
            fontSize: 12,
            fontColor: '#000',
            boxWidth: 10,
        },
        fullWidth: false
    },

    tooltips: {
        titleFontStyle: 'normal',
        xPadding: 8,
        yPadding: 8,
        bodyFontSize: 14,
        titleFontSize: 14,
    },
    elements: {
        line: {
            //  tension: 0,// disables bezier curves
            fill: false
        }
    },
    hover: {
        onHover: function (e) {
            var point = this.getElementAtEvent(e);
            if (point.length) e.target.style.cursor = 'pointer';
            else e.target.style.cursor = 'default';
        }
    },
    scales: {
        xAxes: [{
            labelFontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
            ticks: {
                fontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
                fontSize: 12,
                fontColor: '#000',
                fontStyle: "normal",
                fontWeight: "lighter",
            },
            gridLines: {
                drawborder: false,
                display: false
            },
            stacked: true,
            maxBarThickness: 40,
        }],
        yAxes: [{
            labelFontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
            ticks: {
                fontFamily: 'Roboto,Helvetica Neue,Arial,sans-serif',
                beginAtZero: true,
                fontSize: 12,
                fontColor: '#000',
                fontStyle: "normal",
                fontWeight: "lighter",
            },
            gridLines: {
                drawborder: false,
                display: false
            },
            stacked: true,
            maxBarThickness: 40,
        }]
    },
    plugins: {
        outlabels: false,
    },
}

$(document).ready(function () {

    if (document.getElementById('scoreMeterId')) {
        demo.scoreMeter(metervalue, "scoreMeterId")
    }

    var modeBarButtons = [
        ["toImage"]
    ];

    if (document.getElementById('scoreMeterId')) {
        demo.scoreMeter(metervalue, "scoreMeterId")
    }


    var InfluencerColor = [
        ['#c8e400', '#00ccf7', '#f92000']
    ];
    //=========================================1st =======================now 1st==================================

    if (document.getElementById('mentionsOverTimeBySentiment') && typeof (mentionsobj) !== "undefined" && mentionsobj) {
        var lineChartData = {
            labels: mentionsobj.dates,
            datasets: [{
                borderColor: '#c8e400',
                data: mentionsobj.positiveCount,
                label: "Positive Mentions",
                backgroundColor: '#c8e400',
                lineThickness: 0,

            }, {
                data: mentionsobj.negativeCount,
                borderColor: '#f92000',
                label: "Negative Mentions",
                backgroundColor: '#f92000',
            }, {
                data: mentionsobj.neutralCount,
                borderColor: '#00ccf7',
                label: "Neutral Mentions",
                backgroundColor: '#00ccf7',
            }, {
                data: mentionsobj.totalCount,
                borderColor: '#00FFFF',
                label: "Total Mentions",
                backgroundColor: '#00FFFF',
            }]
        }

        var sentimentChartLayout = JSON.parse(JSON.stringify(lineChartLayout))
        sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
        sentimentChartLayout.scales.xAxes[0].type = 'time'
        sentimentChartLayout.scales.xAxes[0].time = {
            unit: 'day',
            displayFormats: {
                day: 'MMM D'
            },
            round: 'day',
        }
        var ctx = $('#mentionsOverTimeBySentiment');
        var graphData = {
            type: 'line',
            data: lineChartData,
            options: sentimentChartLayout,
        }
        graphData['options']['title'] = {
            display: true,
            text: 'Sentiment Wise',
            fontStyle: "normal",
            fontWeight: "lighter",
        }
        LineChartDemo = new Chart(ctx, graphData);
        chartjsGraphsLoaded.push({
            id: "mentionsOverTimeBySentiment",
            data: graphData,
            chartObj: LineChartDemo,
            expandRatio: 1
        })
        LineChartDemo.resize();
    }


    if (document.getElementById('mentionsOverTimeBySource') && mentionsSourceobj) {
        var lineChartData1 = {
            labels: mentionsobj.dates,
            datasets: [{
                borderColor: '#00b1b4',
                data: mentionsSourceobj.webCount,
                label: "Web Mentions ",
                backgroundColor: '#00b1b4',

            }, {
                data: mentionsSourceobj.socialCount,
                borderColor: '#ee7300',
                label: "Social Mentions",
                backgroundColor: '#ee7300',
            }]
        }
        var sourceChartLayout = JSON.parse(JSON.stringify(lineChartLayout))
        sourceChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsSourceobj.dateFrom + '-' + mentionsSourceobj.dateTo + ')'
        sourceChartLayout.scales.xAxes[0].type = 'time'
        sourceChartLayout.scales.xAxes[0].time = {
            unit: 'day',
            displayFormats: {
                day: 'MMM D'
            },
            round: 'day',
        }
        var ctx = $('#mentionsOverTimeBySource');
        var graphData = {
            type: 'line',
            data: lineChartData1,
            options: sourceChartLayout
        }
        graphData['options']['title'] = {
            display: true,
            text: 'Performance of Web vs Social Channels',
            fontStyle: "normal",
            fontWeight: "lighter",
        }
        mentionsOverTimeBySourceLineChart = new Chart(ctx, graphData);

        chartjsGraphsLoaded.push({
            id: "mentionsOverTimeBySource",
            data: graphData,
            chartObj: mentionsOverTimeBySourceLineChart,
            expandRatio: 1
        })
    }

    if (document.getElementById('mentionsOverTimeBySourceBarGraph') && mentionsSourceobj) {

        var socialMention = document.getElementById('mentionsOverTimeBySourceBarGraph')
        var urlLabel = mentionsobj.dates;
        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        // Chart.defaults.global.title = {
        //     text: 'Performance of Web vs Social Channels',
        //     position: 'top',
        //     display: true,
        //     fontSize: 12,
        // }
        Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: mentionsobj.dates,
            datasets: [{
                borderColor: 'rgb(176, 252, 204)',
                data: mentionsSourceobj.webCount,
                label: "Web Mention",
                backgroundColor: 'rgb(176, 252, 204)',
            }, {
                data: mentionsSourceobj.socialCount,
                borderColor: 'rgb(0,206,244)',
                label: "Social Mention",
                backgroundColor: 'rgb(0,206,244)',
            }]
        }
        var stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
        stackBarLayout.scales.xAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Date of Mention (' + mentionsSourceobj.dateFrom + '-' + mentionsSourceobj.dateTo + ')',
            fontSize: 9,
            fontColor: '#000',
            position: 'top'
        }
        // scaleLabel.labelString = 'Date of Mention (' + mentionsSourceobj.dateFrom + '-' + mentionsSourceobj.dateTo + ')'
        stackBarLayout.legend.labels.fontSize = 10
        stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
        stackBarLayout.scales.yAxes[0].ticks.fontSize = 10
        stackBarLayout.scales.xAxes[0].type = 'time'
        stackBarLayout.scales.xAxes[0].time = {
            unit: 'month',
            displayFormats: {
                month: 'MMM YYYY'
            },
            round: 'month',
        }
        stackBarLayout.tooltips.callbacks = {
            title: function () { }
        }
        // stackBarLayout['title'] = {
        //     display: true,
        //     text: 'Performance of Web vs Social Channels',
        //     position: 'top'
        // }
        var ctx = $('#mentionsOverTimeBySourceBarGraph');
        var chartData = {
            type: 'bar',
            // title: 'Performance of Web vs Social Channels',
            data: BarChartData,
            options: stackBarLayout
        }


        mentionsOverTimeBySourceBarChart = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "mentionsOverTimeBySourceBarGraph",
            data: chartData,
            chartObj: mentionsOverTimeBySourceBarChart,
            expandRatio: 1
        })
        if (mentionsOverTimeBySourceBarChart) {
            socialMention.onclick = function (evt) {
                var divertUrl = getCurrentUrlParamsLink('dateRange')
                var dates = dashboardDateRange.split('-')
                var activePoint = mentionsOverTimeBySourceBarChart.getElementAtEvent(evt)[0];
                var data = activePoint._model;
                var label = data.datasetLabel;
                var startDate = new Date(data.label);
                dates[0] = dates[0].split('/')[2] + "/" + dates[0].split('/')[1] + "/" + dates[0].split('/')[0]
                dates[1] = dates[1].split('/')[2] + "/" + dates[1].split('/')[1] + "/" + dates[1].split('/')[0]
                var firstDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1).toLocaleDateString();
                var lastDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1).toLocaleDateString();
                firstDate = firstDate.split('/')[2] + "/" + firstDate.split('/')[1] + "/" + firstDate.split('/')[0]
                lastDate = lastDate.split('/')[2] + "/" + lastDate.split('/')[1] + "/" + lastDate.split('/')[0]
                if (new Date(firstDate) < new Date(dates[0]))
                    firstDate = dates[0]
                if (new Date(lastDate) > new Date(dates[1]))
                    lastDate = dates[1]
                firstDate = new Date(firstDate).toLocaleDateString()
                lastDate = new Date(lastDate).toLocaleDateString()
                var sourceType = []
                var codeSource = ""
                if (label === "Social Mention") {
                    sourceType = ['TWEET', 'INSTAPOST', 'FBCOMMENT', 'FBPOST', "YTCOMMENT"]
                    for (var i = 0; i < sourceType.length; i++) {
                        codeSource = codeSource + "&sourceType=" + sourceType[i]
                    }
                }
                else {
                    sourceType = ['REVIEW', 'ARTICLE', 'YTVIDEO']
                    for (var i = 0; i < sourceType.length; i++) {
                        codeSource = codeSource + "&sourceType=" + sourceType[i]
                    }
                }
                var url = divertUrl + "&dateRange=" + firstDate + "-" + lastDate + codeSource;
                document.location.href = url
            };
        }
    }


    if (document.getElementById('mentionsOverTimeBySentimentBarGraph') && mentionsobj) {

        var socialMention = document.getElementById('mentionsOverTimeBySentimentBarGraph')
        var urlLabel = mentionsobj.dates;
        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        Chart.defaults.global.title = 'Performance of Web vs Social Channels'
        Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: mentionsobj.dates,
            datasets: [{
                borderColor: '#c8e400',
                data: mentionsobj.positiveCount,
                label: "Positive Mentions",
                backgroundColor: '#c8e400',
                lineThickness: 0,

            }, {
                data: mentionsobj.negativeCount,
                borderColor: '#f92000',
                label: "Negative Mentions",
                backgroundColor: '#f92000',
            }, {
                data: mentionsobj.neutralCount,
                borderColor: '#00ccf7',
                label: "Neutral Mentions",
                backgroundColor: '#00ccf7',
            }, {
                data: mentionsobj.totalCount,
                borderColor: '#00FFFF',
                label: "Total Mentions",
                backgroundColor: '#00FFFF',
            }]
        }

        var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
        sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
            display: true,
            labelString: 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')',
            fontSize: 9,
            fontColor: '#000'
        }
        sentimentChartLayout.legend.labels.fontSize = 10
        sentimentChartLayout.scales.xAxes[0].ticks.fontSize = 10
        sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 10
        sentimentChartLayout.scales.xAxes[0].type = 'time'
        sentimentChartLayout.scales.xAxes[0].time = {
            unit: 'month',
            displayFormats: {
                month: 'MMM YYYY'
            },
            round: 'month',
        }
        sentimentChartLayout.tooltips.callbacks = {
            title: function () { }
        }

        // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
        var ctx = $('#mentionsOverTimeBySentimentBarGraph');
        var chartData = {
            type: 'bar',
            data: BarChartData,
            options: sentimentChartLayout
        }
        mentionsOverTimeBySentimentBarGraph = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "mentionsOverTimeBySentimentBarGraph",
            data: chartData,
            chartObj: mentionsOverTimeBySentimentBarGraph,
            expandRatio: 1
        })
        if (mentionsOverTimeBySentimentBarGraph) {
            socialMention.onclick = function (evt) {
                var divertUrl = getCurrentUrlParamsLink('dateRange')
                var dates = dashboardDateRange.split('-')
                dates[0] = dates[0].split('/')[2] + "/" + dates[0].split('/')[1] + "/" + dates[0].split('/')[0]
                dates[1] = dates[1].split('/')[2] + "/" + dates[1].split('/')[1] + "/" + dates[1].split('/')[0]
                var activePoint = mentionsOverTimeBySentimentBarGraph.getElementAtEvent(evt)[0];
                var data = activePoint._model;
                var label = data.datasetLabel;
                var startDate = new Date(data.label);
                var firstDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1).toLocaleDateString();
                var lastDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1).toLocaleDateString();
                firstDate = firstDate.split('/')[2] + "/" + firstDate.split('/')[1] + "/" + firstDate.split('/')[0]
                lastDate = lastDate.split('/')[2] + "/" + lastDate.split('/')[1] + "/" + lastDate.split('/')[0]
                if (new Date(firstDate) < new Date(dates[0]))
                    firstDate = dates[0]
                if (new Date(lastDate) > new Date(dates[1]))
                    lastDate = dates[1]
                firstDate = new Date(firstDate).toLocaleDateString()
                lastDate = new Date(lastDate).toLocaleDateString()
                var sourceType = []
                var codeSource = ""
                if (label === "Positive Mentions") {
                    codeSource = '&sentiment=POSITIVE'
                }
                else if (label === "Negative Mentions") {
                    codeSource = '&sentiment=NEGATIVE'
                }
                else if (label === "Neutral Mentions") {
                    codeSource = '&sentiment=NEUTRAL'
                }
                var url = divertUrl + "&dateRange=" + firstDate + "-" + lastDate + codeSource;
                document.location.href = url
            };
        }
    }


    if (document.getElementById('MentionsonNonSocialMediaPlatforms') && nsmPlatformDistributionPieChart && nsmPlatformDistributionPieChart != null) {
        var sourceNames = nsmPlatformDistributionPieChart.sourceName
        var lables = []
        for (var i = 0; i < sourceNames.length; i++) {
            lables.push(sourceNames[i])
        }
        var PieChartData = {
            labels: lables,
            datasets: [{
                data: nsmPlatformDistributionPieChart.sourceValue,
                backgroundColor: ultimateColors[0],
                borderColor: ultimateColors[0],
            }]
        }
        var ctx = $('#MentionsonNonSocialMediaPlatforms');
        var graphData = {
            type: 'doughnut',
            data: PieChartData,
            options: JSON.parse(JSON.stringify(doughnutChartLayout)),
        }
        shareOfVoicePieChart = new Chart(ctx, graphData)
        chartjsGraphsLoaded.push({
            id: "MentionsonNonSocialMediaPlatforms",
            data: graphData,
            chartObj: shareOfVoicePieChart,
            expandRatio: 2
        })
        document.getElementById("MentionsonNonSocialMediaPlatforms").onclick = function (event) {
            showLoaderOnClick()
            var activePoint = shareOfVoicePieChart.getElementAtEvent(event)[0]["_index"];
            var url = '/enterprise-mention?projectId=' + projectId + '&website=' + lables[activePoint];
            // window.open(url);
            document.location.href = url
        };
    }

    if (document.getElementById('PopularHashtagsUsedbyYourAudience') && popularAudienceHashtagsWidget && popularAudienceHashtagsWidget != null) {
        var PopularHashtags = document.getElementById('PopularHashtagsUsedbyYourAudience')
        var ctx = $('#PopularHashtagsUsedbyYourAudience');
        var labels = popularAudienceHashtagsWidget.hashtagsText;
        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        var graphData = {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    borderColor: '#00b1b4',
                    data: popularAudienceHashtagsWidget.hashtagsCount,
                    backgroundColor: '#00b1b4',
                }]
            },
            options: JSON.parse(JSON.stringify(BarChartLayout)),
        }
        graphData['options']['legend']['display'] = false
        popularHashtagsBarChart = new Chart(ctx, graphData)
        chartjsGraphsLoaded.push({
            id: "PopularHashtagsUsedbyYourAudience",
            data: graphData,
            chartObj: popularHashtagsBarChart,
            expandRatio: 2
        })
        PopularHashtags.onclick = function hashtagsclick(evt) {
            showLoaderOnClick()
            var activeBarPoints = popularHashtagsBarChart.getElementsAtEvent(evt)[0]["_index"];
            var url = '/enterprise-mention?projectId=' + projectId + '&searchText=HT:' + encodeURI((labels[activeBarPoints]).replace("#", "") + "&mentionOwner=onlyAudiencePost");
            // window.open(url);
            document.location.href = url
        };
    }


    if (document.getElementById('mentionsOverSocial') && mentionsOverSocialObj) {
        var socialMention = document.getElementById('mentionsOverSocial');
        var urlLabel = mentionsOverSocialObj.words.sourceList;
        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: mentionsOverSocialObj.words.sourceList,
            datasets: [{
                borderColor: '#c8e400',
                data: mentionsOverSocialObj.words.positiveMentions,
                label: "Positive",
                backgroundColor: '#c8e400',
            }, {
                data: mentionsOverSocialObj.words.negativeMentions,
                borderColor: '#f92000',
                label: "Negative ",
                backgroundColor: '#f92000',
            }, {
                data: mentionsOverSocialObj.words.neutralMentions,
                borderColor: '#00ccf7',
                label: "Neutral",
                backgroundColor: '#00ccf7',
            }]
        }
        var ctx = $('#mentionsOverSocial');
        var chartData = {
            type: 'horizontalBar',
            data: BarChartData,
            options: JSON.parse(JSON.stringify(BarChartLayout))
        }

        barChartMentionsOverSourceAndSentiment = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "mentionsOverSocial",
            data: chartData,
            chartObj: barChartMentionsOverSourceAndSentiment,
            expandRatio: 1
        })
        socialMention.onclick = function (evt) {
            var activePoint = barChartMentionsOverSourceAndSentiment.getElementAtEvent(evt)[0];
            var data = activePoint._model;
            var currentSentiment = data.datasetLabel;
            var barpoint = data.label
            var codeSource = changeSourceName(barpoint)
            var url = '/enterprise-mention?&projectId=' + projectId + '&sourceType=' + codeSource + "&sentiment=" + currentSentiment.toUpperCase();
            // window.open(url);
            document.location.href = url
        };
    }
    if (document.getElementById('mentionsOverSocialMobile') && mentionsOverSocialMobile) {
        var socialMention = document.getElementById('mentionsOverSocialMobile');
        var urlLabel = mentionsOverSocialObj.words.sourceList;
        Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
        Chart.defaults.global.tooltips.bodyFontColor = "#fff";
        Chart.defaults.global.tooltips.titleFontColor = "#fff";
        Chart.defaults.global.legend.display = true
        var BarChartData = {
            labels: mentionsOverSocialObj.words.sourceList,
            datasets: [{
                borderColor: '#c8e400',
                data: mentionsOverSocialObj.words.positiveMentions,
                label: "Positive",
                backgroundColor: '#c8e400',
            }, {
                data: mentionsOverSocialObj.words.negativeMentions,
                borderColor: '#f92000',
                label: "Negative ",
                backgroundColor: '#f92000',
            }, {
                data: mentionsOverSocialObj.words.neutralMentions,
                borderColor: '#00ccf7',
                label: "Neutral",
                backgroundColor: '#00ccf7',
            }]
        }
        var ctx = $('#mentionsOverSocialMobile');
        var chartData = {
            type: 'bar',
            data: BarChartData,
            options: JSON.parse(JSON.stringify(BarChartLayout))
        }

        barChartMentionsOverSourceAndSentiment = new Chart(ctx, chartData);
        chartjsGraphsLoaded.push({
            id: "mentionsOverSocialMobile",
            data: chartData,
            chartObj: barChartMentionsOverSourceAndSentiment,
            expandRatio: 1
        })
        socialMention.onclick = function (evt) {
            showLoaderOnClick()
            var activePoint = barChartMentionsOverSourceAndSentiment.getElementAtEvent(evt)[0];
            var data = activePoint._model;
            var currentSentiment = data.datasetLabel;
            var barpoint = data.label
            var codeSource = changeSourceName(barpoint)
            var url = '/enterprise-mention?&projectId=' + projectId + '&sourceType=' + codeSource + "&sentiment=" + currentSentiment.toUpperCase();
            // window.open(url);
            document.location.href = url
        };
    }
})

function cityLocationIndiaChartFunction() {
    if (document.getElementById('cityLocationIndiaChart') && bagOfCitiesData) {

        var regionDataKeywords = [
            ["LAT", "LONG", "DESCRIPTION", "VALUE"],
        ];
        if (bagOfCitiesData.tokens && bagOfCitiesData.tokens.length > 0) {
            for (var j = 0; j < bagOfCitiesData.tokens.length; j++) {
                if (bagOfCitiesData.tokens[j].loc)
                    regionDataKeywords.push([bagOfCitiesData.tokens[j].loc.lat, bagOfCitiesData.tokens[j].loc.long, bagOfCitiesData.tokens[j].loc.city, bagOfCitiesData.tokens[j].count])
            }
        }
        var data = google.visualization.arrayToDataTable(
            regionDataKeywords
        );
        var options = {
            region: trendsGraphCountryCode,
            colorAxis: {
                colors: ['#ddd', '#0079c0']
            },
            displayMode: 'markers',
            domain: trendsGraphCountryCode,
            resolution: "provinces"
        };

        var chart = new google.visualization.GeoChart(document.getElementById('cityLocationIndiaChart'));
        cityMapGraphClickFunction = function () {

            var selection = chart.getSelection();
            if (selection != null && selection.length > 0) {
                showLoaderOnClick()
                var goToUrl = '/enterprise-mention?projectId=' + projectId + '&locationCity='
                for (var i = 0; i < selection.length; i++) {
                    var item = selection[i];
                    if (item.row != null) {
                        var selectState = regionDataKeywords[item.row + 1]
                        goToUrl += selectState[2];
                    }
                }
                document.location.href = goToUrl
            }
        }
        geoSummaryGraphData.push({
            id: 'cityLocationIndiaChart',
            options: options,
            data: data,
            location: 'locationCity',
            regionDataKeywords: regionDataKeywords,
            mapGraphClickFunction: cityMapGraphClickFunction
        })
        chart.draw(data, options);
        google.visualization.events.addListener(chart, 'select', cityMapGraphClickFunction);

    }
}




function statesLocationIndiaChartFunction() {
    if (document.getElementById('statesLocationIndiaChart') && bagOfStatesData) {
        var regionDataKeywords = [
            ['StateCode', 'StateName', 'Value']
        ];
        if (bagOfStatesData.tokens && bagOfStatesData.tokens.length > 0) {
            for (var j = 0; j < bagOfStatesData.tokens.length; j++) {
                if (bagOfStatesData.tokens[j].loc) {
                    if (bagOfStatesData.tokens[j].loc.stateCode == "OD")
                        bagOfStatesData.tokens[j].loc.stateCode = "OR"
                    if (bagOfStatesData.tokens[j].loc.state == "jammu and kashmir") {
                        bagOfStatesData.tokens[j].loc.countryCode = "IN"
                        bagOfStatesData.tokens[j].loc.stateCode = "JK"
                    }
                    regionDataKeywords.push([bagOfStatesData.tokens[j].loc.countryCode + "-" + bagOfStatesData.tokens[j].loc.stateCode, bagOfStatesData.tokens[j].loc.state, bagOfStatesData.tokens[j].count])
                }
            }
        }
        var data = google.visualization.arrayToDataTable(
            regionDataKeywords
        );
        var options = {
            region: trendsGraphCountryCode,
            colorAxis: {
                colors: ['#ddd', '#0079c0']
            },
            displayMode: 'regions',
            domain: trendsGraphCountryCode,
            resolution: "provinces"
        };

        var chart = new google.visualization.GeoChart(document.getElementById('statesLocationIndiaChart'));
        stateMapGraphClickFunction = function () {
            var selection = chart.getSelection();
            if (selection != null && selection.length > 0) {
                showLoaderOnClick()
                var goToUrl = '/enterprise-mention?projectId=' + projectId + '&locationState='
                for (var i = 0; i < selection.length; i++) {
                    var item = selection[i];
                    if (item.row != null) {
                        var selectState = regionDataKeywords[item.row + 1]
                        goToUrl += selectState[1];
                    }
                }
                document.location.href = goToUrl
            }
        }
        geoSummaryGraphData.push({
            id: 'statesLocationIndiaChart',
            options: options,
            data: data,
            location: 'locationState',
            regionDataKeywords: regionDataKeywords,
            mapGraphClickFunction: stateMapGraphClickFunction
        })
        chart.draw(data, options);
        google.visualization.events.addListener(chart, 'select', stateMapGraphClickFunction);

    }
}

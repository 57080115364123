Event.prototype._maxListeners = 100
$(document).ready(function () {

    // use for see more filters
    if (document.getElementById('modalResetButton') || document.getElementById('websiteModelResetButton') || document.getElementById('subTopicsFilterModalResetButton') || document.getElementById('locationFilterModalResetButton') || document.getElementById('locationCityFilterModalResetButton') || document.getElementById('locationStateFilterModalResetButton') || document.getElementById('languageFilterModalResetButton') || document.getElementById('websiteFilterModalResetButton') || document.getElementById('locationRegionFilterModalResetButton')) {
        $("#modalResetButton,#websiteModelResetButton,#subTopicsFilterModalResetButton,#locationFilterModalResetButton,#locationCityFilterModalResetButton,#locationStateFilterModalResetButton,#languageFilterModalResetButton,#websiteFilterModalResetButton,#locationRegionFilterModalResetButton").on("click", function () {
            $(".modal-dialog input:checked").each(function () {
                $(this).prop("checked", false);
            });
        });
    }



})
function chartDataOptionDropdown(thisChart) {
    $(thisChart).toggle('bounce')
}
function checkAllSubTopic(topicName, topicId) {
    if ($(topicId).prop("checked") === true) {
        $(topicId).prop('checked', this.checked);
        $(topicName).prop("checked", true);
    }
    else {
        $(topicName).prop("checked", false);
    }
}

function summaryPostListClicked(activeId) {
    $(".summaryPostListTitle1, .summaryPostListTitle2").removeClass('summaryPostListActive')
    $(activeId).addClass('summaryPostListActive')
}

function seePostDetail(description, chevron, postview, topArrow) {
    var check = $(postview).css("display")
    $(description).hide()
    $(chevron).removeClass("fa-chevron-up")
    $(chevron).addClass("fa-chevron-down")
    if (check == "none") {
        $(postview).toggle(function () {
            $(topArrow).toggleClass("fa-chevron-down")
            $(topArrow).toggleClass("fa-chevron-up")
        })
    }
}



//================We are hungry for your feedback! FeedBack Form Submit Validation =========================
$(document).ready(function () {
    $(".feedbackemail , .feedbackmessage").on('click', function () {
        $(".feedbackemail").css("border", "1px solid #E3E3E3")
        $(".feedbackmessage").css("border", "1px solid #E3E3E3")
    })
    $(".feedbackFormOnSubmit").on('click', function (token) {
        var email = $(".feedbackemail").val()
        var message = $(".feedbackmessage").val()
        var check = false
        if (email != "" && email != null) {
            if (email.indexOf("@") > 0 && email.indexOf(".") > 0) {
                check = true
            } else {
                $(".feedbackemail").css("border", "1px solid red")
            }
        } else {
            $(".feedbackemail").css("border", "1px solid red")
        }

        if (message != "" && message != null) {
            check = true
        } else {
            check = false
            $(".feedbackmessage").css("border", "1px solid red")
        }
        if (check) {
            $("#submitbutton").parents(".form-horizontal").ajaxSubmit();
            var feedbackFormResponseStr = "Thank you for your comment! We will get back to you soon.";
            $("#submitbutton").parents(".form-horizontal").html("<p>" + feedbackFormResponseStr + "</p>");
            return false;
        }
    })
})

function resendVerificationEmailLoginPage() {
    var userId = $("#login_userId").val()
    if (userId)
        userId = userId
    showLoaderOnClick()
    $.ajax({
        url: "/sendAgainConfirmEmail",
        method: "POST",
        data: {
            emailId: userId
        },
        async: true,
        success: function (res) {
            //-     $("#verifyotp_userId").attr("value",userId);
            //-     $("#verifyotp").modal();
            //-     $(".showmobileno").html("We've sent a OTP to the mobile no. "+userId+". Please enter OTP below to complete verification.")
            //-     $(".showmobileno").css("color","black"); 
            //- }else{
            $("#verifyemail_userId").attr("value", userId);
            $("#verifyemail").modal();
            $(".showemailid").html("We've sent a link to the email address " + userId + ". Please open link to complete verification. Please check your spam folders too for the email.")
            $(".showemailid").css("color", "#545454");
            document.getElementById('resendTimer').innerHTML = 01 + ":" + 30;
            startTimer();
            hideLoaderOnClick()
            return false;
        },
        error: function (res) {
            var err = "There is some error. Please connect at info@rankmeonline.com"
            if (res !== null && res.responseJSON && res.responseJSON !== null) {
                err = res.responseJSON["error"];
            }
            console.log(err + " " + JSON.stringify(res))
            $('.signuperr').html(err)
            $(".signuperr").css("color", "red");
            hideLoaderOnClick()
            return false;
        },
    });
}

//==================================Cross Button on Search=================================================================
$(document).ready(function () {
    if (document.getElementById('mentionSearchBox')) {
        $("#mentionSearchBox>input").on('click', function () {
            var searchtextcross = $("#mentionSearchBox>input").val()
            if (searchtextcross != "" && searchtextcross != null && searchtextcross != undefined) {
                $(".mentionSearchCrossIcon").css("display", "block")
            } else {
                $(".mentionSearchCrossIcon").css("display", "none")
            }
        });
    }
    $(".mentionSearchCrossIcon").on('click', function () {
        showLoaderOnClick()
        var url = document.location.href;
        var paramArray = splitIntoParams(url)
        var searchType = $("#influencerSearch").val()
        paramArray = paramArray.filter(function (val) {
            if (searchType == "influencerUserName" || searchType == "influencerFullName" || searchType == "influencerSearchText") {
                if (val.param !== searchType) return true
                return false
            } else {
                if (val.param !== "searchText") return true
                return false
            }
        })
        var urlNew = mergeIntoUrl(paramArray)
        document.location.href = urlNew;
    })

    // =======================Similar Result===========================================
    if (document.getElementById('matchSearchText')) {
        $("#matchSearchText").on('click', function () {
            var pageUrl = document.location.href;
            var searchTextValue = $("#mentionSearchBox>input").val()
            var checkinSearchBox = searchTextValue != ""
            var checkboxTrue = $("#matchSearchText").prop("checked")
            var similarflag = 0;
            var searchflag = 0;
            showLoaderOnClick()
            var paramArray = splitIntoParams(pageUrl)
            for (i = 0; i < paramArray.length; i++) {
                if (paramArray[i].param && paramArray[i].value) {
                    if (paramArray[i].param == "similarResults") {
                        similarflag = 1;
                    }
                    if (paramArray[i].param == "searchText") {
                        searchflag = 1;
                    }
                }
            }

            if (checkboxTrue == true && similarflag == 0 && checkinSearchBox == true && searchflag == 1) {
                paramArray.push({
                    param: "similarResults",
                    value: true
                });
                var destUrl = mergeIntoUrl(paramArray, true);
                document.location = destUrl;
            } else if (checkboxTrue == true && similarflag == 0 && checkinSearchBox == true && searchflag == 0) {
                paramArray.push({
                    param: "searchText",
                    value: searchTextValue,
                });
                paramArray.push({
                    param: "similarResults",
                    value: true
                });
                var destUrl = mergeIntoUrl(paramArray, true);
                document.location = destUrl;
            } else if (checkboxTrue != true && similarflag != 0 && checkinSearchBox == true && searchflag != 0) {
                paramArray = paramArray.filter(function (val) {
                    if (val.param !== "similarResults") return true
                    return false
                })
                var destUrl = mergeIntoUrl(paramArray, true);
                document.location = destUrl;
            } else if (checkboxTrue != true && similarflag != 0 && checkinSearchBox == true && searchflag == 0) {
                paramArray.push({
                    param: "searchText",
                    value: searchTextValue,
                });
                paramArray = paramArray.filter(function (val) {
                    if (val.param !== "similarResults") return true
                    return false
                })
                var destUrl = mergeIntoUrl(paramArray, true);
                document.location = destUrl;
            } else if (checkboxTrue == true && similarflag == 0 && checkinSearchBox == false && searchflag == 0) {
                paramArray.push({
                    param: "similarResults",
                    value: true
                });
                var destUrl = mergeIntoUrl(paramArray, true);
                document.location = destUrl;
            } else if (checkboxTrue != true && similarflag == 1 && checkinSearchBox != true && searchflag != 0) {
                paramArray = paramArray.filter(function (val) {
                    if (val.param !== "similarResults") return true
                    return false
                })
                paramArray = paramArray.filter(function (val) {
                    if (val.param !== "searchText") return true
                    return false
                })
                var destUrl = mergeIntoUrl(paramArray, true);
                document.location = destUrl;
            } else {
                hideLoaderOnClick()
            }

        })
    }
});


function jsFilterBarMentionScroller() {
    var attributeLastHeight = $("#filterScroller").scrollTop();
    // if (attributeLastHeight > 100) {
    $("#filterScroller").animate({
        scrollTop: attributeLastHeight + 150
    });
    // }
}

//==================================Number running=================================================================
$(document).ready(function () {
    $('.countrunning').each(function () {
        var $this = $(this),
            countTo = $this.attr('data-count');
        $({
            countNum: $this.text()
        }).animate({
            countNum: countTo
        }, {
            duration: 3000,
            easing: 'swing',
            step: function () {
                $this.text(Math.floor(this.countNum));
            },
            complete: function () {
                $this.text(this.countNum);
            }
        });
    });

    $('.js-location-filter-source').click(function () {
        jsFilterBarMentionScroller()
    });
    $('.js-language-filter-source').click(function () {
        jsFilterBarMentionScroller()
    });
    $('.js-topics-filter-source').click(function () {
        jsFilterBarMentionScroller()
    });

    $('.js-website-filter-source').click(function () {
        jsFilterBarMentionScroller()
    });
    $('.js-attribute-filter-source').click(function () {
        jsFilterBarMentionScroller()
    });

    var filterparamArray = splitIntoParams(document.location.href);
    if (filterparamArray.length > 0) {
        for (var i = 0; i < filterparamArray.length; i++) {

            if (filterparamArray[i].param == "attributetoken" || filterparamArray[i].param == "attributes" || filterparamArray[i].param == "extraattributes") {
                for (var sen = 0; sen < $(".attributes").length; sen++) {
                    if ($(".attributes")[sen].name == filterparamArray[i].value || $(".extraattributes")[sen].name == filterparamArray[i].value) {
                        if ($(".attributes")[sen].name == filterparamArray[i].value) {
                            $(".attributes:checkbox[name='" + filterparamArray[i].value + "']").prop('checked', true);
                        } else {
                            $(".extraattributes:checkbox[name='" + filterparamArray[i].value + "']").prop('checked', true);
                        }
                    }
                }
            }
            if (filterparamArray[i].param == "attributesentiment") {
                var attvalue = filterparamArray[i].value.toUpperCase()
                for (var sen = 0; sen < $(".attributesentiment").length; sen++) {
                    if ($(".attributesentiment")[sen].name == attvalue) {
                        $(".attributesentiment:checkbox[name='" + attvalue + "']").prop('checked', true);
                    }
                }
            }
            if (filterparamArray[i].param == "sentiment") {
                for (var sen = 0; sen < $(".sentiment").length; sen++) {
                    if ($(".sentiment")[sen].name == filterparamArray[i].value) {
                        $("." + filterparamArray[i].param + ":checkbox[name='" + filterparamArray[i].value + "']").prop('checked', true);
                    }
                }
            }
            if (filterparamArray[i].param == "sourceType") {
                for (var sen = 0; sen < $(".sourceType").length; sen++) {
                    if ($(".sourceType")[sen].name == filterparamArray[i].value) {
                        $("." + filterparamArray[i].param + ":checkbox[name='" + filterparamArray[i].value + "']").prop('checked', true);
                    }
                }
            }
            if (filterparamArray[i].param == "mentionType") {
                for (var sen = 0; sen < $(".mentionType").length; sen++) {
                    if ($(".mentionType")[sen].name == filterparamArray[i].value) {
                        $("." + filterparamArray[i].param + ":checkbox[name='" + filterparamArray[i].value + "']").prop('checked', true);
                    }
                }
            }
            if (filterparamArray[i].param == "location") {
                for (var sen = 0; sen < $(".location").length; sen++) {
                    if ($(".location")[sen].name == filterparamArray[i].value) {
                        $("." + filterparamArray[i].param + ":checkbox[name='" + filterparamArray[i].value + "']").prop('checked', true);
                    }
                }
            }

            if (filterparamArray[i].param == "language") {
                for (var sen = 0; sen < $(".language").length; sen++) {
                    if ($(".language")[sen].name == filterparamArray[i].value) {
                        $("." + filterparamArray[i].param + ":checkbox[name='" + filterparamArray[i].value + "']").prop('checked', true);
                    }
                }
            }
        }
    }
});


function plotlyReplot() {
    if (typeof (plotlyPlotData) != 'undefined' && plotlyPlotData && plotlyPlotData.length > 0) {
        setTimeout(function () {
            for (var i = 0; i < plotlyPlotData.length; i++) {
                var finalOptions = {
                    displayModeBar: false,
                    displaylogo: false,
                    showTips: false,
                    responsive: true,
                }
                if (plotlyPlotData[i].modeBarButtons) {
                    finalOptions = {
                        modeBarButtons: plotlyPlotData[i].modeBarButtons,
                        displaylogo: false,
                        showTips: false,
                        responsive: true,
                    }
                }
                Plotly.purge(plotlyPlotData[i].id)
                Plotly.newPlot(plotlyPlotData[i].id, plotlyPlotData[i].data, plotlyPlotData[i].layout, finalOptions);
                if (typeof (plotlyPlotData[i].onClickFunctionName) != 'undefined') {
                    window[plotlyPlotData[i].onClickFunctionName]()
                }
            }
        }, 500)
    }
}

function chartjsGraphsReLoaded() {
    if (typeof (chartjsGraphsLoaded) != 'undefined' && chartjsGraphsLoaded && chartjsGraphsLoaded.length > 0) {
        setTimeout(function () {
            for (var i = 0; i < chartjsGraphsLoaded.length; i++) {
                //console.log("showing graph : " + chartjsGraphsLoaded[i].id);
                var canvasElement = document.getElementById(chartjsGraphsLoaded[i].id).getContext("2d").canvas.parentNode;
                //console.log(canvasElement.style.width);
                var curWidth = canvasElement.style.width;
                if (canvasElement.style.width == "")
                    curWidth = document.getElementById(chartjsGraphsLoaded[i].id).style.width;
                if ($('#sidebar').hasClass('active')) {
                    //console.log("large width required");
                    var newWidth = parseInt(curWidth.split("px")[0]) + 176 / chartjsGraphsLoaded[i].expandRatio;
                    canvasElement.style.width = newWidth + "px";
                } else {
                    //console.log("small width required");
                    var newWidth = parseInt(curWidth.split("px")[0]) - 176 / chartjsGraphsLoaded[i].expandRatio;
                    canvasElement.style.width = newWidth + "px";
                }
            }
        }, 10)
    }
}

function geachartReplot() {
    if (typeof (geoGraphData) != 'undefined' && geoGraphData && geoGraphData.length > 0) {
        setTimeout(function () {
            for (var i = 0; i < geoGraphData.length; i++) {
                var chart = new google.visualization.GeoChart(document.getElementById(geoGraphData[i].id));
                chart.draw(geoGraphData[i].data[0], geoGraphData[i].options[0]);
            }
        }, 500)
    }
    if (typeof (geoSummaryGraphData) != 'undefined' && geoSummaryGraphData && geoSummaryGraphData.length > 0) {
        setTimeout(function () {
            for (var i = 0; i < geoSummaryGraphData.length; i++) {
                if (geoSummaryGraphData[i].id == 'cityLocationIndiaChart') {
                    var cityChart = new google.visualization.GeoChart(document.getElementById(geoSummaryGraphData[i].id));
                    var cityRegionData = geoSummaryGraphData[i].regionDataKeywords
                    function cityGraphClickFunction() {
                        var selection = cityChart.getSelection();
                        if (selection != null && selection.length > 0) {
                            showLoaderOnClick()
                            var goToUrl = '/enterprise-mention?projectId=' + projectId + '&locationCity='
                            for (var i = 0; i < selection.length; i++) {
                                var item = selection[i];
                                if (item.row != null) {
                                    var selectState = cityRegionData[item.row + 1]
                                    goToUrl += selectState[2];
                                }
                            }
                            document.location.href = goToUrl
                        }
                    }
                    cityChart.draw(geoSummaryGraphData[i].data, geoSummaryGraphData[i].options);
                    google.visualization.events.addListener(cityChart, 'select', cityGraphClickFunction);
                }
                else if (geoSummaryGraphData[i].id == 'statesLocationIndiaChart') {
                    var stateChart = new google.visualization.GeoChart(document.getElementById(geoSummaryGraphData[i].id));
                    var stateRegionData = geoSummaryGraphData[i].regionDataKeywords
                    function statesGraphClickFunction() {
                        var selection = stateChart.getSelection();
                        if (selection != null && selection.length > 0) {
                            showLoaderOnClick()
                            var goToUrl = '/enterprise-mention?projectId=' + projectId + '&locationState='
                            for (var i = 0; i < selection.length; i++) {
                                var item = selection[i];
                                if (item.row != null) {
                                    var selectState = stateRegionData[item.row + 1]
                                    goToUrl += selectState[1];
                                }
                            }
                            document.location.href = goToUrl
                        }
                    }
                    stateChart.draw(geoSummaryGraphData[i].data, geoSummaryGraphData[i].options);
                    google.visualization.events.addListener(stateChart, 'select', statesGraphClickFunction);
                }
            }
        }, 500)
    }

}

var newProjectData = {}
var keywordIDNumber = 1
var socialHandleNumber = 1
$(document).ready(function () {
    $(document).on('keyup', '.handleInput', function () {
        var handle = $(this).attr('id').split('_')[1]
        $('.extraHandle_' + handle).addClass('shadowAddButton')
    })
    $(document).on('blur', '.handleInput', function () {
        var handle = $(this).attr('id').split('_')[1]
        $('.extraHandle_' + handle).removeClass('shadowAddButton')
    })
    $(document).on('keyup', '#extraKeyword', function () {
        $('.addExtraKeyword').addClass('shadowAddButton')
    })
    $(document).on('blur', '#extraKeyword', function () {
        $('.addExtraKeyword').removeClass('shadowAddButton')
    })
    if (document.getElementById('onboardingNextStep1')) {
        $("#onboardingNextStep1").on("click", function () {
            $('.websiteUrl').trigger('mouseout')
            $('.noWebsite').trigger('mouseout')
            var div = document.createElement("div");
            div.className += "overlay";
            document.body.appendChild(div);
            showLoaderOnClick()
            var websiteUrl = $('.websiteUrl').val()
            $(".brandImage").prop("src", '')
            $('#country').val("United States")
            $(".brandName").text('')
            $('#step3 .headingOnboarding').text('Add Social Media accounts to monitor')
            $('#step2 .headingOnboarding').text('Add keywords to monitor')
            $(".brandLink").text('')
            $('.brandFavicon').prop("src", '')
            $('.brandTitle').text('')
            $("#keywordsAdded").empty();
            $('.selectedKeywordsNumber').text(1)
            $('.selectedSocialHandleNumber').text(0)
            $("#chosenSocialHandle").empty();
            // var htmlStart = '<div id="filters" class=""><span class="filterListHeading" style="padding-left: 12px;">Add more Keywords: </span><div id="availableKeywords">'
            var htmlEnd = "<div class='flexdirectionRow'><input type='text' class='form-control pdfInput' name='extraKeyword'  placeholder='Enter Keyword to Add' minlength='1' id='extraKeyword' data-toggle='tooltip' title='Press Enter to Add'><i class='fa fa-plus-circle addExtraKeyword'></i></div>"
            $('#availableKeywords').html(htmlEnd)
            $('#chosenSocialHandle').html('<div></div>')
            keywordIDNumber = 1
            socialHandleNumber = 1
            if (websiteUrl.length > 0) {
                $.ajax({
                    method: "POST",
                    url: "/getWebsiteRelatedData",
                    data: { url: websiteUrl },
                    success: function (res) {
                        $('.overlay').remove()
                        if (res.status === "websiteCrawlFailed") {
                            hideLoaderOnClick()
                            $('.step1Check').removeClass('infl_displayNone')
                            $('.step1Check').text('Enter a valid website link')
                            return false
                        }
                        newProjectData["websiteData"] = res.ans
                        logOnboarding(1, newProjectData["websiteData"])
                        hideLoaderOnClick()
                        displayStep2(websiteUrl, 'link')
                        socialHandleOnboarding()
                        $("#step1right").addClass('fadeOutLeftBig').one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
                            $('#step1').css('display', 'none')
                            $("#step1right").hide().removeClass('fadeOutLeftBig');
                            $('#step2').css('display', 'flex')
                            $("#step2right").addClass('fadeInRightBig').show().one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
                                $("#step2right").removeClass('fadeInRightBig')
                            })
                        })
                    },
                    error: function (res) {
                        $('.overlay').remove()
                        $('.step1Check').removeClass('infl_displayNone')
                        $('.step1Check').text('Enter a valid website link')
                        setTimeout(function () {
                            $(".step1Check").fadeOut("fast", function () {
                                $('.step1Check').css('display', '')
                                $(".step1Check").addClass("infl_displayNone");
                            });
                        }, 5000)
                        // alert('Enter valid Website Address')
                    }
                })
            }
            else if ($('.noWebsite').val().trim().length > 0) {
                if ($('.noWebsite').val().trim().length < 3) {
                    $('.overlay').remove()
                    hideLoaderOnClick()
                    $('.step1Check').removeClass('infl_displayNone')
                    $('.step1Check').text("Keyword can't be less then 3 characters")
                    setTimeout(function () {
                        $(".step1Check").fadeOut("fast", function () {
                            $('.step1Check').css('display', 'block')
                            $(".step1Check").addClass("infl_displayNone");
                        });
                    }, 5000)
                    return false
                } else {
                    logOnboarding(1, { keywordStart: $('.noWebsite').val().trim() })
                    $('.overlay').remove()
                    $("#step1right").addClass('fadeOutLeftBig').one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
                        $('#step1').css('display', 'none')
                        $("#step1right").hide().removeClass('fadeOutLeftBig');
                        $('#step2').css('display', 'flex')
                        $("#step2right").addClass('fadeInRightBig').show().one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
                            $("#step2right").removeClass('fadeInRightBig')
                        })
                    })
                    hideLoaderOnClick()
                    websiteUrl = $('.noWebsite').val().trim()
                    displayStep2(websiteUrl, 'name')
                }
            }
            else {
                $('.overlay').remove()
                hideLoaderOnClick()
                $('.step1Check').removeClass('infl_displayNone')
                var errorText = "Please Enter Website Link/Keyword"
                if ($(".onboardingOptionListItem.active").attr("id") == "enterWebsiteOption") {
                    errorText = "Please enter your website link to begin"
                } else if ($(".onboardingOptionListItem.active").attr("id") == "enterNameOption") {
                    errorText = "Please enter a keyword to begin"
                }
                $('.step1Check').text(errorText)
                setTimeout(function () {
                    $(".step1Check").fadeOut("fast", function () {
                        $('.step1Check').css('display', 'block')
                        $(".step1Check").addClass("infl_displayNone");
                    });
                }, 5000)
                // alert('Please Enter Brand Website/Brand Name')
                return false
            }
        })
    }
    $("#onboardingNextStep2").on("click", function () {
        if (parseInt($('.selectedKeywordsNumber').text()) == 0) {
            $('.step2Check').removeClass('infl_displayNone')
            $('.step2Check').text('Minimum 1 keyword is required')
            setTimeout(function () {
                $(".step2Check").fadeOut("fast", function () {
                    $('.step2Check').css('display', '')
                    $(".step2Check").addClass("infl_displayNone");
                });
            }, 5000)
            // alert('Minimum 1 keyword is required')
            return false
        }
        var keywords = $(".addedKeywords").map(function () {
            return $(this).text();
        }).get();
        logOnboarding(2, newProjectData["websiteData"], keywords)
        $("#step2right").addClass('fadeOutLeftBig').one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
            $('#step2').css('display', 'none')
            $("#step2right").hide().removeClass('fadeOutLeftBig');
            $('#step3').css('display', 'flex')
            $('#extraHandle_TWITTER').focus();
            $("#step3right").addClass('fadeInRightBig').show().one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
                $("#step3right").removeClass('fadeInRightBig')
            })
        })
    })
    $('.chooseSocial').on('click', function () {
        var handleClass = $(this).children().attr('id').split('_')
        $('.chooseSocial').children().css('opacity', '0.35')
        $('.chooseSocial').children().css('filter', 'grayscale(1)')
        $(this).children().css('opacity', '1')
        $('.chooseSocial').children().removeClass('activeHandle')
        $('.handleInput').addClass('infl_displayNone')
        $('.addSocialHandle').addClass('infl_displayNone')
        $('.handleInput').val('')
        $('#extraHandle_' + handleClass[1]).removeClass('infl_displayNone')
        $('.extraHandle_' + handleClass[1]).removeClass('infl_displayNone')
        $('#icon_' + handleClass[1]).addClass('activeHandle')
        $('#extraHandle_' + handleClass[1]).trigger('mouseover');
        $('#extraHandle_' + handleClass[1]).focus();
        setTimeout(function () {
            $('#extraHandle_' + handleClass[1]).trigger('mouseout');
        }, 2000);
    })
    $('.addSocialHandle').on("click", function () {
        var addedSocialHandleNumber = parseInt($('.selectedSocialHandleNumber').text())
        var allowedSocialHandleNumber = parseInt($('.allowedSocialHandleNumber').text())
        if (addedSocialHandleNumber < allowedSocialHandleNumber) {
            var website = $('.activeHandle').attr('id').split('_')[1]
            if (website === "TWITTER") {
                imgUrl = '/images/report/twitter.png'
            } else if (website === "FACEBOOK") {
                imgUrl = '/images/report/facebook.png'
            } else if (website === "YOUTUBE") {
                imgUrl = '/images/report/youtube.png'
            } else if (website === "INSTAGRAM") {
                imgUrl = '/images/report/instagram.png'
            }
            else if (website === "TIKTOK") {
                imgUrl = '/images/report/tiktok.png'
            } else if (website === "LINKEDIN") {
                imgUrl = '/images/report/linkedin.png'
            }
            var handle = $('#extraHandle_' + website).val().trim()
            if (handle != undefined && handle != null && handle.length > 0) {
                if (handle[0] != '@')
                    handle = '@' + handle
                var html = "<div class='padding-side12' id='socialHandle_id_" + socialHandleNumber + "'><img src=" + imgUrl + " style='height:40px;border-radius:50%'><i class='fa fa-times-circle removeHandle' style='position:absolute' id='socialHandle_id_" + socialHandleNumber + "'></i><p class='margin0 handleName font-size14'>" + handle + "</p> <span style='display:none'>" + website + "</span></div>"
                socialHandleNumber += 1
                $('#extraHandle_' + website).val('')
                $('#chosenSocialHandle').append(html)
                addedSocialHandleNumber += 1
                $('.selectedSocialHandleNumber').text(addedSocialHandleNumber)
            }
        } else {
            $('.step3Check').removeClass('infl_displayNone')
            $('.step3Check').text('Maximum limit reached')
            setTimeout(function () {
                $(".step3Check").fadeOut("fast", function () {
                    $('.step3Check').css('display', '')
                    $(".step3Check").addClass("infl_displayNone");
                });
            }, 5000)
            // alert('max-limit reached')
        }
    })
    $(document).on("click", '.removeHandle', function () {
        var addedSocialHandleNumber = parseInt($('.selectedSocialHandleNumber').text())
        addedSocialHandleNumber -= 1
        $('.selectedSocialHandleNumber').text(addedSocialHandleNumber)
        $('#' + this.id).remove()
    })
    $('#enterWebsiteOption').on('click', function () {
        $('.websiteUrl').trigger('mouseout')
        $('.noWebsite').trigger('mouseout')
        $('.noWebsite').val("")
        $('#enterWebsiteOption').addClass("active")
        $('#enterNameOption').removeClass('active')
        $('#enterWebsite').css('display', 'block')
        $('#enterName').css('display', 'none')
    })
    $('#enterNameOption').on('click', function () {
        $('.websiteUrl').trigger('mouseout')
        $('.noWebsite').trigger('mouseout')
        $('.websiteUrl').val("")
        $('#enterNameOption').addClass("active")
        $('#enterWebsiteOption').removeClass('active')
        $('#enterName').css('display', 'flex')
        $('#enterWebsite').css('display', 'none')
    })

    $('#onboardingFinalSubmit').on('click', function () {
        $('#step3').css('display', 'none')
        $('#loadingOnboarding').css('display', 'flex')
        var keywords = $(".addedKeywords").map(function () {
            return $(this).text();
        }).get();
        var handle = $(".removeHandle").map(function () {
            var id = $(this).attr('id')
            var websiteName = $('#' + id + '>span').text()
            var handleName = $('#' + id + '>p').text()
            return { website: websiteName, handle: handleName };
        }).get();
        for (var i = 0; i < handle.length; i++) {
            for (var j = 0; j < handle[i].handle.length; j++) {
                if (handle[i].handle[j] == '@')
                    continue
                else
                    break
            }
            handle[i].handle = handle[i].handle.slice(j)
        }
        var country = $('#country').val()
        if (country.trim().length = 0)
            country = 'United States'
        if (newProjectData["websiteData"]) {
            newProjectData["websites"] = [newProjectData.websiteData.websites]
            newProjectData['projectName'] = newProjectData.websiteData.name
        } else {
            newProjectData["projectName"] = $('.noWebsite').val().trim()
        }
        newProjectData['logoURL'] = $('.brandImage').attr('src')
        newProjectData["country"] = country
        newProjectData["enteredKeywords"] = keywords
        newProjectData["socialMediaHandles"] = handle
        newProjectData["category"] = "Information Technology/IT"
        logOnboarding(3, newProjectData, keywords, handle, country)
        $.ajax({
            method: "POST",
            url: "/onboarding",
            data: { "project": newProjectData },
            success: function (res) {
                var data = {
                    'sourceType': 'all',
                    'userProject': res.project
                }
                setTimeout(function () {
                    document.location.assign("/enterprise-panel-result?firstTime=true&projectId=" + data.userProject._id)
                }, 15000)
                $.ajax({
                    method: 'POST',
                    url: ' /admin/instantRunStrategyForNewProject',
                    data: data,
                    success: function (ans) {
                        if (ans.status == "done")
                            document.location.assign("/enterprise-panel-result?firstTime=true&projectId=" + data.userProject._id)
                        else {
                            $('#step3').css('display', 'flex')
                            $('#loadingOnboarding').css('display', 'none')
                            $('.projectCreateError').removeClass('infl_displayNone')
                            $(".projectCreateError").text("*Error in creating the project. Please contact support@rankmeonline.com")
                        }
                    },
                    error: function (ans) {
                        if (ans !== null) {
                            $('#step3').css('display', 'flex')
                            $('#loadingOnboarding').css('display', 'none')
                            $('.projectCreateError').removeClass('infl_displayNone')
                            $(".projectCreateError").text("*Error in creating the project. Please contact support@rankmeonline.com")
                            //window.scrollTo(0, 0);
                            // $("#confirmationbackend").attr("disabled", false)
                            hideLoaderOnClick()
                        }
                    }
                })
                // document.location.assign("/enterprise-dashboard?firstTime=true&projectId=" + res["project"]["_id"])
            },
            error: function (ans) {
                if (ans !== null) {
                    $('#step3').css('display', 'flex')
                    $('#loadingOnboarding').css('display', 'none')
                    var str = ans.errorStatus
                    $('.projectCreateError').removeClass('infl_displayNone')
                    $(".projectCreateError").text("*Error in creating the project. Please contact support@rankmeonline.com")
                    //window.scrollTo(0, 0);
                    // $("#confirmationbackend").attr("disabled", false)
                    hideLoaderOnClick()
                }
            }
        })
    })
    $(document).on("click", '.addExtraKeyword', function () {
        appendExtraKeywordOnboarding()
    })
    // $('.addExtraKeyword').on('click', function () {
    //     appendExtraKeywordOnboarding()
    // })
    $(document).on('keyup', '.websiteUrl', function (e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            $('#onboardingNextStep1').click()
        }
    });
    $(document).on('keyup', '.noWebsite', function (e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            $('#onboardingNextStep1').click()
        }
    });
    $(document).on('keyup', '#extraKeyword', function (e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            appendExtraKeywordOnboarding()
        }
    });
    $(document).on('keyup', '.handleInput', function (e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            $('.addSocialHandle').click()
        }
    });
})
function socialHandleOnboarding() {
    if (newProjectData && newProjectData.websiteData && newProjectData.websiteData.socialMediaHandles && newProjectData.websiteData.socialMediaHandles.length > 0) {
        $('.selectedSocialHandleNumber').text(newProjectData.websiteData.socialMediaHandles.length)
        for (var i = 0; i < newProjectData.websiteData.socialMediaHandles.length; i++) {
            var imgUrl
            var socialHandleWebsite = newProjectData.websiteData.socialMediaHandles[i].website
            if (socialHandleWebsite === "TWITTER") {
                imgUrl = '/images/report/twitter.png'
            } else if (socialHandleWebsite === "FACEBOOK") {
                imgUrl = '/images/report/facebook.png'
            } else if (socialHandleWebsite === "YOUTUBE") {
                imgUrl = '/images/report/youtube.png'
            } else if (socialHandleWebsite === "INSTAGRAM") {
                imgUrl = '/images/report/instagram.png'
            } else if (socialHandleWebsite === "TIKTOK") {
                imgUrl = '/images/report/tiktok.png'
            } else if (socialHandleWebsite === "LINKEDIN") {
                imgUrl = '/images/report/linkedin.png'
            }
            var html = "<div class='padding-side12' id='socialHandle_id_" + socialHandleNumber + "'><img src=" + imgUrl + " style='height:40px;border-radius:50%'><i class='fa fa-times-circle removeHandle' style='position:absolute' id='socialHandle_id_" + socialHandleNumber + "'></i><p class='margin0 handleName font-size14'>@" + newProjectData.websiteData.socialMediaHandles[i].handle + "</p>  <span style='display:none'>" + newProjectData.websiteData.socialMediaHandles[i].website + "</span></div>"
            socialHandleNumber += 1
            $('#chosenSocialHandle').append(html)
        }
    }

}

function logOnboarding(step, projectData, keyword, handle, country) {
    $.ajax({
        method: 'POST',
        url: ' /loggeronboarding',
        data: { "step": step, "projectData": projectData, "keywordsAddedByUser": keyword, "handlesAddedByUser": handle, "countryAddedByUser": country }
    })
}
function displayStep2(websiteUrl, link) {
    // var country = country
    var elementHtml = ""
    if (link === 'link') {
        $('.brandImage').css('display', 'flex')
        if (newProjectData.websiteData.logoURL) {
            $(".brandImage").prop("src", newProjectData.websiteData.logoURL)
        } else {
            $(".brandImage").prop("src", 'https://logo.clearbit.com/' + newProjectData.websiteData.websites.split('//')[1])
        }
        if (websiteUrl) {
            $('.brandLinkDisplay').css('display', 'flex')
            $(".brandLink").text(newProjectData.websiteData.websites)
        } else {
            $('.brandLinkDisplay').css('display', 'none')
        }
        if (newProjectData.websiteData && newProjectData.websiteData.country && typeof (newProjectData.websiteData.country) == "string" && newProjectData.websiteData.country.length > 0) {
            $('#country').val(newProjectData.websiteData.country)
        }
        else if (country && country.length > 0) {
            $('#country').val(country)
        }
        else {
            $('#country').val("United States")
        }
        if (newProjectData.websiteData.name && newProjectData.websiteData.name.length > 0) {
            $('.brandNameDisplay').css('display', 'flex')
            $(".brandName").text(newProjectData.websiteData.name)
            $('.headingOnboarding').append(' for project: ' + newProjectData.websiteData.name)
        } else {
            $('.brandNameDisplay').css('display', 'none')
        }
        var favicon = "https://www.google.com/s2/favicons?domain=" + newProjectData.websiteData.websites.split("//")[1]
        if (favicon) {
            $('.brandFavicon').css('display', 'flex')
            $('.brandFavicon').prop("src", favicon)
        } else {
            $('.brandFavicon').css('display', 'none')
        }
        if (newProjectData.websiteData.title && newProjectData.websiteData.title.length > 0) {
            $('.brandTitleDisplay').css('display', 'flex')
            $('.brandTitle').text(newProjectData.websiteData.title)
        } else {
            $('.brandTitleDisplay').css('display', 'none')
        }
        // var specialCharacterKeyword = escape(newProjectData.websiteData.name.trim())
        var firstElement = "<span class='showingFilterList  changeOnHover addedKeywords form-check-label font-size14 border-round' id='keyword_id_" + keywordIDNumber + "' onclick='removeKeywordOnboarding(\"" + keywordIDNumber + "\")'>" + newProjectData.websiteData.name + "<i style='margin-left:10px' class='fa fa-minus-circle' id='addKeyword'></i></span>"
        keywordIDNumber += 1
        $(firstElement).appendTo('#keywordsAdded')
        if (newProjectData.websiteData.metaKeywords) {
            var keywordsLength = newProjectData.websiteData.metaKeywords.split(',').length > 15 ? 15 : newProjectData.websiteData.metaKeywords.split(',').length
            for (var i = 0; i < keywordsLength; i++) {
                var keyword = newProjectData.websiteData.metaKeywords.split(',')[i].trim()
                // var specialCharacterKeyword = escape(keyword.trim())
                if (keyword && keyword != null && keyword != undefined && keyword.length > 0) {
                    if (i < 5 && keyword.search(newProjectData.websiteData.name) == -1) {
                        elementHtml += "<span class='showingFilterList  changeOnHover availableKeyword form-check-label font-size14 border-round' id='keyword_id_" + keywordIDNumber + "' onclick='appendKeywordOnboarding(\"" + keywordIDNumber + "\")'>" + newProjectData.websiteData.name + " " + keyword + "<i style='margin-left:10px' class='fa fa-plus-circle' id='addKeyword'></i></span>"
                    }
                    else
                        elementHtml += "<span class='showingFilterList  changeOnHover availableKeyword form-check-label font-size14 border-round' id='keyword_id_" + keywordIDNumber + "' onclick='appendKeywordOnboarding(\"" + keywordIDNumber + "\")'>" + keyword + "<i style='margin-left:10px' class='fa fa-plus-circle' id='addKeyword'></i></span>"
                    keywordIDNumber += 1
                }

            }
        }
    }
    else if (link === 'name') {
        if (country && country.length > 0) {
            $('#country').val(country)
        }
        else {
            $('#country').val("United States")
        }
        $('.brandLinkDisplay').css('display', 'none')
        $('.brandFavicon').css('display', 'none')
        $('.brandImage').css('display', 'flex')
        $('.brandImage').prop('src', '')
        $('.brandTitleDisplay').css('display', 'none')
        $('.brandNameDisplay').css('display', 'none')
        $('.headingOnboarding').append(' for project: ' + websiteUrl)
        // $(".brandName").text(websiteUrl)
        // var specialCharacterKeyword = escape(websiteUrl.trim())
        var firstElement = "<span class='showingFilterList  changeOnHover addedKeywords form-check-label font-size14 border-round' id='keyword_id_" + keywordIDNumber + "' onclick='removeKeywordOnboarding(\"" + keywordIDNumber + "\")'>" + websiteUrl + "<i style='margin-left:10px' class='fa fa-minus-circle' id='addKeyword'></i></span>"
        keywordIDNumber += 1
        $(firstElement).appendTo('#keywordsAdded')
        // elementHtml += "<span class='showingFilterList changeOnHover availableKeyword form-check-label border-round' id='keyword_" + websiteUrl.trim().split(" ").join('') + "' onclick='appendKeywordOnboarding(\"" + websiteUrl.trim().split(" ").join('') + "\")'>" + websiteUrl + "<i style='margin-left:10px' class='fa fa-plus-circle' id='addKeyword'></i></span>"

    }


    $('#availableKeywords').prepend(elementHtml)

}

function appendKeywordOnboarding(keywordNumber) {
    var addedKeywordsNumber = parseInt($('.selectedKeywordsNumber').text())
    var allowedKeywordsNumber = parseInt($('.allowedKeywordsNumber').text())
    if (addedKeywordsNumber < allowedKeywordsNumber) {
        // var specialCharacterKeyword = decodeURIComponent(keyword)
        var keyWordText = $('#keyword_id_' + keywordNumber).text()
        if (keyWordText.length < 3) {
            $('.step2Check').removeClass('infl_displayNone')
            $('.step2Check').text("Keyword can't be less then 3 characters")
            setTimeout(function () {
                $(".step2Check").fadeOut("fast", function () {
                    $('.step2Check').css('display', '')
                    $(".step2Check").addClass("infl_displayNone");
                });
            }, 5000)
            return
        }
        var html = "<span class='showingFilterList changeOnHover  addedKeywords form-check-label font-size14 border-round' id='keyword_id_" + keywordNumber + "' onclick='removeKeywordOnboarding(\"" + keywordNumber + "\")'>" + keyWordText + "<i style='margin-left:10px' class='fa fa-minus-circle' id='addKeyword'></i></span>"
        $('#keyword_id_' + keywordNumber).remove()
        $(html).appendTo('#keywordsAdded')
        addedKeywordsNumber += 1
        $('.selectedKeywordsNumber').text(addedKeywordsNumber)
    }
    else {
        $('.step2Check').removeClass('infl_displayNone')
        $('.step2Check').text('Maximum Limit of Keywords Reached')
        setTimeout(function () {
            $(".step2Check").fadeOut("fast", function () {
                $('.step2Check').css('display', '')
                $(".step2Check").addClass("infl_displayNone");
            });
        }, 5000)
        // alert('Max Limit of Keywords Reached')
    }
}
function removeKeywordOnboarding(keywordNumber) {
    var addedKeywordsNumber = parseInt($('.selectedKeywordsNumber').text())
    if (addedKeywordsNumber > 0) {
        // var keywordClean = keyword.replace(/[^a-zA-Z0-9 ]/g, "").split(" ").join('')
        var keyWordText = $('#keyword_id_' + keywordNumber).text()
        var html = "<span class='showingFilterList changeOnHover availableKeyword form-check-label font-size14 border-round' id='keyword_id_" + keywordNumber + "' onclick='appendKeywordOnboarding(\"" + keywordNumber + "\")'>" + keyWordText + "<i style='margin-left:10px' class='fa fa-plus-circle' id='addKeyword'></i></span>"
        $('#keyword_id_' + keywordNumber).remove()
        $(html).prependTo('#availableKeywords')
        addedKeywordsNumber -= 1
        $('.selectedKeywordsNumber').text(addedKeywordsNumber)
    }
    else {
        $('.step2Check').removeClass('infl_displayNone')
        $('.step2Check').text('Minimum Limit of Keywords Reached')
        setTimeout(function () {
            $(".step2Check").fadeOut("fast", function () {
                $('.step2Check').css('display', '')
                $(".step2Check").addClass("infl_displayNone");
            });
        }, 5000)
        // alert('Min Limit of Keywords Reached')
    }
}
function alreadyAddedKeyword(icon, message) {
    var offsetdata = checkScreenViewForNotify()
    $.notify({
        title: '',
        icon: icon,
        message: message,
    }, {
        type: 'info',
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutRight'
        },
        placement: {
            from: "top",
            align: "right"
        },

        offset: offsetdata,
        spacing: 10,
        z_index: 1051,

    });
}
function appendExtraKeywordOnboarding() {
    var addedKeywordsNumber = parseInt($('.selectedKeywordsNumber').text())
    var allowedKeywordsNumber = parseInt($('.allowedKeywordsNumber').text())
    if (addedKeywordsNumber < allowedKeywordsNumber) {
        var keywordText = $('#extraKeyword').val()
        if (keywordText && keywordText != undefined && keywordText != null && keywordText.length > 0) {
            keywordText = keywordText.trim()
            if (keywordText.length < 3) {
                $('.step2Check').removeClass('infl_displayNone')
                $('.step2Check').text("Keyword can't be less then 3 characters")
                setTimeout(function () {
                    $(".step2Check").fadeOut("fast", function () {
                        $('.step2Check').css('display', '')
                        $(".step2Check").addClass("infl_displayNone");
                    });
                }, 5000)
                return
            }
            if (keywordText != "") {
                var html = "<span class='showingFilterList changeOnHover  addedKeywords form-check-label font-size14 border-round' id='keyword_id_" + keywordIDNumber + "' onclick='removeKeywordOnboarding(\"" + keywordIDNumber + "\")'>" + keywordText + "<i style='margin-left:10px' class='fa fa-minus-circle' id='addKeyword'></i></span>"
                $('#extraKeyword').val("")
                var elementExist = checkKeywordExist(keywordText)
                if (elementExist > 0) {
                    var parentId = $('#keyword_id_' + elementExist).parent().attr('id')
                    if (parentId == "keywordsAdded") {
                        addedKeywordsNumber -= 1
                        alreadyAddedKeyword('fa fa-times', "Keyword already added")
                    }
                    $('#keyword_id_' + elementExist).remove()
                }
                keywordIDNumber += 1
                // $('#keyword_' + specialCharacterKeyword).remove()
                $(html).appendTo('#keywordsAdded')
                addedKeywordsNumber += 1
                $('.selectedKeywordsNumber').text(addedKeywordsNumber)
            }
        }
    }
    else {
        $('.step2Check').removeClass('infl_displayNone')
        $('.step2Check').text('Maximum Limit of Keywords Reached')
        setTimeout(function () {
            $(".step2Check").fadeOut("fast", function () {
                $('.step2Check').css('display', '')
                $(".step2Check").addClass("infl_displayNone");
            });
        }, 5000)
        // alert('Max Limit of Keywords Reached')
    }
}

function checkKeywordExist(keywordText) {
    var keywordTextCheck = false
    for (var i = 1; i < keywordIDNumber; i++) {
        var keywordTextValue = $('#keyword_id_' + i).text()
        if (keywordTextValue == keywordText) {
            keywordTextCheck = true
            break
        }
    }
    if (keywordTextCheck == true)
        return i
    else
        return 0
}

function changeOnboardingStepsthree(steptogo, currStep) {
    $('[data-toggle=tooltip]').trigger('mouseout')
    $(currStep + "right").addClass('fadeOutRightBig').one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
        $(currStep + "right").hide().removeClass('fadeOutRightBig');
        $(currStep).css('display', 'none')
        $(steptogo).css('display', 'flex')
        $(steptogo + "right").addClass('fadeInLeftBig').show().one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
            $(steptogo + "right").removeClass('fadeInLeftBig')
        })
    })
}
// *************************sidebar code*********************************
$(document).ready(function () {

    function autosideBarClose() {
        setTimeout(function () {
            $('#sidebar, #content').toggleClass('active');
            $('.angle').toggleClass('fa-angle-double-left');
            $('.angle').toggleClass('fa-angle-double-right');
            plotlyReplot()
            geachartReplot()
            chartjsGraphsReLoaded()
        }, 50)
    }
    function toggleSidebar() {
        if (typeof (Storage) !== "undefined") {
            if ($('.angle').hasClass('fa-angle-double-left')) {
                if ((localStorage.getItem('enterprise-sidebar') === null) || (localStorage.getItem('enterprise-sidebar') === 'OPEN')) {
                    localStorage.setItem('enterprise-sidebar', "CLOSE");
                }
            }
            else if ($('.angle').hasClass('fa-angle-double-right')) {
                if ((localStorage.getItem('enterprise-sidebar') === null) || (localStorage.getItem('enterprise-sidebar') === 'CLOSE')) {
                    localStorage.setItem('enterprise-sidebar', "OPEN");
                }
            }
        }
    }

    $('#sidebarCollapse').on('click', function () {
        toggleSidebar()
        $('#sidebar, #content').toggleClass('active');
        $('.angle').toggleClass('fa-angle-double-left');
        $('.angle').toggleClass('fa-angle-double-right');
        plotlyReplot()
        geachartReplot()
        chartjsGraphsReLoaded()

    });


    if (typeof (Storage) !== "undefined") {
        if ((localStorage.getItem('enterprise-sidebar') === "OPEN")) {
            console.log(localStorage.getItem('enterprise-sidebar'))
            if ($('.angle').hasClass('fa-angle-double-right')) {
                autosideBarClose()

            }
        }
        else {
            console.log(localStorage.getItem('enterprise-sidebar'))
            if ($('.angle').hasClass('fa-angle-double-left')) {
                autosideBarClose()

            }
        }
        // $('#sidebar').addClass('showSideBarClass')
    }



    // ***********************project-settings code****************************************

    $('.showSingle').on('click', function () {
        $('.option-settings>li').removeClass("active");
        $(this).addClass("active");
        if (!$(this).is("#projectSupport")) {
            deletetheVideo()
        }
        $('.targetDiv').hide();
        $('#div' + $(this).attr('target')).show();
    });




    $('.influential-li>li').on('click', function () {
        $('.influential-li>li').removeClass("active");
        $(this).addClass("active");
    });

    $('.pagination>li').on('click', function () {
        $('.pagination>li').removeClass("active");
        $(this).addClass("active");
    });

    $('.account_showSingle').on('click', function () {
        $('.option-settings>li').removeClass("active");
        $(this).addClass("active");
        $('.targetDiv').hide();
        $('#personalsetting' + $(this).attr('target')).show();
    });

    $("#js-account_edit_profile-click").on('click', function () {
        $("#js_account_nonEdit").css("display", "none")
        $("#account_edit_profile").show()
    })
});


function beautifyTags(postDescription, tags) {
    var listOfTags = tags.map(function (val) {
        if (val.tag.startsWith("#") || val.tag.startsWith("@") || val.tag.startsWith(".") || val.tag.endsWith(".") || val.tag.endsWith(","))
            return val.tag.substr(1);
        return val.tag
    });

    var combine = new RegExp("\\b(?:" + listOfTags.join("|") + ")\\b", "ig");
    var highlightNew = postDescription.replace(combine, function (match, offset) {
        var tagFound = tags.find(function (val) {
            return (val.sentiment !== "NOT_FOUND" && (val.tag.toLowerCase() === match.toLowerCase() || val.tag.toLowerCase() === "#" + match.toLowerCase() || val.tag.toLowerCase() === "@" + match.toLowerCase()))
        })
        if (tagFound) {

            if (tagFound.sentiment == "POSITIVE") {
                // return '<span class="highlight" style="background:#4CAF50">' + tagFound.tag + '</span>';
                return '<span class="highlight" style="color: #000;font-weight: 800;padding: 0;">' + tagFound.tag + '</span>';
            }
            if (tagFound.sentiment == "NEGATIVE") {
                // return '<span class="highlight" style="background:#f44336">' + tagFound.tag + '</span>';
                return '<span class="highlight" style="color: #000;font-weight: 800;padding: 0;">' + tagFound.tag + '</span>';
            }
            if (tagFound.sentiment == "NEUTRAL") {
                // return '<span class="highlight" style="background:#2196F3">' + tagFound.tag + '</span>';
                return '<span class="highlight" style="color: #000;font-weight: 800;padding: 0;">' + tagFound.tag + '</span>';
            }
        }
        return match;
    })
    //highlightNew = highlightNew.replace(new RegExp("#<span", "ig"), "<span")
    //highlightNew = highlightNew.replace(new RegExp("@<span", "ig"), "<span")
    return highlightNew
}

function postFunction(divid, postDescription, tags) {

    var maxLength = 125;
    // var myStr = $("#" + divid).html();
    var myStr = postDescription
    if ($.trim(myStr).length > maxLength) {
        var newStr = myStr.substring(0, maxLength);
        var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
        var oldStringBeautified = beautifyTags(newStr, tags)
        var newStringBeautified = beautifyTags(removedStr, tags)
        $("#" + divid).html(oldStringBeautified)
        $("#" + divid).append('<a href="javascript:void(0);" class="read-more-' + divid + '"> read more...</a>');
        $("#" + divid).append('<span id="more-text-' + divid + '" class="more-text">' + newStringBeautified + '</span>');
    }
    $(".read-more-" + divid).click(function () {
        //- $("#"+divid).siblings(".more-text").contents().unwrap();
        var data = $("#" + divid).parentsUntil(".mentionBorderDisplayTop")
        data.attr("style", "height:100% !important")
        $("#more-text-" + divid).contents().unwrap()
        $(".read-more-" + divid).remove();
    });
}



function ajaxMark(projectId, postId, markType) {
    $.ajax({
        method: "GET",
        url: "/mention/markpost?postId=" + postId + "&markType=" + markType + "&projectId=" + projectId
    });
};
function showDetailEngagementBox() {
    if ($("#js-detailmainlist").hasClass('detailshowClicklist')) {

    }
    else {
        if ($("#js-detailmainlist").hasClass('CLOSE')) {
            $("#js-detailmainlist").addClass("OPEN")
            $("#js-detailmainlist").removeClass("CLOSE")
        }
        else {
            $("#js-detailmainlist").addClass("detailshowClicklist")
        }

    }
}

function getCommentExcelFromDetailPage(postId) {
    showLoaderOnClick()
    $.ajax({
        url: "/reports/commentexcel",
        method: "POST",
        data: {
            postId: postId
        },
        async: true,
        success: function (res) {
            console.log("success")
            hideLoaderOnClick()
            alreadyAddedKeyword('fa fa-check', "excel mail sent successfully")
            return true
        },
        error: function (res) {
            console.log(err + " " + JSON.stringify(res))
            hideLoaderOnClick()
            alreadyAddedKeyword('fa fa-times', "please contact to customer support")
            return false;
        },
    });
}


function changeSentimentByDetailPage(projectId, postid, sentiment) {
    // var projectId = "";
    //- var projectId = !{JSON.stringify(activeProjectId)};
    // var postid = $(this).attr("id");
    // postid = postid.substring(12);
    // sentiment = $(this).attr("name");
    ajaxSentiment(projectId, postid, sentiment);
    $(".detailSentimentByUser").text(sentiment);
    $(".sentimentfurterUldetail").removeClass("showlabelSection")
    $("#js-detailmainlist").removeClass('detailshowClicklist')
    $(".js-sentimentColorChange").removeClass("NEGATIVE")
    $(".js-sentimentColorChange").removeClass("NEUTRAL")
    $(".js-sentimentColorChange").removeClass("POSITIVE")
    $(".js-sentimentColorChange").addClass(sentiment)
    $("#js-detailmainlist").addClass('CLOSE')
    // document.location.reload()

}

function ajaxSentiment(projectId, postId, sentimentType) {
    $.ajax({
        method: "GET",
        url: "/manualMarkSentiment?sentiment=" + sentimentType + "&postId=" + postId + "&projectId=" + projectId
    });
    var offsetdata = checkScreenViewForNotify()
    $.notify({
        title: '',
        icon: 'fa fa-heart',
        message: "The sentiment for this post has been updated. Please allow a minute for the changes to reflect",
    }, {
        type: 'info',
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutRightBig'
        },
        placement: {
            from: "top",
            align: "right"
        },

        offset: offsetdata,
        spacing: 10,
        z_index: 1051,

    });
};



function mergeIntoUrl(paramArray, fromBeginning) {
    var pageUrl = document.location.href;
    var destUrl = pageUrl.split("?")[0] + "?";
    for (i = 0; i < paramArray.length; i++) {
        if (paramArray[i].param === "startFrom" && fromBeginning === true) {
            paramArray[i].value = 0
        }
        destUrl += "&" + paramArray[i].param + "=" + encodeURIComponent(paramArray[i].value);
    }
    return destUrl;
}

function changeDateRange(range) {
    var pageUrl = document.location.href;
    var paramArray = splitIntoParams(pageUrl);
    var flag = 0;
    for (i = 0; i < paramArray.length; i++) {
        if (paramArray[i].param == "dateRange") {
            flag = 1;
            paramArray[i].value = range;
        }
    }
    if (!flag)
        paramArray.push({
            param: 'dateRange',
            value: range
        });
    document.location.href = mergeIntoUrl(paramArray, true);
}

function clearAll() {
    var pageUrl = document.location.href;
    var paramArray = splitIntoParams(pageUrl);
    var projectId = null
    for (var i = 0; i < paramArray.length; i++) {
        if (paramArray[i].param === "projectId") {
            projectId = paramArray[i].value
        }
    }
    if (projectId != null) {
        return pageUrl.split("?")[0] + "?projectId=" + projectId
    }
    else {
        return pageUrl.split("?")[0]
    }

}

function mentionPostFunctionMentionTrending(divid, text) {
    var activeProjectId = JSON.stringify(activeProjectId)
    var maxLength = 600;
    // var myStr = $("#" + divid).html();
    var myStr = text
    if ($.trim(myStr).length > maxLength) {
        $('<a href="/showDetailedPost/projectId/' + projectId + '/mentionId/' + divid + '" class="read-more-' + divid + '"> read more...</a>').insertAfter("#description-" + divid);
    } else {
        $("#description-" + divid).removeClass('mention-post-desc-clip')
    }
}

function mentionPostFunctionMentionDetail(divid, text) {
    var activeProjectId = JSON.stringify(activeProjectId)
    var maxLength = 1000;
    // var myStr = $("#" + divid).html();
    var myStr = text
    if ($.trim(myStr).length > maxLength) {
        $('<a href="javascript:void(0);" class="read-more-' + divid + '"> read more...</div>').insertAfter("#" + divid);
    } else {
        $("#" + divid).removeClass('mentionDetail-post-desc-clip')
    }
    $(".read-more-" + divid).click(function () {
        $("#" + divid).removeClass('mentionDetail-post-desc-clip')
        $(".read-more-" + divid).remove()
    });

}

function mentionPostFunction(divid, text, tags) {
    var maxLength = 400;
    // var myStr = $("#" + divid).html();
    var myStr = text
    if ($.trim(myStr).length > maxLength) {
        var newStr = myStr.substring(0, maxLength);
        var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
        var oldStringBeautified = beautifyTags(newStr, tags)
        var newStringBeautified = beautifyTags(removedStr, tags)
        $("#" + divid).html(oldStringBeautified)
        $("#" + divid).append('<a href="javascript:void(0);" class="read-more-' + divid + '"> read more...</a>');
        $("#" + divid).append('<span id="more-text-' + divid + '" class="more-text">' + newStringBeautified + '</span>');
    } else {
        //console.log("tags : " + JSON.stringify(tags))
        var oldStringBeautified = beautifyTags(myStr, tags)
        $("#" + divid).html(oldStringBeautified)
    }
    $(".read-more-" + divid).click(function () {
        //- $("#"+divid).siblings(".more-text").contents().unwrap();
        $("#" + divid).css("height", "100%");
        $("#more-text-" + divid).contents().unwrap()
        $(".read-more-" + divid).remove();
    });
}

function mobilementionPostFunction(divid, text, tags) {
    var maxLength = 150;
    // var myStr = $("#" + divid).html();
    var myStr = text
    if ($.trim(myStr).length > maxLength) {
        var newStr = myStr.substring(0, maxLength);
        // var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
        var oldStringBeautified = beautifyTags(newStr, tags)
        // var newStringBeautified = beautifyTags(removedStr, tags)
        $("#" + divid).html(oldStringBeautified)
        // $("#" + divid).append('<a href="javascript:void(0);" class="read-more-' + divid + '"> read more...</a>');
        // $("#" + divid).append('<span id="more-text-' + divid + '" class="more-text">' + newStringBeautified + '</span>');
    } else {
        //console.log("tags : " + JSON.stringify(tags))
        var oldStringBeautified = beautifyTags(myStr, tags)
        $("#" + divid).html(oldStringBeautified)
    }
    // $(".read-more-" + divid).click(function () {
    //     //- $("#"+divid).siblings(".more-text").contents().unwrap();
    //     $("#" + divid).css("height", "100%");
    //     $("#more-text-" + divid).contents().unwrap()
    //     $(".read-more-" + divid).remove();
    // });
}

function detailedFunction(divid) {
    var maxLength = 400;
    var myStr = $("#" + divid).html();
    if ($.trim(myStr).length > maxLength) {
        var newStr = myStr.substring(0, maxLength);
        var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
        var oldStringBeautified = newStr
        var newStringBeautified = removedStr
        $("#" + divid).html(oldStringBeautified)
        $("#" + divid).append('<a href="javascript:void(0);" class="read-more-' + divid + '"> read more...</a>');
        $("#" + divid).append('<span id="more-text-' + divid + '" class="more-text">' + newStringBeautified + '</span>');
    }
    $(".read-more-" + divid).click(function () {
        $("#" + divid).removeClass('mentionDetail-post-desc-clip')
        //- $("#"+divid).siblings(".more-text").contents().unwrap();
        $("#" + divid).css("height", "100%");
        $("#more-text-" + divid).contents().unwrap()
        $(".read-more-" + divid).remove();
    });
}


$(document).ready(function () {
    var pageName = document.location.href
    if (pageName.indexOf("enterprise-dashboard") > -1) {
        $.ajax({
            url: "/fetch-trending-tokens",
            method: "POST",
            data: {
                projectId: currentActiveProject._id,
                startDateInEpoch: startDateInEpoch,
                endDateInEpoch: endDateInEpoch,
                userId: user._id
            },
            timeout: false,
            success: function (res) {
                if (res && res.status == "success") {
                    if (res.brandHashtags && res.brandHashtags.wordList && res.brandHashtags.wordList.length > 0) {
                        var hashtagHtml = ""
                        for (var index = 0; index < res.brandHashtags.wordList.length; index++) {
                            hashtagHtml += "<a href=" + res.brandHashtags.wordList[index].href + "'>"
                            hashtagHtml += "<p class='cloudParablock' style='font-size: " + res.brandHashtags.wordsFontSize[index] + "px; color: " + res.brandHashtags.wordsColorSelect[index] + "; transform: " + res.brandHashtags.wordsRotationSelect[index] + ";'>"
                            hashtagHtml += res.brandHashtags.wordList[index].text
                            hashtagHtml += "</p></a>"
                        }
                        $("#dashboard-hashtag").html(hashtagHtml)
                    } else {
                        noDataFoundHtml("dashboard-hashtag")
                    }
                    if (res.brandEntities && res.brandEntities.wordList && res.brandEntities.wordList.length > 0) {
                        var buzzwordHtml = ""
                        for (var index = 0; index < res.brandEntities.wordList.length; index++) {
                            buzzwordHtml += "<a href=" + res.brandEntities.wordList[index].href + "'>"
                            buzzwordHtml += "<p class='cloudParablock' style='font-size: " + res.brandEntities.wordsFontSize[index] + "px; color: " + res.brandEntities.wordsColorSelect[index] + "; transform: " + res.brandEntities.wordsRotationSelect[index] + ";'>"
                            buzzwordHtml += res.brandEntities.wordList[index].text
                            buzzwordHtml += "</p></a>"
                        }
                        $("#dashboard-buzzword").html(buzzwordHtml)
                    } else {
                        noDataFoundHtml("dashboard-buzzword")
                    }

                }
            },
            error: function (res) {

            }
        });
    }
})

function noDataFoundHtml(id) {
    var activeProject = currentActiveProject
    var html = ""
    if (activeProject && activeProject["createdAt"]) {
        var timeleft = Math.floor((new Date().getTime() - new Date(activeProject["createdAt"]).getTime()) / 3600000)
        if (timeleft <= 2) {
            html += "<div style='display: flex;align-items: center;justify-content: center;align-content: center;'>"
            html += "<div>"
            html += "<img class='nodatafound1' src='/assets/img/loading-data.gif' style='width:100px'>"
            html += "</div></div>"
        } else {
            html = "<img class='nodatafound' src='/assets/img/nodatafound.png'>"
        }
    } else {
        html = "<img class='nodatafound' src='/assets/img/nodatafound.png'>"
    }
    $("#" + id).html(html)
}
// global variables
var plotlyPlotData = [];
var competitionPlotlyReplot = [];
var chartjsGraphsLoaded = [];
var pieChartOfTotalMentionWebCountOnClick;
var pieChartOfTotalMentionSourceCountOnClick;
var graphWiseInfluencerCategoryDataOnClick;
var competitiveTotalMentionsPieChartOnClick;
var competitiveTopRelevantAuthorsOnClick;
var competitiveTotalMentionsPieChart;
var competitiveEngagementPieChart;
var competitiveTotalMentionsByTimePeriodLineChart;
var competitivePositiveMentionsByTimePeriodLineChart;
var competitiveNegativeMentionsByTimePeriodLineChart;
var competitiveArticleMentionsOnClick;

$(document).ready(function () {
  var modeBarButtons = [
    ["toImage"]
  ];
  var ultimateColors = [
    'rgb(176, 252, 204)', 'rgb(0,206,244)', 'rgb(200,228,0)', 'rgb(255,155,0)', 'rgb(175,157,200)', 'rgb(237,69,21)', 'rgb(45,107,182)', 'rgb(242,255,0)', 'rgb(218,181,69)', 'rgb(255,128,114)', 'rgb(241,0,82)', 'rgb(241,201,122)', 'rgb(127,103,191)', 'rgb(203,72,195)', 'rgb(235,247,249)', 'rgb(230,207,88)', 'rgb(248,0,118)', 'rgb(169,193,205)'
  ];

  //=========================================1st =======================now 1st==================================

  var scatterLayout = {

    plot_bgcolor: 'white',
    paper_bgcolor: 'white',
    hovermode: 'closest',
    hoveron: 'points',
    font: {
      family: 'Roboto,Helvetica Neue,Arial,sans-serif',
      size: 12,
      color: "black"
    },
    legend: {
      font: {
        size: 10,
        color: '#000'
      },
      "orientation": "h",
      y: -.7,
    },
    margin: {
      t: 20,
      // pad: 4,
      r: 10,
      b: 10,
      l: 50,
    },
    autosize: true,
    xaxis: {
      tickformat: '%d/%b',
      titlefont: {
        size: 10,
        color: '#000',
      },
      tickangle: -15,
      showticklabels: true,
      tickfont: {
        size: 10,
        color: '#000'
      },
      fixedrange: true,

      showgrid: false,

    },
    yaxis: {
      title: 'Counts',
      titlefont: {
        size: 10,
        color: '#000'
      },
      showticklabels: true,
      tickangle: 0,
      tickfont: {
        size: 10,
        color: '#000'
      },
      fixedrange: true,
      rangemode: 'nonnegative',
      autorange: true,
      showgrid: false,
    },
    height: 200

  }

  // if (document.getElementById('competitiveTotalMentionsByTimePeriod') && competitiveTotalMentionsByTimePeriod) {
  //   if (allCompetitorData.length > 0) {
  //     var projMentionCounts = []
  //     for (var i = 0; i < allCompetitorData.length; i++) {
  //       var totalCount = {
  //         x: allCompetitorData[i].sentimentMentionsByTimePeriod.dates,
  //         y: allCompetitorData[i].sentimentMentionsByTimePeriod.totalMentions,
  //         type: 'scatter',
  //         mode: 'lines+markers',
  //         name: allCompetitorData[i].projectName,
  //         hoverinfo: 'x+y',
  //         line: {
  //           color: ultimateColors[i]
  //         }
  //       }
  //       projMentionCounts.push(totalCount)
  //     }
  //   }

  //   var layout = JSON.parse(JSON.stringify(scatterLayout))
  //   layout['xaxis']['title'] = 'Date of Mention (' + competitorMetadata.dateFrom + '-' + competitorMetadata.dateTo + ')'
  //   layout['yaxis']['gridwidth'] = false
  //   layout['xaxis']['gridwidth'] = false

  //   plotlyPlotData.push({
  //     id: 'competitiveTotalMentionsByTimePeriod',
  //     data: projMentionCounts,
  //     layout: layout,
  //     modeBarButtons: modeBarButtons
  //   });
  //   Plotly.newPlot('competitiveTotalMentionsByTimePeriod', projMentionCounts, layout, {
  //     modeBarButtons: modeBarButtons,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  // }

  if (document.getElementById('competitiveTotalMentionsByTimePeriod') && competitiveTotalMentionsByTimePeriod) {
    if (allCompetitorData.length > 0) {
      var datasetForLineGraph = allCompetitorData.map(function (obj, index) {
        return {
          borderColor: ultimateColors[index],
          data: obj.engagementMentionsByTimePeriod.totalMentions,
          label: obj.projectName,
          backgroundColor: ultimateColors[index]
        }
      })

      var lineChartData = {
        labels: allCompetitorData[0].engagementMentionsByTimePeriod.dates,
        datasets: datasetForLineGraph
      }
      var startDate = moment(startDateInEpoch).format("DD MMM")
      var endDate = moment(endDateInEpoch).format("DD MMM")
      var totalMentionByTime = JSON.parse(JSON.stringify(lineChartLayout))
      totalMentionByTime.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + startDate + '-' + endDate + ')'
      totalMentionByTime.scales.xAxes[0].type = 'time'
      totalMentionByTime.scales.xAxes[0].time = {
        unit: 'day',
        displayFormats: {
          day: 'MMM D'
        },
        round: 'day',
      }
      var ctx = $('#competitiveTotalMentionsByTimePeriod');
      var graphData = {
        type: 'line',
        data: lineChartData,
        options: totalMentionByTime
      }
      competitiveTotalMentionsByTimePeriodLineChart = new Chart(ctx, graphData);
      chartjsGraphsLoaded.push({
        id: "competitiveTotalMentionsByTimePeriod",
        data: graphData,
        chartObj: competitiveTotalMentionsByTimePeriodLineChart,
        expandRatio: 1
      })

    }
  }

  if (document.getElementById('competitorTopicWiseTimeGraph0')) {
    if (allCompetitorData.length > 0) {
      for (var k = 0; k < allCompetitorData[0].featureChartData.topicWiseTimeDatas.length; k++) {
        {
          var idtoplot = "competitorTopicWiseTimeGraph" + k
          if (document.getElementById(idtoplot)) {
            var topicName = allCompetitorData[0].featureChartData.topicWiseTimeDatas[k].topics //fitness
            // plot this topic for all competitors
            var oneTopicGraphData = []
            for (var m = 0; m < allCompetitorData.length; m++) {
              if (allCompetitorData[m] && allCompetitorData[m].featureChartData && allCompetitorData[m].featureChartData.topicWiseTimeDatas) {
                var allCompetitor = allCompetitorData[m].featureChartData.topicWiseTimeDatas.filter(function (obj) { return obj.topics == topicName })
                //var competitor = allCompetitor[0]
                if (allCompetitor && allCompetitor.length > 0) {
                  var xData = allCompetitor && allCompetitor[0].dates ? allCompetitor[0].dates : []
                  var yData = allCompetitor && allCompetitor[0].counts ? allCompetitor[0].counts : []
                  var projectname = allCompetitorData[m].projectName
                  oneTopicGraphData.push({
                    x: xData,
                    y: yData,
                    type: 'scatter',
                    mode: 'lines+markers',
                    name: projectname,
                    hoverinfo: 'x+y',
                    line: {
                      color: ultimateColors[m]
                    }
                  })
                }
              }
            }
            //plot this topic
            var layout = JSON.parse(JSON.stringify(scatterLayout))
            layout['xaxis']['title'] = 'Date of Mention'
            layout['yaxis']['gridwidth'] = false
            layout['xaxis']['gridwidth'] = false
            layout["showlegend"] = true
            layout['margin'] = {
              t: 20,
              pad: 4,
              r: 10,
              b: 50,
              l: 50,
            }
            competitionPlotlyReplot.push({
              id: idtoplot,
              data: oneTopicGraphData,
              layout: layout,
              modeBarButtons: modeBarButtons
            });
            plotlyPlotData.push({
              id: idtoplot,
              data: oneTopicGraphData,
              layout: layout,
              modeBarButtons: modeBarButtons
            });
            Plotly.newPlot(idtoplot, oneTopicGraphData, layout, {
              modeBarButtons: modeBarButtons,
              displaylogo: false,
              showTips: false,
              responsive: true
            });
          }
        }
      }
    }
  }

  // if (document.getElementById('competitivePositiveMentionsByTimePeriod') && competitivePositiveMentionsByTimePeriod) {
  //   if (allCompetitorData.length > 0) {
  //     var projMentionCounts = []
  //     for (var i = 0; i < allCompetitorData.length; i++) {
  //       var positiveMentions = {
  //         x: allCompetitorData[i].sentimentMentionsByTimePeriod.dates,
  //         y: allCompetitorData[i].sentimentMentionsByTimePeriod.positiveMentions,
  //         type: 'scatter',
  //         mode: 'lines+markers',
  //         name: allCompetitorData[i].projectName,
  //         hoverinfo: 'x+y',
  //         line: {
  //           color: ultimateColors[i]
  //         }
  //       }
  //       projMentionCounts.push(positiveMentions)
  //     }
  //   }

  //   var layout = JSON.parse(JSON.stringify(scatterLayout))
  //   layout['xaxis']['title'] = 'Date of Mention (' + competitorMetadata.dateFrom + '-' + competitorMetadata.dateTo + ')'
  //   layout['yaxis']['gridwidth'] = false
  //   layout['xaxis']['gridwidth'] = false
  //   // console.log("rendering plotlyPlotData");
  //   plotlyPlotData.push({
  //     id: 'competitivePositiveMentionsByTimePeriod',
  //     data: projMentionCounts,
  //     layout: layout,
  //     modeBarButtons: modeBarButtons
  //   });
  //   Plotly.newPlot('competitivePositiveMentionsByTimePeriod', projMentionCounts, layout, {
  //     modeBarButtons: modeBarButtons,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  // }

  if (document.getElementById('competitivePositiveMentionsByTimePeriod') && competitivePositiveMentionsByTimePeriod) {
    if (allCompetitorData.length > 0) {
      var datasetForLineGraph = allCompetitorData.map(function (obj, index) {
        return {
          borderColor: ultimateColors[index],
          data: obj.sentimentMentionsByTimePeriod.positiveMentions,
          label: obj.projectName,
          backgroundColor: ultimateColors[index]
        }
      })

      var lineChartData = {
        labels: allCompetitorData[0].sentimentMentionsByTimePeriod.dates,
        datasets: datasetForLineGraph
      }
      var startDate = moment(startDateInEpoch).format("DD MMM")
      var endDate = moment(endDateInEpoch).format("DD MMM")
      var totalMentionByTime = JSON.parse(JSON.stringify(lineChartLayout))
      totalMentionByTime.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + startDate + '-' + endDate + ')'
      totalMentionByTime.scales.xAxes[0].type = 'time'
      totalMentionByTime.scales.xAxes[0].time = {
        unit: 'day',
        displayFormats: {
          day: 'MMM D'
        },
        round: 'day',
      }
      var ctx = $('#competitivePositiveMentionsByTimePeriod');
      var graphData = {
        type: 'line',
        data: lineChartData,
        options: totalMentionByTime
      }
      competitivePositiveMentionsByTimePeriodLineChart = new Chart(ctx, graphData);
      chartjsGraphsLoaded.push({
        id: "competitivePositiveMentionsByTimePeriod",
        data: graphData,
        chartObj: competitivePositiveMentionsByTimePeriodLineChart,
        expandRatio: 1
      })
    }
  }

  // if (document.getElementById('competitiveNegativeMentionsByTimePeriod') && competitiveNegativeMentionsByTimePeriod) {
  //   if (allCompetitorData.length > 0) {
  //     var projMentionCounts = []
  //     for (var i = 0; i < allCompetitorData.length; i++) {
  //       var negativeMentions = {
  //         x: allCompetitorData[i].sentimentMentionsByTimePeriod.dates,
  //         y: allCompetitorData[i].sentimentMentionsByTimePeriod.negativeMentions,
  //         type: 'scatter',
  //         mode: 'lines+markers',
  //         name: allCompetitorData[i].projectName,
  //         hoverinfo: 'x+y',
  //         line: {
  //           color: ultimateColors[i]
  //         }
  //       }
  //       projMentionCounts.push(negativeMentions)
  //     }
  //   }

  //   var layout = JSON.parse(JSON.stringify(scatterLayout))
  //   layout['xaxis']['title'] = 'Date of Mention (' + competitorMetadata.dateFrom + '-' + competitorMetadata.dateTo + ')'
  //   layout['yaxis']['gridwidth'] = false
  //   layout['xaxis']['gridwidth'] = false

  //   plotlyPlotData.push({
  //     id: 'competitiveNegativeMentionsByTimePeriod',
  //     data: projMentionCounts,
  //     layout: layout,
  //     modeBarButtons: modeBarButtons
  //   });
  //   Plotly.newPlot('competitiveNegativeMentionsByTimePeriod', projMentionCounts, layout, {
  //     // modeBarButtons: modeBarButtons,
  //     modeBarButtons: modeBarButtons,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  // }
  if (document.getElementById('competitiveNegativeMentionsByTimePeriod') && competitiveNegativeMentionsByTimePeriod) {
    if (allCompetitorData.length > 0) {
      var datasetForLineGraph = allCompetitorData.map(function (obj, index) {
        return {
          borderColor: ultimateColors[index],
          data: obj.sentimentMentionsByTimePeriod.negativeMentions,
          label: obj.projectName,
          backgroundColor: ultimateColors[index]
        }
      })

      var lineChartData = {
        labels: allCompetitorData[0].sentimentMentionsByTimePeriod.dates,
        datasets: datasetForLineGraph
      }
      var startDate = moment(startDateInEpoch).format("DD MMM")
      var endDate = moment(endDateInEpoch).format("DD MMM")
      var totalMentionByTime = JSON.parse(JSON.stringify(lineChartLayout))
      totalMentionByTime.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + startDate + '-' + endDate + ')'
      totalMentionByTime.scales.xAxes[0].type = 'time'
      totalMentionByTime.scales.xAxes[0].time = {
        unit: 'day',
        displayFormats: {
          day: 'MMM D'
        },
        round: 'day',
      }
      var ctx = $('#competitiveNegativeMentionsByTimePeriod');
      var graphData = {
        type: 'line',
        data: lineChartData,
        options: totalMentionByTime
      }
      competitiveNegativeMentionsByTimePeriodLineChart = new Chart(ctx, graphData);
      chartjsGraphsLoaded.push({
        id: "competitiveNegativeMentionsByTimePeriod",
        data: graphData,
        chartObj: competitiveNegativeMentionsByTimePeriodLineChart,
        expandRatio: 2
      })

    }
  }

  if (document.getElementById('competitiveTopRelevantAuthors')) {
    if (allCompetitorData.length > 0) {
      var influencerGraphData = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        // var followerCount = allCompetitorData[i].topRelevantAuthors.map(function (obj) { return obj.followerCount })
        var followerCount = allCompetitorData[i].topRelevantAuthors.map(function (obj) { return Math.ceil(Math.random() * 10) })
        var influencerCount = allCompetitorData[i].topRelevantAuthors.map(function (obj) { return Math.ceil(Math.random() * 10) + 10 })
        var showinfluencerCount = allCompetitorData[i].topRelevantAuthors.map(function (obj) { return ('Name - ' + obj.fullname + '<br>(Influencer Score - ' + obj.influenceScore + ')<br>Project Name - ' + allCompetitorData[i].projectName + '<br>Source - ' + obj.authorType) })
        var size = allCompetitorData[i].topRelevantAuthors.map(function (obj) { return (obj.influenceScore * 5 + 10) })
        var onClickData = allCompetitorData[i].topRelevantAuthors.map(function (obj) { return obj })

        var influencerGraph = {
          x: followerCount.slice(0, 5),
          y: influencerCount.slice(0, 5),
          clickData: onClickData.slice(0, 5),
          text: showinfluencerCount.slice(0, 5),
          marker: {
            size: size,
          },
          type: 'scatter',
          mode: 'markers',
          name: allCompetitorData[i].projectName,
          hoverinfo: 'text',
          line: {
            color: ultimateColors[i]
          }
        }
        influencerGraphData.push(influencerGraph)
      }
    }

    // var layout = JSON.parse(JSON.stringify(scatterLayout))
    // layout['xaxis']['title'] = 'Follower Count'
    // layout['xaxis']['zeroline'] = false
    // layout['xaxis']['showgrid'] = false
    // layout['yaxis']['showgrid'] = false
    // layout['xaxis']['zeroline'] = false
    // layout['xaxis']['title'] = ''
    // layout['height'] = 400
    // layout['legend']['y'] = -.3
    // layout['xaxis']['tickformat'] = ''

    var scatterLayout = {
      plot_bgcolor: 'white',
      paper_bgcolor: 'white',
      hovermode: 'closest',
      hoveron: 'points',
      font: {
        family: 'Roboto,Helvetica Neue,Arial,sans-serif',
        size: 12,
        color: "black"
      },
      legend: {
        font: {
          size: 10,
          color: '#000'
        },
        "orientation": "h",
        // y: -.3,
      },
      margin: {
        t: 5,
        r: 5,
        b: 5,
        l: 5,
      },
      autosize: true,
      xaxis: {
        // title: 'Follower Count',
        titlefont: {
          size: 10,
          color: '#000',
        },
        // tickangle: -15,
        showticklabels: true,
        tickfont: {
          size: 10,
          color: '#000'
        },
        fixedrange: true,
        showgrid: false,
        zeroline: false,
        visible: false,
      },
      yaxis: {
        titlefont: {
          size: 10,
          color: '#000'
        },
        showticklabels: true,
        tickangle: 0,
        tickfont: {
          size: 10,
          color: '#000'
        },
        visible: false,
        fixedrange: true,
        rangemode: 'nonnegative',
        autorange: true,
        showgrid: false,
        zeroline: false,
      },
      height: 350

    }
    competitiveTopRelevantAuthorsOnClick = function () {
      document.getElementById('competitiveTopRelevantAuthors').on('plotly_click', function (data) {
        console.log(data)
        var index = data.points[0].pointIndex
        var sourceId = data.points[0].data.clickData[index].authorType
        var userId = data.points[0].data.clickData[index].userId
        var pts = '/influencer/source/' + sourceId + '/id/' + userId;
        window.open(pts, '_blank');
      });
    }
    console.log(influencerGraphData)
    plotlyPlotData.push({
      id: 'competitiveTopRelevantAuthors',
      data: influencerGraphData,
      layout: scatterLayout,
      modeBarButtons: modeBarButtons,
      onClickFunctionName: 'competitiveTopRelevantAuthorsOnClick'
    });
    Plotly.newPlot('competitiveTopRelevantAuthors', influencerGraphData, scatterLayout, {
      // modeBarButtons: modeBarButtons,
      modeBarButtons: modeBarButtons,
      displaylogo: false,
      showTips: false,
      responsive: true
    });
    competitiveTopRelevantAuthorsOnClick()
  }


  if (document.getElementById('competitiveArticleMentions')) {
    if (allCompetitorData.length > 0 && allCompetitorData[0].articleMentions.length > 0) {
      var articleMentionsGraphData = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        // var followerCount = allCompetitorData[i].topRelevantAuthors.map(function (obj) { return obj.followerCount })
        var xData = allCompetitorData[i].articleMentions.map(function (obj) { return Math.ceil(Math.random() * 10) })
        var yData = allCompetitorData[i].articleMentions.map(function (obj) { return Math.ceil(Math.random() * 10) + 10 })
        var showArticleText = allCompetitorData[i].articleMentions.map(function (obj) { return ('Website - ' + obj.postUrl + '<br>(Influencer Score - ' + obj.influenceScore + ')<br>Project Name - ' + allCompetitorData[i].projectName) })
        var size = allCompetitorData[i].articleMentions.map(function (obj) { return (obj.influenceScore * 5) + 10 })
        var onClickData = allCompetitorData[i].articleMentions.map(function (obj) { return (obj) })
        var articleMentions = {
          x: xData.slice(0, 5),
          y: yData.slice(0, 5),
          text: showArticleText.slice(0, 5),
          clickData: onClickData.slice(0, 5),
          marker: {
            size: size,
          },
          type: 'scatter',
          mode: 'markers',
          name: allCompetitorData[i].projectName,
          hoverinfo: 'text',
          line: {
            color: ultimateColors[i]
          }
        }
        articleMentionsGraphData.push(articleMentions)
      }
    }

    var articleLayout = {
      plot_bgcolor: 'white',
      paper_bgcolor: 'white',
      hovermode: 'closest',
      hoveron: 'points',
      font: {
        family: 'Roboto,Helvetica Neue,Arial,sans-serif',
        size: 10,
        color: "black"
      },
      legend: {
        font: {
          size: 10,
          color: '#000'
        },
        orientation: "h",
        // y: -.,
      },
      margin: {
        t: 5,
        r: 5,
        b: 5,
        l: 5,
      },
      autosize: true,
      xaxis: {
        // title: 'Follower Count',
        titlefont: {
          size: 10,
          color: '#000',
        },
        // tickangle: -15,
        showticklabels: true,
        tickfont: {
          size: 10,
          color: '#000'
        },
        fixedrange: true,
        showgrid: false,
        zeroline: false,
        visible: false,
      },
      yaxis: {
        titlefont: {
          size: 10,
          color: '#000'
        },
        showticklabels: true,
        tickangle: 0,
        tickfont: {
          size: 10,
          color: '#000'
        },
        visible: false,
        fixedrange: true,
        rangemode: 'nonnegative',
        autorange: true,
        showgrid: false,
        zeroline: false,
      },
      height: 350

    }

    competitiveArticleMentionsOnClick = function () {
      document.getElementById('competitiveArticleMentions').on('plotly_click', function (data) {
        var index = data.points[0].pointIndex
        var projId = allCompetitorData[data.points[0].curveNumber].brandProject._id
        var articleId = data.points[0].data.clickData[index].postId
        var pts = '/showDetailedPost/projectId/' + projId + '/mentionId/' + articleId;
        window.open(pts, '_blank');
      });
    }
    // console.log(influencerGraphData)
    plotlyPlotData.push({
      id: 'competitiveArticleMentions',
      data: articleMentionsGraphData,
      layout: articleLayout,
      modeBarButtons: modeBarButtons,
      onClickFunctionName: 'competitiveArticleMentionsOnClick'
    });
    Plotly.newPlot('competitiveArticleMentions', articleMentionsGraphData, articleLayout, {
      // modeBarButtons: modeBarButtons,
      modeBarButtons: modeBarButtons,
      displaylogo: false,
      showTips: false,
      responsive: true
    });
    competitiveArticleMentionsOnClick()
  }




  var layoutInclination = {
    titlefont: {
      family: 'Roboto,Helvetica Neue,Arial,sans-serif',
      size: 14,
      color: '#545454'
    },
    polar: {
      radialaxis: {
        visible: false,
        // size: 12,
        // range: [0, maxele],
        autorange: true,
        // tickfont: {
        //   size: 10,
        //   color: "#000"
        // },
        angle: 90
      },
      angularaxis: {
        rotation: 90,
        tickfont: {
          family: 'Roboto,Helvetica Neue,Arial,sans-serif',
          size: 12,
          color: "#000"
        },
      },
    },
    showlegend: true,
  }

  if (document.getElementById('radarCompetitorsOverChannels')) {
    var rdata = []
    var theta = []
    var data = []
    for (var i = 0; i < allCompetitorData.length; i++) {
      var rdatas = allCompetitorData[i].mentionsBySourceBySentiment.sourceValue
      //rdatas.push(allCompetitorData[i].mentionsBySourceBySentiment.sourceValue[0])
      var thetas = allCompetitorData[i].mentionsBySourceBySentiment.sourceList
      //thetas.push(allCompetitorData[i].mentionsBySourceBySentiment.sourceList[0])
      data.push({
        type: 'scatterpolar',
        r: rdatas,
        theta: thetas,
        fill: 'toself',
        name: allCompetitorData[i].projectName
      })
    }

    var layout = JSON.parse(JSON.stringify(layoutInclination))
    // layout['legend'] = { 'orientation': 'h' }

    plotlyPlotData.push({
      id: 'radarCompetitorsOverChannels',
      data: data,
      layout: layout,
      modeBarButtons: modeBarButtons,
    });
    Plotly.plot("radarCompetitorsOverChannels", data, layout, {
      modeBarButtons: modeBarButtons,
      displaylogo: false,
      showTips: false,
      responsive: true
    })
  }


  if (document.getElementById('radarCompetitorsOverSentiments')) {
    var theta = ['Positive', 'Neutral', 'Negative']
    var data = []
    for (var i = 0; i < allCompetitorData.length; i++) {
      var rdata = []

      rdata.push(allCompetitorData[i].sentimentMentionsByTimePeriod.positiveMentions.reduce(function (a, b) { return a + b }))
      rdata.push(allCompetitorData[i].sentimentMentionsByTimePeriod.neutralMentions.reduce(function (a, b) { return a + b }))
      rdata.push(allCompetitorData[i].sentimentMentionsByTimePeriod.negativeMentions.reduce(function (a, b) { return a + b }))
      //rdatas.push(allCompetitorData[i].mentionsBySourceBySentiment.sourceValue[0])
      // var thetas = allCompetitorData[i].sentimentMentionsByTimePeriod.sourceList
      //thetas.push(allCompetitorData[i].mentionsBySourceBySentiment.sourceList[0])
      data.push({
        type: 'scatterpolar',
        r: rdata,
        theta: theta,
        fill: 'toself',
        name: allCompetitorData[i].projectName
      })
    }

    var layout = JSON.parse(JSON.stringify(layoutInclination))
    // layout['title'] = 'Channel Inclination'

    plotlyPlotData.push({
      id: 'radarCompetitorsOverSentiments',
      data: data,
      layout: layout,
      modeBarButtons: modeBarButtons,
    });
    Plotly.plot("radarCompetitorsOverSentiments", data, layout, {
      modeBarButtons: modeBarButtons,
      displaylogo: false,
      showTips: false,
      responsive: true
    })
  }




  if (document.getElementById('radarCompetitorsOverFeatureChartData')) {
    //var otherThetas = []
    var graphTraces = []
    for (var i = 0; i < allCompetitorData.length; i++) {
      //rdatas.push(allCompetitorData[i].mentionsBySourceBySentiment.sourceValue[0])
      // var thetas = allCompetitorData[i].featureChartData.topicDivision.keys
      if (allCompetitorData[i] && allCompetitorData[i].featureChartData && allCompetitorData[i].featureChartData.topicDivision && allCompetitorData[i].featureChartData.topicDivision.keys && allCompetitorData[i].featureChartData.topicDivision.keys.length > 0) {

        var topicWiseData = []
        var allTopics = allCompetitorData[i].featureChartData.topicDivision.keys
        var allTopicCounts = allCompetitorData[i].featureChartData.topicDivision.counts
        for (var j = 0; j < allTopics.length; j++) {
          topicWiseData.push({ topic: allTopics[j], count: allTopicCounts[j] })
        }
        console.log(topicWiseData)

        topicWiseData = topicWiseData.sort(function (a, b) {
          if (a.topic > b.topic) return 1;
          else
            return -1;
        })
        console.log(topicWiseData)

        var thetas = topicWiseData.map(function (data) { return data.topic })
        var rdatas = topicWiseData.map(function (data) { return data.count })
        console.log(thetas)
        console.log(rdatas)
        //thetas.push(allCompetitorData[i].mentionsBySourceBySentiment.sourceList[0])
        console.log(topicWiseData)
        graphTraces.push({
          type: 'scatterpolar',
          r: rdatas,
          theta: thetas,
          fill: 'toself',
          name: allCompetitorData[i].projectName
        })
      }
    }

    var layout = JSON.parse(JSON.stringify(layoutInclination))
    // layout['title'] = 'Topic Division Inclination'

    plotlyPlotData.push({
      id: 'radarCompetitorsOverFeatureChartData',
      data: graphTraces,
      layout: layout,
      modeBarButtons: modeBarButtons,
    });
    Plotly.plot("radarCompetitorsOverFeatureChartData", graphTraces, layout, {
      modeBarButtons: modeBarButtons,
      displaylogo: false,
      showTips: false,
      responsive: true
    })
  }



  var stacklayout = {
    titlefont: {
      family: 'Roboto,Helvetica Neue,Arial,sans-serif',
      size: 12,
      color: '#000'
    },
    margin: {
      // l: 100,
      // t: 50,
      // r: 10,
      // b: 50,
      pad: 5,
    },
    font: {
      family: 'Roboto,Helvetica Neue,Arial,sans-serif',
      size: 10,
      color: "black"
    },
    legend: {
      font: {
        size: 10,
        color: '#000'
      }
    },
    xaxis: {
      titlefont: {
        size: 10,
        color: '#000'
      },
      fixedrange: true,
    },
    yaxis: {
      titlefont: {
        size: 10,
        color: '#000'
      },
      fixedrange: true,
    },
    barmode: 'stack',
    //height: height
  };

  // if (document.getElementById('competitorsOverSentimentsBarGraph')) {
  //   var xdata = ['Positive', 'Neutral', 'Negative']
  //   var data = []
  //   for (var i = 0; i < allCompetitorData.length; i++) {
  //     var ydata = []
  //     ydata.push(allCompetitorData[i].sentimentMentionsByTimePeriod.positiveMentions.reduce(function (a, b) { return a + b }))
  //     ydata.push(allCompetitorData[i].sentimentMentionsByTimePeriod.neutralMentions.reduce(function (a, b) { return a + b }))
  //     ydata.push(allCompetitorData[i].sentimentMentionsByTimePeriod.negativeMentions.reduce(function (a, b) { return a + b }))
  //     //rdatas.push(allCompetitorData[i].mentionsBySourceBySentiment.sourceValue[0])
  //     // var thetas = allCompetitorData[i].sentimentMentionsByTimePeriod.sourceList
  //     //thetas.push(allCompetitorData[i].mentionsBySourceBySentiment.sourceList[0])
  //     data.push({
  //       type: 'bar',
  //       y: ydata,
  //       x: xdata,
  //       name: allCompetitorData[i].projectName,
  //       marker: {
  //         color: ultimateColors[i],
  //         opacity: 0.7,
  //       },
  //     })
  //   }

  //   var layout = JSON.parse(JSON.stringify(stacklayout))
  //   layout['barmode'] = 'group'
  //   layout['legend']['orientation'] = 'h'
  //   layout['margin'] = {
  //     l: 40,
  //     t: 10,
  //     r: 10,
  //     b: 40,
  //     pad: 5,
  //   }


  //   plotlyPlotData.push({
  //     id: 'competitorsOverSentimentsBarGraph',
  //     data: data,
  //     layout: layout,
  //     modeBarButtons: modeBarButtons,
  //   });
  //   Plotly.plot("competitorsOverSentimentsBarGraph", data, layout, {
  //     modeBarButtons: modeBarButtons,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   })
  // }



  // if (document.getElementById('competitiveChannelWiseDistribution') && competitiveChannelWiseDistribution) {
  //   if (allCompetitorData.length > 0) {
  //     var channelData = []
  //     for (var i = 0; i < allCompetitorData.length; i++) {
  //       var channels = {
  //         y: allCompetitorData[i].mentionsBySourceBySentiment.sourceValue,
  //         x: allCompetitorData[i].mentionsBySourceBySentiment.sourceList,
  //         type: 'bar',
  //         name: allCompetitorData[i].projectName,
  //         marker: {
  //           color: ultimateColors[i],
  //           opacity: 0.7,
  //         },
  //         hoverinfo: 'x+y',
  //         //     orientation: 'h',

  //       }
  //       channelData.push(channels)
  //     }
  //   }
  //   var layout = JSON.parse(JSON.stringify(stacklayout))
  //   // layout['title'] = 'Channel-wise distribution of mentions'
  //   plotlyPlotData.push({
  //     id: 'competitiveChannelWiseDistribution',
  //     data: channelData,
  //     layout: layout,
  //     modeBarButtons: modeBarButtons,
  //   });
  //   Plotly.newPlot('competitiveChannelWiseDistribution', channelData, layout, {
  //     modeBarButtons: modeBarButtons,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  // }


  // if (document.getElementById('competitiveAudienceGenderAggregation')) {
  //   if (allCompetitorData.length > 0) {
  //     var channelData = []
  //     for (var i = 0; i < allCompetitorData.length; i++) {
  //       var genders = allCompetitorData[i].audienceGenderAggregation.map(function (obj) { return obj.key })
  //       var counts = allCompetitorData[i].audienceGenderAggregation.map(function (obj) { return obj.doc_count })
  //       var channels = {
  //         y: counts,
  //         x: genders,
  //         type: 'bar',
  //         name: allCompetitorData[i].projectName,
  //         marker: {
  //           color: ultimateColors[i],
  //           opacity: 0.7,
  //         },
  //         hoverinfo: 'x+y',
  //         //     orientation: 'h',

  //       }
  //       channelData.push(channels)
  //     }
  //   }

  //   var layout = JSON.parse(JSON.stringify(stacklayout))
  //   layout['legend'] = { orientation: 'h' }
  //   layout['margin'] = {
  //     l: 50,
  //     t: 40,
  //     r: 20,
  //     b: 40,
  //     pad: 5,
  //   }
  //   plotlyPlotData.push({
  //     id: 'competitiveAudienceGenderAggregation',
  //     data: channelData,
  //     layout: layout,
  //     modeBarButtons: modeBarButtons,
  //   });
  //   Plotly.newPlot('competitiveAudienceGenderAggregation', channelData, layout, {
  //     modeBarButtons: modeBarButtons,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  // }

  // if (document.getElementById('competitiveAudienceCategoryAggregation')) {
  //   if (allCompetitorData.length > 0) {
  //     var channelData = []
  //     for (var i = 0; i < allCompetitorData.length; i++) {
  //       var genders = allCompetitorData[i].audienceCategoryAggregation.map(function (obj) { return changeInfluencerType(obj.key) })
  //       var counts = allCompetitorData[i].audienceCategoryAggregation.map(function (obj) { return obj.doc_count })
  //       var channels = {
  //         y: counts,
  //         x: genders,
  //         type: 'bar',
  //         name: allCompetitorData[i].projectName,
  //         marker: {
  //           color: ultimateColors[i],
  //           opacity: 0.7,
  //         },
  //         hoverinfo: 'x+y',
  //         //     orientation: 'h',

  //       }
  //       channelData.push(channels)
  //     }
  //   }

  //   var layout = JSON.parse(JSON.stringify(stacklayout))
  //   layout['legend'] = { orientation: 'h', y: -.3 }
  //   layout['xaxis']['tickangle'] = -25
  //   layout['margin'] = {
  //     l: 50,
  //     t: 40,
  //     r: 40,
  //     b: 40,
  //     pad: 5,
  //   }

  //   plotlyPlotData.push({
  //     id: 'competitiveAudienceCategoryAggregation',
  //     data: channelData,
  //     layout: layout,
  //     modeBarButtons: modeBarButtons,
  //   });
  //   Plotly.newPlot('competitiveAudienceCategoryAggregation', channelData, layout, {
  //     modeBarButtons: modeBarButtons,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  // }



  if (document.getElementById('competitiveSocialMediaInteractionsCounts')) {
    if (allCompetitorData.length > 0) {
      var interactionData = []
      var totalLikesCounts = 0
      var totalCommentsCounts = 0
      var totalSharesCounts = 0
      for (var i = 0; i < allCompetitorData.length; i++) {
        var obj = allCompetitorData[i].socialMediaCounts
        if (obj.likesCount)
          totalLikesCounts += obj.likesCount;
        if (obj.commentsCount)
          totalCommentsCounts += obj.commentsCount;
        if (obj.sharesCount)
          totalSharesCounts += obj.sharesCount;
      }
      for (var i = 0; i < allCompetitorData.length; i++) {

        var likesPercentage = Math.round(((allCompetitorData[i].socialMediaCounts.likesCount / totalLikesCounts) * 100) * 100) / 100
        var commentsPercentage = Math.round(((allCompetitorData[i].socialMediaCounts.commentsCount / totalCommentsCounts) * 100) * 100) / 100
        var sharesPercentage = Math.round(((allCompetitorData[i].socialMediaCounts.sharesCount / totalSharesCounts) * 100) * 100) / 100

        var labels = ['Comments', 'Shares', 'Likes']
        var values = [commentsPercentage, sharesPercentage, likesPercentage]
        var text = [commentsPercentage + '%', sharesPercentage + '%', likesPercentage + '%']
        var interactions = {
          y: labels,
          x: values,
          text: text,
          type: 'bar',
          orientation: 'h',
          name: allCompetitorData[i].projectName,
          marker: {
            color: ultimateColors[i],
            opacity: 0.7,
          },
          hoverinfo: 'text',

        }
        interactionData.push(interactions)
      }
    }

    var layout = JSON.parse(JSON.stringify(stacklayout))
    layout['margin']['pad'] = '10'
    layout['xaxis']['zeroline'] = false
    layout['xaxis']['visible'] = false
    layout['xaxis']['showgrid'] = false
    layout['yaxis']['showgrid'] = false
    layout['yaxis']['zeroline'] = false
    layout['height'] = 300


    plotlyPlotData.push({
      id: 'competitiveSocialMediaInteractionsCounts',
      data: interactionData,
      layout: layout,
      modeBarButtons: modeBarButtons,
    });
    Plotly.newPlot('competitiveSocialMediaInteractionsCounts', interactionData, layout, {
      modeBarButtons: modeBarButtons,
      displaylogo: false,
      showTips: false,
      responsive: true
    });
  }


  var inclinationLayout = {
    // title: 'Social Media Inclination',
    // titlefont: {
    //   family: 'Roboto,Helvetica Neue,Arial,sans-serif',
    //   size: 14,
    //   color: '#545454'
    // },
    sort: false,
    showlegend: false,
    legend: {
      // "orientation": "h",
      size: 8,
      // y: 0.5
    },
    margin: {
      b: 30,
      t: 30,
      l: 10,
      r: 10,
      pad: 0,
    },
    autosize: true,
    paper_bgcolor: 'rgba(0,0,0,0)',
    paper_bgcolor: 'rgba(0,0,0,0)',
    font: {
      size: 10,
    },
    height: 200,
  };



  // if (document.getElementById('competitiveTotalMentionsPieChart')) {

  //   if (allCompetitorData.length > 0) {
  //     var values = []
  //     var labels = []
  //     var showText = []
  //     for (var i = 0; i < allCompetitorData.length; i++) {
  //       var total = allCompetitorData[i].sentimentMentionsByTimePeriod.totalMentions.reduce(function (a, b) { return a + b })
  //       values.push(total)
  //       labels.push(allCompetitorData[i].projectName)
  //       showText.push(allCompetitorData[i].projectName + '(' + total + ')')
  //     }
  //     var data = [{
  //       values: values,
  //       labels: labels,
  //       type: 'pie',
  //       marker: {
  //         colors: ultimateColors
  //       },
  //       text: showText,
  //       // textinfo: 'none',
  //       textinfo: 'text+percent',
  //       hoverinfo: "text+percent",
  //       textposition: 'outside',
  //       hole: .4
  //     }];
  //   }

  //   var layout = {
  //     height: 200,
  //     margin: {
  //       b: 30,
  //       t: 30,
  //       l: 10,
  //       r: 10,
  //       pad: 0,
  //     },
  //     font: {
  //       size: 10,
  //     },
  //     showlegend: false,
  //     legend: {
  //       orientation: 'h'
  //     }
  //   };

  //   competitiveTotalMentionsPieChartOnClick = function () {
  //     document.getElementById('competitiveTotalMentionsPieChart').on('plotly_click', function (data) {
  //       var projId = null
  //       for (var i = 0; i < selectedCompetitiveProjects.length; i++) {
  //         if (selectedCompetitiveProjects[i].projectName == data.points[0].label) {
  //           projId = selectedCompetitiveProjects[i]._id
  //         }
  //       }
  //       var pts = '/enterprise-mention?&projectId=' + projId
  //       // pts = pts
  //       window.open(pts, '_blank');
  //     });
  //   }
  //   // layout['legend']['x'] = -0.5
  //   plotlyPlotData.push({
  //     id: 'competitiveTotalMentionsPieChart',
  //     data: data,
  //     layout: layout,
  //     onClickFunctionName: 'competitiveTotalMentionsPieChartOnClick'
  //   });
  //   Plotly.newPlot('competitiveTotalMentionsPieChart', data, layout, {
  //     displayModeBar: false,
  //     // modeBarButtons: modeBarButtons,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  //   competitiveTotalMentionsPieChartOnClick()

  // }

  if (document.getElementById('competitiveTotalMentionsPieChart')) {

    if (allCompetitorData.length > 0) {
      var values = []
      var labels = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        var total = allCompetitorData[i].engagementMentionsByTimePeriod.totalMentions.reduce(function (a, b) { return a + b })
        values.push(total)
        labels.push(allCompetitorData[i].projectName)
      }

      var PieChartData = {
        labels: labels,
        datasets: [{
          data: values,
          backgroundColor: ultimateColors,
          borderColor: ultimateColors,
        }]
      }
      var ctx = $('#competitiveTotalMentionsPieChart');
      var graphData = {
        type: 'doughnut',
        data: PieChartData,
        options: JSON.parse(JSON.stringify(doughnutChartLayout)),
      }
      competitiveTotalMentionsPieChart = new Chart(ctx, graphData)
      chartjsGraphsLoaded.push({
        id: "competitiveTotalMentionsPieChart",
        data: graphData,
        chartObj: competitiveTotalMentionsPieChart,
        expandRatio: 2
      })
      if (competitiveTotalMentionsPieChart) {
        document.getElementById('competitiveTotalMentionsPieChart').onclick = function (evt) {
          var activePoint = competitiveTotalMentionsPieChart.getElementAtEvent(evt)[0];
          var data = activePoint._model;
          var codeSource = data.label
          var currentId = allCompetitorData.filter(function (obj) {
            if (obj.projectName === codeSource) return true
            return false
          })
          var url = '/enterprise-mention?&projectId=' + currentId[0].brandProject._id + mergeIntoUrl(splitIntoParams(document.location.href)).split("?")[1]
          document.location.href = url
        };
      }

    }
  }

  if (document.getElementById('competitiveEngagementPieChart')) {

    if (allCompetitorData.length > 0) {
      var values = []
      var labels = []
      for (var i = 0; i < allCompetitorData.length; i++) {
        values.push(allCompetitorData[i].engagementTotal)
        labels.push(allCompetitorData[i].projectName)
      }

      var PieChartData = {
        labels: labels,
        datasets: [{
          data: values,
          backgroundColor: ultimateColors,
          borderColor: ultimateColors,
        }]
      }
      var ctx = $('#competitiveEngagementPieChart');
      var graphData = {
        type: 'doughnut',
        data: PieChartData,
        options: JSON.parse(JSON.stringify(doughnutChartLayout)),
      }
      competitiveEngagementPieChart = new Chart(ctx, graphData)
      chartjsGraphsLoaded.push({
        id: "competitiveEngagementPieChart",
        data: graphData,
        chartObj: competitiveEngagementPieChart,
        expandRatio: 2
      })

      competitiveTotalMentionsPieChartOnClick = function () {

        document.getElementById('competitiveTotalMentionsPieChart').on('plotly_click', function (data) {
          var projId = null
          for (var i = 0; i < selectedCompetitiveProjects.length; i++) {
            if (selectedCompetitiveProjects[i].projectName == data.points[0].label) {
              projId = selectedCompetitiveProjects[i]._id
            }
          }
          var pts = '/enterprise-mention?&projectId=' + projId
          // pts = pts
          window.open(pts, '_blank');
        });
      }

    }
  }




  // if (document.getElementById('competitiveTotalMentionsPieChart') && competitiveTotalMentionsPieChart) {
  //   var layout = JSON.parse(JSON.stringify(inclinationLayout))
  //   layout['showlegend'] = true
  //   // layout['legend']['x'] = -0.5


  //   Plotly.newPlot('competitiveTotalMentionsPieChart', [competitiveTotalMentionsPieChart], layout, {
  //     displayModeBar: false,
  //     // modeBarButtons: modeBarButtons,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  // }


  if (document.getElementById('pieChartOfTotalMentionWebCount') && pieChartOfTotalMentionWebCount) {
    var projectsName = []
    for (var i = 0; i < pieChartOfTotalMentionWebCount.projects.length; i++) {
      projectsName.push(pieChartOfTotalMentionWebCount.projects[i].projectName)
    }
    var textInBracket = []
    for (var i = 0; i != pieChartOfTotalMentionWebCount.projects.length; i++) {
      textInBracket.push(pieChartOfTotalMentionWebCount.projects[i].projectName + "(" + pieChartOfTotalMentionWebCount.totalMentionWebCount[i] + ")")
    }
    var webCountData = [{
      values: pieChartOfTotalMentionWebCount.totalMentionWebCount,
      labels: projectsName,
      text: textInBracket,
      type: 'pie',
      textinfo: 'text',
      marker: {
        colors: ultimateColors
      },
      textposition: 'outside',
      hoverinfo: 'text+percent',
      hole: .4,
    }]

    pieChartOfTotalMentionWebCountOnClick = function () {
      document.getElementById('pieChartOfTotalMentionWebCount').on('plotly_click', function (data) {
        var projId = null
        for (var i = 0; i < selectedCompetitiveProjects.length; i++) {
          if (selectedCompetitiveProjects[i].projectName == data.points[0].label) {
            projId = selectedCompetitiveProjects[i]._id
          }
        }
        var pts = '/enterprise-mention?&projectId=' + projId;
        pts = pts + '&sourceType=TWEET&sourceType=INSTAPOST&sourceType=FBCOMMENT&sourceType=FBPOST&sourceType=YTCOMMENT'
        window.open(pts, '_blank');
      });
    }
    plotlyPlotData.push({
      id: 'pieChartOfTotalMentionWebCount',
      data: webCountData,
      layout: inclinationLayout,
      onClickFunctionName: 'pieChartOfTotalMentionWebCountOnClick'
    });
    Plotly.newPlot('pieChartOfTotalMentionWebCount', webCountData, inclinationLayout, {
      displayModeBar: false,
      // modeBarButtons: modeBarButtons,
      displaylogo: false,
      showTips: false,
      responsive: true
    });
    pieChartOfTotalMentionWebCountOnClick()


  }




  /* if (document.getElementById('competitiveSourceMentionsByTimePeriod') && competitiveSourceMentionsByTimePeriod) {
    if (competitiveSourceMentionsByTimePeriod.totalCount.length > 0) {
      var totalCounts = competitiveSourceMentionsByTimePeriod.totalCount
      var projMentionCounts = []
      for (var i = 0; i < totalCounts.length; i++) {
        var totalCount = {
          x: competitiveSourceMentionsByTimePeriod.dates,
          y: totalCounts[i],
          // type: 'scatter',
          mode: 'markers',
          name: competitiveSourceMentionsByTimePeriod.projects[i].projectName,
          hoverinfo: 'y+name',
          line: {
            color: ultimateColors[i]
          },
          marker: {
            size: 14
          }
        }
        projMentionCounts.push(totalCount)
      }
    }
 
    var layout = {
      plot_bgcolor: 'white',
      paper_bgcolor: 'white',
      hovermode: 'closest',
      hoveron: 'points',
      font: {
        family: 'Roboto,Helvetica Neue,Arial,sans-serif',
        size: 12,
        color: "black"
      },
      legend: {
        font: {
          size: 10,
          color: '#000'
        },
        "orientation": "h",
        y: -.2,
      },
      margin: {
        t: 10,
        // pad: 4,
        r: 10,
        b: 0,
        l: 50,
      },
      autosize: true,
      xaxis: {
        title: 'Date of Mention (' + competitiveSourceMentionsByTimePeriod.dateFrom + '-' + competitiveSourceMentionsByTimePeriod.dateTo + ')',
        tickformat: '%d/%b',
        titlefont: {
          size: 10,
          color: '#000',
        },
        tickangle: -15,
        showticklabels: true,
        tickfont: {
 
          size: 10,
          color: '#000'
        },
        // gridwidth: false,
        showgrid: false,
        fixedrange: true,
        showline: true,
        zeroline: false
      },
      yaxis: {
        title: 'Counts',
        titlefont: {
          size: 10,
          color: '#000'
        },
        showticklabels: true,
        tickangle: 0,
        tickfont: {
          size: 10,
          color: '#000'
        },
        // rangemode: 'nonnegative',
        fixedrange: true,
        // gridwidth: false,
        showgrid: false,
        showline: true,
        zeroline: false
      },
      height: 300
 
    }
    plotlyPlotData.push({
      id: 'competitiveSourceMentionsByTimePeriod',
      data: projMentionCounts,
      layout: layout,
      modeBarButtons: modeBarButtons,
    });
    Plotly.newPlot('competitiveSourceMentionsByTimePeriod', projMentionCounts, layout, {
      modeBarButtons: modeBarButtons,
      displaylogo: false,
      showTips: false,
      responsive: true
    });
  }*/
})


function heightOfhorizontalBar(length) {
  var height = 200
  for (var i = 0; i < length; i++) {
    if (i < 2)
      height = 200
    else
      height += 50
  }
  return height
}

function truncateTexts(namesArrays) {
  var projectsNames = []
  for (var i = 0; i < namesArrays.length; i++) {
    if (namesArrays[i].length > 15) {
      projectsNames.push(namesArrays[i].substr(0, 11).concat('...'))
    } else {
      projectsNames.push(namesArrays[i])
    }
  }
  return projectsNames
}


function changeInfluencerType(influencerType) {
  if (influencerType === 'mega')
    return 'Mega-Influencer(Celebrity)'
  else if (influencerType === 'macro')
    return 'Macro-Influencer'
  else if (influencerType === 'micro')
    return 'Micro-Influencer'
  else if (influencerType === 'social_profile')
    return 'Social Profile'
  else
    return influencerType
}

$(document).ready(function () {
  var pageName = document.location.href;
  var totalProj = typeof (selectedCompetitiveProjects) != 'undefined' ? selectedCompetitiveProjects.length : 0;
  function makeCompareSentimentAjax(projIds, projIndex) {
    var url = "/fetch-compareSentiment-trending-tokens?"
    if (pageName.split('?').length > 1) {
      url = url + pageName.split('?')[1]
    }
    $.ajax({
      url: url,
      method: "POST",
      data: {
        projIds: projIds,
        projectId: activeProject._id,
        startDateInEpoch: startDateInEpoch,
        endDateInEpoch: endDateInEpoch,
        userId: user._id
      },
      timeout: 0,
      success: function (res) {
        if (res && res.status == "success") {

          if (res.comparisonChoiceData && res.comparisonChoiceData.length > 0) {
            var comparisonChoiceData = []
            // console.log("res comparisonChoiceData 0" + res.comparisonChoiceData[0].projectName + " and 1 " + res.comparisonChoiceData[1].projectName)
            var ids = res.comparisonChoiceData.map(function (b) { if (b && b.brandProject && b.brandProject['_id']) { return b.brandProject['_id'] } })
            for (var a = 0; a < projIds.length; a++) {
              comparisonChoiceData.push(res.comparisonChoiceData[ids.findIndex(function (m) { if (m == projIds[a]) { return true } })])
            }
            // console.log("comparisonChoiceData 0" + comparisonChoiceData[0].projectName + " and 1 " + comparisonChoiceData[1].projectName)

            for (var i = 0; i < comparisonChoiceData.length; i++) {
              var projNumber = projIndex + i
              $("#mentionsPositiveProjectName" + projNumber).html("Top Positive Mentions for " + comparisonChoiceData[i].projectName)
              if (comparisonChoiceData[i] && comparisonChoiceData[i].positiveMentions && comparisonChoiceData[i].positiveMentions.length > 0) {
                var hashtagHtml = ''
                for (var index = 0; index < comparisonChoiceData[i].positiveMentions.length; index++) {
                  var post = comparisonChoiceData[i].positiveMentions[index]
                  var postDescription = post.postDescription && post.postDescription.length > 250 ? post.postDescription.substring(0, 250) + "..." : post.postDescription
                  var mentionBody = ''
                  var defaultImg = "'/images/fallback-img-post-2.png'"
                  if (post.videos) {
                    mentionBody = '<div class="picturedesc col-xs-12 text-center"><a href="' + post.engageUrl + '" target="_blank"><img  src="/images/icons/videoicon.png" /></a></div>\
                      <div class="textdesc text-left col-xs-12">\
                        <p class="mention_text text-left onhoverdisplay show-read-more mention-post-desc-clip" style="margin-left: 0" id="description-'+ post.postId + '"> ' + postDescription + '\
                        </p>\
                    </div>'
                  }
                  else if (post.images && post.images[0]) {
                    mentionBody = '<div class="picturedesc col-xs-12">\
                    <div class="item active mentiondescImageSize"><a href="'+ post.engageUrl + '" target="_blank"><img id="resizeImage" src="' + post.images[0] + '" onerror="this.src=' + defaultImg + '" style="max-height:200px" /></a>\
                    </div></div>\
                  <div class="textdesc text-left col-xs-12">\
                    <p class="mention_text text-left onhoverdisplay show-read-more mention-post-desc-clip" style="margin-left: 0" id="description-'+ post.postId + '">' + postDescription + '</p>\
                  </div>'
                  }
                  else {
                    mentionBody = '<div class="col-sm-12 textdesc text-left">\
                    <p class="mention_text text-left onhoverdisplay show-read-more mention-post-desc-clip" style="margin-left: 0" id="description-'+ post.postId + '"> ' + postDescription + '\
                    </p>\
                  </div>'
                  }
                  var faviconIcon = undefined
                  var sentimenthtml = ''
                  if (post.sentiment) {
                    sentimenthtml = '<div class="mention-information" style="margin-top: 0; display: flex;">\
                                      <div class="flexdirectionRow score text-right mention-optionType"  style="display: flex;align-items: center;">\
                                        <div class="font-size-12 text-left mention-valueType">\
                                          <div class="sentiment-type sentiment'+ post.sentiment + '"></div>\
                                        </div>\
                                        <div class="font-size-12 mention-optionName">Sentiment</div>\
                                      </div>\
                                    </div>'
                  }
                  var websitename = ''
                  if (post.postSocialMedia == "Article" || post.postSocialMedia == "Review") {
                    faviconIcon = 'https://www.google.com/s2/favicons?domain=' + post.postUrl
                    websitename = '<div class="mention-title-url"><img src="' + faviconIcon + '" /><span> ' + post.postUrl + '</span></div>'
                  }
                  hashtagHtml += '<div class="topMentionsDiv">\
                  <div class="col-sm-12 mentionBox row text-center selectedSentiment mention_post_walkthrough0 aos-init aos-animate" data-aos="fade-up" id="main-section-' + post.postId + '">\
                    <section class="mention-box1 mention-site-2">\
                      <section class="mention-box mention-site-2 mentionBorderDisplay" style="margin-bottom: 0;box-shadow: none;">\
                        <div class="header-enterprise" id="mention_post_walkthrough-header' + index + '" style="padding:0;">\
                          <div class="filteravatar flexdirectionRow">\
                            <div class="author">\
                              <div class="mention-title-box mention-hidden-title"><a href="/showDetailedPost/projectId/'+ comparisonChoiceData[i].brandProject["_id"] + '/mentionId/' + post.postId + '"><i class="fa fa-' + post.iconImage + '"></i><span>' + post.postTitle + '</span></a></div>\
                              '+ websitename + '<span class="mention-date"><i class="fa fa-calendar"></i> ' + post.postDate + '</span></div></div>\
                                <div class="mentionDescription" style="padding: 12px 0 24px;">\
                                  <div class="row">'+ mentionBody + '\
                                  </div>\
                                </div>'+ sentimenthtml + '\
                              </div>\
                            </div>\
                          </div>\
                        </div>\
                      </section>\
                    </section>\
                  </div>\
                </div> '
                }
                $("#dashboard-positive-mention" + projNumber).html(hashtagHtml)
              } else {
                $("#dashboard-positive-mention" + projNumber).html('<div class="text-center marginzero width100"><img class="nodatafound" src="/assets/img/nodatafound.png" /></div>')
              }
            }
            for (var j = 0; j < comparisonChoiceData.length; j++) {
              var projNumber = projIndex + j
              $("#mentionsNegativeProjectName" + projNumber).html("Top Negative Mentions for " + comparisonChoiceData[j].projectName)
              if (comparisonChoiceData[j] && comparisonChoiceData[j].negativeMentions && comparisonChoiceData[j].negativeMentions.length > 0) {
                var buzzwordHtml = ''
                for (var index = 0; index < comparisonChoiceData[j].negativeMentions.length; index++) {
                  var post = comparisonChoiceData[j].negativeMentions[index]
                  var postDescription = post.postDescription && post.postDescription.length > 250 ? post.postDescription.substring(0, 250) + "..." : post.postDescription
                  var mentionBody = ''
                  if (post.videos) {
                    mentionBody = '<div class="picturedesc col-xs-12 text-center"><a href="' + post.engageUrl + '" target="_blank"><img  src="/images/icons/videoicon.png" /></a></div>\
                      <div class="textdesc text-left col-xs-12">\
                        <p class="mention_text text-left onhoverdisplay show-read-more mention-post-desc-clip" style="margin-left: 0" id="description-'+ post.postId + '"> ' + postDescription + '\
                        </p>\
                      </div>'
                  }
                  else if (post.images && post.images[0]) {
                    mentionBody = '<div class="picturedesc col-xs-12">\
                    <div class="item active mentiondescImageSize"><a href="'+ post.engageUrl + '" target="_blank"><img id="resizeImage" src="' + post.images[0] + '" onerror="this.src=' + defaultImg + '" style="max-height:200px" /></a></div>\
                  </div>\
                  <div class="textdesc text-left col-xs-12">\
                    <p class="mention_text text-left onhoverdisplay show-read-more mention-post-desc-clip" style="margin-left: 0" id="description-'+ post.postId + '">' + postDescription + '</p>\
                  </div>'
                  } else {
                    mentionBody = '<div class="col-sm-12 textdesc text-left">\
                    <p class="mention_text text-left onhoverdisplay show-read-more mention-post-desc-clip" style="margin-left: 0" id="description-review_5f4f9efe15be5b58cfd3e109"> '+ postDescription + '\
                    </p>\
                  </div>'
                  }

                  var faviconIcon = undefined
                  var sentimenthtml = ''
                  if (post.sentiment) {
                    sentimenthtml = '<div class="mention-information" style="margin-top: 0; display: flex;">\
                                      <div class="flexdirectionRow score text-right mention-optionType" style="display: flex;align-items: center;">\
                                        <div class="font-size-12 text-left mention-valueType">\
                                          <div class="sentiment-type sentiment'+ post.sentiment + '"></div>\
                                        </div>\
                                        <div class="font-size-12 mention-optionName">Sentiment</div>\
                                      </div>\
                                    </div>'
                  }
                  var websitename = ''
                  if (post.postSocialMedia == "Article" || post.postSocialMedia == "Review") {
                    faviconIcon = 'https://www.google.com/s2/favicons?domain=' + post.postUrl
                    websitename = '<div class="mention-title-url"><img src="' + faviconIcon + '" /><span> ' + post.postUrl + '</span></div>'
                  }
                  buzzwordHtml += '<div class="topMentionsDiv">\
                  <div class="col-sm-12 mentionBox row text-center selectedSentiment mention_post_walkthrough0 aos-init aos-animate" data-aos="fade-up" id="main-section-' + post.postId + '">\
                    <section class="mention-box1 mention-site-2">\
                      <section class="mention-box mention-site-2 mentionBorderDisplay" style="margin-bottom: 0;box-shadow: none;">\
                        <div class="header-enterprise" id="mention_post_walkthrough-header' + index + '" style="padding:0;">\
                          <div class="filteravatar flexdirectionRow">\
                            <div class="author">\
                              <div class="mention-title-box mention-hidden-title"><a href="/showDetailedPost/projectId/'+ comparisonChoiceData[j].brandProject["_id"] + '/mentionId/' + post.postId + '"><i class="fa fa-' + post.iconImage + '"></i><span>' + post.postTitle + '</span></a></div>\
                              '+ websitename + '<span class="mention-date"><i class="fa fa-calendar"></i> ' + post.postDate + '</span></div></div>\
                                <div class="mentionDescription" style="padding: 12px 0 24px;">\
                                  <div class="row">'+ mentionBody + '\
                                  </div >\
                                </div > '+ sentimenthtml + '\
                              </div >\
                            </div >\
                          </div >\
                        </div >\
                      </section >\
                    </section >\
                  </div >\
                </div > '
                }
                $("#dashboard-negative-mention" + projNumber).html(buzzwordHtml)
              } else {
                $("#dashboard-negative-mention" + projNumber).html('<div class="text-center marginzero width100"><img class="nodatafound" src="/assets/img/nodatafound.png" /></div>')
              }
            }
          }
          var newProjectsData = selectedCompetitiveProjects.slice(projIndex + 2, projIndex + 4)
          var newProjIds = newProjectsData.map(function (proj) { return proj._id })
          if (newProjIds.length > 0) {
            makeCompareSentimentAjax(newProjIds, projIndex + 2)
          }
        }
      },
      error: function (res) {
      }
    });
  }

  function makeCompareSourceAjax(projIds, projIndex) {
    var url = "/fetch-compareSource-trending-tokens?"
    if (pageName.split('?').length > 1) {
      url = url + pageName.split('?')[1]
    }
    $.ajax({
      url: url,
      method: "POST",
      data: {
        projIds: projIds,
        projectId: activeProject._id,
        startDateInEpoch: startDateInEpoch,
        endDateInEpoch: endDateInEpoch,
        userId: user._id
      },
      timeout: 0,
      success: function (res) {
        if (res && res.status == "success") {
          if (res.comparisonChoiceData && res.comparisonChoiceData.length > 0) {
            for (var i = 0; i < res.comparisonChoiceData.length; i++) {
              var projNumber = projIndex + i
              $("#dashboard-hashtag-name" + projNumber).html("Top Hashtags of " + res.comparisonChoiceData[i].projectName)
              if (res.comparisonChoiceData[i] && res.comparisonChoiceData[i].popularFeaturingHashtags && res.comparisonChoiceData[i].popularFeaturingHashtags.length > 0) {
                var hashtaglist = ''
                for (var index = 0; index < res.comparisonChoiceData[i].popularFeaturingHashtags.length && index < 5; index++) {
                  var hash = res.comparisonChoiceData[i].popularFeaturingHashtags[index]
                  var filterapply = ''
                  if (pageName.split('?')[1] != undefined) {
                    filterapply = pageName.split('?')[1]
                  }
                  hashtaglist += '<li class="profile-li some-hashtagData"><a style="color:#545454" href="/enterprise-mention?projectId=' + res.comparisonChoiceData[i].brandProject._id + '&searchText=' + hash.text.replace('#', "HT:") + filterapply + '">\
                      <div class="flexdirectionRow profile-li-box">\
                        <div><span style="margin-right:12px; ">'+ hash.text + ' </span></div>\
                      </div>\
                    </a></li>'
                }
                var hashtagHtml = '<ul class="profile-ul">' + hashtaglist + '</ul>'
                $("#dashboard-hashtag-list" + projNumber).html(hashtagHtml)
              } else {
                $("#dashboard-hashtag-list" + projNumber).html('<div class="text-center marginzero width100"><img class="nodatafound" src="/assets/img/nodatafound.png" /></div>')
              }
            }
            for (var j = 0; j < res.comparisonChoiceData.length; j++) {
              var projNumber = projIndex + j
              $("#dashboard-buzzword-name" + projNumber).html("Top Buzzwords of " + res.comparisonChoiceData[j].projectName)
              if (res.comparisonChoiceData[j] && res.comparisonChoiceData[j].popularFeaturingEntities && res.comparisonChoiceData[j].popularFeaturingEntities.length > 0) {
                var buzzwordlist = ''
                for (var index = 0; index < res.comparisonChoiceData[j].popularFeaturingEntities.length && index < 5; index++) {
                  var buzz = res.comparisonChoiceData[j].popularFeaturingEntities[index]
                  var filterapply = ''
                  if (pageName.split('?')[1] != undefined) {
                    filterapply = pageName.split('?')[1]
                  }
                  buzzwordlist += '<li class="profile-li some-hashtagData"><a style="color:#545454" href="/enterprise-mention?projectId=' + res.comparisonChoiceData[j].brandProject._id + '&searchBuzzword=' + buzz.text + filterapply + '">\
                      <div class="flexdirectionRow profile-li-box">\
                        <div><span style="margin-right:12px; ">'+ buzz.text + ' </span></div>\
                      </div>\
                    </a></li>'
                }
                var buzzwordHtml = '<ul class="profile-ul">' + buzzwordlist + '</ul>'
                $("#dashboard-buzzword-list" + projNumber).html(buzzwordHtml)
              } else {
                $("#dashboard-buzzword-list" + projNumber).html('<div class="text-center marginzero width100"><img class="nodatafound" src="/assets/img/nodatafound.png" /></div>')
              }
            }
            var newProjectsData = selectedCompetitiveProjects.slice(projIndex + 2, projIndex + 4)
            var newProjIds = newProjectsData.map(function (proj) { return proj._id })
            if (newProjIds.length > 0) {
              makeCompareSourceAjax(newProjIds, projIndex + 2)
            }
          }
        }
      },
      error: function (res) {
      }
    });
  }
  if (pageName.indexOf("compareSentiment") > -1) {
    if (totalProj > 1) {
      var projectsData = selectedCompetitiveProjects.slice(0, 2)
      var projIds = projectsData.map(function (proj) { return proj._id })
      makeCompareSentimentAjax(projIds, 0, 1)
    }
  }
  if (pageName.indexOf("compareSource") > -1) {
    if (totalProj > 1) {
      var projectsData = selectedCompetitiveProjects.slice(0, 2)
      var projIds = projectsData.map(function (proj) { return proj._id })
      makeCompareSourceAjax(projIds, 0, 1)
    }
  }


})
//defining all globle Functions

var keywordChannelWiseDataOnClick;
var keywordSentimentWiseDataOnClick;
var brandHashtagChannelWiseDataOnClick;
// var audienceHashtagChannelWiseDataOnClick;
var contentTypePieChartOnClick;
// var buzzwordOverSocialMediaBarOnClick;
// var buzzwordOverWebMentionsBarOnClick;
// var buzzwordOverAllBarOnClick;
var changeSourceName;
var rmoBarGraphlayout;
var rmoBarlayout;
var rmoPielayout;
var rmoLineGraphLayout;
var rmoLineLayout;
var changeMentionOwnerText;
var changeMentionType

$(document).ready(function () {
  var ultimateColors = [
    '#87D0F3', 'rgb(0,206,244)', 'rgb(200,228,0)', 'rgb(255,155,0)', 'rgb(175,157,200)', 'rgb(237,69,21)', 'rgb(45,107,182)'
  ];

  var href = document.location.href

  changeMentionType = function (mentionType) {
    if (mentionType === 'Text')
      return 'text_body'
    else if (mentionType === 'Images')
      return 'image_body'
    else if (mentionType === 'Videos')
      return 'video_body'
  }
  changeSourceName = function changeSourceName(sourceType) {
    sourceType = sourceType.replace('%20', ' ')
    if (sourceType === 'Articles') {
      return 'ARTICLE'
    } else if (sourceType === 'Facebook Comments') {
      return 'FBCOMMENT'
    } else if (sourceType === 'Facebook Posts') {
      return 'FBPOST'
    } else if (sourceType === 'Instagram') {
      return 'INSTAPOST'
    } else if (sourceType === 'Consumer Review') {
      return 'REVIEW'
    } else if (sourceType === 'Twitter') {
      return 'TWEET'
    } else if (sourceType === 'YouTube Videos') {
      return 'YTVIDEO'
    }
    else if (sourceType === 'YouTube Comments') {
      return 'YTCOMMENT'
    } else if (sourceType === 'TikTok') {
      return 'TIKTOK'
    } else if (sourceType === 'Linkedin') {
      return 'LINKEDIN'
    } else
      return sourceType
  }
  changeMentionOwnerText = function (val) {
    if (val === "By Your Audience")
      return "onlyAudiencePost"
    else if (val === "By You")
      return "onlyYourPost"
    else
      return val
  }


  rmoLineLayout = {

    plot_bgcolor: 'white',
    paper_bgcolor: 'white',
    hovermode: 'closest',
    hoveron: 'points',
    font: {
      family: 'Roboto,Helvetica Neue,Arial,sans-serif',
      size: 12,
      color: "black"
    },
    legend: {
      font: {
        size: 10,
        color: '#000'
      },
      "orientation": "h",
      y: -.7,
    },
    margin: {
      t: 10,
      // pad: 4,
      r: 5,
      b: 0,
      l: 50,
    },
    autosize: true,
    xaxis: {
      tickformat: '%d/%b',
      titlefont: {
        size: 10,
        color: '#000',
      },
      tickangle: -15,
      showticklabels: true,
      tickfont: {
        size: 10,
        color: '#000'
      },
      fixedrange: true,
      gridwidth: false,
      showgrid: false,
    },
    yaxis: {
      title: 'Counts',
      titlefont: {
        size: 10,
        color: '#000'
      },
      showticklabels: true,
      tickangle: 0,
      tickfont: {
        size: 10,
        color: '#000'
      },
      fixedrange: true,
      rangemode: 'nonnegative',
      autorange: true,
      gridwidth: false,
      showgrid: false,
    },
    height: 400

  }

  rmoLineGraphLayout = {
    plot_bgcolor: 'white',
    paper_bgcolor: 'white',
    hovermode: 'closest',
    hoveron: 'points',
    font: {
      family: 'Roboto,Helvetica Neue,Arial,sans-serif',
      size: 12,
      color: "black"
    },
    legend: {
      font: {
        size: 10,
        color: '#000'
      },
      "orientation": "h",
      y: -.5,
    },
    margin: {
      t: 10,
      // pad: 4,
      r: 10,
      b: 50,
      l: 50,
    },
    autosize: true,
    xaxis: {
      title: 'Date of Mentions',
      tickformat: '%d/%b',
      titlefont: {
        size: 10,
        color: '#000',
      },
      tickangle: -15,
      showticklabels: true,
      tickfont: {
        size: 10,
        color: '#000'
      },
      fixedrange: true,
      gridwidth: false,
      showgrid: false,
    },
    yaxis: {
      title: 'Counts',
      titlefont: {
        size: 10,
        color: '#000'
      },
      showticklabels: true,
      tickangle: 0,
      tickfont: {
        size: 10,
        color: '#000'
      },
      fixedrange: true,
      rangemode: 'nonnegative',
      autorange: true,
      gridwidth: false,
      showgrid: false,
    },
    height: 300,
  }

  rmoPielayout = {
    sort: false,
    showlegend: true,
    font: {
      family: 'Roboto,Helvetica Neue,Arial,sans-serif',
      size: 10,
      color: "black"
    },
    legend: {
      font: {
        size: 10,
        family: 'Roboto,Helvetica Neue,Arial,sans-serif',
        color: '#000'
      }
    },
    margin: {
      b: 0,
      t: 0,
      l: 0,
      r: 0,
      pad: 0,
    },
    autosize: true,
    paper_bgcolor: 'rgba(0,0,0,0)',
    height: 200
  };


  rmoBarGraphlayout = {
    xaxis: {
      tickangle: -45,
      fixedrange: true,
      rangemode: 'nonnegative',
    },
    yaxis: {
      fixedrange: true,
      rangemode: 'nonnegative',
    },
    legend: {
      orientation: 'h',
      font: {
        size: 10,
        family: 'Roboto,Helvetica Neue,Arial,sans-serif',
        color: '#000'
      },
      // y: -.3,
    },
    font: {
      family: 'Roboto,Helvetica Neue,Arial,sans-serif',
      size: 10,
      color: "black"
    },
    margin: {
      b: 100,
      t: 10,
      r: 80,
      l: 100,
    },
    height: 400,
  };

  rmoBarlayout = {
    barmode: 'stack',
    xaxis: {
      tickangle: -45,
      fixedrange: true,
    },
    yaxis: {
      fixedrange: true,
    },
    font: {
      family: 'Roboto,Helvetica Neue,Arial,sans-serif',
      size: 10,
      color: "black"
    },
    legend: {
      orientation: 'h',
      font: {
        size: 10,
        family: 'Roboto,Helvetica Neue,Arial,sans-serif',
        color: '#000'
      },
      y: -.3,
    },
    margin: {
      b: 100,
      t: 10,
      r: 80,
      l: 100,
    },
    height: 400,
  };

  // if (document.getElementById('numberofMentionsofYourHashtags') && brandHashtagMentionsWidget && brandHashtagMentionsWidget != null) {
  //   var setHeightManual = (60 * brandHashtagMentionsWidget.brandHashtagText.length)
  //   setHeightManual = setHeightManual < 200 ? 200 : setHeightManual
  //   var brandHashtagCount = {
  //     y: fixWordBreaks(brandHashtagMentionsWidget.brandHashtagText, 25),
  //     x: brandHashtagMentionsWidget.brandHashtagBrandCount,
  //     width: brandHashtagMentionsWidget.brandHashtagWidth,
  //     name: 'By You',
  //     marker: {
  //       color: 'rgb(159,216,243)'
  //     },
  //     type: 'bar',
  //     orientation: 'h',
  //     // textinfo: 'name',
  //     hoverinfo: 'x',
  //     width: ".4"
  //   };

  //   var audienceHashtagCount = {
  //     y: fixWordBreaks(brandHashtagMentionsWidget.brandHashtagText, 25),
  //     x: brandHashtagMentionsWidget.brandHashtagAudienceCount,
  //     width: brandHashtagMentionsWidget.brandHashtagWidth,
  //     name: 'By Your Audience',
  //     marker: {
  //       color: 'rgb(255,155,0)'
  //     },
  //     type: 'bar',
  //     orientation: 'h',
  //     hoverinfo: 'x',
  //     width: ".4",
  //   };

  //   var brandHashtagData = [brandHashtagCount, audienceHashtagCount];

  //   var brandHashtagDataLayout = {
  //     xaxis: {
  //       tickfont: {
  //         size: 14,
  //         color: '#000',
  //       },
  //       tickangle: -30,
  //       fixedrange: true,
  //       showticklabels: true,
  //       gridwidth: false,
  //       showgrid: false,
  //       zeroline: false,
  //       rangemode: 'nonnegative',
  //     },
  //     yaxis: {
  //       tickfont: {
  //         size: 14,
  //         color: "#000"
  //       },
  //       fixedrange: true,
  //       showticklabels: true,
  //       gridwidth: false,
  //       showgrid: false,
  //       rangemode: 'nonnegative',
  //       autorange: true,
  //       zeroline: false,
  //       rangemode: 'nonnegative',

  //     },
  //     margin: {
  //       t: 10,
  //       l: 300,
  //       r: 100,
  //       pad: 10
  //     },
  //     legend: {
  //       "orientation": "h",
  //       bgcolor: 'rgba(255, 255, 255, 0)',
  //       bordercolor: 'rgba(255, 255, 255, 0)',
  //       font: {
  //         size: 14,
  //         color: '#000',
  //       },
  //       y: -0.4,
  //     },
  //     barmode: 'group',
  //     bargap: 0.15,
  //     bargroupgap: 0.1,
  //     hovermode: 'closest',
  //     hoveron: 'points',
  //     height: setHeightManual
  //   };
  //   plotlyPlotData.push({
  //     id: 'numberofMentionsofYourHashtags',
  //     data: brandHashtagData,
  //     layout: brandHashtagDataLayout,
  //   });

  //   Plotly.newPlot('numberofMentionsofYourHashtags', brandHashtagData, brandHashtagDataLayout, {
  //     displayModeBar: false,
  //     displaylogo: false,
  //     showTips: false
  //   });

  // }


  if (document.getElementById('keywordChannelWiseData') && brandKeywordSourceGraph) {
    if (brandKeywordSourceGraph.type === 'pie') {
      keywordChannelWiseDataOnClick = function () {
        document.getElementById('keywordChannelWiseData').on('plotly_click', function (data) {
          var paramArray = splitIntoParams(href)
          paramArray = paramArray.filter(function (val) {
            if (val.param !== 'sourceType') return true
            return false
          })

          var searchResult = paramArray.find(function (element) { return element.param == "searchText" });
          if (searchResult) {
            paramArray = paramArray.map(function (obj) {
              if (obj.param == "searchText") {
                obj.value += " and " + brandKeywordSourceGraph.keyword
              }
              return obj
            })
          } else {
            paramArray.push({
              param: "searchText",
              value: brandKeywordSourceGraph.keyword
            })
          }
          var hrefParams = mergeIntoUrl(paramArray)
          hrefParams = hrefParams.split('?')[1]
          var pts = '/enterprise-mention?' + hrefParams + "&similarResults=true";
          for (var i = 0; i < data.points.length; i++) {
            data.points[i].label.replace("<br>", "")
            pts = pts + '&sourceType=' + changeSourceName(encodeURI(data.points[i].label))
          }
          window.open(pts, '_blank');
        });
      }
      plotlyPlotData.push({
        id: 'keywordChannelWiseData',
        data: [brandKeywordSourceGraph.graph],
        layout: rmoPielayout,
        onClickFunctionName: 'keywordChannelWiseDataOnClick'
      });
      Plotly.newPlot('keywordChannelWiseData', [brandKeywordSourceGraph.graph], rmoPielayout, {
        displayModeBar: false,
        displaylogo: false,
        showTips: false,
        responsive: true
      });
      keywordChannelWiseDataOnClick()
    }
  }

  if (document.getElementById('keywordSentimentWiseData') && brandKeywordSentimentGraph) {
    if (brandKeywordSentimentGraph.type === 'pie') {
      keywordSentimentWiseDataOnClick = function () {
        document.getElementById('keywordSentimentWiseData').on('plotly_click', function (data) {
          var paramArray = splitIntoParams(href)
          paramArray = paramArray.filter(function (val) {
            if (val.param !== 'sentiment') return true
            return false
          })

          var searchResult = paramArray.find(function (element) { return element.param == "searchText" });
          if (searchResult) {
            paramArray = paramArray.map(function (obj) {
              if (obj.param == "searchText") {
                obj.value += " and " + brandKeywordSentimentGraph.keyword
              }
              return obj
            })
          } else {
            paramArray.push({
              param: "searchText",
              value: brandKeywordSentimentGraph.keyword
            })
          }
          var hrefParams = mergeIntoUrl(paramArray)
          hrefParams = hrefParams.split('?')[1]
          var pts = '/enterprise-mention?' + hrefParams;
          for (var i = 0; i < data.points.length; i++) {
            data.points[i].label.replace("<br>", "")
            pts = pts + '&sentiment=' + encodeURI(data.points[i].label.toUpperCase())
          }
          window.open(pts, '_blank');
        });
      }
      plotlyPlotData.push({
        id: 'keywordSentimentWiseData',
        data: [brandKeywordSentimentGraph.graph],
        layout: rmoPielayout,
        onClickFunctionName: 'keywordSentimentWiseDataOnClick'
      });
      Plotly.newPlot('keywordSentimentWiseData', [brandKeywordSentimentGraph.graph], rmoPielayout, {
        displayModeBar: false,
        displaylogo: false,
        showTips: false,
        responsive: true
      });
      keywordSentimentWiseDataOnClick()

    }
  }

  // if (document.getElementById('audienceHashtagChannelWiseData') && socialMediaHashtagGraph) {
  //   audienceHashtagChannelWiseDataOnClick = function () {
  //     document.getElementById('audienceHashtagChannelWiseData').on('plotly_click', function (data) {
  //       var paramArray = splitIntoParams(href)
  //       paramArray = paramArray.filter(function (val) {
  //         if (val.param !== 'searchText' || val.param !== 'sourceType' || val.param !== 'onlyAudiencePost') return true
  //         return false
  //       })
  //       var hrefParams = mergeIntoUrl(paramArray)
  //       hrefParams = hrefParams.split('?')[1]
  //       var pts = '/enterprise-mention?' + hrefParams;
  //       for (var i = 0; i < data.points.length; i++) {
  //         data.points[i].x.replace("<br>", "")
  //         pts = pts + '&sourceType=TWEET&sourceType=INSTAPOST&sourceType=FBCOMMENT&sourceType=FBPOST&searchText=' + encodeURI(data.points[i].x)
  //       }
  //       window.open(pts, '_blank');
  //     });
  //   }
  //   plotlyPlotData.push({
  //     id: 'audienceHashtagChannelWiseData',
  //     data: socialMediaHashtagGraph,
  //     layout: rmoBarGraphlayout,
  //     onClickFunctionName: 'audienceHashtagChannelWiseDataOnClick'
  //   });
  //   Plotly.newPlot('audienceHashtagChannelWiseData', socialMediaHashtagGraph, rmoBarGraphlayout, {
  //     displayModeBar: false,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  //   audienceHashtagChannelWiseDataOnClick()
  // }

  // if (document.getElementById('buzzwordOverAllBar') && buzzwordGraph) {
  //   buzzwordOverAllBarOnClick = function () {
  //     document.getElementById('buzzwordOverAllBar').on('plotly_click', function (data) {
  //       var paramArray = splitIntoParams(href)
  //       paramArray = paramArray.filter(function (val) {
  //         if (val.param !== 'searchText') return true
  //         return false
  //       })
  //       var hrefParams = mergeIntoUrl(paramArray)
  //       hrefParams = hrefParams.split('?')[1]
  //       var pts = '/enterprise-mention?' + hrefParams;
  //       for (var i = 0; i < data.points.length; i++) {
  //         data.points[i].x.replace("<br>", "")
  //         pts = pts + '&searchText=' + encodeURI(data.points[i].x)
  //       }
  //       window.open(pts, '_blank');
  //     });
  //   }
  //   plotlyPlotData.push({
  //     id: 'buzzwordOverAllBar',
  //     data: buzzwordGraph,
  //     layout: rmoBarGraphlayout,
  //     onClickFunctionName: 'buzzwordOverAllBarOnClick'
  //   });
  //   Plotly.newPlot('buzzwordOverAllBar', buzzwordGraph, rmoBarGraphlayout, {
  //     displayModeBar: false,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  //   buzzwordOverAllBarOnClick()
  // }
  // if (document.getElementById('buzzwordOverSocialMediaBar') && socialMediaBuzzwordGraph) {

  //   buzzwordOverSocialMediaBarOnClick = function () {
  //     document.getElementById('buzzwordOverSocialMediaBar').on('plotly_click', function (data) {
  //       var paramArray = splitIntoParams(href)
  //       paramArray = paramArray.filter(function (val) {
  //         if (val.param !== 'searchText') return true
  //         return false
  //       })
  //       var hrefParams = mergeIntoUrl(paramArray)
  //       hrefParams = hrefParams.split('?')[1]
  //       var pts = '/enterprise-mention?' + hrefParams;
  //       for (var i = 0; i < data.points.length; i++) {
  //         data.points[i].x.replace("<br>", "")
  //         pts = pts + '&searchText=' + encodeURI(data.points[i].x)
  //       }
  //       window.open(pts, '_blank');
  //     });
  //   }

  //   plotlyPlotData.push({
  //     id: 'buzzwordOverSocialMediaBar',
  //     data: socialMediaBuzzwordGraph,
  //     layout: rmoBarGraphlayout,
  //     onClickFunctionName: 'buzzwordOverSocialMediaBarOnClick'
  //   });
  //   Plotly.newPlot('buzzwordOverSocialMediaBar', socialMediaBuzzwordGraph, rmoBarGraphlayout, {
  //     displayModeBar: false,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  //   buzzwordOverSocialMediaBarOnClick()
  // }

  //   if (document.getElementById('buzzwordOverWebMentionsBar') && nonSMPBuzzwordGraph) {
  //     buzzwordOverWebMentionsBarOnClick = function () {
  //       document.getElementById('buzzwordOverWebMentionsBar').on('plotly_click', function (data) {
  //         var paramArray = splitIntoParams(href)
  //         paramArray = paramArray.filter(function (val) {
  //           if (val.param !== 'searchText') return true
  //           return false
  //         })
  //         var hrefParams = mergeIntoUrl(paramArray)
  //         hrefParams = hrefParams.split('?')[1]
  //         var pts = '/enterprise-mention?' + hrefParams;
  //         for (var i = 0; i < data.points.length; i++) {
  //           data.points[i].x.replace("<br>", "")
  //           pts = pts + '&searchText=' + encodeURI(data.points[i].x)
  //         }
  //         window.open(pts, '_blank');
  //       });

  //     }
  //     plotlyPlotData.push({
  //       id: 'buzzwordOverWebMentionsBar',
  //       data: nonSMPBuzzwordGraph,
  //       layout: rmoBarGraphlayout,
  //       onClickFunctionName: 'buzzwordOverWebMentionsBarOnClick'
  //     });
  //     Plotly.newPlot('buzzwordOverWebMentionsBar', nonSMPBuzzwordGraph, rmoBarGraphlayout, {
  //       displayModeBar: false,
  //       displaylogo: false,
  //       showTips: false,
  //       responsive: true
  //     });
  //     buzzwordOverWebMentionsBarOnClick()
  //   }
})

function setContentBarManualWidth(length) {
  if (length <= 2)
    return 300
  else
    return
}
var InfluencerColor = [
  '#c8e400', '#00ccf7', '#f92000'
];
var profileGenderData
var profileSentimentPie
var influencerFollowerTypeGraph
var influencerDetailFollowerCount
var genderDistributionPie
var ageDistributionPie
var countryDistributionPie
var stateDistributionPie
var cityDistributionPie
var languageDistributionPie

$(document).ready(function () {
  var ultimateColors = [
    'rgb(176, 252, 204)', 'rgb(0,206,244)', 'rgb(200,228,0)', 'rgb(255,155,0)', 'rgb(175,157,200)', 'rgb(237,69,21)', 'rgb(45,107,182)', 'rgb(242,255,0)', 'rgb(218,181,69)', 'rgb(255,128,114)', 'rgb(241,0,82)', 'rgb(241,201,122)', 'rgb(127,103,191)', 'rgb(203,72,195)', 'rgb(235,247,249)', 'rgb(230,207,88)', 'rgb(248,0,118)', 'rgb(169,193,205)'
  ];
  $('.subNav_navLink').on('click', function (e) {
    console.log(("'#" + $(this).attr('data-linkcontent') + "'"))
    if ($(this).data("linkcontent") == e && $(this).hasClass('selected')) {
      console.log('already selected')
    }
    else {
      $('.subNav_navLink').removeClass('selected')
      $('.subNav_contentContainer').removeClass('infl_displayBlock')
      $(this).addClass('selected')
      $("#" + $(this).attr('data-linkcontent')).addClass('infl_displayBlock')
    }
    hideLoaderOnClick()
    $('html, body').animate({
      scrollTop: $("#" + $(this).attr('data-linkcontent')).offset().top - 50
    }, 500);

  })
  if (typeof (userDataPoints) != 'undefined')
    userDataPoints = JSON.parse(userDataPoints)

  if (document.getElementById('influencerSentiment') && sentimentMap && sentimentMap != null) {
    var PieChartData = {
      labels: sentimentMap.sentimentKeys,
      datasets: [{
        data: sentimentMap.sentimentValues,
        backgroundColor: InfluencerColor,
        borderColor: InfluencerColor,
      }]
    }
    var ctx = $('#influencerSentiment');
    var graphData = {
      type: 'doughnut',
      data: PieChartData,
      options: JSON.parse(JSON.stringify(doughnutChartLayout)),
    }
    profileSentimentPie = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "influencerSentiment",
      data: graphData,
      chartObj: profileSentimentPie,
      expandRatio: 2
    })
  }

  if (document.getElementById('influencerGenderOutreach') && userDataPoints && userDataPoints.followerGenderData) {

    var genderPieChartData = {
      labels: Object.keys(userDataPoints.followerGenderData),
      datasets: [{
        data: Object.values(userDataPoints.followerGenderData),
        backgroundColor: ultimateColors[0],
        borderColor: ultimateColors[0],
      }]
    }
    var ctx = $('#influencerGenderOutreach');
    var graphData = {
      type: 'doughnut',
      data: genderPieChartData,
      options: JSON.parse(JSON.stringify(doughnutChartLayout)),
    }
    profileGenderData = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "influencerGenderOutreach",
      data: graphData,
      chartObj: profileGenderData,
      expandRatio: 2
    })
  }

  if (document.getElementById('influencerGenderDistribution') && genderData && genderData != null) {
    var genderColor = ["#3190f6", "#e9bbba", "#c8e400"]
    var genderDistribution = {
      labels: genderData.genderKeys,
      datasets: [{
        data: genderData.genderValues,
        backgroundColor: ultimateColors,
        borderColor: ultimateColors,
      }]
    }
    var ctx = $('#influencerGenderDistribution');
    var graphData = {
      type: 'doughnut',
      data: genderDistribution,
      options: JSON.parse(JSON.stringify(doughnutChartLayout)),
    }
    genderDistributionPie = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "influencerGenderDistribution",
      data: graphData,
      chartObj: genderDistributionPie,
      expandRatio: 1
    })
    convertToPercent(genderDistributionPie, '')
  }

  if (document.getElementById('influencerAgeDistribution') && ageGroupDetails && ageGroupDetails != null) {
    // var genderColor = ["#3190f6", "#e9bbba", "#c8e400"]
    var ageGroupDistribution = {
      labels: ageGroupDetails.ageGroupKeys,
      datasets: [{
        data: ageGroupDetails.ageGroupValues,
        backgroundColor: ultimateColors,
        borderColor: ultimateColors,
      }]
    }
    var ctx = $('#influencerAgeDistribution');
    var graphData = {
      type: 'doughnut',
      data: ageGroupDistribution,
      options: JSON.parse(JSON.stringify(doughnutChartLayout)),
    }
    ageDistributionPie = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "influencerAgeDistribution",
      data: graphData,
      chartObj: ageDistributionPie,
      expandRatio: 1
    })
    convertToPercent(ageDistributionPie, '')
  }

  if (document.getElementById('influencerCountryDistribution') && countryData && countryData != null) {
    // var genderColor = ["#3190f6", "#e9bbba", "#c8e400"]
    var countryDistribution = {
      labels: countryData.countryKeys,
      datasets: [{
        data: countryData.countryValues,
        backgroundColor: ultimateColors,
        borderColor: ultimateColors,
      }]
    }
    var ctx = $('#influencerCountryDistribution');
    var graphData = {
      type: 'doughnut',
      data: countryDistribution,
      options: JSON.parse(JSON.stringify(doughnutChartLayout)),
    }
    countryDistributionPie = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "influencerCountryDistribution",
      data: graphData,
      chartObj: countryDistributionPie,
      expandRatio: 1
    })
    convertToPercent(countryDistributionPie, '')
  }

  if (document.getElementById('influencerStateDistribution') && stateDetails && stateDetails != null) {
    // var genderColor = ["#3190f6", "#e9bbba", "#c8e400"]
    var stateDistribution = {
      labels: stateDetails.stateKeys,
      datasets: [{
        data: stateDetails.stateValues,
        backgroundColor: ultimateColors,
        borderColor: ultimateColors,
      }]
    }
    var ctx = $('#influencerStateDistribution');
    var graphData = {
      type: 'doughnut',
      data: stateDistribution,
      options: JSON.parse(JSON.stringify(doughnutChartLayout)),
    }
    stateDistributionPie = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "influencerStateDistribution",
      data: graphData,
      chartObj: stateDistributionPie,
      expandRatio: 1
    })
    convertToPercent(stateDistributionPie, '')
  }

  if (document.getElementById('influencerCityDistribution') && cityDetails && cityDetails != null) {
    // var genderColor = ["#3190f6", "#e9bbba", "#c8e400"]
    var cityDistribution = {
      labels: cityDetails.cityKeys,
      datasets: [{
        data: cityDetails.cityValues,
        backgroundColor: ultimateColors,
        borderColor: ultimateColors,
      }]
    }
    var ctx = $('#influencerCityDistribution');
    var graphData = {
      type: 'doughnut',
      data: cityDistribution,
      options: JSON.parse(JSON.stringify(doughnutChartLayout)),
    }
    cityDistributionPie = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "influencerCityDistribution",
      data: graphData,
      chartObj: cityDistributionPie,
      expandRatio: 1
    })
    convertToPercent(cityDistributionPie, '')
  }
  if (document.getElementById('influencerCategoryDistribution') && categoryData && categoryData != null) {
    // var genderColor = ["#3190f6", "#e9bbba", "#c8e400"]
    var categoryDistribution = {
      labels: categoryData.categoryKeys,
      datasets: [{
        data: categoryData.categoryValues,
        backgroundColor: ultimateColors,
        borderColor: ultimateColors,
      }]
    }
    var ctx = $('#influencerCategoryDistribution');
    var graphData = {
      type: 'doughnut',
      data: categoryDistribution,
      options: JSON.parse(JSON.stringify(doughnutChartLayout)),
    }
    categoryDistributionPie = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "influencerCategoryDistribution",
      data: graphData,
      chartObj: categoryDistributionPie,
      expandRatio: 1
    })
    convertToPercent(categoryDistributionPie, '')
  }

  if (document.getElementById('influencerLanguageDistribution') && languageDetails && languageDetails != null) {
    // var genderColor = ["#3190f6", "#e9bbba", "#c8e400"]
    var languageDistribution = {
      labels: languageDetails.languageKeys,
      datasets: [{
        data: languageDetails.languageValues,
        backgroundColor: ultimateColors,
        borderColor: ultimateColors,
      }]
    }
    var ctx = $('#influencerLanguageDistribution');
    var graphData = {
      type: 'doughnut',
      data: languageDistribution,
      options: JSON.parse(JSON.stringify(doughnutChartLayout)),
    }
    languageDistributionPie = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "influencerLanguageDistribution",
      data: graphData,
      chartObj: languageDistributionPie,
      expandRatio: 1
    })
    convertToPercent(languageDistributionPie, '')
  }


  if (document.getElementById('influencerFollowerTypeGraph') && userDataPoints.followerTypeData && userDataPoints.followerTypeData != null) {
    // var genderColor = ["#3190f6", "#e9bbba"]
    var inflTypeLables = Object.keys(userDataPoints.followerTypeData).map(function (obj) { return changeInfluencerType(obj) })
    var inflTypeValues = Object.values(userDataPoints.followerTypeData)
    // Chart.defaults.global.legend.display = true
    var influencerFollowerTypeChartData = {
      labels: inflTypeLables,
      datasets: [{
        data: inflTypeValues,
        backgroundColor: ultimateColors[0],
        borderColor: ultimateColors[0],
      }]
    }
    var ctx = $('#influencerFollowerTypeGraph');
    var graphData = {
      type: 'doughnut',
      data: influencerFollowerTypeChartData,
      options: JSON.parse(JSON.stringify(doughnutChartLayout)),
    }
    influencerFollowerTypeGraph = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "influencerFollowerTypeGraph",
      data: graphData,
      chartObj: influencerFollowerTypeGraph,
      expandRatio: 2
    })
  }

  if (document.getElementById('influencerDetailFollowerCount') && userDataPoints && userDataPoints.followerCountBrackets && userDataPoints.followerCountBrackets != null) {
    var ctx = document.getElementById("influencerDetailFollowerCount");
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    Chart.defaults.global.tooltips.bodyFontColor = "#fff";
    Chart.defaults.global.tooltips.titleFontColor = "#fff";
    var data = [];
    var followerKeys = Object.keys(userDataPoints.followerCountBrackets);
    var followerValues = Object.values(userDataPoints.followerCountBrackets)
    for (var i = 0; i < followerKeys.length; i++) {
      data.push({ x: followerKeys[i], y: followerValues[i] })
    }
    // End Defining data
    var sourceChartLayout = JSON.parse(JSON.stringify(ScatterChartLayout))
    sourceChartLayout.scales.xAxes[0]['ticks']['display'] = false
    sourceChartLayout.scales.yAxes[0]['type'] = 'logarithmic'
    sourceChartLayout.scales.yAxes[0]['scaleLabel']['labelString'] = 'Total sum of followers in particular range'
    sourceChartLayout.scales.yAxes[0]['ticks']['autoSkip'] = true
    sourceChartLayout.scales.yAxes[0]['ticks']['maxTicksLimit'] = 14
    sourceChartLayout.scales.yAxes[0]['ticks']['callback'] = function (value, index, values) {
      return Number(value.toString());//pass tick values as a string into Number function
    }
    sourceChartLayout.scales.xAxes[0]['type'] = 'logarithmic'
    sourceChartLayout.scales.xAxes[0]['scaleLabel']['labelString'] = 'Followers range by number of followers'
    sourceChartLayout.scales.xAxes[0]['ticks']['autoSkip'] = true
    // sourceChartLayout.scales.xAxes[0]['ticks']['maxTicksLimit'] = 12
    sourceChartLayout.scales.xAxes[0]['ticks']['callback'] = function (value, index, values) {
      return Number(value.toString());//pass tick values as a string into Number function
    }
    sourceChartLayout.tooltips = { callbacks: { label: null } }
    sourceChartLayout['tooltips']['callbacks']['label'] = function (tooltipItems, data) {
      var output = [];
      var index = tooltipItems.index;
      var datasetIndex = tooltipItems.datasetIndex;
      var dataset = data.datasets[datasetIndex];
      var datasetItem = dataset.data[index];
      output.push("Range: " + datasetItem.x)
      output.push("Counts: " + datasetItem.y)
      return output;
    }
    var graphData = {
      type: 'scatter',
      data: {
        datasets: [{
          label: 'Followers', // Name the series
          data: data, // Specify the data values array
          borderColor: '#2196f3', // Add custom color border            
          backgroundColor: '#2196f3', // Add custom color background (Points and Fill)
        }]
      },
      options: sourceChartLayout
    }
    var influencerDetailFollowerCount = new Chart(ctx, graphData);
    chartjsGraphsLoaded.push({
      id: "influencerDetailFollowerCount",
      data: graphData,
      chartObj: influencerDetailFollowerCount,
      expandRatio: 1,
    })
  }


  if (document.getElementById('influencerDetailStatusCount') && userDataPoints && userDataPoints.statusCountBrackets && userDataPoints.statusCountBrackets != null) {
    var ctx = document.getElementById("influencerDetailStatusCount");
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    Chart.defaults.global.tooltips.bodyFontColor = "#fff";
    Chart.defaults.global.tooltips.titleFontColor = "#fff";
    var data = [];
    var statusKeys = Object.keys(userDataPoints.statusCountBrackets);
    var statusValues = Object.values(userDataPoints.statusCountBrackets)
    for (var i = 0; i < statusKeys.length; i++) {
      data.push({ x: statusKeys[i], y: statusValues[i] })
    }
    // End Defining data
    var sourceChartLayout = JSON.parse(JSON.stringify(ScatterChartLayout))
    sourceChartLayout.scales.xAxes[0]['ticks']['display'] = false
    sourceChartLayout.scales.yAxes[0]['type'] = 'logarithmic'
    sourceChartLayout.scales.yAxes[0]['scaleLabel']['labelString'] = "Total sum of followers in particular range"
    sourceChartLayout.scales.yAxes[0]['ticks']['autoSkip'] = true
    sourceChartLayout.scales.yAxes[0]['ticks']['maxTicksLimit'] = 14
    sourceChartLayout.scales.yAxes[0]['ticks']['callback'] = function (value, index, values) {
      return Number(value.toString());//pass tick values as a string into Number function
    }
    sourceChartLayout.scales.xAxes[0]['type'] = 'logarithmic'
    sourceChartLayout.scales.xAxes[0]['scaleLabel']['labelString'] = 'Followers range by activity rate'
    sourceChartLayout.scales.xAxes[0]['ticks']['autoSkip'] = true
    // sourceChartLayout.scales.xAxes[0]['ticks']['maxTicksLimit'] = 12
    sourceChartLayout.scales.xAxes[0]['ticks']['callback'] = function (value, index, values) {
      return Number(value.toString());//pass tick values as a string into Number function
    }
    sourceChartLayout.tooltips = { callbacks: { label: null } }
    sourceChartLayout['tooltips']['callbacks']['label'] = function (tooltipItems, data) {
      var output = [];
      var index = tooltipItems.index;
      var datasetIndex = tooltipItems.datasetIndex;
      var dataset = data.datasets[datasetIndex];
      var datasetItem = dataset.data[index];
      output.push("Range: " + datasetItem.x)
      output.push("Counts: " + datasetItem.y)
      return output;
    }

    var graphData = {
      type: 'scatter',
      data: {
        datasets: [{
          label: 'Status', // Name the series
          data: data, // Specify the data values array
          borderColor: '#2196f3', // Add custom color border            
          backgroundColor: '#2196f3', // Add custom color background (Points and Fill)
        }]
      },
      options: sourceChartLayout
    }
    var influencerDetailStatusCount = new Chart(ctx, graphData);

    chartjsGraphsLoaded.push({
      id: "influencerDetailStatusCount",
      data: graphData,
      chartObj: influencerDetailStatusCount,
      expandRatio: 1,
    })
  }


  if (document.getElementById('dateWiseEngagement') && dateWiseEngagement && dateWiseEngagement.length > 0) {
    var ctx = document.getElementById("dateWiseEngagement");
    dateWiseEngagement = dateWiseEngagement.filter(function (item) { return item })
    var labels = dateWiseEngagement.map(function (obj) { if (obj && obj.postDate) { return obj.postDate } })
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    var optionsLayout = JSON.parse(JSON.stringify(BarChartLayout))
    optionsLayout.scales.xAxes[0]['scaleLabel'] = {
      display: true,
      labelString: "Post Date"
    }
    optionsLayout.scales.yAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Total Engagements'
    }
    optionsLayout.scales.xAxes[0].ticks.display = false
    var values = dateWiseEngagement.map(function (obj) { if (obj) { return obj.count } })
    var graphData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          borderColor: '#00b1b4',
          data: values,
          backgroundColor: '#00b1b4',
          label: "Engagements",
        }]
      },
      options: optionsLayout,
    }
    graphData['options']['legend']['display'] = false
    dateWiseEngagementChart = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "dateWiseEngagement",
      data: graphData,
      chartObj: dateWiseEngagementChart,
      expandRatio: 2
    })
  }

  if (document.getElementById('dayOfWeek') && dayOfWeek && dayOfWeek.length > 0) {
    var ctx = document.getElementById("dayOfWeek");
    dayOfWeek = dayOfWeek.filter(function (item) { return item })
    var labels = dayOfWeek.map(function (obj) { if (obj && obj.postDate) { return obj.postDate } })
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    var optionsLayout = JSON.parse(JSON.stringify(BarChartLayout))
    optionsLayout.scales.yAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'No. of Posts'
    }
    optionsLayout.scales.xAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Day Of Week'
    }
    // optionsLayout.scales.xAxes[0].ticks.display = false
    var values = dayOfWeek.map(function (obj) { if (obj) { return obj.count } })
    var graphData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          borderColor: '#00b1b4',
          data: values,
          backgroundColor: '#00b1b4',
          label: "Posts",
        }]
      },
      options: optionsLayout,
    }
    graphData['options']['legend']['display'] = false
    dayOfWeekChart = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "dayOfWeek",
      data: graphData,
      chartObj: dayOfWeekChart,
      expandRatio: 2
    })
  }


  if (document.getElementById('hourOfDay') && hourOfDay && hourOfDay.length > 0) {
    var ctx = document.getElementById("hourOfDay");
    hourOfDay = hourOfDay.filter(function (item) { return item })
    var labels = hourOfDay.map(function (obj) { if (obj && obj.postDate) { return obj.postDate } })
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    var optionsLayout = JSON.parse(JSON.stringify(BarChartLayout))
    optionsLayout.scales.yAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'No. of Posts'
    }
    optionsLayout.scales.xAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Hour Of Day'
    }
    // optionsLayout.scales.xAxes[0].ticks.display = false
    var values = hourOfDay.map(function (obj) { if (obj) { return obj.count } })
    var graphData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          borderColor: '#00b1b4',
          data: values,
          backgroundColor: '#00b1b4',
          label: "Posts",
        }]
      },
      options: optionsLayout,
    }
    graphData['options']['legend']['display'] = false
    hourOfDayChart = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "hourOfDay",
      data: graphData,
      chartObj: hourOfDayChart,
      expandRatio: 2
    })
  }
  if (document.getElementById('hourOfDayWithDayOfWeek') && hourOfDayWithDayOfWeek && hourOfDayWithDayOfWeek.length > 0) {
    var ctx = document.getElementById("hourOfDayWithDayOfWeek");
    hourOfDayWithDayOfWeek = hourOfDayWithDayOfWeek.filter(function (item) { return item })
    var labels = hourOfDayWithDayOfWeek.map(function (obj) { if (obj && obj.postDate) { return obj.postDate } })
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    var optionsLayout = JSON.parse(JSON.stringify(BarChartLayout))
    optionsLayout.scales.yAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'No. of Posts'
    }
    optionsLayout.scales.xAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Hour of day with day of week'
    }
    // optionsLayout.scales.xAxes[0].ticks.display = false
    var values = hourOfDayWithDayOfWeek.map(function (obj) { if (obj) { return obj.count } })
    var graphData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          borderColor: '#00b1b4',
          data: values,
          backgroundColor: '#00b1b4',
          label: "Posts",
        }]
      },
      options: optionsLayout,
    }
    graphData['options']['legend']['display'] = false
    hourOfDayWithDayOfWeekChart = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "hourOfDayWithDayOfWeek",
      data: graphData,
      chartObj: hourOfDayWithDayOfWeekChart,
      expandRatio: 2
    })
  }

})

function showAllReferenceData(fullData, someData, showTextClass) {
  if ($(showTextClass).text() === 'more') {
    $(someData).addClass('infl_displayNone')
    $(fullData).addClass('infl_displayBlock')
    $(showTextClass).text('less')
    return
  }
  else if ($(showTextClass).text() === 'less') {
    $(someData).removeClass('infl_displayNone')
    $(fullData).removeClass('infl_displayBlock')
    $(showTextClass).text('more')
    return
  }
}

function updateSocialFollowsDataInBackend(username) {
  showLoaderOnClick()
  $.ajax({
    url: "/admin/updateSocialFollowsAndSaveGraphData?username=" + username,
    type: "POST",
    withCredentials: true,
    xhrFields: {
      withCredentials: true
    },
    success: function (res) {
      hideLoaderOnClick()
      console.log(res)
    },
    error: function (res) {
      hideLoaderOnClick()
      console.log(res)
    }
  })
}


function updateSocialFollowersInBackend(userId, authorType) {
  showLoaderOnClick()
  $.ajax({
    url: "/updateFollowersDemogrphicFromBackend?userId=" + userId + "&authorType=" + authorType,
    type: "GET",
    withCredentials: true,
    xhrFields: {
      withCredentials: true
    },
    success: function (res) {
      repliedSuccess('fa fa-check', "Request has been acknowledged, Please check in 5 minutes for audience demographic.")
      hideLoaderOnClick()
      console.log(res)
    },
    error: function (res) {
      hideLoaderOnClick()
      console.log(res)
    }
  })
}




var profileSorterDay = {
  // "sunday": 0, // << if sunday is first day of week
  "sun": 0,
  "mon": 1,
  "tue": 2,
  "wed": 3,
  "thu": 4,
  "fri": 5,
  "sat": 6,
}
var profileSorterFullDay = {
  // "sunday": 0, // << if sunday is first day of week
  "sunday": 0,
  "monday": 1,
  "tuesday": 2,
  "wednesday": 3,
  "thursday": 4,
  "friday": 5,
  "saturday": 6,
}


var profileSorterHour = {
  // "sunday": 0, // << if sunday is first day of week
  "12am": 0,
  "1am": 1,
  "2am": 2,
  "3am": 3,
  "4am": 4,
  "5am": 5,
  "6am": 6,
  "7am": 7,
  "8am": 8,
  "9am": 9,
  "10am": 10,
  "11am": 11,
  "12pm": 12,
  "1pm": 13,
  "2pm": 14,
  "3pm": 15,
  "4pm": 16,
  "5pm": 17,
  "6pm": 18,
  "7pm": 19,
  "8pm": 20,
  "9pm": 21,
  "10pm": 22,
  "11pm": 23,
}
function sumOfCountOfSameKeysFilter(currentArrayOfObject) {
  var filterArrayOfObject = []
  if (currentArrayOfObject && currentArrayOfObject.length > 0) {
    for (var i = 0; i < currentArrayOfObject.length; i++) {
      if (filterArrayOfObject && filterArrayOfObject.length > 0 && currentArrayOfObject[i] && (filterArrayOfObject.map(function (obj) { return obj.postDate }).indexOf(currentArrayOfObject[i].postDate)) > -1) {
        var dayIndex = filterArrayOfObject.findIndex(function (obj) { if (obj.postDate == currentArrayOfObject[i].postDate) { return true } })
        filterArrayOfObject[dayIndex].count += currentArrayOfObject[i].count
      }
      else {
        filterArrayOfObject.push(currentArrayOfObject[i])
      }
    }
  }
  return filterArrayOfObject
}



var getPostByEngagement = function (recentPostsData) {
  var dateWiseEngagement = []
  var dayOfWeek = []
  var hourOfDay = []
  var hourOfDayWithDayOfWeek = []
  if (recentPostsData && recentPostsData.length > 0) {
    for (var i = 0; i < recentPostsData.length; i++) {
      if (recentPostsData[i] && recentPostsData[i].postDate) {
        dateWiseEngagement.push({ postDate: moment(recentPostsData[i].postDate).format('D MMM'), count: recentPostsData[i].postInteraction.likeCount + recentPostsData[i].postInteraction.shareCount + recentPostsData[i].postInteraction.commentCount })
        dayOfWeek.push({ postDate: moment(recentPostsData[i].postDate).format('dddd'), count: 1 })
        hourOfDay.push({ postDate: moment(recentPostsData[i].postDate).format('ha'), count: 1 })
        hourOfDayWithDayOfWeek.push({ postDate: moment(recentPostsData[i].postDate).format('ddd ha'), count: 1 })
      }
    }
  }
  dayOfWeek = JSON.parse(JSON.stringify(sumOfCountOfSameKeysFilter(dayOfWeek)))
  dayOfWeek = JSON.parse(JSON.stringify(dayOfWeek.sort(function sortByDay(a, b) {
    var day1 = a.postDate.toLowerCase();
    var day2 = b.postDate.toLowerCase();
    return profileSorterFullDay[day1] - profileSorterFullDay[day2];
  })))
  hourOfDay = JSON.parse(JSON.stringify(sumOfCountOfSameKeysFilter(hourOfDay)))
  hourOfDay = JSON.parse(JSON.stringify(hourOfDay.sort(function sortByHour(a, b) {
    var day1 = a.postDate;
    var day2 = b.postDate;
    return profileSorterHour[day1] - profileSorterHour[day2];
  })))
  hourOfDayWithDayOfWeek = JSON.parse(JSON.stringify(sumOfCountOfSameKeysFilter(hourOfDayWithDayOfWeek)))
  var arrOfDay = Object.keys(profileSorterDay)
  var hourOfDayWithDayOfWeekFilter = []
  for (var i = 0; i < arrOfDay.length; i++) {
    var currDay = hourOfDayWithDayOfWeek.filter(function (obj) { if (arrOfDay[i] == obj.postDate.split(' ')[0].toLowerCase()) { return true } })
    if (currDay && currDay.length > 0) {
      currDay = currDay.sort(function sortByHour(a, b) {
        var day1 = a.postDate.split(' ')[1];
        var day2 = b.postDate.split(' ')[1]
        return profileSorterHour[day1] - profileSorterHour[day2];
      })
      hourOfDayWithDayOfWeekFilter.push(currDay)
    }

  }
  hourOfDayWithDayOfWeekFilter = [].concat.apply([], hourOfDayWithDayOfWeekFilter)

  return { dateWiseEngagement: dateWiseEngagement, dayOfWeek: dayOfWeek, hourOfDay: hourOfDay, hourOfDayWithDayOfWeek: hourOfDayWithDayOfWeekFilter }
}



function reloadInfluencerData(influencerUserName, authorType, crawltype, linkedinProfileTypes) {
  showLoaderOnClick()
  if (crawltype == "newcrawling") {
    $("#influencerCrawlingMessage p").text("Fetching data for " + influencerUserName + ". It may take upto 1 minute to complete.")
    $("#influencerCrawlingMessage").removeClass('infl_displayNone')
    $("#influencerCrawlingMessage").addClass('infl_displayBlock')

  } else if (crawltype == "autoupdating" || crawltype == "manualupdating") {
    $("#influencerCrawlingMessage p").text("Updating data for " + influencerUserName + ". It may take upto 1 minute to complete.")
    $("#influencerCrawlingMessage").removeClass('infl_displayNone')
    $("#influencerCrawlingMessage").addClass('infl_displayBlock')
  }
  if (influencerUserName && influencerUserName != '' && authorType && authorType != '') {
    $.ajax({
      method: "POST",
      url: "/updateInfluencerByUserCall",
      async: true,
      data: { "influencerUserName": influencerUserName, "authorType": authorType, "crawltype": crawltype, "profileType": linkedinProfileTypes },
      success: function (res) {
        console.log(JSON.stringify(res))
        hideLoaderOnClick()
        $("#influencerCrawlingMessage").addClass('infl_displayNone').removeClass('infl_displayBlock')
        if (res && res.status && res.status == "success") {
          repliedSuccess('fa fa-check', res.showStatus)
          showLoaderOnClick()
          location.reload()
        }
        else if (res && res.status == "fail" && res.crawltype == "newcrawling") {
          // repliedSuccess('fa fa-times', res.showStatus)
          $("#tryAnotherInfluencer").modal()
          callRedirectToUrl("#sendToFindNewInfluencerPage")
        }
        else if (res && res.status == "fail") {
          console.log("fail " + res.showStatus)
          repliedSuccess('fa fa-times', res.showStatus)
          $("#influencerCrawlingMessage p").text('')
        }
      },
      error: function (res) {
        console.log(JSON.stringify(res))
        $("#influencerCrawlingMessage").addClass('infl_displayNone').removeClass('infl_displayBlock')
        hideLoaderOnClick()
        if (res && res.showStatus)
          repliedSuccess('fa fa-times', res.showStatus)
        else
          repliedSuccess('fa fa-info', "Updating data for " + influencerUserName + ". It's taking more time to complete, please reload after some time.")
      }
    })
  }
}

function callRedirectToUrl(id) {
  $(id).click(function () {
    document.location = "https://www.rankme.online/analyze-instagram-tiktok-youtube-twitter-and-facebook/"
  });

  setTimeout(function () {
    $(id).trigger('click');
  }, 5000)

}
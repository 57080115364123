function callAjaxForMasonryView(oembedUrl, postId) {
  $.ajax({
    url: oembedUrl,
    async: false,
    type: "GET",
    success: function (res) {
      $(postId).html(res.html)

    },
    error: function (ans) {
      if (postId.indexOf("pdfInstaTopPost") > -1 || postId.indexOf("pdfKolInstaTopPost") > -1)
        $(postId).html("<a class='pdf-top-mentions-boxes' href=" + oembedUrl.split('?url=')[1].split('&')[0] + "><div class='sum_mentionsdiv defaultombed' style='width: fit-content !important;  max-height: fit-content !important'><img src='/images/fallback-img-post-2.png' ><h4 style='text-align: center;' >Link Broken</h4></div></a>")
      console.log(ans)


    }

  });
}

// $(document).ready(function () {
//   setTimeout(function () { $('iframe.instagram-media').css('min-width', '100%') }, 5000);
//   setTimeout(function () { $('iframe.instagram-media').css('width', '100%') }, 15000);
// })
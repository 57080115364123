function changeUserDemoSteps(step1, step2, defaultImage) {
  if ($('#nu-step-2').hasClass('active')) {
    $(step2).hide();
    $(step1).fadeIn('0.6');
    $(defaultImage).fadeIn('0.6');
    $('#nu-step-2').removeClass('active')
  }

}

function newUserSelecttionBoxClickFunction(selectbox, step1, selectImage) {
  $(step1).hide();
  $(selectbox).show();
  $(selectImage).fadeIn('0.6');
  $('#revertSectionId').text(selectbox)
  $('#nu-step-2').addClass('active')
}

function changeUserDemoStepsthree(step2, defaultImage) {
  if ($('#nu-step-3').hasClass('active')) {
    $(step2).hide();
    var containerId = $('#revertSectionId').text()
    $(containerId).fadeIn('0.6');
    if (containerId == '#nu-social-media-select') {
      $('#nu-social-page-image').fadeIn('0.6')
    }
    else if (containerId == '#nu-engagements-select') {
      $('#nu-orm-page-image').fadeIn('0.6')
    }
    else if (containerId == '#nu-influencer-discovery-select') {
      $('#nu-influencer-page-image').fadeIn('0.6')
    }
    else {
      $(defaultImage).fadeIn('0.6');
    }
    $('#nu-step-3').removeClass('active')
  }

}

function redirectToDemoProject(selectbox, step1, redirectTo, user, loginImage) {
  $('#nu-step-3').addClass('active')
  if (user != '') {

    $("#nu-PageLoader").html("<img style='height: 50px;width: 50px;z-index:1051;position: fixed;top: 40%;left: 45%;' src = '/images/loader-icon.gif'/>")
    // $("#nu-PageLoader").html("<p style='height: 50px;width: 50px;z-index:1051;position: fixed;top: 40%;left: 45%;color: #000; font-size:28px; font-weight:800px'>Loading...</p>")
    document.location.href = redirectTo
  }
  else {
    $("#nu-PageLoader").html("<img style='height: 50px;width: 50px;z-index:1051;position: fixed;top: 40%;left: 45%;' src = '/images/loader-icon.gif'/>")
    document.location.href = redirectTo
    // $(step1).hide();
    // $(selectbox).fadeIn('0.6');
    // $(loginImage).fadeIn('0.6')
    // $('input[name="redirectToProjectOnLogin"]').val(redirectTo);

  }
  // showLoaderOnClick()
  // document.location.href = '/enterprise-dashboard?projectId=' + projectId
}

function redirectToOwnProjectDemo(selectbox, step1, userId, loginImage) {
  var currentUrl = document.location.href
  $('#nu-step-3').addClass('active')
  $("#nu-PageLoader").html("<img style='height: 50px;width: 50px;z-index:1051;position: fixed;top: 40%;left: 45%;' src = '/images/loader-icon.gif'/>")
  if (userId != '') {
    $.ajax({
      url: "/checkUserDetailsForMakingDemoProject",
      type: "POST",
      withCredentials: true,
      xhrFields: {
        withCredentials: true
      },
      success: function (res) {
        console.log("success " + res)
        if (res && res.status && res.redirect) {
          document.location.href = res.redirect
        }
        // }

        // else {
        //   document.location.href = res.redirect
        // $("#nu-PageLoader").html("")
        // $(step1).hide();
        // $(selectbox).fadeIn('0.6')
        // if (userId != '') {
        //   $('#nu-pricing-page-data').fadeIn('0.6')
        // }
        // else {
        //   $(loginImage).fadeIn('0.6')
        // }
        // }
      },
      error: function (res) {
        document.location.href = currentUrl
        // $(step1).hide();
        // $(selectbox).fadeIn('0.6')
        // if (userId != '') {
        //   $('#nu-pricing-page-data').fadeIn('0.6')
        // }
        // else {
        //   $(loginImage).fadeIn('0.6')
        // }

      }
    })
  }
  else {
    document.location.href = "/login"
  }
}

// -------------------------------------------------razorpay-------------------------------------
// for auto click on razorpay button on init pug
// $(document).ready(function () {
//   if (document.getElementsByClassName('razorpay-payment-button')) {
//     $('.razorpay-payment-button').click()
//   }
// })


function changeSubscriptionStatus(subscription) {
  console.log(JSON.stringify(subscription))
  $.ajax({
    url: "/changeSubscriptionStatus",
    type: "POST",
    data: {
      subscription: subscription
    },
    withCredentials: true,
    xhrFields: {
      withCredentials: true
    },
    success: function (res) {
      console.log("success" + JSON.stringify(res))
      hideLoaderOnClick()
      $('.updateSubscriptionDataStatus').text(res.subscriptionStatus)
      return;
    },
    error: function (res) {
      hideLoaderOnClick()
      console.log(JSON.stringify(res))
      return;
    }
  })
}




function fadeOutModal() {
  $('.modal-backdrop.fade.in').removeClass('in').addClass('out').css('position', 'unset')
}


function sendtoStep1(selectType) {
  $("#nu-PageLoader").html("<img style='height: 50px;width: 50px;z-index:1051;position: fixed;top: 40%;left: 45%;' src = '/images/loader-icon.gif'/>")
  document.location.href = "/onboardingStep" + selectType
  $("#nu-PageLoader").html("")
}


function deletetheVideo() {
  $('iframe[src*="https://www.youtube.com/embed/"]').addClass("youtube-iframe");
  // changes the iframe src to prevent playback or stop the video playback in our case
  $('.youtube-iframe').each(function (index) {
    $(this).attr('src', $(this).attr('src').replace("&autoplay=1", ""));
    return false;
  });
}

function startAutoPlayVideo() {
  $('iframe[src*="https://www.youtube.com/embed/"]').addClass("youtube-iframe");
  $('.youtube-iframe').each(function (index) {
    $(this).attr("allow", "autoplay;")
    $(this).attr('src', $(this).attr('src').concat("&autoplay=1"));
    return false;
  });
}


function sendAgainConfirmEmail(emailbox) {
  $("#resendMailBtn").addClass("resend-disable")
  $('#resendMailBtn').removeAttr('onclick');
  $('#timerText').removeClass('infl_displayNone')
  $('#sendNowText').addClass('infl_displayNone')
  var paramArray = splitIntoParams(document.location.href)
  paramArray = paramArray.filter(function (val) {
    if (val.param == "returnUrl") return true
  })
  var returnUrl = null
  if (paramArray.length > 0) {
    returnUrl = paramArray[0].value
  }
  document.getElementById('resendTimer').innerHTML = 01 + ":" + 30;
  startTimer();
  var emailId = $(emailbox).val()
  var data = {
    emailId: emailId,
    returnUrl: returnUrl
  }
  $.ajax({
    url: "/sendAgainConfirmEmail",
    type: "POST",
    withCredentials: true,
    xhrFields: {
      withCredentials: true
    },
    data: data,
    success: function (res) {
      console.log(res)
    },
    error: function (res) {
      console.log(res)
    }
  })

}


function startTimer() {
  var presentTime = document.getElementById('resendTimer').innerHTML;
  var timeArray = presentTime.split(/[:]+/);
  var m = timeArray[0];
  var s = checkSecond((timeArray[1] - 1));
  if (s == 59) { m = m - 1 }
  //if(m<0){alert('timer completed')}
  if (parseInt(m) > -1) {
    document.getElementById('resendTimer').innerHTML = m + ":" + s;
    setTimeout(startTimer, 1000)
  }
  else {
    $('#timerText').addClass('infl_displayNone')
    $('#sendNowText').removeClass('infl_displayNone')
    $("#resendMailBtn").removeClass("resend-disable")
    $("#resendMailBtn").attr("onClick", "sendAgainConfirmEmail('#verifyemail_userId')");
  }
  console.log(m)
}

function checkSecond(sec) {
  if (sec < 10 && sec >= 0) { sec = "0" + sec }; // add zero in front of numbers < 10
  if (sec < 0) { sec = "59" };
  return sec;
}


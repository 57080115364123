function divertToMentionPage(addonUrl) {
  showLoaderOnClick()
  var url = document.location.href.split('?')[1]
  if (typeof (addonUrl) !== 'undefined' && addonUrl !== null) {
    url = url + addonUrl
  }
  url = '/enterprise-mention?' + url
  // hideLoaderOnClick()
  document.location.href = url
  return;
}
// written by ankur
// show notification 'You are not seeing the latest data right now.. You will get a notification once all the data is loaded.' 
function showLatestDataInfo(showinfoBar, latestDataInfoCookie, onClickId) {
    if (typeof (Storage) !== "undefined") {
        if ((localStorage.getItem(latestDataInfoCookie) === null) || (localStorage.getItem(latestDataInfoCookie) !== 'ACCEPT')) {
            $(showinfoBar).fadeIn()

        }
    }
    $(onClickId).click(function () {
        if (typeof (Storage) !== "undefined") {
            localStorage.setItem(latestDataInfoCookie, "ACCEPT");
            $(showinfoBar).fadeOut();

        }
    })
}
function firstTimeSummaryVideoHideFunction(latestDataInfoCookie) {
    if (typeof (Storage) !== "undefined") {
        localStorage.setItem(latestDataInfoCookie, "ACCEPT");
    }
}


function showSidebarOptionHighlight() {
    var sidebarurl = document.location.href
    if (sidebarurl.indexOf("enterprise-panel-result") > -1) {
        $("#sb-realtimeactive").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("enterprise-dashboard") > -1) {
        $("#sb-summaryactive").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("enterprise-mention") > -1) {
        $("#sb-mentionsactive").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("analysis/feature") > -1) {
        $("#sb-fanalysisactive").addClass("activeSidebarOption")
        $("#sb-analysisactive").addClass("activeSidebarOptionMain")
    }
    if (sidebarurl.indexOf("analysis/content") > -1) {
        $("#sb-canalysisactive").addClass("activeSidebarOption")
        $("#sb-analysisactive").addClass("activeSidebarOptionMain")
    }
    if (sidebarurl.indexOf("analysis/demographic") > -1) {
        $("#sb-danalysisactive").addClass("activeSidebarOption")
        $("#sb-analysisactive").addClass("activeSidebarOptionMain")
    }
    if (sidebarurl.indexOf("analysis/audience") > -1) {
        $("#sb-aanalysisactive").addClass("activeSidebarOption")
        $("#sb-analysisactive").addClass("activeSidebarOptionMain")
    }
    if (sidebarurl.indexOf("enterprise-competitor-analysis") > -1) {
        $("#sb-competitiveactive").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("track-influencers") > -1) {
        $("#sb-influencersactive").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("enterprise-reports") > -1) {
        $("#sb-reportsactive").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("project-settings") > -1) {
        $("#sb-project_settingsactive").addClass("activeSidebarOption")
    }
}

function showSidebarCompareOptionHighlight() {
    var sidebarurl = document.location.href
    if (sidebarurl.indexOf("compareSummary") > -1) {
        $("#sb-compareSummary").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("compareSentiment") > -1) {
        $("#sb-compareSentiment").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("compareSource") > -1) {
        $("#sb-compareSource").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("compareAudience") > -1) {
        $("#sb-compareAudience").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("compareFeature") > -1) {
        $("#sb-compareFeature").addClass("activeSidebarOption")
    }
}
function showSidebarReportOptionHighlight() {
    var sidebarurl = document.location.href
    if (sidebarurl.indexOf("reports/infographics") > -1) {
        $("#sb-infographicsactive").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("reports/pdf") > -1) {
        $("#sb-reportsPdfactive").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("reports/excel") > -1) {
        $("#sb-reportsExcelactive").addClass("activeSidebarOption")
    }
    if (sidebarurl.indexOf("reports/custom") > -1) {
        $("#sb-reportsCustomactive").addClass("activeSidebarOption")
    }
}

function clickHandler(event) {
    if (event.ctrlKey || event.metaKey) {
        //ctrlKey to detect ctrl + click
        //metaKey to detect command + click on MacOS
        return true
    } else {
        return false
    }
};

$(document).ready(function () {
    $("a[href]").on('click', function (event) {
        var cntrlIsPressed = clickHandler(event)
        if (!cntrlIsPressed) {
            var checkOnboardingCircle = $(this).hasClass("btn-circle")
            var checkhash = $(this).attr("href")
            var checktarget = $(this).is("[target]")
            var checktoggle = $(this).is("[data-toggle]")
            if (typeof (is_app) != 'undefined' && is_app) {
                if (checkOnboardingCircle != true && checkhash != '#' && checkhash != "" && checkhash != "javascript:void(0);" && checktoggle == false) {
                    showLoaderOnClick()
                }
            } else {
                if (checkOnboardingCircle != true && checkhash != '#' && checkhash != "" && checkhash != "javascript:void(0);" && checktarget == false && checktoggle == false) {
                    showLoaderOnClick()
                }
            }

        }
    })

})

function showLoaderOnClick() {
    $("#allPageLoader").html("<img style='height: 50px;width: 50px;z-index:1051;position: fixed;top: 40%;left: 45%;' src = '/images/loader-icon.gif'/>")
}

function hideLoaderOnClick() {
    $("#allPageLoader").html("")
}
$(document).on("click", function (e) {
    var container = $("#showAllProjectClick"); // YOUR CONTAINER SELECTOR

    if (!container.is(e.target) // if the target of the click isn't the container...
        &&
        container.has(e.target).length === 0) { // ... nor a descendant of the container
        $(".sm-projectSelectNames").removeClass("detailshowClicklist")
    } else {
        $(".sm-projectSelectNames").addClass("detailshowClicklist")
    }

});

function brandScoreRunningCircle(circleId, timerId, score) {
    // math trick 2*pi*57 = 358, must be less than 360 degree 
    var circle = document.getElementById(circleId);
    var myTimer = document.getElementById(timerId);
    if (circle && typeof (circle) != 'undefined') {
        var interval = 5;
        var angle = 0;
        var angle_increment = 1;
        var influenceScore = score
        var myangle = influenceScore * 0.1 * 360

        var intervelId = window.setInterval(function () {
            angle += angle_increment;
            circle.setAttribute("stroke-dasharray", angle + ", 20000");
            myTimer.innerHTML = score + "/10";
            if (angle >= 360 || myangle <= angle) {
                window.clearInterval(intervelId);
            }
        }, interval);
    }
}

function brandScoreRunningInflCircle(circleId, timerId, score) {
    // math trick 2*pi*57 = 358, must be less than 360 degree 
    var circle = document.getElementById(circleId);
    var myTimer = document.getElementById(timerId);
    var interval = 5;
    var angle = 0;
    var angle_increment = 3.6;
    var influenceScore = score
    var myangle = influenceScore * 0.1 * 360

    var intervelId = window.setInterval(function () {
        angle += angle_increment;
        circle.setAttribute("stroke-dasharray", angle + ", 20000");
        myTimer.innerHTML = score + "/10";
        if (angle >= 360 || myangle <= angle) {
            window.clearInterval(intervelId);
        }
    }, interval);
}


function brandScoreRunningCircleWithoutMax(circleId, timerId, score) {
    // math trick 2*pi*57 = 358, must be less than 360 degree 
    var circle = document.getElementById(circleId);
    var myTimer = document.getElementById(timerId);
    var interval = 5;
    var angle = 0;
    var angle_increment = 120;
    var influenceScore = score
    var myangle = 360

    var intervelId = window.setInterval(function () {
        angle += angle_increment;
        circle.setAttribute("stroke-dasharray", angle + ", 200");
        myTimer.innerHTML = score;
        if (angle >= 360 || myangle <= angle) {
            window.clearInterval(intervelId);
        }
    }, interval);
}

// function countDownThreeHours() {
//     const second = 1000;
//     var minute = second * 60;
//     var hour = minute * 60;
//     var day = hour * 24;
//     var current = new Date()
//     var countDown = moment(current).add(3, 'hours'),
//         x = setInterval(function () {

//             var now = new Date().getTime(),
//                 distance = countDown - now;
//             if (distance > 0) {
//                 document.getElementById('hoursCounterFirstTime').innerText = Math.floor((distance % (day)) / (hour)),
//                     document.getElementById('minutesCounterFirstTime').innerText = Math.floor((distance % (hour)) / (minute)),
//                     document.getElementById('secondsCounterFirstTime').innerText = Math.floor((distance % (minute)) / second);
//             }
//             //do something later when date is reached
//             else {
//                 clearInterval(x);
//                 document.getElementById('hoursCounterFirstTime').innerText = 0
//                 document.getElementById('minutesCounterFirstTime').innerText = 0
//                 document.getElementById('secondsCounterFirstTime').innerText = 0
//             }

//         }, second)
// }


function splitIntoParams(pageUrl) {

    var paramString = pageUrl.split("?");
    var paramArray = [];
    if (paramString.length > 1) {
        var params = paramString[1].split("&");
        paramArray = params.filter(function (item) {
            if (item != "") {
                return true;
            }
            return false;
        }).map(function (item) {
            var properties = item.split("=");
            return {
                param: properties[0],
                value: decodeURIComponent(properties[1])
            };
        });
    }
    return paramArray;
}

// ================================influencePage================================
$(document).ready(function () {
    $(".infl_seeMoreButton, .infl_seeLessButton").on("click", function () {
        $(".infl_seeMorePosts").toggleClass("infl_displayNone").toggleClass("infl_displayBlock")
        $(".infl_seeMoreButton").toggleClass("infl_displayNone").toggleClass("infl_displayBlock")
        $(".infl_seeLessButton").toggleClass("infl_displayNone").toggleClass("infl_displayBlock")
    })
})
// ************************************************************

$(document).ready(function () {
    //for showing tooltip title
    $('[data-toggle="tooltip"]').tooltip();

    // $('.userDetails').on('click', function mentionDetailView(e) {
    //     e.stopPropagation();
    //     $('#mentionDetails-modal').modal('toggle');
    //     $('#mentionDetailsContent').html("<img style='height: 50px;width: 50px;position: absolute;top: 45%;left: 45%;margin-top:30vh' src = '../images/loader-icon.gif' >");
    //     // var that = $(this);
    //     var url = "/showDetailedPost?postId=" + $(this).attr("id") + "&projectId=" + $(this).attr("name");
    //     $.getJSON({
    //         url: url
    //     }).done(function (data) {
    //         //alert(data["htmlData"]);
    //         //alert(JSON.stringify(data));
    //         if (data["error"] == "No Data Found") {
    //             detailPostError();
    //             // $('#mentionDetailsContent').css("display", "none");
    //             $("#mentionDetails-modal").click()

    //         } else {
    //             $('#mentionDetailsContent').html(data["htmlData"]);
    //         }
    //         //$(this).closest('#mentionDetails').show();
    //         //$("[data-toggle='modal']").bootstrapToggle('destroy')
    //         //$("[data-toggle='modal']").bootstrapToggle();
    //     })

    // });
    if (document.getElementsByClassName('select2-multiple').length > 1) {
        $('.select2-multiple').select2();
    }





    $(document).keydown(function (event) {
        if (event.keyCode == 27) {
            $('#mentionDetails-modal').click();
        }
    });


    // ********************** disable future dates in datepicker ********************

    var d = new Date();
    var todateset = d.toISOString().split("T")[0];
    d.setMonth(d.getMonth() - 3);
    var fromdateset = d.toISOString().split("T")[0];
    $("#fromdate").attr("min", fromdateset).attr("max", todateset)
    $("#todate").attr("min", fromdateset).attr("max", todateset)




    $(".markpost").on("click", function () {
        if ($($(this).children("span")).text() === ' Mark for later') {
            var text = ' Marked';
            $($(this).children("span")).text(text)
            $(this).attr("onClick", "markforlater(true)")
        } else {
            var text = ' Mark for later';
            $($(this).children("span")).text(text)
            $(this).attr("onClick", "markforlater(false)")
        }
        $($(this).children("span")).css("color", "#0079c0")
        $($(this).children(".markicon")).toggleClass("marktrue").toggleClass("markfalse")
    });
    if (typeof (countries) != "undefined")
        autocomplete(document.getElementById("country"), countries);
    autocomplete(document.getElementById("city"), cities);
    if (typeof (categories) != "undefined")
        autocomplete(document.getElementById("categories"), categories);
    if (typeof (languages) != "undefined")
        autocomplete(document.getElementById("languages"), languages);
    if (typeof (allTimeZone) != "undefined")
        autocomplete(document.getElementById("accountUserTimeZone"), allTimeZone);
});

// for autocomplete country, city and industry

function autocomplete(inp, arr) {
    if (inp == undefined) {
        return;
    }
    var currentFocus;
    var autoCompletefunction = function (event) {
        var userInput = this.value
        if (userInput == "" || userInput == undefined || userInput == null) {
            userInput = ""
        }
        var a, b, i, val = userInput
        /*close any already open lists of autocompleted values*/
        closeAllLists();
        /*if (!val) {
            return false;
        }*/
        currentFocus = -1;
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        this.parentNode.appendChild(a);
        /*for each item in the array...*/
        if (val.length > 0) {
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                    });
                    a.appendChild(b);
                }
            }
        } else {
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    //b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i]
                    //.substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                    });
                    a.appendChild(b);
                }
            }
        }
    }
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/

    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", autoCompletefunction);
    inp.addEventListener("click", autoCompletefunction);
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function (e) {
        var x = document.getElementById(this.id + "autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        if (e.keyCode == 40) {
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 38) { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) x[currentFocus].click();
            }
        }
    });

    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
    }

    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }

    function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {

        if (e.srcElement.id !== "categories" && e.srcElement.id !== "country" && e.srcElement.id !== "languages" && e.srcElement.id !== "accountUserTimeZone") {

            closeAllLists(e.target);
        }
    });
}

/*An array containing all the country names in the world:*/
var cities = ["Delhi", "Mumbai", "Hyderabad", "Chennai", "Bengaluru", "Kolkata", "Pune", "Kochi", "Jaipur", "Varanasi", "Ahmedabad", "Kanpur", "Chandigarh", "Lucknow", "Indore", "Agra", "Vadodara", "Visakhapatnam", "Surat", "Allahabad", "Bhopal", "New Delhi", "Nagpur", "Thiruvananthpuram", "Mangalore", "Ludhiana", "Gurugram"];

/*initiate the autocomplete function on the "myInput" element, and pass along the countries array as possible autocomplete values:*/
if (typeof (countries) != "undefined")
    autocomplete(document.getElementById("country"), countries);
autocomplete(document.getElementById("city"), cities);
if (typeof (categories) != "undefined")
    autocomplete(document.getElementById("categories"), categories);
if (typeof (languages) != "undefined")
    autocomplete(document.getElementById("languages"), languages);
if (typeof (allTimeZone) != "undefined")
    autocomplete(document.getElementById("accountUserTimeZone"), allTimeZone);


function checkScreenViewForNotify() {
    if (is_desktop) {
        var offset = {
            x: -800,
            y: 40
        }
    } else if (is_tablet) {
        var offset = {
            x: -500,
            y: 50
        }
    } else {
        var offset = {
            x: -100,
            y: 10
        }
    }
    return offset
}

function markforlater(active) {
    var offsetdata = checkScreenViewForNotify()
    $.notify({
        title: '',
        icon: 'fa fa-check',
        message: (active === false) ? "This post has been <strong>marked</strong>" : "This post has been <strong>unmarked</strong>",
    }, {
        type: 'info',
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutRight'
        },
        spacing: 10,
        offset: offsetdata,
        z_index: 1051,
    });
}



function detailMarkAndDelete(projectId, domElement) {

    if (domElement.attr("id") == "deletePost") {
        // ajaxMark(projectId, domElement.attr("name"), "delete");
        domElement.parents(".filter-head").hide();
    } else if (domElement.attr("id") == "markPostForLater") {
        ajaxMark(projectId, domElement.attr("name"), "markForLater");
    } else if (domElement.attr("id") == "unmark") {
        ajaxMark(projectId, domElement.attr("name"), "unmark");
    }

}


function deletePost(postId) {
    ajaxMark(projectId, postId, "delete");
    var offsetdata = checkScreenViewForNotify()
    $.notify({
        title: '',
        icon: 'fa fa-trash',
        message: "This post has been <strong>Marked Irrelevant</strong>",
    }, {
        type: 'info',
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutRight'
        },
        placement: {
            from: "top",
            align: "right"
        },
        offset: offsetdata,
        spacing: 10,
        z_index: 1051,

    });
    if (document.location.href.indexOf("analysis/feature") || document.location.href.indexOf("enterprise-mention") > 0) {
        showLoaderOnClick()
        document.location.reload()
    }
}

function detailPostError() {
    var offsetdata = checkScreenViewForNotify()
    $.notify({
        title: '',
        icon: 'fa fa-info',
        message: "No Data Found",
    }, {
        type: 'info',
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutRight'
        },
        placement: {
            from: "top",
            align: "right"
        },
        offset: offsetdata,
        spacing: 10,
        z_index: 1051,

    });
}

function reportClearAllNotify() {
    var offsetdata = checkScreenViewForNotify()
    $.notify({
        title: '',
        icon: 'fa fa-check',
        message: "All filters are cleared",
    }, {
        type: 'info',
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutRight'
        },
        placement: {
            from: "top",
            align: "right"
        },
        offset: offsetdata,
        spacing: 10,
        z_index: 1051,

    });
}

function labelAllNotify() {
    var offsetdata = checkScreenViewForNotify()
    $.notify({
        title: '',
        icon: 'fa fa-check',
        message: "Labels updated",
    }, {
        type: 'info',
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutRight'
        },
        placement: {
            from: "top",
            align: "right"
        },
        offset: offsetdata,
        spacing: 10,
        z_index: 1051,

    });
}


function init() {
    var count = 5;
    var counter = setInterval(timer, 1000);

    function timer() {
        count = count - 1;
        if (count == 0) {
            var offsetdata = checkScreenViewForNotify()
            $.notify({
                icon: 'fa fa-info-circle',
                message: "Select start & end date before generate report",
            }, {
                type: 'info',
                animate: {
                    enter: 'animated fadeInDown',
                    exit: 'animated fadeOutUp'
                },
                placement: {
                    from: "top",
                    align: "right"
                },
                offset: offsetdata,
                spacing: 10,
                z_index: 1051,
            });
            return;
        }
    }
}

function addToAppliedFilters(filterType, sources, appliedFilters) {
    var allLabels = $("label[for]");

    for (var i = 0; i < sources.length; i++) {
        var id = sources[i].id
        var filtername = sources[i].name
        for (var j = 0; j < allLabels.length; j++) {
            if (allLabels[j].htmlFor === id) {
                filtername = allLabels[j].textContent
            }
        }
        appliedFilters.push({
            "name": filterType,
            "value": filtername
        })
    }
}

function addToAppliedLabelFilters(filterType, sources, appliedFilters) {
    for (var i = 0; i < sources.length; i++) {
        var filtername = sources[i].getAttribute("name")
        appliedFilters.push({
            "name": filterType,
            "value": filtername
        })
    }
}
function activateSearchFilter(filterUrl) {
    $("#showAllSaveFilterDiv").toggleClass("showAllFilters").toggleClass("hideAllFilters")
    $(".reportFilterClear")[0].reset();
    var paramsArray = splitIntoParams(filterUrl)
    for (var i = 0; i < paramsArray.length; i++) {
        if (paramsArray[i].param != "projectId") {
            $("input[name='" + paramsArray[i].value + "']:checkbox").prop("checked", "true");
            if (paramsArray[i].param === "searchText") {
                if (paramsArray[i].value.startsWith("HT:"))
                    paramsArray[i].value = paramsArray[i].value.replace("HT:", "#")
                $(".filtersearch").val(paramsArray[i].value)
            }
        }
    }
    checkAndSetFiltersApplied()
}
var currentFiltersHtml = ""

function checkAndSetFiltersApplied() {
    // find all checkboxes checked with class
    var influencerType = $('.influencerType:checkbox:checked')
    var profileType = $('.profileType:checkbox:checked')
    var mentionOwner = $('.mentionOwner:checkbox:checked')
    var mentionLabel = $('.mentionLabel.is-active')
    var sources = $('.sourceType:checkbox:checked')
    var mentionType = $('.mentionType:checkbox:checked')
    var sentiment = $('.sentiment:checkbox:checked')
    var location = $('.location:checkbox:checked')
    var language = $('.language:checkbox:checked')
    var sort = $('.sort:radio:checked')
    var dateRange = $("#reportSelectDate span").html()
    var searchText = $(".filtersearch").val()
    var websites = $('.website:checkbox:checked')
    var attributeTopic = $('.attributeTopic:checkbox:checked')
    var attributeSubtopic = $('.attributeSubtopic:checkbox:checked')
    var attributesentiment = $('.attributesentiment:checkbox:checked')
    var locationCity = $('.locationCity:checkbox:checked')
    var locationState = $('.locationState:checkbox:checked')
    var locationRegion = $('.locationRegion:checkbox:checked')
    // var isVerified = $('.isVerified:radio:checked')




    // for filter
    var followerMinValue = $("#follower_min_count").val()
    var followerMaxValue = $("#follower_max_count").val()
    var inflMinValue = $("#influence_min_score").val()
    var inflMaxValue = $("#influence_max_score").val()

    var appliedFilters = []

    if (dateRange !== null && dateRange !== undefined && dateRange !== "") {

        appliedFilters.push({
            "name": "Start Date",
            "value": dateRange.substr(0, dateRange.indexOf("-"))
        })
        appliedFilters.push({
            "name": "End Date",
            "value": dateRange.substr(dateRange.indexOf("-") + 1)
        })
    }
    if (searchText !== null && searchText !== undefined && searchText !== "") {
        appliedFilters.push({
            "name": "Search Text",
            "value": searchText
        })
    }
    if (followerMinValue !== null && followerMinValue !== undefined && followerMinValue !== "" && followerMaxValue !== null && followerMaxValue !== undefined && followerMaxValue !== "") {
        paramArray.push({
            param: "Follower Count",
            value: followerMinValue + '-' + followerMaxValue
        })
    }
    if (inflMinValue !== null && inflMinValue !== undefined && inflMinValue !== "" && inflMaxValue !== null && inflMaxValue !== undefined && inflMaxValue !== "") {
        paramArray.push({
            param: "influencer Score",
            value: inflMinValue + '-' + inflMaxValue
        })
    }

    if (mentionOwner.length > 0) {
        var filterType = "Mention Owner"
        addToAppliedFilters(filterType, mentionOwner, appliedFilters)
    }

    if (profileType.length > 0) {
        var filterType = "Profile Type"
        addToAppliedFilters(filterType, profileType, appliedFilters)
    }
    if (influencerType.length > 0) {
        var filterType = "Influencer Type"
        addToAppliedFilters(filterType, influencerType, appliedFilters)
    }
    if (mentionLabel.length > 0) {
        var filterType = "Label"
        addToAppliedLabelFilters(filterType, mentionLabel, appliedFilters)
    }
    if (sources.length > 0) {
        var filterType = "Source"
        addToAppliedFilters(filterType, sources, appliedFilters)
    }
    if (mentionType.length > 0) {
        var filterType = "Type"
        addToAppliedFilters(filterType, mentionType, appliedFilters)
    }
    if (sentiment.length > 0) {
        var filterType = "Sentiment"
        addToAppliedFilters(filterType, sentiment, appliedFilters)
    }
    if (location.length > 0) {
        var filterType = "Location"
        addToAppliedFilters(filterType, location, appliedFilters)
    }
    if (language.length > 0) {
        var filterType = "Language"
        addToAppliedFilters(filterType, language, appliedFilters)
    }

    if (sort.length > 0) {
        var filterType = "Sorted By"
        addToAppliedFilters(filterType, sort, appliedFilters)
    }
    if (websites.length > 0) {
        var filterType = "Website"
        addToAppliedFilters(filterType, websites, appliedFilters)
    }
    if (attributeTopic.length > 0) {
        var filterType = "Attribute Topic"
        addToAppliedFilters(filterType, attributeTopic, appliedFilters)
    }
    if (attributeSubtopic.length > 0) {
        var filterType = "Attribute Subtopic"
        addToAppliedFilters(filterType, attributeSubtopic, appliedFilters)
    }
    if (attributesentiment.length > 0) {
        var filterType = "Attribute Sentiment"
        addToAppliedFilters(filterType, attributesentiment, appliedFilters)
    }
    if (locationCity.length > 0) {
        var filterType = "City"
        addToAppliedFilters(filterType, locationCity, appliedFilters)
    }
    if (locationState.length > 0) {
        var filterType = "State"
        addToAppliedFilters(filterType, locationState, appliedFilters)
    }
    if (locationRegion.length > 0) {
        var filterType = "Region"
        addToAppliedFilters(filterType, locationRegion, appliedFilters)
    }

    var elementHtmlNew = ""
    for (var i = 0; i < appliedFilters.length; i++) {
        elementHtmlNew += '<span class="showingFilterList" style="padding:6px; margin: 0 5px 6px 0"><b>' + appliedFilters[i].name + ": </b>" + appliedFilters[i].value + "</span>"
    }
    var pageUrl = document.location.href
    var htmlStart
    if (pageUrl.indexOf("/reports/") > 0) {
        htmlStart = '<div id="filters" class="reportFiltersApplied"><a class="btn btn-default applyFilterBtn" href="#" data-toggle="modal" data-target="#pdfReportFilterModal" style="margin: 0 12px 6px 0">Apply Filters </a>'
    }
    else
        htmlStart = '<div id="filters"><span class="filterListHeading">Filters Applied: </span>'
    // var htmlStart = '<div id="filters" style="margin-top:10px"><span class="filterListHeading">Filters Applied: </span><span class="filterclearall" id="reportClearAllFilterright" style="cursor:pointer" title="clear all"> <i class="fa fa-times-circle"></i></span>'
    var elementHtml = '<span class="showingFilterList"><b>Source: </b>Twitter</span>'
    var htmlEnd = '</div>'

    var filtersAppliedHtml = htmlStart + elementHtmlNew + htmlEnd
    if (filtersAppliedHtml !== currentFiltersHtml) {
        $('#generateinfo').removeAttr('disabled')
        $('#pdfgenerate').removeAttr('disabled')
        $('.generateExcelButton').removeAttr('disabled')
        $('.generatepdfButton').removeAttr('disabled')
        $('.pdferr').text('')
        $('.excelerr').text('')
        $("#filtersSection").html(filtersAppliedHtml)
        currentFiltersHtml = filtersAppliedHtml
    }

}

// ==============================influencerCSVDownload========================================

function downloadInfluencerCSV() {
    // $("#influencerCSVDownload").one('click', function (e) {
    // e.stopPropagation();

    var reportrange = $("#reportrange>span").text()
    var url = "/influencerCSVReport?"
    var params = splitIntoParams(document.location.href)
    var data = params.filter(function (obj) { if (obj.param != "startFrom") { return true } })
    if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {

            url = url + "&" + data[i].param + "=" + encodeURIComponent(data[i].value)
        }
    }
    showLoaderOnClick()
    // document.location.href = url

    $.ajax({
        method: "GET",
        url: url,
        success: function (ans) {
            influencerCSVDownloadNotify(ans["email"])
            hideLoaderOnClick()
        },
        error: function () {
            influencerCSVDownloadNotifyError()
            hideLoaderOnClick()
        }
    });
}


function downloadInfluencerProfileCSV() {
    // $("#influencerCSVDownload").one('click', function (e) {
    // e.stopPropagation();
    var url = "/influencerCSVReport?"
    var params = splitIntoParams(document.location.href)
    var data = params.filter(function (obj) { if (obj.param != "startFrom") { return true } })
    if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
            url = url + "&" + data[i].param + "=" + encodeURIComponent(data[i].value)
        }
    }
    url = url + '&influencerDiscovery=true'
    showLoaderOnClick()
    $.ajax({
        method: "GET",
        url: url,
        success: function (ans) {
            influencerCSVDownloadNotify(ans["email"])
            hideLoaderOnClick()
        },
        error: function () {
            influencerCSVDownloadNotifyError()
            hideLoaderOnClick()
        }
    });
}




function showFilterModal() {
    $("#pdfReportFilterModal").modal('show')
}

function influencerCSVDownloadNotify(email) {
    var offsetdata = checkScreenViewForNotify()
    $.notify({
        title: '',
        icon: 'fa fa-check',
        message: "Your CSV report is queued and will be mailed to " + email + " in a few minutes!",
    }, {
        type: 'info',
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutRight'
        },
        placement: {
            from: "top",
            align: "right"
        },
        offset: offsetdata,
        spacing: 10,
        z_index: 1051,

    });
}


function influencerCSVDownloadNotifyError() {
    var offsetdata = checkScreenViewForNotify()
    $.notify({
        title: '',
        icon: 'fa fa-info',
        message: "There is some error!. Please contact support or drop an email to info@rankmeonline.com",
    }, {
        type: 'error',
        animate: {
            enter: 'animated fadeInUp',
            exit: 'animated fadeOutRight'
        },
        placement: {
            from: "top",
            align: "right"
        },
        offset: offsetdata,
        spacing: 10,
        z_index: 1051,

    });
}
// ---------------------------------------------------------------------

// ===============================scrollButton on all page=========================================


$(window).scroll(function () {
    $(".gotoButtom").hide()
    var slidehref = document.location.href
    if (slidehref && (slidehref.indexOf("onboarding") > -1 || slidehref.indexOf("login") > -1 || slidehref.indexOf("enterprise-reports") > -1)) {

        $(".gotoButtom").hide()
    } else {
        if ($(window).scrollTop() + $(window).height() > $(document).height() - 500) {
            $(".gotoButtom").hide()
        } else {
            $(".gotoButtom").delay(5000).show();
        }
    }
});


function slidetoBottom() {
    var windowHeight = $(window).height()
    var bodyHeight = $("body").height()
    var scrollTop = $(window).scrollTop();
    if (scrollTop < bodyHeight - windowHeight) {
        $("html, body").animate({
            scrollTop: $(document).height() - $(window).height()
        });
        $(".gotoButtom").hide()
    }
}


function signupReturnUrl(returnUrlId) {
    var paramArray = splitIntoParams(document.location.href)
    paramArray = paramArray.filter(function (val) {
        if (val.param == "returnUrl") return true
    })
    if (paramArray.length > 0) {
        $(returnUrlId).val(paramArray[0].value)
    }

}


function addSelectedOnClick(elementClicked, selectedElement, outputClass) {
    var html = ""
    var duplicateElement = false
    for (var i = 0; i < selectedElement.length; i++) {
        if (selectedElement[i].value == elementClicked) {
            duplicateElement = true
        }
    }
    if (!duplicateElement) {
        html = "<span class='tag label label-info' style='padding: .5em .6em .5em; margin: 10px 3px 0 0;'>" + elementClicked + "<input type='hidden' value='" + elementClicked + "'><span style='margin-left: 8px'>x</span></span>"

        $(outputClass).append(html)
    }
    if ($(outputClass + " span").length > 0) {
        $(outputClass).removeClass("infl_displayNone")
    }
}

$(document).ready(function () {
    $(document).on("change input click", "#influencerDiscoveryForm #influencerStateautocomplete-list>div", function () {
        var elementClicked = $(this).children("input").val()
        var selectedElement = $("#selectedStates span input")
        $("#influencerState").val("")
        addSelectedOnClick(elementClicked, selectedElement, "#selectedStates")
    })
    $(document).on("click", "#selectedStates>span", function () {
        $(this).remove()
        if ($("#selectedStates span").length == 0) {
            $("#selectedStates").addClass("infl_displayNone")
        }
    })

    $(document).on("change input click", "#influencerDiscoveryForm #influencerCityautocomplete-list>div", function () {
        var elementClicked = $(this).children("input").val()
        var selectedElement = $("#selectedCities span input")
        $("#influencerCity").val("")
        addSelectedOnClick(elementClicked, selectedElement, "#selectedCities")
    })
    $(document).on("click", "#selectedCities>span", function () {
        $(this).remove()
        if ($("#selectedCities span").length == 0) {
            $("#selectedCities").addClass("infl_displayNone")
        }
    })

    $(document).on('keyup', '#interestInput', function (e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            var elementClicked = $("#interestInput").val()
            var selectedElement = $("#selectedInterests span input")
            $("#interestInput").val("")
            addSelectedOnClick(elementClicked, selectedElement, "#selectedInterests")
        }
    });
    $(document).on("click", "#selectedInterests>span", function () {
        $(this).remove()
        if ($("#selectedInterests span").length == 0) {
            $("#selectedInterests").addClass("infl_displayNone")
        }
    })

    $(document).on("click", "#selectedInterests>span", function () {
        $(this).remove()
        if ($("#selectedInterests span").length == 0) {
            $("#selectedInterests").addClass("infl_displayNone")
        }
    })

    $(document).on("change input click", "#influencerDiscoveryForm #categoriesautocomplete-list>div", function () {
        var elementClicked = $(this).children("input").val()
        var selectedElement = $("#selectedCategories span input")
        $("#categories").val("")
        addSelectedOnClick(elementClicked, selectedElement, "#selectedCategories")
    })
    $(document).on("click", "#selectedCategories>span", function () {
        $(this).remove()
        if ($("#selectedCategories span").length == 0) {
            $("#selectedCategories").addClass("infl_displayNone")
        }
    })
    $(".submit-influencerDiscovery-button").click(function () {
        showLoaderOnClick()
        var url = "/showInfluencersSuggestions?"
        // var influencer_gender = $("input[name='influencerGender']:checked").val()
        var influencer_country = $("input[name='myCountry']").val()
        var influencer_category = $("input[name='categories']").val()
        var locationCity = $("input[name='myCity']").val()
        var locationState = $("input[name='myState']").val()

        // var influencer_age = $("#influencerAgeRange option:selected").val()
        // for (var i = 0; i < $("#selectedCities span input").length; i++) {
        //     var influencer_city = $("#selectedCities span input")[i].value
        //     url += "&locationCity=" + influencer_city
        // }
        // for (var i = 0; i < $("#selectedStates span input").length; i++) {
        //     var influencer_city = $("#selectedStates span input")[i].value
        //     url += "&locationState=" + influencer_city
        // }
        /*
        for (var i = 0; i < $("#selectedInterests .bootstrap-tagsinput>span").length; i++) {
            var searchtext = $("#selectedInterests .bootstrap-tagsinput>span")[i].textContent
            searchtext = searchtext.replace(/#/g, "HT:")
            url += "&influencerMainSearchText=" + searchtext
        }
        */
        // for (var i = 0; i < $("#selectedCategories span input").length; i++) {
        //     var searchtext = $("#selectedCategories span input")[i].value
        //     url += "&influencerGenre=" + searchtext
        // }
        // if (influencer_gender && influencer_gender != null && influencer_gender != undefined)
        //     url += "&influencerGender=" + influencer_gender
        if (influencer_country && influencer_country != null && influencer_country != undefined)
            url += "&locationCountry=" + influencer_country
        if (influencer_category && influencer_category != null && influencer_category != undefined)
            url += "&influencerGenre=" + encodeURIComponent(influencer_category)
        if (locationCity && locationCity != null && locationCity != undefined)
            url += "&locationCity=" + encodeURIComponent(locationCity)
        if (locationState && locationState != null && locationState != undefined)
            url += "&locationState=" + encodeURIComponent(locationState)
        // if (influencer_age && influencer_age != null && influencer_age != undefined)
        //     url += "&influencerAge=" + influencer_age
        var params = splitIntoParams(url)
        // var influencerMainSearchText = params.filter(function (obj) { if (obj.param == "influencerMainSearchText") { return true } })
        var msg = ""
        var check = true
        var checkgenra = params.filter(function (obj) { if (obj.param == "influencerGenre") { return true } })
        if (checkgenra && checkgenra.length <= 0) {
            check = false
            msg = "Please select genre from list"
        }
        // if (influencerMainSearchText && influencerMainSearchText.length <= 0) {
        //     check = false
        //     msg = "Please fill atleast one interested keyword"
        // }
        if (check) {
            hideLoaderOnClick()
            window.open(url, "_blank")

        } else {
            hideLoaderOnClick()
            $.notify({
                title: '',
                icon: 'fa fa-info',
                message: msg,
            }, {
                type: 'info',
                animate: {
                    enter: 'animated fadeInUp',
                    exit: 'animated fadeOutRight'
                },
                placement: {
                    from: "top",
                    align: "right"
                },

                offset: {
                    x: -800,
                    y: 60
                },
                spacing: 10,
                z_index: 1051,

            });
        }

    })



    $(document).on("click", "#createNewInfluencerListMeta", function () {
        var listName = $(".newListName").val()
        if (typeof (user) == undefined || typeof (user) == "undefined" || user == null || user == undefined) {
            $("#createNewList").modal('hide');
            $("#userLoginRequired").modal('show');
            return
        }
        if (listName.length == 0) {
            alert("Enter valid List Name")
            return
        }
        if (listName.length > 40) {
            alert("Maximum 40 characters allowed")
            return
        }
        showLoaderOnClick()
        $.ajax({
            url: "/addNewInfluencerList",
            method: "POST",
            data: { listName: listName },
            success: function (ans) {
                if (ans && ans.status && ans.message && ans.status == "Success") {
                    if (ans.alreadyExist == false) {
                        repliedSuccess('fa fa-check', ans.message)
                        location.reload();
                    } else {
                        $("#createNewList").modal('hide')
                        $(".newListName").val("")
                        repliedSuccess('fa fa-times', ans.message)
                    }
                } else if (ans && ans.status && ans.message && ans.status == "Failed") {
                    repliedSuccess('fa fa-times', ans.message)
                } else {
                    repliedSuccess('fa fa-times', "List creation failed")
                }
            },
            error: function () {
                hideLoaderOnClick()
                repliedSuccess('fa fa-times', "List is not added, try again.")
            }
        })
    })
})

function addRemoveFromList(influencerId, authorType, listId, url, influencerName, listName, currentInfluencer, index) {
    showLoaderOnClick()
    $.ajax({
        url: url,
        method: "POST",
        data: {
            influencerId: influencerId,
            sourceType: authorType,
            listId: listId
        },
        success: function (ans) {
            if (ans && ans.message) {
                repliedSuccess('fa fa-check', influencerName + ans.message + listName)
                var onclickId = '#' + authorType + influencerId + listId
                showAddedInfluencerInView(currentInfluencer, listName, ans.actionType, index, listId, onclickId)


            }
            hideLoaderOnClick()
            // location.reload();


        },
        error: function () {
            hideLoaderOnClick()
            repliedSuccess('fa fa-times', "Try again.")
        }
    })
}

function showAddedInfluencerInView(currentInfluencer, listName, actionType, index, listId, onclickId) {
    if (actionType == 'add') {
        if ($(currentInfluencer + '>.badge.badge-info').length < 2) {
            $(currentInfluencer).append('<span class="badge badge-info" id="' + currentInfluencer.replace('#', '') + listId + '">' + listName + ' </span>')
        }
        else if ($(currentInfluencer + '>.badge.badge-info').length == 2) {

            $(currentInfluencer).append('<span class="badge badge-info showMoreList" id="' + currentInfluencer.replace('#', '') + 'showMoreList">&nbsp;+ 1</span>')
            $(currentInfluencer).append('<div class="badgeOnHover"></div>')
            $(currentInfluencer + ' .badgeOnHover').append('<span class="badge badge-info" id="' + currentInfluencer.replace('#', '') + listId + '">' + listName + ' </span>')
        } else {
            var badgeOnHoverCount = $(currentInfluencer + ' .badgeOnHover .badge.badge-info').length
            badgeOnHoverCount = badgeOnHoverCount + 1
            $(currentInfluencer + 'showMoreList').html('&nbsp;+ ' + badgeOnHoverCount)
            $(currentInfluencer + ' .badgeOnHover').append('<span class="badge badge-info" id="' + currentInfluencer.replace('#', '') + listId + '">' + listName + ' </span>')
        }
        $(index).css('color', '#5cb85c')
        var onClickData = $(onclickId).attr('onclick')
        onClickData = onClickData.replace("addInfluencerToList", "removeInfluencerFromList")
        $(onclickId).attr('onclick', onClickData)
    }
    else if (actionType == 'remove') {

        if ($(currentInfluencer + '>.badge.badge-info').length <= 2) {
            $(currentInfluencer + listId).remove()
            // $(currentInfluencer).append('<span class="badge badge-info" id="' + currentInfluencer.replace('#', '') + listId + '">' + listName + ' </span>')
        } else {
            $(currentInfluencer + listId).remove()
            var visiblebadgeCount = $(currentInfluencer + '>.badge.badge-info').length
            if (visiblebadgeCount > 2) {
                var badgeOnHoverCount = $(currentInfluencer + ' .badgeOnHover .badge.badge-info').length
                if (badgeOnHoverCount == 0)
                    $(currentInfluencer + 'showMoreList').remove()
                else {
                    badgeOnHoverCount = badgeOnHoverCount
                    $(currentInfluencer + 'showMoreList').html('&nbsp;+ ' + badgeOnHoverCount)
                }
            } else {
                var shiftHtml = $(currentInfluencer + ' .badgeOnHover .badge.badge-info')[0]
                $(currentInfluencer).append(shiftHtml)
                var badgeOnHoverCount = $(currentInfluencer + ' .badgeOnHover .badge.badge-info').length
                if (badgeOnHoverCount == 0)
                    $(currentInfluencer + 'showMoreList').remove()
                else {
                    badgeOnHoverCount = badgeOnHoverCount
                    $(currentInfluencer + 'showMoreList').html('&nbsp;+ ' + badgeOnHoverCount)
                }
            }

            // .append('<span class="badge badge-info" id="' + currentInfluencer.replace('#', '') + listId + '">' + listName + ' </span>')
        }

        var onClickData = $(onclickId).attr('onclick')
        onClickData = onClickData.replace("removeInfluencerFromList", 'addInfluencerToList')
        $(onclickId).attr('onclick', onClickData)
        $(index).css('color', '#cccccc')
    }
}

$("#pdfReportFilterModal").on("hidden.bs.modal", function () {
    $('#filterLabels').removeClass('infl_displayNone');
    $('#language').addClass('infl_displayNone');
});
$(document).ready(function () {
    var url = document.location.href
    var paramsArray = splitIntoParams(url)
    $("#influencerDiscoveryClearAllFilter").click(function () {
        showLoaderOnClick()
        var paramArray = paramsArray.filter(function (obj) {
            if ((obj.param == "influencerGenre") || (obj.param == "locationCountry")) return true;
            return false
        });
        var destUrl = mergeIntoUrl(paramArray, true);
        window.location = destUrl
    })


    for (var i = 0; i < paramsArray.length; i++) {
        if (paramsArray[i].param != "projectId") {
            $("input[name='" + paramsArray[i].value + "']:checkbox").prop("checked", "true");
            if (paramsArray[i].param === "savedListId") {
                $("#listIdButton" + paramsArray[i].value).prop("checked", true)
            }
            if (paramsArray[i].param === "sortBy") {
                $('input[name="filter"][value="' + paramsArray[i].value + '"]').prop('checked', true)
            }

        }
    }
})
function filterRangeSubmit(min, max) {
    var minValue = 0
    if (parseInt($(min).val()) > 0) {
        minValue = parseInt($(min).val())
    }
    var maxValue = 0
    if (parseInt($(max).val()) > 0) {
        maxValue = parseInt($(max).val())
    }
    if (minValue >= maxValue) {
        alert("Check filter value")
        return { err: "invalid" }
    }
    else {
        return { min: minValue, max: maxValue }
    }
}

function changeFilterTextBtn() {
    showLoaderOnClick()
    var paramArray = splitIntoParams(document.location.href)
    paramArray = paramArray.filter(function (obj) { if (obj.param != "influencerMainSearchText") { return true } })
    for (var i = 0; i < $("#chanageInterests .bootstrap-tagsinput>span").length; i++) {
        var searchtext = $("#chanageInterests .bootstrap-tagsinput>span")[i].textContent
        searchtext = searchtext.replace(/#/g, "HT:")
        paramArray.push({
            param: "influencerMainSearchText",
            value: searchtext
        });
    }
    // var checkSearchText = paramArray.filter(function (obj) { if (obj.param == "influencerMainSearchText") { return true } })
    // if (checkSearchText && checkSearchText.length > 0) {
    var newUrl = mergeIntoUrl(paramArray)
    document.location.href = newUrl
    // }
    // else {
    //     hideLoaderOnClick()
    //     $.notify({
    //         title: '',
    //         icon: 'fa fa-info',
    //         message: "Please fill atleast one interested keyword",
    //     }, {
    //         type: 'info',
    //         animate: {
    //             enter: 'animated fadeInUp',
    //             exit: 'animated fadeOutRight'
    //         },
    //         placement: {
    //             from: "top",
    //             align: "right"
    //         },

    //         offset: {
    //             x: -800,
    //             y: 60
    //         },
    //         spacing: 10,
    //         z_index: 1051,

    //     });
    // }

}

function applyFiltersinfluencerDiscovery() {
    showLoaderOnClick()
    var url = document.location.href;

    var enteredInput = $("input[name='search']").val();
    var postText = enteredInput.split(",")
    var enteredText = $(".influ-search-box-div .bootstrap-tagsinput").text();
    var checkData = enteredText.trim();
    if (checkData.length <= 2 && checkData.length > 0) {
        alert("Please enter atleast 3 letters for search text");
    }
    var preText = enteredText.split("   ");
    preText.pop();
    preText = preText
    var enterparams = $("#influencerSearch").val();

    var textArray = $.merge($.merge([], preText), postText);

    var textArrayTrim = []
    for (var i = 0; i < textArray.length; i++) {
        var trimtext = textArray[i].trim()
        if (trimtext.length > 2) {
            textArrayTrim.push(textArray[i].trim())
        }
    }
    var paramArray = splitIntoParams(url)
    var paramArray = paramArray.filter(function (obj) {
        if ((obj.param == "influencerGender") || (obj.param == "influencerAge") || (obj.param == "locationCity") || (obj.param == "locationState") || (obj.param == "influencerGenre") || (obj.param == "locationCountry") || (obj.param === "influencerMainSearchText")) return true;
        return false
    });
    if (textArrayTrim && textArrayTrim.length > 0) {
        for (var i = 0; i < textArrayTrim.length; i++) {
            textArrayTrim[i] = textArrayTrim[i].replace(/#/g, "HT:")
            paramArray.push({
                param: enterparams,
                value: textArrayTrim[i]
            });
        }
    }

    var mentionOwner = $(".mentionOwner:checkbox:checked")
    if (mentionOwner !== null && mentionOwner !== undefined) {
        var mentionName
        for (var mention = 0; mention != mentionOwner.length; mention++) {
            mentionName = mentionOwner[mention].name
            if (mentionName !== null && mentionName !== undefined) {
                paramArray.push({
                    param: "profileType",
                    value: mentionName
                });
            }
        }
    }

    var authorCategory = $(".authorCategory:checkbox:checked")

    if (authorCategory !== null && authorCategory !== undefined) {
        var authorCategoryName
        for (var i = 0; i != authorCategory.length; i++) {
            authorCategoryName = authorCategory[i].name
            if (authorCategoryName !== null && authorCategoryName !== undefined) {
                paramArray.push({
                    param: "authorCategory",
                    value: authorCategoryName
                });
            }
        }
    }

    var authorType = $(".authorType:checkbox:checked")
    if (authorType !== null && authorType !== undefined) {
        var authorTypeName
        for (var i = 0; i != authorType.length; i++) {
            authorTypeName = authorType[i].name
            if (authorTypeName !== null && authorTypeName !== undefined) {
                paramArray.push({
                    param: "authorType",
                    value: authorTypeName
                });
            }
        }
    }
    var verifyType = $(".verifyType:checkbox:checked")
    if (verifyType !== null && verifyType !== undefined) {
        var verifyTypeName
        for (var i = 0; i != verifyType.length; i++) {
            verifyTypeName = verifyType[i].name
            if (verifyTypeName !== null && verifyTypeName !== undefined) {
                paramArray.push({
                    param: "isVerified",
                    value: verifyTypeName
                });
            }
        }
    }

    var savedLists = $("input[name=listIds]:checkbox:checked")
    if (savedLists !== null && savedLists !== undefined && savedLists.length > 0) {
        for (var i = 0; i != savedLists.length; i++) {
            savedListID = savedLists[i]
            if (savedListID !== null && savedListID !== undefined) {
                paramArray.push({
                    param: "savedListId",
                    value: savedListID.value
                });
            }
        }

    }

    var sortBy = $("input[name='filter']:checked").val();
    if (sortBy !== null && sortBy !== undefined) {
        paramArray.push({
            param: "sortBy",
            value: sortBy
        });
    }

    var language = $(".language:checkbox:checked")
    if (language !== null && language !== undefined) {
        for (var i = 0; i != language.length; i++) {
            languageName = language[i].name
            if (languageName !== null && languageName !== undefined) {
                paramArray.push({
                    param: "language",
                    value: languageName
                });
            }
        }
    }


    // var influencerAge = $(".influencerAge:checkbox:checked")
    // if (influencerAge !== null && influencerAge !== undefined) {
    //     var influencerAgeRange
    //     for (var i = 0; i != influencerAge.length; i++) {
    //         influencerAgeRange = influencerAge[i].name
    //         if (influencerAgeRange !== null && influencerAgeRange !== undefined) {
    //             paramArray.push({
    //                 param: "influencerAge",
    //                 value: influencerAgeRange
    //             });
    //         }
    //     }
    // }




    var influencerType = $(".influencerType:checkbox:checked")
    if (influencerType !== null && influencerType !== undefined) {
        var influencerTypeName
        for (var i = 0; i != influencerType.length; i++) {
            influencerTypeName = influencerType[i].name
            if (influencerTypeName !== null && influencerTypeName !== undefined) {
                paramArray.push({
                    param: "authorType",
                    value: influencerTypeName
                });
            }
        }
    }
    var influenceScoreFilter = filterRangeSubmit('#influence_min_score', '#influence_max_score', 'influenceScore')
    var followerCountFilter = filterRangeSubmit('#follower_min_count', '#follower_max_count', 'followerCount')


    if (influenceScoreFilter && influenceScoreFilter.err) { }
    else if (influenceScoreFilter && influenceScoreFilter.min >= 0 && influenceScoreFilter.max <= 100 && ((influenceScoreFilter.min > 0 && influenceScoreFilter.max <= 100) || (influenceScoreFilter.min >= 0 && influenceScoreFilter.max < 100))) {
        paramArray.push({
            param: "influenceScore",
            value: influenceScoreFilter.min / 10 + "-" + influenceScoreFilter.max / 10
        });
    }

    if (followerCountFilter && followerCountFilter.err) { }
    else if (followerCountFilter && followerCountFilter.min >= 0 && followerCountFilter.max <= 10000000 && ((followerCountFilter.min > 0 && followerCountFilter.max <= 10000000) || (followerCountFilter.min >= 0 && followerCountFilter.max < 10000000))) {
        paramArray.push({
            param: "followerCount",
            value: followerCountFilter.min + "-" + followerCountFilter.max
        });
    }
    var destUrl = mergeIntoUrl(paramArray, true);
    window.location = destUrl
}


function influencerDiscoveryDropboxOnClickFunction(thisInfluencerIndex) {
    // $("#influencer-dropdown-icon-" + thisInfluencerIndex + " i").toggleClass("infl_displayNone")
    $("#influencer-dropbox-" + thisInfluencerIndex).fadeToggle('slow')

}


function influencerDiscoveryClearFilters() {
    showLoaderOnClick()
    var pageUrl = document.location.href;
    var paramArray = splitIntoParams(pageUrl);
    paramArray = paramArray.filter(function (obj) {
        if ((obj.param == "influencerMainSearchText") || (obj.param == "locationCountry")) return true;
        return false
    });
    var destUrl = mergeIntoUrl(paramArray, true);
    window.location = destUrl
}

function convertToPercent(chartName, id) {

    var numberOfDataset = chartName.data.datasets.length
    if (chartName.config.type == "horizontalBar")
        numberOfDataset = chartName.data.datasets[0].data.length
    if (numberOfDataset > 0) {
        if (chartName.config.type != "doughnut" && chartName.config.type != "pie") {

            chartName.options.tooltips.callbacks = {
                label: function (t, e) {
                    var i = e.datasets[t.datasetIndex].label || "";
                    var chart = this._chart
                    var activeDataLines = this._chart.data.datasets.filter(function (obj, index) {
                        return chart.getDatasetMeta(index).hidden == null
                    });
                    var sumArray = []
                    for (j = 0; j < activeDataLines[0].data.length; j++) {
                        var tempSumArray = activeDataLines.map(function (obj) { return obj.data[j] })
                        var sum = tempSumArray.reduce(function (a, b) { return a + b; }, 0)
                        sumArray.push(sum)
                    }
                    var percent = 0
                    var value = typeof (t.yLabel) == "string" ? t.xLabel : t.yLabel
                    if (sumArray[t.index] > 0)
                        percent = (value) != undefined || value != null || value != "undefined" ? value / sumArray[t.index] : Number(t.value) / sumArray[t.index]
                    percent = Math.round(percent * 100)
                    var returnValue = i + ": " + percent + "%"
                    return returnValue
                }
            }

        } else {
            chartName.options.tooltips.callbacks = {
                label: function (t, e) {
                    var chart = this._chart
                    var metaData = chart.getDatasetMeta(t.datasetIndex)
                    var i = e.labels[t.index]
                    var sumArray = metaData.total
                    var value = e.datasets[t.datasetIndex].data[t.index]
                    var percent = 0
                    if (sumArray > 0)
                        percent = value / sumArray
                    percent = Math.round(percent * 100)
                    var returnValue = i + ": " + percent + "%"
                    return returnValue
                }
            }
        }
    }
    chartName.update()
    if (id && id != "") {
        $(id).fadeToggle('slow')
    }

}

function convertToCount(chartName, id) {
    var numberOfDataset = chartName.data.datasets.length
    if (chartName.config.type == "horizontalBar")
        numberOfDataset = chartName.data.datasets[0].data.length
    if (numberOfDataset > 0) {
        if (chartName.config.type != "doughnut" && chartName.config.type != "pie") {
            chartName.options.tooltips.callbacks = {
                label: function (t, e) {
                    var i = e.datasets[t.datasetIndex].label || "";
                    var count = 0
                    var value = typeof (t.yLabel) == "string" ? t.xLabel : t.yLabel
                    value = value != undefined || value != null ? value : Number(t.value)
                    count = value
                    var returnValue = i + ": " + count
                    return returnValue
                }
            }

        } else {
            chartName.options.tooltips.callbacks = {
                label: function (t, e) {
                    var i = e.labels[t.index], n = ": " + e.datasets[t.datasetIndex].data[t.index];
                    return Array.isArray(i) ? (i = i.slice())[0] += n : i += n, i
                }
            }
        }
    }
    chartName.update()
    if (id && id != "") {
        $(id).fadeToggle('slow')
    }
}
/*function submitSelectedProjects() {
  showLoaderOnClick()
  var pageUrl = document.location.href;
  var paramArray = splitIntoParams(pageUrl);
  paramArray = paramArray.filter(function (val) {
    if (val.param !== "competitiveProjects") return true
    return false
  })
  var checkprojects = ''
  var totalProjects = $('.custom-control-input').length
  if (totalProjects > 0) {
    for (var i = 0; i < totalProjects; i++) {
      var checkValue = $('#project' + i).val()
      if ($('#project' + i).prop("checked") && checkValue != undefined) {
        checkprojects += checkValue + " "
      }
    }
    // if (checkprojects === ''){
    //   for (var i = 0; i < totalProjects; i++) {
    //     var checkValue = $('#project' + i).val()
    //     if (checkValue != undefined) {
    //       checkprojects += checkValue + " "
    //     }
    //   }
    // }
    if (checkprojects != '') {
      paramArray.push({
        param: "competitiveProjects",
        value: checkprojects
      });
    }
  }
  var destUrl = mergeIntoUrl(paramArray, true);

  document.location.href = destUrl
}*/
function selectForCompare(projectIndex) {
  $('.' + projectIndex).toggleClass('borderCompareListSelected')
  var checkBoxes = $('#' + projectIndex)
  checkBoxes.prop("checked", !checkBoxes.prop("checked"));
}

function submitSelectedProjects() {
  showLoaderOnClick()
  var pageUrl = document.location.href;
  var paramArray = splitIntoParams(pageUrl);
  paramArray = paramArray.filter(function (val) {
    if (val.param !== "competitiveProjects") return true
    return false
  })
  var changeComparisonURL = "/changeComparison?";
  var totalProjects = $('.custom-control-input').length
  var projectChoosenToCompare = 0
  if (totalProjects > 0) {
    for (var i = 0; i < totalProjects; i++) {
      var checkValue = $('#project' + i).val()
      if ($('#project' + i).prop("checked") && checkValue != undefined) {
        changeComparisonURL += 'projectId=';
        changeComparisonURL += checkValue;
        changeComparisonURL += "&";
        projectChoosenToCompare += 1
      }
    }
  }
  if (projectChoosenToCompare < 2) {
    alert("Please select atleast 2 project.");
    hideLoaderOnClick()
    return false
  }
  var destUrl = mergeIntoUrl(paramArray, true);
  var callSuccess = false;
  console.log(changeComparisonURL)
  // TODO : AJAX call to make change in database
  $.ajax({
    url: changeComparisonURL,
    type: "GET",
    async: false,
    success: function (res) {
      callSuccess = true;
    },
    error: function (err) {
    }
  });

  // TODO : reload the page  
  document.location.href = destUrl
}


$(document).ready(function () {
  var pageUrl = document.location.href;
  var paramArray = splitIntoParams(pageUrl);
  paramArray = paramArray.filter(function (val) {
    if (val.param === "competitiveProjects") return true
    return false
  })
  if (paramArray && paramArray.length > 0) {
    var projects = paramArray[0].value.split(' ')
    var totalProjects = $('.custom-control-input').length
    if (totalProjects > 0 && projects && projects.length > 0) {
      for (var i = 0; i < totalProjects; i++) {
        for (var j = 0; j < projects.length; j++) {
          var checkValue = $('#project' + i).val()
          if (checkValue === projects[j]) {
            $('#project' + i).attr("checked", 'checked')
          }
        }
      }
    }
  } else {
    if (typeof (selectiveProjects) !== "undefined" && selectiveProjects) {
      var compareProjectIds = null;
      if (selectiveProjects.indexOf(" ") > 0) {
        compareProjectIds = selectiveProjects.split(' ')
      }
      else {
        compareProjectIds = selectiveProjects
      }
      var totalProjects = $('.custom-control-input').length
      if (compareProjectIds.length > 0 && totalProjects > 0 && compareProjectIds != null) {
        for (var i = 0; i < totalProjects; i++) {
          for (var j = 0; j < compareProjectIds.length; j++) {
            if (compareProjectIds[j] == $('#project' + i).val()) {
              $('#project' + i).attr("checked", 'checked')
            }
          }
        }
      }
    }
  }

});


$(document).ready(function () {
  if (document.getElementsByClassName('competitionFeatureSlide')) {
    $('.competitionFeatureSlide').on('click', function () {
      if (typeof (competitionPlotlyReplot) != 'undefined' && competitionPlotlyReplot && competitionPlotlyReplot.length > 0) {
        setTimeout(function () {
          for (var i = 0; i < competitionPlotlyReplot.length; i++) {
            var finalOptions = {
              displayModeBar: false,
              displaylogo: false,
              showTips: false,
              responsive: true,
            }
            if (competitionPlotlyReplot[i].modeBarButtons) {
              finalOptions = {
                modeBarButtons: competitionPlotlyReplot[i].modeBarButtons,
                displaylogo: false,
                showTips: false,
                responsive: true,
              }
            }
            Plotly.newPlot(competitionPlotlyReplot[i].id, competitionPlotlyReplot[i].data, competitionPlotlyReplot[i].layout, finalOptions);
            if (typeof (competitionPlotlyReplot[i].onClickFunctionName) != 'undefined') {
              window[competitionPlotlyReplot[i].onClickFunctionName]()
            }
          }
        }, 100)
      }
    })
  }
})
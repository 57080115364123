var keywordChannelWiseDataGraph;
var keywordSentimentWiseDataGraph;
var brandHashtagChannelWiseDataGraph;
var brandHashtagbyTimePeriodChart;
var ugcRelatedDataGraphTimePeriodChart;
var sourceDistributionByChannelGraph;
var sentimentDistributionByChannelGraph;
var contentTypePieChartGraph;
var languagePieChartLayout;
var mentionsWithTimeGraphChart;
var dayOfWeekGraphChart;
var hourOfDayGraphChart;
var hourOfDayWithDayOfWeekGraphChart;
var contentmentionsOverTimeBySentimentBarGraph


var sorterDay = {
  // "sunday": 0, // << if sunday is first day of week
  "sun": 0,
  "mon": 1,
  "tue": 2,
  "wed": 3,
  "thu": 4,
  "fri": 5,
  "sat": 6,
}

var sorterFullDay = {
  // "sunday": 0, // << if sunday is first day of week
  "sunday": 0,
  "monday": 1,
  "tuesday": 2,
  "wednesday": 3,
  "thursday": 4,
  "friday": 5,
  "saturday": 6,
}


var sorterHour = {
  '00': "12am",
  '01': "1am",
  '02': "2am",
  '03': "3am",
  '04': "4am",
  '05': "5am",
  '06': "6am",
  '07': "7am",
  '08': "8am",
  '09': "9am",
  '10': "10am",
  '11': "11am",
  '12': "12pm",
  '13': "1pm",
  '14': "2pm",
  '15': "3pm",
  '16': "4pm",
  '17': "5pm",
  '18': "6pm",
  '19': "7pm",
  '20': "8pm",
  '21': "9pm",
  '22': "10pm",
  '23': "11pm",
}



$(document).ready(function () {
  var ultimateColors = [
    'rgb(176, 252, 204)', 'rgb(0,206,244)', 'rgb(200,228,0)', 'rgb(255,155,0)', 'rgb(175,157,200)', 'rgb(237,69,21)', 'rgb(45,107,182)', 'rgb(242,255,0)', 'rgb(218,181,69)', 'rgb(255,128,114)', 'rgb(241,0,82)', 'rgb(241,201,122)', 'rgb(127,103,191)', 'rgb(203,72,195)', 'rgb(235,247,249)', 'rgb(230,207,88)', 'rgb(248,0,118)', 'rgb(169,193,205)'
  ];
  if (document.getElementById('keywordChannelWiseData') && brandKeywordSourceGraph) {
    if (brandKeywordSourceGraph.type === 'bar') {
      var keywordChannelWiseGraph = document.getElementById('keywordChannelWiseData')
      var keywordsLabels = brandKeywordSourceGraph.graph[0].x
      // var allSentimentLabels = ['Positive', 'Negative', 'Neutral']
      var allvalues = []
      for (var i = 0; i < brandKeywordSourceGraph.graph.length; i++) {
        allvalues.push(brandKeywordSourceGraph.graph[i].y)
      }
      Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
      Chart.defaults.global.tooltips.bodyFontColor = "#fff";
      Chart.defaults.global.tooltips.titleFontColor = "#fff";
      Chart.defaults.global.legend.display = true
      var BarChartData = {
        labels: keywordsLabels,
        datasets: []
      }
      for (var i = 0; i < brandKeywordSourceGraph.graph.length; i++) {
        var data = {
          borderColor: ultimateColors[i],
          data: allvalues[i],
          label: brandKeywordSourceGraph.graph[i].name,
          backgroundColor: ultimateColors[i],
        }
        BarChartData.datasets.push(data)
      }

      stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
      stackBarLayout.legend.labels.fontSize = 10
      stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
      stackBarLayout.scales.yAxes[0].ticks.fontSize = 10

      var ctx = $('#keywordChannelWiseData');
      var chartData = {
        type: 'bar',
        data: BarChartData,
        options: stackBarLayout
      }

      keywordChannelWiseDataGraph = new Chart(ctx, chartData);
      chartjsGraphsLoaded.push({
        id: "keywordChannelWiseData",
        data: chartData,
        chartObj: keywordChannelWiseDataGraph,
        expandRatio: 1
      })
      if (keywordChannelWiseDataGraph) {
        keywordChannelWiseGraph.onclick = function (evt) {
          var divertUrl = getCurrentUrlParamsLink('sourceType')
          var activePoint = keywordChannelWiseDataGraph.getElementAtEvent(evt)[0];
          var data = activePoint._model;
          var label = data.datasetLabel;
          var codeSource = data.label
          var searchTextCheckUrl = splitIntoParams(divertUrl)
          var searchResult = searchTextCheckUrl.find(function (element) { return element.param == "searchText" });
          if (searchResult) {
            searchTextCheckUrl = searchTextCheckUrl.map(function (obj) {
              if (obj.param == "searchText") {
                obj.value += " and " + codeSource
              }
              return obj
            })
          } else {
            searchTextCheckUrl.push({
              param: "searchText",
              value: codeSource
            })
          }
          var finalUrl = mergeIntoRedirectUrl(searchTextCheckUrl, "enterprise-mention")
          var url = finalUrl + '&sourceType=' + changeSourceName(label) + '&similarResults=true';
          window.open(url, '_blank')
          // document.location.href = url
        };
      }
    }
  }
  if (document.getElementById('keywordSentimentWiseData') && brandKeywordSentimentGraph && brandKeywordSentimentGraph.graph && brandKeywordSentimentGraph.graph[0]) {
    if (brandKeywordSentimentGraph.type === 'bar') {
      var keywordSentimentWiseGraph = document.getElementById('keywordSentimentWiseData')
      var keywordsLabels = brandKeywordSentimentGraph.graph[0].x
      var allvalues = []
      for (var i = 0; i < brandKeywordSentimentGraph.graph.length; i++) {
        allvalues.push(brandKeywordSentimentGraph.graph[i].y)
      }
      Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
      Chart.defaults.global.tooltips.bodyFontColor = "#fff";
      Chart.defaults.global.tooltips.titleFontColor = "#fff";
      Chart.defaults.global.legend.display = true
      var BarChartData = {
        labels: keywordsLabels,
        datasets: []
      }
      for (var i = 0; i < brandKeywordSentimentGraph.graph.length; i++) {
        var data = {
          borderColor: ultimateColors[i],
          data: allvalues[i],
          label: brandKeywordSentimentGraph.graph[i].name,
          backgroundColor: ultimateColors[i],
        }
        BarChartData.datasets.push(data)
      }

      stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
      stackBarLayout.legend.labels.fontSize = 10
      stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
      stackBarLayout.scales.yAxes[0].ticks.fontSize = 10

      var ctx = $('#keywordSentimentWiseData');
      var chartData = {
        type: 'bar',
        data: BarChartData,
        options: stackBarLayout
      }

      keywordSentimentWiseDataGraph = new Chart(ctx, chartData);
      chartjsGraphsLoaded.push({
        id: "keywordSentimentWiseData",
        data: chartData,
        chartObj: keywordSentimentWiseDataGraph,
        expandRatio: 1
      })
      if (keywordSentimentWiseDataGraph) {
        keywordSentimentWiseGraph.onclick = function (evt) {
          var divertUrl = getCurrentUrlParamsLink('sentiment')
          var activePoint = keywordSentimentWiseDataGraph.getElementAtEvent(evt)[0];
          var data = activePoint._model;
          var label = data.datasetLabel;
          var codeSource = data.label
          var searchTextCheckUrl = splitIntoParams(divertUrl)
          var searchResult = searchTextCheckUrl.find(function (element) { return element.param == "searchText" });
          if (searchResult) {
            searchTextCheckUrl = searchTextCheckUrl.map(function (obj) {
              if (obj.param == "searchText") {
                obj.value += " and " + codeSource
              }
              return obj
            })
          } else {
            searchTextCheckUrl.push({
              param: "searchText",
              value: codeSource
            })
          }
          var finalUrl = mergeIntoRedirectUrl(searchTextCheckUrl, "enterprise-mention")
          var url = finalUrl + '&similarResults=true&sentiment=' + label.toUpperCase()
          window.open(url, '_blank')
          // document.location.href = url
        };
      }
    }
  }



  if (document.getElementById('brandHashtagChannelWiseData') && brandHashtagSourceBarGraph) {
    var brandHashtagChannelWiseGraph = document.getElementById('brandHashtagChannelWiseData')
    var hashtagLabels = brandHashtagSourceBarGraph[0].x
    // var allSentimentLabels = ['Positive', 'Negative', 'Neutral']
    var allvalues = []
    for (var i = 0; i < brandHashtagSourceBarGraph.length; i++) {
      allvalues.push(brandHashtagSourceBarGraph[i].y)
    }
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    Chart.defaults.global.tooltips.bodyFontColor = "#fff";
    Chart.defaults.global.tooltips.titleFontColor = "#fff";
    Chart.defaults.global.legend.display = true
    var BarChartData = {
      labels: hashtagLabels,
      datasets: []
    }
    for (var i = 0; i < brandHashtagSourceBarGraph.length; i++) {
      var data = {
        borderColor: ultimateColors[i],
        data: allvalues[i],
        label: brandHashtagSourceBarGraph[i].name,
        backgroundColor: ultimateColors[i],
      }
      BarChartData.datasets.push(data)
    }

    stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
    stackBarLayout.legend.labels.fontSize = 10
    stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
    stackBarLayout.scales.yAxes[0].ticks.fontSize = 10

    var ctx = $('#brandHashtagChannelWiseData');
    var chartData = {
      type: 'bar',
      data: BarChartData,
      options: stackBarLayout
    }

    brandHashtagChannelWiseDataGraph = new Chart(ctx, chartData);
    chartjsGraphsLoaded.push({
      id: "brandHashtagChannelWiseData",
      data: chartData,
      chartObj: brandHashtagChannelWiseDataGraph,
      expandRatio: 1
    })
    if (brandHashtagChannelWiseDataGraph) {
      brandHashtagChannelWiseGraph.onclick = function (evt) {
        var divertUrl = getCurrentUrlParamsLink('sourceType')
        var activePoint = brandHashtagChannelWiseDataGraph.getElementAtEvent(evt)[0];
        var data = activePoint._model;
        var label = data.datasetLabel;
        var codeSource = data.label
        var searchTextCheckUrl = splitIntoParams(divertUrl)
        var searchResult = searchTextCheckUrl.find(function (element) { return element.param == "searchText" });
        if (searchResult) {
          searchTextCheckUrl = searchTextCheckUrl.map(function (obj) {
            if (obj.param == "searchText") {
              obj.value += " and " + codeSource.replace("#", 'HT:')
            }
            return obj
          })
        } else {
          searchTextCheckUrl.push({
            param: "searchText",
            value: codeSource.toLowerCase().replace("#", "HT:")
          })
        }
        var finalUrl = mergeIntoRedirectUrl(searchTextCheckUrl, "enterprise-mention")
        var url = finalUrl + '&sourceType=' + changeSourceName(label)
        window.open(url, '_blank')
        // document.location.href = url
      };
    }
  }


  if (document.getElementById('numberofMentionsofYourHashtags') && brandHashtagMentionsWidget) {
    var brandHashtagChannelWiseGraph = document.getElementById('numberofMentionsofYourHashtags')
    var brandHashtagLabels = brandHashtagMentionsWidget.brandHashtagText
    var allvalues = []
    allvalues.push(brandHashtagMentionsWidget.brandHashtagBrandCount)
    allvalues.push(brandHashtagMentionsWidget.brandHashtagAudienceCount)
    var noOfHashtags = ['By You', 'By Your Audience']
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    Chart.defaults.global.tooltips.bodyFontColor = "#fff";
    Chart.defaults.global.tooltips.titleFontColor = "#fff";
    Chart.defaults.global.legend.display = true
    var BarChartData = {
      labels: brandHashtagLabels,
      datasets: []
    }
    for (var i = 0; i < noOfHashtags.length; i++) {
      var data = {
        borderColor: ultimateColors[i],
        data: allvalues[i],
        label: noOfHashtags[i],
        backgroundColor: ultimateColors[i],
      }
      BarChartData.datasets.push(data)
    }

    stackBarLayout = JSON.parse(JSON.stringify(BarChartLayout))
    stackBarLayout.legend.labels.fontSize = 10
    stackBarLayout.scales.xAxes[0].ticks.fontSize = 10
    stackBarLayout.scales.yAxes[0].ticks.fontSize = 10

    var ctx = $('#numberofMentionsofYourHashtags');
    var chartData = {
      type: 'horizontalBar',
      data: BarChartData,
      options: stackBarLayout
    }

    numberofMentionsofYourHashtagsGraph = new Chart(ctx, chartData);
    chartjsGraphsLoaded.push({
      id: "numberofMentionsofYourHashtags",
      data: chartData,
      chartObj: numberofMentionsofYourHashtagsGraph,
      expandRatio: 1
    })
    if (numberofMentionsofYourHashtagsGraph) {
      brandHashtagChannelWiseGraph.onclick = function (evt) {
        var divertUrl = getCurrentUrlParamsLink('mentionOwner')
        var activePoint = numberofMentionsofYourHashtagsGraph.getElementAtEvent(evt)[0];
        var data = activePoint._model;
        var label = data.datasetLabel;
        var codeSource = data.label
        var searchTextCheckUrl = splitIntoParams(divertUrl)
        var searchResult = searchTextCheckUrl.find(function (element) { return element.param == "searchText" });
        if (searchResult) {
          searchTextCheckUrl = searchTextCheckUrl.map(function (obj) {
            if (obj.param == "searchText") {
              obj.value += " and " + codeSource.replace("#", 'HT:')
            }
            return obj
          })
        } else {
          searchTextCheckUrl.push({
            param: "searchText",
            value: codeSource.replace("#", "HT:")
          })
        }
        var finalUrl = mergeIntoRedirectUrl(searchTextCheckUrl, "enterprise-mention")
        var divertUrl = finalUrl + '&mentionOwner=' + changeMentionOwnerText(label)
        window.open(divertUrl, '_blank')
        // document.location.href = divertUrl
      };
    }
  }

  if (document.getElementById('pdfTwitterEngagement') && pdfTwitterEngagement) {

    var urlLabel = pdfTwitterEngagement.map(function (obj) {
      return obj.postTime
    })
    var data = pdfTwitterEngagement.map(function (obj) {
      return obj.engagementCount
    })

    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    Chart.defaults.global.tooltips.bodyFontColor = "#fff";
    Chart.defaults.global.tooltips.titleFontColor = "#fff";
    // Chart.defaults.global.legend.display = true
    var BarChartData = {
      labels: urlLabel,
      datasets: [{
        data: data,
        borderColor: '#00FFFF',
        label: "Engagements",
        backgroundColor: '#00FFFF',
      }]
    }

    var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
    sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Date of Engagement (' + startDate.split(',')[0] + '-' + endDate.split(',')[0] + ')'
    }
    sentimentChartLayout.scales.yAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Count'
    }

    sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 9
    sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
    sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12

    // sentimentChartLayout.scales.xAxes[0].type = 'time'
    sentimentChartLayout.legend.display = false
    sentimentChartLayout.scales.xAxes[0].ticks.display = false

    sentimentChartLayout.tooltips.callbacks = {
      title: function () { }
    }
    // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
    var ctx = $('#pdfTwitterEngagement');
    var chartData = {
      type: 'bar',
      data: BarChartData,
      options: sentimentChartLayout
    }
    pdfTwitterEngagementChart = new Chart(ctx, chartData);
    chartjsGraphsLoaded.push({
      id: "pdfTwitterEngagement",
      data: chartData,
      chartObj: pdfTwitterEngagementChart,
      expandRatio: 1
    })

    pdfTwitterEngagementChart.resize();
  }

  if (document.getElementById('pdFBEngagement') && pdFBEngagement) {

    var urlLabel = pdFBEngagement.map(function (obj) {
      return obj.postTime
    })
    var data = pdFBEngagement.map(function (obj) {
      return obj.engagementCount
    })

    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    Chart.defaults.global.tooltips.bodyFontColor = "#fff";
    Chart.defaults.global.tooltips.titleFontColor = "#fff";
    // Chart.defaults.global.legend.display = true
    var BarChartData = {
      labels: urlLabel,
      datasets: [{
        data: data,
        borderColor: '#00FFFF',
        label: "Engagements",
        backgroundColor: '#00FFFF',
      }]
    }

    var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
    sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Date of Engagement (' + startDate.split(',')[0] + '-' + endDate.split(',')[0] + ')'
    }
    sentimentChartLayout.scales.yAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Count'
    }
    sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 9
    sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
    sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12
    sentimentChartLayout.scales.xAxes[0].ticks.display = false
    // sentimentChartLayout.scales.xAxes[0].type = 'time'
    sentimentChartLayout.legend.display = false
    sentimentChartLayout.tooltips.callbacks = {
      title: function () { }
    }


    // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
    var ctx = $('#pdFBEngagement');
    var chartData = {
      type: 'bar',
      data: BarChartData,
      options: sentimentChartLayout
    }
    pdFBEngagementChart = new Chart(ctx, chartData);
    chartjsGraphsLoaded.push({
      id: "pdFBEngagement",
      data: chartData,
      chartObj: pdFBEngagementChart,
      expandRatio: 1
    })
  }

  if (document.getElementById('pdfInstaEngagement') && pdfInstaEngagement) {

    var urlLabel = pdfInstaEngagement.map(function (obj) {
      return obj.postTime
    })
    var enagagementData = pdfInstaEngagement.map(function (obj) {
      return obj.engagementCount
    })

    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    Chart.defaults.global.tooltips.bodyFontColor = "#fff";
    Chart.defaults.global.tooltips.titleFontColor = "#fff";
    // Chart.defaults.global.legend.display = true
    var BarChartData = {
      labels: urlLabel,
      datasets: [{
        data: enagagementData,
        borderColor: '#00FFFF',
        label: "Engagements",
        backgroundColor: '#00FFFF',
      }]
    }

    var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
    sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Date of Engagement (' + startDate.split(',')[0] + '-' + endDate.split(',')[0] + ')'
    }
    sentimentChartLayout.scales.yAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Count'
    }

    sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 9
    sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
    sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12

    sentimentChartLayout.scales.xAxes[0].ticks.display = false
    // sentimentChartLayout.scales.xAxes[0].type = 'time'
    sentimentChartLayout.legend.display = false

    sentimentChartLayout.tooltips.callbacks = {
      title: function () { }
    }


    // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
    var ctx = $('#pdfInstaEngagement');
    var chartData = {
      type: 'bar',
      data: BarChartData,
      options: sentimentChartLayout
    }
    pdfInstaEngagementChart = new Chart(ctx, chartData);
    chartjsGraphsLoaded.push({
      id: "pdfInstaEngagement",
      data: chartData,
      chartObj: pdfInstaEngagementChart,
      expandRatio: 1
    })
  }


  if (document.getElementById('pdfTiktokEngagement') && pdfTiktokEngagement) {

    var urlLabel = pdfTiktokEngagement.map(function (obj) {
      return obj.postTime
    })
    var enagagementData = pdfTiktokEngagement.map(function (obj) {
      return obj.engagementCount
    })

    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    Chart.defaults.global.tooltips.bodyFontColor = "#fff";
    Chart.defaults.global.tooltips.titleFontColor = "#fff";
    // Chart.defaults.global.legend.display = true
    var BarChartData = {
      labels: urlLabel,
      datasets: [{
        data: enagagementData,
        label: "Engagements",
        borderColor: '#00FFFF',
        backgroundColor: '#00FFFF',
      }]
    }

    var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
    sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Date of Engagement (' + startDate.split(',')[0] + '-' + endDate.split(',')[0] + ')'
    }
    sentimentChartLayout.scales.yAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Count'
    }
    sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 9
    sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
    sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12

    sentimentChartLayout.scales.xAxes[0].ticks.display = false
    // sentimentChartLayout.scales.xAxes[0].type = 'time'
    sentimentChartLayout.legend.display = false

    sentimentChartLayout.tooltips.callbacks = {
      title: function () { }
    }


    // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
    var ctx = $('#pdfTiktokEngagement');
    var chartData = {
      type: 'bar',
      data: BarChartData,
      options: sentimentChartLayout
    }
    pdfTiktokEngagementChart = new Chart(ctx, chartData);
    chartjsGraphsLoaded.push({
      id: "pdfTiktokEngagement",
      data: chartData,
      chartObj: pdfTiktokEngagementChart,
      expandRatio: 1
    })
  }

  if (document.getElementById('pdfLinkedinEngagement') && pdfLinkedinEngagement) {

    var urlLabel = pdfLinkedinEngagement.map(function (obj) {
      return obj.postTime
    })
    var enagagementData = pdfLinkedinEngagement.map(function (obj) {
      return obj.engagementCount
    })

    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    Chart.defaults.global.tooltips.bodyFontColor = "#fff";
    Chart.defaults.global.tooltips.titleFontColor = "#fff";
    // Chart.defaults.global.legend.display = true
    var BarChartData = {
      labels: urlLabel,
      datasets: [{
        data: enagagementData,
        label: "Engagements",
        borderColor: '#00FFFF',
        backgroundColor: '#00FFFF',
      }]
    }

    var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
    sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Date of Engagement (' + startDate.split(',')[0] + '-' + endDate.split(',')[0] + ')'
    }
    sentimentChartLayout.scales.yAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Count'
    }
    sentimentChartLayout.scales.yAxes[0].ticks.fontSize = 9
    sentimentChartLayout.scales.xAxes[0].scaleLabel.fontSize = 12
    sentimentChartLayout.scales.yAxes[0].scaleLabel.fontSize = 12

    sentimentChartLayout.scales.xAxes[0].ticks.display = false
    // sentimentChartLayout.scales.xAxes[0].type = 'time'
    sentimentChartLayout.legend.display = false

    sentimentChartLayout.tooltips.callbacks = {
      title: function () { }
    }


    // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
    var ctx = $('#pdfLinkedinEngagement');
    var chartData = {
      type: 'bar',
      data: BarChartData,
      options: sentimentChartLayout
    }
    pdfLinkedinEngagementChart = new Chart(ctx, chartData);
    chartjsGraphsLoaded.push({
      id: "pdfLinkedinEngagement",
      data: chartData,
      chartObj: pdfLinkedinEngagementChart,
      expandRatio: 1
    })
  }


  if (document.getElementById('brandHashtagbyTimePeriod') && brandHashtagWithTimeGraph) {
    var datasets = [];
    for (var i = 0; i < brandHashtagWithTimeGraph.graph.length; i++) {
      datasets.push({
        borderColor: ultimateColors[i],
        data: brandHashtagWithTimeGraph.graph[i].y,
        label: "#" + brandHashtagWithTimeGraph.graph[i].name,
        backgroundColor: ultimateColors[i],
      })
    }
    var lineChartData1 = {
      labels: brandHashtagWithTimeGraph.graph[0].x,
      datasets: datasets
    }
    var sourceChartLayout = JSON.parse(JSON.stringify(lineChartLayout))
    sourceChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention'
    sourceChartLayout.scales.xAxes[0].type = 'time'
    sourceChartLayout.scales.xAxes[0].time = {
      // unit: 'month',
      displayFormats: {
        quarter: 'MMM D'
      },
      tooltipFormat: "DD-MM-YYYY",
      // round: 'month',
    }
    var ctx = $('#brandHashtagbyTimePeriod');
    var graphData = {
      type: 'line',
      data: lineChartData1,
      options: sourceChartLayout
    }
    // graphData['options']['title'] = {
    //   display: true,
    //   text: 'Performance of Web vs Social Channels',
    //   fontStyle: "normal",
    //   fontWeight: "lighter",
    // }
    brandHashtagbyTimePeriodChart = new Chart(ctx, graphData);
    chartjsGraphsLoaded.push({
      id: "brandHashtagbyTimePeriod",
      data: graphData,
      chartObj: brandHashtagbyTimePeriodChart,
      expandRatio: 1
    })
  }


  if (document.getElementById('mentionsWithTimeGraph') && mentionsWithTimeGraph) {
    var datasets = [];
    for (var i = 0; i < mentionsWithTimeGraph.length; i++) {
      datasets.push({
        borderColor: ultimateColors[i],
        data: mentionsWithTimeGraph[0].y,
        label: mentionsWithTimeGraph[0].name,
        backgroundColor: ultimateColors[i],
      })
    }
    var lineChartData1 = {
      labels: mentionsWithTimeGraph[0].x,
      datasets: datasets
    }
    var sourceChartLayout = JSON.parse(JSON.stringify(lineChartLayout))
    sourceChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention'
    sourceChartLayout['legend']['display'] = false
    if ($(window).width() < 480 && mentionsWithTimeGraph[0].x.length > 20) {
      sourceChartLayout.scales.xAxes[0].ticks.display = false
    }
    else {
      sourceChartLayout.scales.xAxes[0].type = 'time'
      if (mentionsWithTimeGraph[0].x.length == 1) {
        sourceChartLayout.scales.xAxes[0].time = {
          // unit: 'day',
          displayFormats: {
            quarter: 'MMM D'
          },
          tooltipFormat: "DD-MM-YYYY",
          // round: 'day',
        }
      }
      else {
        if ($(window).width() > 480)
          sourceChartLayout.scales.xAxes[0].ticks.source = 'data'
        sourceChartLayout.scales.xAxes[0].time = {
          // unit: 'day',
          minUnit: 'day',
          displayFormats: {
            quarter: 'MMM D'
          },
          tooltipFormat: "DD-MM-YYYY",
          // round: 'day',
        }
      }
    }
    var ctx = $('#mentionsWithTimeGraph');
    var graphData = {
      type: 'line',
      data: lineChartData1,
      options: sourceChartLayout
    }
    // graphData['options']['title'] = {
    //   display: true,
    //   text: 'Performance of Web vs Social Channels',
    //   fontStyle: "normal",
    //   fontWeight: "lighter",
    // }
    mentionsWithTimeGraphChart = new Chart(ctx, graphData);
    chartjsGraphsLoaded.push({
      id: "mentionsWithTimeGraph",
      data: graphData,
      chartObj: mentionsWithTimeGraphChart,
      expandRatio: 1
    })
  }

  changeOfPerformanceOfContent = function (val) {
    if (val === "branddata") {
      return 'Brand Posted Content'
    }
    else if (val === "promoteddata") {
      return 'Brand Promoted UGC'
    }
    else if (val === "audiencedata") {
      return 'Independent UGC'
    }
  }


  if (document.getElementById('ugcRelatedDataGraphTimePeriod') && ugcRelatedDataGraph && Object.keys(ugcRelatedDataGraph).length > 0) {
    var datasets = [];
    var looplength = Object.keys(ugcRelatedDataGraph)
    for (var i = 0; i < looplength.length; i++) {
      datasets.push({
        borderColor: ultimateColors[i],
        data: ugcRelatedDataGraph[looplength[i]].counts,
        label: changeOfPerformanceOfContent(looplength[i]),
        backgroundColor: ultimateColors[i],
      })
    }
    var lineChartData1 = {
      labels: ugcRelatedDataGraph[looplength[0]].dates,
      datasets: datasets
    }
    var sourceChartLayout = JSON.parse(JSON.stringify(lineChartLayout))
    sourceChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention'
    sourceChartLayout.scales.xAxes[0].type = 'time'
    sourceChartLayout.scales.xAxes[0].time = {
      // unit: 'day',
      displayFormats: {
        quarter: 'MMM D'
      },
      tooltipFormat: "DD-MM-YYYY",
      // round: 'day',
    }
    var ctx = $('#ugcRelatedDataGraphTimePeriod');
    var graphData = {
      type: 'line',
      data: lineChartData1,
      options: sourceChartLayout
    }
    // graphData['options']['title'] = {
    //   display: true,
    //   text: 'Performance of Web vs Social Channels',
    //   fontStyle: "normal",
    //   fontWeight: "lighter",
    // }
    ugcRelatedDataGraphTimePeriodChart = new Chart(ctx, graphData);
    chartjsGraphsLoaded.push({
      id: "ugcRelatedDataGraphTimePeriod",
      data: graphData,
      chartObj: ugcRelatedDataGraphTimePeriodChart,
      expandRatio: 1
    })
  }



  if (document.getElementById('sourceDistributionByChannel') && sourcePieChart && sourcePieChart != null) {
    // var sourceNames = 
    var PieChartData = {
      labels: sourcePieChart.labels,
      datasets: [{
        data: sourcePieChart.values,
        backgroundColor: ultimateColors,
        borderColor: ultimateColors,
      }]
    }
    var ctx = $('#sourceDistributionByChannel');
    var sourcebyChannelChartLayout = JSON.parse(JSON.stringify(doughnutChartLayout))
    // locationChartLayout['legend']['display'] = false
    sourcebyChannelChartLayout['legend']['position'] = 'right'

    var graphData = {
      type: 'doughnut',
      data: PieChartData,
      options: sourcebyChannelChartLayout,
    }
    sourceDistributionByChannelGraph = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "sourceDistributionByChannel",
      data: graphData,
      chartObj: sourceDistributionByChannelGraph,
      expandRatio: 2
    })
    if (sourceDistributionByChannelGraph) {
      document.getElementById('sourceDistributionByChannel').onclick = function (evt) {
        var divertUrl = getCurrentUrlParamsLink('sourceType')
        var activePoint = sourceDistributionByChannelGraph.getElementAtEvent(evt)[0];
        var data = activePoint._model;
        var codeSource = data.label
        var divertUrl = divertUrl + '&sourceType=' + changeSourceName(codeSource);
        window.open(divertUrl, '_blank')
        // document.location.href = divertUrl
      };
    }
  }

  if (document.getElementById('sentimentDistributionByChannel') && sentimentPieChart && sentimentPieChart.graph != null) {
    // var sourceNames = 
    var PieChartData = {
      labels: sentimentPieChart.graph.labels,
      datasets: [{
        data: sentimentPieChart.graph.values,
        backgroundColor: ultimateColors,
        borderColor: ultimateColors,
      }]
    }
    var ctx = $('#sentimentDistributionByChannel');
    var sentimentDistributionByChannelLayout = JSON.parse(JSON.stringify(doughnutChartLayout))
    // locationChartLayout['legend']['display'] = false
    sentimentDistributionByChannelLayout['legend']['position'] = 'right'
    var graphData = {
      type: 'doughnut',
      data: PieChartData,
      options: sentimentDistributionByChannelLayout,
    }
    sentimentDistributionByChannelGraph = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "sentimentDistributionByChannel",
      data: graphData,
      chartObj: sentimentDistributionByChannelGraph,
      expandRatio: 1
    })
    if (sentimentDistributionByChannelGraph) {
      document.getElementById('sentimentDistributionByChannel').onclick = function (evt) {
        var divertUrl = getCurrentUrlParamsLink('sentiment')
        var activePoint = sentimentDistributionByChannelGraph.getElementAtEvent(evt)[0];
        var data = activePoint._model;
        var codeSource = data.label
        if (codeSource == "Not Found") {
          codeSource = "Not_Found"
        }
        var divertUrl = divertUrl + '&sentiment=' + codeSource.toUpperCase();
        window.open(divertUrl, '_blank')
        // document.location.href = divertUrl
      };
    }
  }

  if (document.getElementById('contentTypePieChart') && contentTypePieChart && contentTypePieChart.graph != null) {
    // var sourceNames = 
    var PieChartData = {
      labels: contentTypePieChart.graph.labels,
      datasets: [{
        data: contentTypePieChart.graph.values,
        backgroundColor: ultimateColors,
        borderColor: ultimateColors,
      }]
    }
    var ctx = $('#contentTypePieChart');
    var contentTypePieChartLayout = JSON.parse(JSON.stringify(doughnutChartLayout))
    // locationChartLayout['legend']['display'] = false
    contentTypePieChartLayout['legend']['position'] = 'right'
    var graphData = {
      type: 'doughnut',
      data: PieChartData,
      options: contentTypePieChartLayout,
    }
    contentTypePieChartGraph = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "contentTypePieChart",
      data: graphData,
      chartObj: contentTypePieChartGraph,
      expandRatio: 2
    })
    if (contentTypePieChartGraph) {
      document.getElementById('contentTypePieChart').onclick = function (evt) {
        var divertUrl = getCurrentUrlParamsLink('mentionType')
        var activePoint = contentTypePieChartGraph.getElementAtEvent(evt)[0];
        var data = activePoint._model;
        var codeSource = data.label
        var divertUrl = divertUrl + '&mentionType=' + changeMentionType(codeSource);
        window.open(divertUrl, '_blank')
        // document.location.href = divertUrl
      };
    }
  }

  if (document.getElementById('languagePieChart') && languagePieChart && languagePieChart != null) {
    // var sourceNames = 
    var PieChartData = {
      labels: languagePieChart.labels,
      datasets: [{
        data: languagePieChart.values,
        backgroundColor: ultimateColors,
        borderColor: ultimateColors,
      }]
    }
    var ctx = $('#languagePieChart');
    var languagePieChartLayout = JSON.parse(JSON.stringify(doughnutChartLayout))
    // locationChartLayout['legend']['display'] = false
    languagePieChartLayout['legend']['position'] = 'right'
    var graphData = {
      type: 'doughnut',
      data: PieChartData,
      options: languagePieChartLayout,
    }
    languagePieChartGraph = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "languagePieChart",
      data: graphData,
      chartObj: languagePieChartGraph,
      expandRatio: 2
    })
    if (languagePieChartGraph) {
      document.getElementById('languagePieChart').onclick = function (evt) {
        var divertUrl = getCurrentUrlParamsLink('language')
        var activePoint = languagePieChartGraph.getElementAtEvent(evt)[0];
        var data = activePoint._model;
        var codeSource = data.label
        var divertUrl = divertUrl + '&language=' + codeSource;
        window.open(divertUrl, '_blank')
        // document.location.href = divertUrl
      };
    }
  }

  if (document.getElementById('dayOfWeekGraph') && dayOfWeekGraph && dayOfWeekGraph.length > 0) {
    var ctx = document.getElementById("dayOfWeekGraph");
    dayOfWeekGraph = dayOfWeekGraph.sort(function sortByDay(a, b) {
      var day1 = a.key.toLowerCase();
      var day2 = b.key.toLowerCase();
      return sorterFullDay[day1] - sorterFullDay[day2];
    })
    var labels = dayOfWeekGraph.map(function (obj) { if (obj && obj.key) { return obj.key } })
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    var optionsLayout = JSON.parse(JSON.stringify(BarChartLayout))
    optionsLayout.scales.yAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'No. of Posts'
    }
    optionsLayout.scales.xAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Day Of Week'
    }
    var values = dayOfWeekGraph.map(function (obj) { if (obj) { return obj.doc_count } })
    var graphData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          borderColor: '#00b1b4',
          data: values,
          backgroundColor: '#00b1b4',
          label: "Posts",
        }]
      },
      options: optionsLayout,
    }
    graphData['options']['legend']['display'] = false
    dayOfWeekGraphChart = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "dayOfWeekGraph",
      data: graphData,
      chartObj: dayOfWeekGraphChart,
      expandRatio: 1
    })
  }

  if (document.getElementById('hourOfDayGraph') && hourOfDayGraph && hourOfDayGraph.length > 0) {
    var ctx = document.getElementById("hourOfDayGraph");
    var labels = hourOfDayGraph.map(function (obj) { if (obj && obj.key) { return sorterHour[obj.key] } })
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    var optionsLayout = JSON.parse(JSON.stringify(BarChartLayout))
    optionsLayout.scales.yAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'No. of Posts'
    }
    optionsLayout.scales.xAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Hour Of Day'
    }
    var values = hourOfDayGraph.map(function (obj) { if (obj) { return obj.doc_count } })
    var graphData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          borderColor: '#00b1b4',
          data: values,
          backgroundColor: '#00b1b4',
          label: "Posts",
        }]
      },
      options: optionsLayout,
    }
    graphData['options']['legend']['display'] = false
    hourOfDayGraphChart = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "hourOfDayGraph",
      data: graphData,
      chartObj: hourOfDayGraphChart,
      expandRatio: 1
    })
  }

  if (document.getElementById('hourOfDayWithDayOfWeekGraph') && hourOfDayWithDayOfWeekGraph && hourOfDayWithDayOfWeekGraph.length > 0) {
    var ctx = document.getElementById("hourOfDayWithDayOfWeekGraph");
    hourOfDayWithDayOfWeekGraph = hourOfDayWithDayOfWeekGraph.sort(function sortByDay(a, b) {
      var day1 = a.key.split(' ')[0].toLowerCase();
      var day2 = b.key.split(' ')[0].toLowerCase();
      return sorterDay[day1] - sorterDay[day2];
    })
    var labels = hourOfDayWithDayOfWeekGraph.map(function (obj) { if (obj && obj.key) { return obj.key.split(' ')[0] + ' ' + sorterHour[obj.key.split(' ')[1]] } })
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    var optionsLayout = JSON.parse(JSON.stringify(BarChartLayout))
    optionsLayout.scales.yAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'No. of Posts'
    }
    optionsLayout.scales.xAxes[0]['scaleLabel'] = {
      display: true,
      labelString: 'Hour of day with day of week'
    }
    var values = hourOfDayWithDayOfWeekGraph.map(function (obj) { if (obj) { return obj.doc_count } })
    var graphData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          borderColor: '#00b1b4',
          data: values,
          backgroundColor: '#00b1b4',
          label: "Posts",
        }]
      },
      options: optionsLayout,
    }
    graphData['options']['legend']['display'] = false
    hourOfDayWithDayOfWeekGraphChart = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "hourOfDayWithDayOfWeekGraph",
      data: graphData,
      chartObj: hourOfDayWithDayOfWeekGraphChart,
      expandRatio: 1
    })
  }


  if (document.getElementById('contentPersonalities') && contentPersonalities && contentPersonalities != null) {
    var contentPersonality = document.getElementById('contentPersonalities')
    var ctx = $('#contentPersonalities');
    var labels = contentPersonalities.map(function (obj) { return obj.key });
    var counts = contentPersonalities.map(function (obj) { return obj.doc_count })
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    var graphData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          borderColor: '#00cef4',
          data: counts,
          backgroundColor: '#00cef4',
          label: "Mention count",
        }]
      },
      options: JSON.parse(JSON.stringify(BarChartLayout)),
    }
    graphData['options']['legend']['display'] = false
    contentPersonalityBarChart = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "contentPersonalities",
      data: graphData,
      chartObj: contentPersonalityBarChart,
      expandRatio: 1
    })
    contentPersonality.onclick = function hashtagsclick(evt) {
      showLoaderOnClick()
      var activeBarPoints = contentPersonalityBarChart.getElementsAtEvent(evt)[0]["_index"];
      var url = '/enterprise-mention?projectId=' + projectId + '&searchText=' + encodeURI(labels[activeBarPoints]);
      window.open(url, '_blank')
      // document.location.href = url
    };
  }


  if (document.getElementById('contentmentionsOverTimeBySentiment') && mentionsOverTimeBySentiment) {

    var lineChartData = {
      labels: mentionsOverTimeBySentiment.dates,
      datasets: [{
        borderColor: '#c8e400',
        data: mentionsOverTimeBySentiment.positiveMentions,
        label: "Positive Mentions",
        backgroundColor: '#c8e400',
        lineThickness: 0,

      }, {
        data: mentionsOverTimeBySentiment.negativeMentions,
        borderColor: '#f92000',
        label: "Negative Mentions",
        backgroundColor: '#f92000',
      }, {
        data: mentionsOverTimeBySentiment.neutralMentions,
        borderColor: '#00ccf7',
        label: "Neutral Mentions",
        backgroundColor: '#00ccf7',
      }]
    }

    var sentimentChartLayout = JSON.parse(JSON.stringify(lineChartLayout))
    sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + moment(mentionsOverTimeBySentiment.dates[0]).format("Do MMM") + '-' + moment(mentionsOverTimeBySentiment.dates.slice(-1)[0]).format("Do MMM") + ')'
    sentimentChartLayout.scales.xAxes[0].type = 'time'
    sentimentChartLayout.scales.xAxes[0].ticks.source = 'data'
    sentimentChartLayout.scales.xAxes[0].time = {
      // unit: 'day',
      minUnit: 'day',
      displayFormats: {
        quarter: 'MMM D'
      },
      tooltipFormat: "DD-MM-YYYY",
      // round: 'day',
    }
    var ctx = $('#contentmentionsOverTimeBySentiment');
    var graphData = {
      type: 'line',
      data: lineChartData,
      options: sentimentChartLayout,
    }

    LineChartDemo = new Chart(ctx, graphData);
    chartjsGraphsLoaded.push({
      id: "contentmentionsOverTimeBySentiment",
      data: graphData,
      chartObj: LineChartDemo,
      expandRatio: 2
    })
    LineChartDemo.resize();



  }

  if (document.getElementById('contentmentionsOverTimeBySentimentBarGraph') && mentionsOverTimeBySentiment) {

    var urlLabel = mentionsOverTimeBySentiment.dates;
    var labelString = 'Date of Mention (' + moment(mentionsOverTimeBySentiment.dates[0]).format("Do MMM") + '-' + moment(mentionsOverTimeBySentiment.dates.slice(-1)[0]).format("Do MMM") + ')'
    for (var i = 0; i < mentionsOverTimeBySentiment.dates.length; i++)
      urlLabel[i] = new Date(urlLabel[i]).toLocaleString('default', { month: 'short' }) + " " + new Date(urlLabel[i]).getFullYear()
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    Chart.defaults.global.tooltips.bodyFontColor = "#fff";
    Chart.defaults.global.tooltips.titleFontColor = "#fff";
    Chart.defaults.global.legend.display = true
    var BarChartData = {
      labels: urlLabel,
      datasets: [{
        borderColor: '#c8e400',
        data: mentionsOverTimeBySentiment.positiveMentions,
        label: "Postive Mentions",
        backgroundColor: '#c8e400',
        lineThickness: 0,

      }, {
        data: mentionsOverTimeBySentiment.negativeMentions,
        borderColor: '#f92000',
        label: "Negative Mentions",
        backgroundColor: '#f92000',
      }, {
        data: mentionsOverTimeBySentiment.neutralMentions,
        borderColor: '#00ccf7',
        label: "Neutral Mentions",
        backgroundColor: '#00ccf7',
      }]
    }

    var sentimentChartLayout = JSON.parse(JSON.stringify(BarChartLayout))
    sentimentChartLayout.scales.xAxes[0]['scaleLabel'] = {
      labelString: labelString
    }
    // sentimentChartLayout.scales.xAxes[0].type = 'time'
    sentimentChartLayout.scales.xAxes[0].time = {
      // unit: 'month',
      displayFormats: {
        month: 'MMM YYYY'
      },
      // round: 'month',
    }
    sentimentChartLayout.tooltips.callbacks = {
      title: function () { }
    }

    // sentimentChartLayout.scales.xAxes[0].scaleLabel.labelString = 'Date of Mention (' + mentionsobj.dateFrom + '-' + mentionsobj.dateTo + ')'
    var ctx = $('#contentmentionsOverTimeBySentimentBarGraph');
    var chartData = {
      type: 'bar',
      data: BarChartData,
      options: sentimentChartLayout
    }
    contentmentionsOverTimeBySentimentBarGraph = new Chart(ctx, chartData);
    chartjsGraphsLoaded.push({
      id: "contentmentionsOverTimeBySentimentBarGraph",
      data: chartData,
      chartObj: contentmentionsOverTimeBySentimentBarGraph,
      expandRatio: 2
    })
  }


})

function getCurrentUrlParamsLink(selectParam) {
  var paramArray = splitIntoParams(document.location.href)
  paramArray = paramArray.filter(function (val) {
    if (val.param !== selectParam) return true
    return false
  })
  var hrefParams = mergeIntoUrl(paramArray)
  hrefParams = hrefParams.split('?')[1]
  var pts = '/enterprise-mention?' + hrefParams;
  return pts
}

function mergeIntoRedirectUrl(paramArray, redirectPage) {
  var destUrl = document.location.origin + "/" + redirectPage + "?"
  for (i = 0; i < paramArray.length; i++) {
    destUrl += "&" + paramArray[i].param + "=" + paramArray[i].value;
  }
  return destUrl;
}
// var topicDivisionPieChartOnClick
var sourceTopicPieChartOnClick
var sentimentTopicsPieChartsOnClick
var topicWithTimeGraphLineChart;
$(document).ready(function () {
  var modeBarButtons = [
    ["toImage"]
  ];

  var href = document.location.href


  // var layoutline = {
  //   plot_bgcolor: 'white',
  //   paper_bgcolor: 'white',
  //   hovermode: 'closest',
  //   hoveron: 'points',
  //   showlegend: false,
  //   font: {
  //     family: 'Roboto,Helvetica Neue,Arial,sans-serif',
  //     size: 12,
  //     color: "black"
  //   },
  //   // legend: {
  //   //   font: {
  //   //     size: 10,
  //   //     color: '#000'
  //   //   },
  //   //   "orientation": "h",
  //   //   y: -.5,
  //   // },
  //   margin: {
  //     t: 10,
  //     // pad: 4,
  //     r: 10,
  //     b: 50,
  //     l: 50,
  //   },
  //   autosize: true,
  //   xaxis: {
  //     title: 'Date',
  //     tickformat: '%d/%b',
  //     titlefont: {
  //       size: 10,
  //       color: '#000',
  //     },
  //     tickangle: -15,
  //     showticklabels: true,
  //     tickfont: {
  //       size: 10,
  //       color: '#000'
  //     },
  //     fixedrange: true,
  //     gridwidth: false,
  //     showgrid: false,
  //   },
  //   yaxis: {
  //     title: 'Counts',
  //     titlefont: {
  //       size: 10,
  //       color: '#000'
  //     },
  //     showticklabels: true,
  //     tickangle: 0,
  //     tickfont: {
  //       size: 10,
  //       color: '#000'
  //     },
  //     fixedrange: true,
  //     rangemode: 'nonnegative',
  //     autorange: true,
  //     gridwidth: false,
  //     showgrid: false,
  //   },
  //   height: 250,

  // }

  // var pielayout = {
  //   sort: false,
  //   font: {
  //     family: 'Roboto,Helvetica Neue,Arial,sans-serif',
  //     size: 10,
  //     color: "black"
  //   },
  //   showlegend: false,
  //   legend: {
  //     // orientation: 'h',
  //     font: {
  //       size: 10,
  //       family: 'Roboto,Helvetica Neue,Arial,sans-serif',
  //       color: '#000'
  //     }
  //   },
  //   margin: {
  //     b: 60,
  //     t: 60,
  //     l: 20,
  //     r: 20,
  //     pad: 100,
  //   },
  //   autosize: true,
  //   paper_bgcolor: 'rgba(0,0,0,0)',
  //   font: {
  //     size: 10,
  //   },
  //   height: 250,
  //   // width: 300
  // };

  if (document.getElementById('topicDivisionPieChart') && topicDivisionPieChart && topicDivisionPieChart != null && topicDivisionPieChart.length > 0) {
    var topicDivision = document.getElementById('topicDivisionPieChart')
    var ctx = $('#topicDivisionPieChart');
    var labels = topicDivisionPieChart[0].x;
    Chart.defaults.global.tooltips.backgroundColor = 'rgba(0, 0, 0, 1)';
    var graphData = {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          borderColor: '#00b1b4',
          data: topicDivisionPieChart[0].y,
          backgroundColor: '#00b1b4',
        }]
      },
      options: JSON.parse(JSON.stringify(BarChartLayout)),
    }
    graphData['options']['legend']['display'] = false
    topicDivisionBarChart = new Chart(ctx, graphData)
    chartjsGraphsLoaded.push({
      id: "topicDivisionPieChart",
      data: graphData,
      chartObj: topicDivisionBarChart,
      expandRatio: 2
    })
    topicDivision.onclick = function hashtagsclick(evt) {
      var activeBarPoints = topicDivisionBarChart.getElementsAtEvent(evt)[0]["_index"];
      var paramArray = splitIntoParams(document.location.href)
      paramArray = paramArray.filter(function (val) {
        if (val.param !== 'attributeTopic') return true
        return false
      })
      var hrefParams = mergeIntoUrl(paramArray)
      hrefParams = hrefParams.split('?')[1]
      var pts = '/enterprise-mention?' + hrefParams;
      pts = pts + '&attributeTopic=' + encodeURI((labels[activeBarPoints]));
      showLoaderOnClick()
      document.location.href = pts
    };
  }


  // if (document.getElementById('topicDivisionPieChart') && topicDivisionPieChart) {
  //   var layout = JSON.parse(JSON.stringify(rmoBarlayout))
  //   layout['margin'] = {
  //     b: 100,
  //     t: 10,
  //     r: 20,
  //     l: 40
  //   }
  //   layout['font']['size'] = 12
  //   layout['legend']['font']['size'] = 12

  //   topicDivisionPieChartOnClick = function () {
  //     document.getElementById('topicDivisionPieChart').on('plotly_click', function (data) {
  //       var paramArray = splitIntoParams(document.location.href)
  //       paramArray = paramArray.filter(function (val) {
  //         if (val.param !== 'attributeTopic') return true
  //         return false
  //       })
  //       var hrefParams = mergeIntoUrl(paramArray)
  //       hrefParams = hrefParams.split('?')[1]
  //       var pts = '/enterprise-mention?' + hrefParams;
  //       for (var i = 0; i < data.points.length; i++) {
  //         pts = pts + '&attributeTopic=' + encodeURI((data.points[i].x).replace("#", ""))
  //       }
  //       showLoaderOnClick()
  //       document.location.href = pts
  //     });
  //   }
  //   plotlyPlotData.push({
  //     id: 'topicDivisionPieChart',
  //     data: topicDivisionPieChart,
  //     modearButtons: modeBarButtons,
  //     layout: layout,
  //     onClickFunctionName: 'topicDivisionPieChartOnClick'
  //   });
  //   Plotly.newPlot('topicDivisionPieChart', topicDivisionPieChart, layout, {
  //     modeBarButtons: modeBarButtons,
  //     // displayModeBar: false,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });

  //   topicDivisionPieChartOnClick()
  // }

  // if (document.getElementById('positiveTopicPieChart') && positiveTopicPieChart) {
  //   plotlyPlotData.push({
  //     id: 'positiveTopicPieChart',
  //     data: [positiveTopicPieChart],
  //     layout: pielayout,
  //   });
  //   Plotly.newPlot('positiveTopicPieChart', [positiveTopicPieChart], pielayout, {
  //     // modeBarButtons: modeBarButtons,
  //     displayModeBar: false,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });


  //   document.getElementById('positiveTopicPieChart').on('plotly_click', function (data) {
  //     var paramArray = splitIntoParams(href)
  //     paramArray = paramArray.filter(function (val) {
  //       if (val.param !== 'sourceType') return true
  //       return false
  //     })
  //     var hrefParams = mergeIntoUrl(paramArray)
  //     hrefParams = hrefParams.split('?')[1]
  //     var pts = '/enterprise-mention?' + hrefParams;
  //     for (var i = 0; i < data.points.length; i++) {
  //       data.points[i].label.replace("<br>", "")
  //       pts = pts + '&sourceType=' + changeSourceName(encodeURI(data.points[i].label))
  //     }
  //     window.open(pts, '_blank');
  //   });
  // }
  // if (document.getElementById('negativeTopicPieChart') && negativeTopicPieChart) {
  //   plotlyPlotData.push({
  //     id: 'negativeTopicPieChart',
  //     data: [negativeTopicPieChart],
  //     layout: pielayout,
  //   });
  //   Plotly.newPlot('negativeTopicPieChart', [negativeTopicPieChart], pielayout, {
  //     // modeBarButtons: modeBarButtons,
  //     displayModeBar: false,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  // }
  // if (document.getElementById('neutralTopicPieChart') && neutralTopicPieChart) {
  //   plotlyPlotData.push({
  //     id: 'neutralTopicPieChart',
  //     data: [neutralTopicPieChart],
  //     layout: pielayout,
  //   });
  //   Plotly.newPlot('neutralTopicPieChart', [neutralTopicPieChart], pielayout, {
  //     // modeBarButtons: modeBarButtons,
  //     displayModeBar: false,
  //     displaylogo: false,
  //     showTips: false,
  //     responsive: true
  //   });
  // }


  if (typeof (sourceTopicPieChart) != 'undefined' && sourceTopicPieChart && sourceTopicPieChart.length > 0) {
    var elementId = 'sourceTopicPieChart'
    for (var i = 0; i < sourceTopicPieChart.length; i++) {
      elementId = 'sourceTopicPieChart' + i
      var width = 335
      width = ($('#feature-channelwiseDistribution0').width() > 335) ? 335 : $('#feature-channelwiseDistribution0').width()
      var layout = JSON.parse(JSON.stringify(rmoPielayout))
      layout['width'] = width
      layout['showlegend'] = false
      layout['height'] = 250
      layout['margin'] = {
        t: 60,
        b: 60,
        pad: 10
      }
      if (document.getElementById(elementId) && sourceTopicPieChart[i].graph) {
        sourceTopicPieChartOnClick = function () {
          console.log("setting onclick source")
          document.getElementById(elementId).on('plotly_click', function (data) {
            var paramArray = splitIntoParams(document.location.href)
            paramArray = paramArray.filter(function (val) {
              if (val.param !== 'sourceType') return true
              return false
            })
            var hrefParams = mergeIntoUrl(paramArray)
            hrefParams = hrefParams.split('?')[1]
            var pts = '/enterprise-mention?' + hrefParams;
            for (var i = 0; i < data.points.length; i++) {
              data.points[i].label.replace("<br>", "")
              pts = pts + '&attributeTopic=' + encodeURI((data.points[i].data.extraData)) + '&sourceType=' + changeSourceName(encodeURI(data.points[i].label))
            }
            // window.open(pts);
            showLoaderOnClick()
            document.location.href = pts
          })
        }
        plotlyPlotData.push({
          id: elementId,
          data: [sourceTopicPieChart[i].graph],
          layout: layout,
          onClickFunctionName: 'sourceTopicPieChartOnClick'
        });
        Plotly.newPlot(elementId, [sourceTopicPieChart[i].graph], layout, {
          displayModeBar: false,
          displaylogo: false,
          showTips: false,
          responsive: true
        });
        sourceTopicPieChartOnClick();
      }
    }
  }
  if (typeof (sentimentTopicsPieCharts) != 'undefined' && sentimentTopicsPieCharts && sentimentTopicsPieCharts.length > 0) {
    // sentimentTopicsPieCharts = sentimentTopicsPieCharts.map(function(obj){ return sentimentTopicsPieCharts = sentimentTopicsPieCharts.extraData = })
    var elementId = 'sentimentTopicsPieCharts'
    for (var i = 0; i < sentimentTopicsPieCharts.length; i++) {
      elementId = 'sentimentTopicsPieCharts' + i
      var width = 335
      width = ($('#feature-channelwiseDistribution0').width() > 335) ? 335 : $('#feature-channelwiseDistribution0').width()
      var layout = JSON.parse(JSON.stringify(rmoPielayout))
      layout['width'] = width
      layout['showlegend'] = false
      layout['height'] = 250
      layout['margin'] = {
        t: 60,
        b: 60,
        pad: 10
      }
      if (document.getElementById(elementId) && sentimentTopicsPieCharts[i]) {
        console.log("setting onclick sentiment")
        sentimentTopicsPieChartsOnClick = function () {
          document.getElementById(elementId).on('plotly_click', function (data) {
            var paramArray = splitIntoParams(document.location.href)
            paramArray = paramArray.filter(function (val) {
              if (val.param !== 'attributesentiment') return true
              return false
            })
            var hrefParams = mergeIntoUrl(paramArray)
            hrefParams = hrefParams.split('?')[1]
            var pts = '/enterprise-mention?' + hrefParams;
            for (var i = 0; i < data.points.length; i++) {
              pts = pts + '&attributeTopic=' + encodeURI((data.points[i].data.extraData)) + '&attributesentiment=' + encodeURI((data.points[i].label.toUpperCase()))
            }
            // window.open(pts);
            showLoaderOnClick()
            document.location.href = pts
          });
        }
        plotlyPlotData.push({
          id: elementId,
          data: [sentimentTopicsPieCharts[i]],
          layout: layout,
          onClickFunctionName: 'sentimentTopicsPieChartsOnClick'
        });
        Plotly.newPlot(elementId, [sentimentTopicsPieCharts[i]], layout, {
          displayModeBar: false,
          displaylogo: false,
          showTips: false,
          responsive: true
        });
        sentimentTopicsPieChartsOnClick()
      }
    }
  }

  // if (typeof (subTopicDivisionPieChart) != 'undefined' && subTopicDivisionPieChart && subTopicDivisionPieChart.length > 0) {
  //   var elementId = 'subTopicDivisionPieChart'
  //   for (var i = 0; i < subTopicDivisionPieChart.length; i++) {
  //     elementId = 'subTopicDivisionPieChart' + i
  //     if (document.getElementById(elementId) && subTopicDivisionPieChart[i].graph) {

  //       plotlyPlotData.push({
  //         id: elementId,
  //         data: [subTopicDivisionPieChart[i].graph],
  //         layout: rmoPielayout,
  //       });
  //       Plotly.newPlot(elementId, [subTopicDivisionPieChart[i].graph], rmoPielayout, {
  //         displayModeBar: false,
  //         displaylogo: false,
  //         showTips: false,
  //         responsive: true
  //       });
  //     }
  //   }
  // }

  // if (typeof (topicWithTimeGraph) != 'undefined' && topicWithTimeGraph && topicWithTimeGraph.length > 0) {
  //   var elementId = 'topicWithTimeGraph'
  //   for (var i = 0; i < topicWithTimeGraph.length; i++) {
  //     elementId = 'topicWithTimeGraph' + i
  //     if (document.getElementById(elementId) && topicWithTimeGraph[i].graph) {
  //       plotlyPlotData.push({
  //         id: elementId,
  //         data: topicWithTimeGraph[i].graph,
  //         layout: layoutline,
  //       });
  //       Plotly.newPlot(elementId, topicWithTimeGraph[i].graph, layoutline, {
  //         displayModeBar: false,
  //         displaylogo: false,
  //         showTips: false,
  //         responsive: true
  //       });
  //     }
  //   }
  // }


  var ultimateColors = [
    'rgb(176, 252, 204)', 'rgb(0,206,244)', 'rgb(200,228,0)', 'rgb(255,155,0)', 'rgb(175,157,200)', 'rgb(237,69,21)', 'rgb(45,107,182)', 'rgb(242,255,0)', 'rgb(218,181,69)', 'rgb(255,128,114)', 'rgb(241,0,82)', 'rgb(241,201,122)', 'rgb(127,103,191)', 'rgb(203,72,195)', 'rgb(235,247,249)', 'rgb(230,207,88)', 'rgb(248,0,118)', 'rgb(169,193,205)'
  ];

  if (document.getElementById('topicWithTimeGraph') && topicWithTimeGraph) {
    var graphDataSet = topicWithTimeGraph.graph.map(function (obj) {
      return { label: obj.name, data: obj.y }
    })
    var lineChartData = {
      labels: topicWithTimeGraph.graph[0].x,
      datasets: []
    }
    graphDataSet.map(function (obj, index) {
      lineChartData.datasets.push({
        borderColor: ultimateColors[index],
        data: obj.data,
        label: obj.label,
        backgroundColor: ultimateColors[index],
        lineThickness: 0,
      })
    })

    var topicWithTimeGraphLayout = JSON.parse(JSON.stringify(lineChartLayout))
    topicWithTimeGraphLayout.scales.xAxes[0].type = 'time'
    topicWithTimeGraphLayout.scales.xAxes[0].time = {
      minUnit: 'day',
      displayFormats: {
        day: 'MMM D'
      },
      tooltipFormat: "DD-MM-YYYY",
    }
    var ctx = $('#topicWithTimeGraph');
    var graphData = {
      type: 'line',
      data: lineChartData,
      options: topicWithTimeGraphLayout,
    }
    topicWithTimeGraphLineChart = new Chart(ctx, graphData);
    chartjsGraphsLoaded.push({
      id: "topicWithTimeGraph",
      data: graphData,
      chartObj: topicWithTimeGraphLineChart,
      expandRatio: 2
    })
    topicWithTimeGraphLineChart.resize();


    // console.log("topicWithTimeGraph")
    // var manualWidthSet = ''
    // if (topicWithTimeGraph && topicWithTimeGraph.length > 0 && topicWithTimeGraph[0].x && topicWithTimeGraph[0].x.length > 0) {
    //   manualWidthSet = setContentBarManualWidth(topicWithTimeGraph[0].x.length)
    // }
    // var lineLayout = JSON.parse(JSON.stringify(rmoLineLayout))
    // lineLayout['legend']['y'] = 'unset'
    // lineLayout['font']['size'] = 12
    // lineLayout['legend']['font']['size'] = 12
    // lineLayout['xaxis']['titlefont']['size'] = 12
    // lineLayout['xaxis']['tickfont']['size'] = 12
    // lineLayout['yaxis']['titlefont']['size'] = 12
    // lineLayout['yaxis']['tickfont']['size'] = 12
    // topicWithTimeGraph.graph.map(function (obj) {
    //   obj['line']['shape'] = 'spline'
    //   return obj
    // })
    // plotlyPlotData.push({
    //   id: 'topicWithTimeGraph',
    //   data: topicWithTimeGraph.graph,
    //   layout: lineLayout,
    //   modeBarButtons: modeBarButtons,
    // });

    // Plotly.newPlot('topicWithTimeGraph', topicWithTimeGraph.graph, lineLayout, {
    //   // displayModeBar: false,
    //   modeBarButtons: modeBarButtons,
    //   displaylogo: false,
    //   showTips: false,
    //   responsive: true
    // });

  }

  // var barGraphlayout = {
  //   xaxis: {
  //     tickangle: -45,
  //     fixedrange: true,
  //     rangemode: 'nonnegative',
  //   },
  //   yaxis: {
  //     fixedrange: true,
  //     rangemode: 'nonnegative',
  //   },
  //   legend: {
  //     orientation: 'h',
  //     font: {
  //       size: 10,
  //       family: 'Roboto,Helvetica Neue,Arial,sans-serif',
  //       color: '#000'
  //     },
  //     // y: -.3,
  //   },
  //   font: {
  //     family: 'Roboto,Helvetica Neue,Arial,sans-serif',
  //     size: 10,
  //     color: "black"
  //   },
  //   margin: {
  //     b: 100,
  //     t: 10,
  //     r: 40,
  //     l: 60,
  //   },
  //   height: 400,
  // };

  // if (typeof (sentimentSubTopicsStackedBarGraph) != 'undefined' && sentimentSubTopicsStackedBarGraph && sentimentSubTopicsStackedBarGraph.length > 0) {
  //   var elementId = 'sentimentSubTopicsStackedBarGraph'
  //   for (var i = 0; i < sentimentSubTopicsStackedBarGraph.length; i++) {
  //     elementId = 'sentimentSubTopicsStackedBarGraph' + i
  //     if (document.getElementById(elementId) && sentimentSubTopicsStackedBarGraph[i].graph) {
  //       var manualWidthSet = ''
  //       if (sentimentSubTopicsStackedBarGraph[i].graph && sentimentSubTopicsStackedBarGraph[i].graph.length > 0 && sentimentSubTopicsStackedBarGraph[i].graph[0].x && sentimentSubTopicsStackedBarGraph[i].graph[0].x.length > 0) {
  //         manualWidthSet = setContentBarManualWidth(sentimentSubTopicsStackedBarGraph[i].graph[0].x.length)
  //       }

  //       var layout = {
  //         barmode: 'stack',
  //         xaxis: {
  //           tickangle: -45,
  //           fixedrange: true,
  //         },
  //         yaxis: {
  //           fixedrange: true,
  //         },
  //         font: {
  //           family: 'Roboto,Helvetica Neue,Arial,sans-serif',
  //           size: 10,
  //           color: "black"
  //         },
  //         legend: {
  //           orientation: 'h',
  //           font: {
  //             size: 10,
  //             family: 'Roboto,Helvetica Neue,Arial,sans-serif',
  //             color: '#000'
  //           },
  //           y: -.3,
  //         },
  //         margin: {
  //           b: 100,
  //           t: 10,
  //           r: 40,
  //           l: 60,
  //         },
  //         height: 300,
  //         width: manualWidthSet
  //       };

  //       Plotly.newPlot(elementId, sentimentSubTopicsStackedBarGraph[i].graph, layout, {
  //         displayModeBar: false,
  //         displaylogo: false,
  //         showTips: false,
  //         responsive: true
  //       });
  //     }
  //   }
  // }

})


